/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable object-shorthand */

import { Injectable } from '@angular/core';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

import { Platform } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { getStorage, ref, getDownloadURL, deleteObject } from '@firebase/storage';
import { DisclosureDoc, Guest, Listing, QuestionsAnswers, User_global } from '../app.models';
import { UtilsService } from './utils.service';
import { CommonProvider } from './common';
import { AuthService } from './auth.service';

export interface DisclosureDocData {
	listings: Listing;
	guest: Guest;
	questionsAnswers: QuestionsAnswers[];
	createdAt: string;
}

@Injectable({
	providedIn: 'root'
})


export class PdfEditorService {

	discloseDocs: any = {};
	pdfDirectory = 'pdfs';

	constructor(
		private platform: Platform,
		private file: File,
		private transfer: FileTransfer,
		private utils: UtilsService,
		private common: CommonProvider,
		private auth: AuthService
	) {


	}

	async fetchAndModifyPdf(url: string, fields: Array<any>): Promise<Uint8Array | null> {
		console.log('fetchAndModifyPdf ' + url);
		const fileName = url.split('/').pop();
		let existingPdfBytes;


		const baseDir = this.platform.is('android') ? this.file.dataDirectory : this.file.dataDirectory;

		console.log('base dir is: ' + baseDir);
		if (!this.discloseDocs[url]) {
			if (this.platform.is('cordova')) {
				try {
					console.log('fetchAndModifyPdf checkDir' + this.pdfDirectory);
					await this.file.checkDir(baseDir, this.pdfDirectory);
				} catch (err) {
					console.log('dir does not exist: ' + JSON.stringify(err));
					await this.file.createDir(baseDir, this.pdfDirectory, false);
				}

				try {
					console.log('trying to disclosure load file: ' + fileName);
					await this.file.checkFile(baseDir, `${this.pdfDirectory}/${fileName}`);
					const arrayBuffer = await this.file.readAsArrayBuffer(baseDir, `${this.pdfDirectory}/${fileName}`);
					//	existingPdfBytes = new Uint8Array(arrayBuffer);
					existingPdfBytes = arrayBuffer;
					this.discloseDocs[url] = existingPdfBytes;
					return this.drawPdf(existingPdfBytes, fields);
				} catch (err) {
					if (err.code === 1) { // NOT_FOUND_ERR
						console.log('File not found locally, fetching from remote storage');
						try {

							const storage = getStorage();
							const storageRef = ref(storage, `${url}`);
							const url1 = await getDownloadURL(storageRef);

							console.log('Fetch URL:', url1);
							const arrayBuffer = await this.downloadFile(url1, `${baseDir}${this.pdfDirectory}/${fileName}`);
							//existingPdfBytes = new Uint8Array(arrayBuffer);
							existingPdfBytes = arrayBuffer;
							this.discloseDocs[url] = existingPdfBytes;

							console.log('trying to write file to :' + baseDir + this.pdfDirectory + '/' + fileName);
							await this.file.writeFile(baseDir, `${this.pdfDirectory}/${fileName}`, arrayBuffer, { replace: true });

							return this.drawPdf(existingPdfBytes, fields);
						} catch (fetchErr) {
							console.error('Error fetching PDF from remote:', JSON.stringify(fetchErr));
							return null;
						}
					} else {
						console.error('Error getting PDF: ', err);
						return null;
					}
				}
			} else {
				try {

					const storage = getStorage();
					const storageRef = ref(storage, `${url}`);
					const url1 = await getDownloadURL(storageRef);

					const arrayBuffer = await fetch(url1).then(res => res.arrayBuffer());
					existingPdfBytes = arrayBuffer;
					this.discloseDocs[url] = existingPdfBytes;

					return this.drawPdf(existingPdfBytes, fields);
				} catch (fetchErr) {
					console.error('Error fetching PDF from remote 2:', JSON.stringify(fetchErr));
					return null;
				}
			}


		} else {
			existingPdfBytes = this.discloseDocs[url];
		}

		if (!fields || fields.length === 0) {
			console.error('Fields array is empty or not provided');
			return null;
		}

		return this.drawPdf(existingPdfBytes, fields);
	}

	/**
	 * Draws text fields on a PDF document.
	 *
	 * @param existingPdfBytes - The bytes of the existing PDF document.
	 * @param fields - An array of field objects containing value, x, and y coordinates.
	 * @returns - The bytes of the modified PDF document.
	 */
	async drawPdf(existingPdfBytes: Uint8Array, fields: Array<any>): Promise<Uint8Array> {

		if (!fields || !existingPdfBytes) {
			return;
		}

		try {

			const pdfDoc = await PDFDocument.load(existingPdfBytes);

			const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

			const pages = pdfDoc.getPages();
			const firstPage = pages[0];
			const { width, height } = firstPage.getSize();

			// Iterate over the fields array and draw each field on the PDF

			fields.forEach(item => {
				firstPage.drawText(item.value, {
					x: item.x,
					y: height - item.y, // PDF-lib uses bottom-left corner as origin
					size: 10,
					color: rgb(0, 0, 0),
					font: font
				});
			});

			const pdfBytes = await pdfDoc.save();
			return pdfBytes;
		} catch (error) {
			console.error('Error drawing on PDF:', error);
			throw error;
		}
	}


	async downloadFile(url: string, filePath: string): Promise<ArrayBuffer> {
		return new Promise((resolve, reject) => {
			const fileTransfer: FileTransferObject = this.transfer.create();
			console.log('Disclosure Download URL:', url);
			console.log('Disclosure File path:', filePath);

			fileTransfer.download(url, filePath)
				.then((entry) => {
					this.file.readAsArrayBuffer(this.file.dataDirectory, filePath)
						.then(arrayBuffer => resolve(arrayBuffer))
						.catch(err => reject(err));
				})
				.catch((error) => {
					console.error('Download error source:', error.source);
					console.error('Download error target:', error.target);
					console.error('Download error code:', error.code);
					reject(error);
				});
		});
	}

	getPopulateeDisclosureFields(baseDisclosureDoc: DisclosureDoc, data: DisclosureDocData) {
		try {
			const { listings, guest, questionsAnswers, createdAt } = data;

			if (!baseDisclosureDoc || !baseDisclosureDoc.fields) {
				throw new Error('Invalid baseDisclosureDoc provided.');
			}

			if (!listings || !guest || !questionsAnswers || !createdAt) {
				throw new Error('Incomplete data provided.');
			}

			let buyersAgentQ = questionsAnswers.find(q => q.divId === 'customInput_1');

			if (!buyersAgentQ) {
				buyersAgentQ = questionsAnswers.find(q => q.question === 'Who is your real estate agent?');
			}

			if (!buyersAgentQ) {
				throw new Error('Buyers agent question not found.');
			}

			const selectedAnswer = buyersAgentQ.multipleChoiceOptions?.find(o => o.isSelected);
			let buyersAgentAnswer = '';
			if (selectedAnswer?.details?.inputValue) {
				buyersAgentAnswer = selectedAnswer.value + ': ' + selectedAnswer.details.inputValue;
			} else if (selectedAnswer) {
				buyersAgentAnswer = selectedAnswer.value;
			}

			// Copy the array so that we don't mutate the original object
			const fields = [...baseDisclosureDoc.fields];

			fields.forEach(item => {
				if (!item.field) {
					console.warn('Field item missing "field" property.');
					return;
				}

				switch (item.field) {
					case 'address':
						item.value = listings.address?.title || 'Unknown Address';
						break;
					case 'fullName':
						item.value = String(guest.fullName || '').replace(/[^\x00-\x7F]/g, '');
						break;
					case 'phoneNumber':
						if (guest.phoneNumber && guest.phoneCountryCode) {
							item.value = this.common.formatPhone(guest.phoneNumber, guest.phoneCountryCode);
						} else {
							console.warn('Phone number or country code missing.');
							item.value = 'Unknown Phone Number';
						}
						break;
					case 'agentName':
						item.value = User_global.username || 'Unknown Agent';
						break;
					case 'date':
						item.value = createdAt || 'Unknown Date';
						break;
					case 'brokerOfRecord':
						item.value = User_global.brokerOfRecord || 'Unknown Broker';
						break;
					case 'buyersAgent':
						item.value = buyersAgentAnswer;
						break;
					default:
						item.value = guest[item.field] || `Unknown ${item.field}`;
						break;
				}
			});

			return fields;
		} catch (error) {
			console.error('Error in getPopulateeDisclosureFields:', error.message);
			return [];
		}
	}


	async downloadFulfilledDisclosurePDF(data: DisclosureDocData, disclose?: DisclosureDoc) {
		try {
			const discloseDoc = disclose || await this.auth.getDisclosures();

			if (!discloseDoc.active) {
				console.error('Disclosure is not active!');
				return;
			}

			const populatedFields = this.getPopulateeDisclosureFields(discloseDoc, data);

			const guestName = populatedFields.find(f => f.field === 'fullName')?.value;
			if (!guestName) {
				throw new Error('Guest name not found in populated fields.');
			}

			const fileName = `Disclosure_${guestName.replace(' ', '_')}.pdf`;

			const pdfBytes = await this.fetchAndModifyPdf(discloseDoc.url, populatedFields);

			if (!pdfBytes) {
				throw new Error('No PDF bytes were generated.');
			}

			this.utils.downloadFile(pdfBytes, 'application/pdf', fileName);
		} catch (error) {
			console.error('Error in downloadFulfilledDisclosurePDF:', error.message);
			// Optionally, you could also show an error message to the user or perform other error handling actions here.
		}
	}

}
