/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { User_global } from 'src/app/app.models';
import { UserColors } from 'src/app/constants';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-rollout-integration',
	templateUrl: './rollout-integration.component.html',
	styleUrls: ['./rollout-integration.component.scss'],
	encapsulation: ViewEncapsulation.Emulated
})
export class RolloutIntegrationComponent implements OnInit {

	constructor(private auth: AuthService) { }

	async ngOnInit() {
		console.log('rollout comp loaded.');
		const container = document.querySelector('#rollout-container');
		//	const token = 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI1REc3em5XeDJqLUZub3lseklFV2NZSU5UTE94NkRpbCIsInN1YiI6InJvbGxvdXQiLCJpYXQiOjE2ODE0MTIwMjYsImV4cCI6MTY5MTQxMjAyNiwicm9sbG91dGhxLmNvbSI6eyJkZXYiOnRydWV9fQ.nbpQil-E6Fcugd7ylZt3dW0vKzvl6c6OYD1ni43pw52wnCGeq38eyKytQjYhojLqPsTOEN7T5rOhNhyClHK7OQ';


		const token = await this.auth.getRolloutToken();
		const Rollout = (window as any).Rollout;
		//	Rollout.defaultTheme();

		console.log('trying to use rollout token ', token);
		const ro = Rollout.init({ token, apiBaseUrl: environment.rollout_base_url });
		const APP_KEY = environment.rollout_app;

		const options = await this.auth.getRolloutOptions();
		console.log('my token ' + token);

		const prefilledSalesforceInputParams = {
			addNewContact: {
				description: '{{Notes}}\nMortgage Info wanted: {{Question_Mortgage_Info_Wanted}}\nAgent name: {{agentName}}\nAgent company: {{agentCompany}}\nListing sale status: {{listingSaleStatus}}\n Microsite: {{listingMicrositeUrl}}',
				firstName: '{{firstName}}',
				lastName: '{{lastName}}',
				phone: '{{phoneNumber}}',
				email: '{{email}}',
			},
			addNewLead: {
				firstName: '{{firstName}}',
				lastName: '{{lastName}}',
				phone: '{{phoneNumber}}',
				email: '{{email}}',
				company: '{{agentCompany}}',
			},
		};
		const trigger = {
			appKey: APP_KEY,
			triggerKey: 'new-lead-trigger',
		};
		const renderFields = {
			trigger: false,
			action: {
				appKey: ({ ActionAppKeyField }) => { },
				// Rollout.createElement(ActionAppKeyField, { disabled: true }),
				actionKey: ({ ActionKeyField }) => { },
				// Rollout.createElement(ActionKeyField, { disabled: true }),
			},
		};

		const salesforce = {
			blueprintKey: 'syncToSalesForce',
			name: 'Salesforce',
			prefilled: {
				trigger,
				//	trigger: { appKey: 'curb_hero-dev', triggerKey: 'new_lead' },
				action: {
					appKey: 'salesforce',
				},
			},
			renderFields: {
				trigger: false,
				action: ({
					ActionCredentialKeyField,
					ActionInputFields,
					ActionKeyField,
					Card,
					createElement,
				}) => createElement(
					Card,
					null,
					'Select account and provide lead details',
					createElement(ActionKeyField),
					createElement(ActionCredentialKeyField),
					ActionInputFields && createElement(ActionInputFields)
				),
			},
		};


		const followup = {
			blueprintKey: 'syncToFollowUpBoss',
			name: 'Follow Up Boss',
			renderFields: {
				trigger: false,
				action: ({
					ActionCredentialKeyField,
					ActionInputFields,
					Card,
					createElement,
				}) => createElement(
					Card,
					null,
					'Select account and provide contact details',
					createElement(ActionCredentialKeyField),
					ActionInputFields && createElement(ActionInputFields)
				),
			},
			prefilled: {
				trigger,
				action: {
					appKey: 'followup-boss', actionKey: 'create-contact', inputParams: {
						emailAddress: '{{email}}',
						firstName: '{{firstName}}',
						lastName: '{{lastName}}',
						phoneNumber: '{{phoneNumber}}',
						agentEmail: '{{agentEmail}}',

						// listing
						streetAddress: '{{listingAddress}}',
						city: '{{listingCity}}',
						stateProvince: '{{listingRegion}}',
						zipPostalCode: '{{listingPostalCode}}',

						price: '{{listingPrice}}',
						bedrooms: '{{listingBeds}}',
						bathrooms: '{{listingBaths}}',

						type: 'Property Inquiry',
						source: 'Open House'
					},
				},
			},
		};

		const realgeeks = {
			blueprintKey: 'syncToRealGeeks',
			name: 'RealGeeks',
			icon: '', // Can I define icon here?
			renderFields: {
				trigger: false,
				action: ({
					ActionCredentialKeyField,
					ActionInputFields,
					// ActionKeyField,
					Card,
					createElement,
				}) => createElement(
					Card,
					null,
					'Configure account and integration details',
					createElement(ActionCredentialKeyField),
					// createElement(ActionKeyField, { disabled: true }),

					ActionInputFields && createElement(ActionInputFields)
				),
			},
			prefilled: {
				trigger,
				action: {
					appKey: 'realgeeks', actionKey: 'create-lead', inputParams: {


						email: '{{email}}',
						first_name: '{{firstName}}',
						last_name: '{{lastName}}',
						phone: '{{phoneNumber}}',
						notes: '{{notes}}',
						source: 'Open House',
						agentEmail: '{{agentEmail}}',
						// listing
						street_address: '{{listingAddress}}',
						city: '{{listingCity}}',
						state: '{{listingRegion}}',
						zip: '{{listingPostalCode}}',
						listing_price: '{{listingPrice}}',
						beds: '{{listingBeds}}',
						full_baths: '{{listingBaths}}'

					},
				},
			},
		};

		const cloze = {
			blueprintKey: 'syncToCloze',
			name: 'Cloze',
			icon: '', // Can I define icon here?
			renderFields: {
				trigger: false,
				action: ({
					ActionCredentialKeyField,
					ActionInputFields,
					// ActionKeyField,
					Card,
					createElement,
				}) => createElement(
					Card,
					null,
					'Configure account and integration details',
					createElement(ActionCredentialKeyField),
					// createElement(ActionKeyField, { disabled: true }),

					ActionInputFields && createElement(ActionInputFields)
				),
			},
			prefilled: {
				trigger,
				action: {
					appKey: 'cloze', actionKey: 'create-contact', inputParams: {


						email: '{{email}}',
						first_name: '{{firstName}}',
						last_name: '{{lastName}}',
						phone: '{{phoneNumber}}',
						// notes: '{{notes}}',
						source: 'Open House',
						agentEmail: '{{agentEmail}}',
						// listing
						street_address: '{{listingAddress}}',
						city: '{{listingCity}}',
						state: '{{listingRegion}}',
						zip: '{{listingPostalCode}}',
						listing_price: '{{listingPrice}}',
						beds: '{{listingBeds}}',
						full_baths: '{{listingBaths}}'

					},
				},
			},
		};

		const blueprints = [];

		if (options.syncToFollowUpBoss) {
			blueprints.push(followup);
		}
		if (options.syncToSalesForce) {
			blueprints.push(salesforce);
		}

		if (options.syncToRealGeeks) {
			blueprints.push(realgeeks);
		}

		if (options.syncToCloze) {
			blueprints.push(cloze);
		}

		// if (User_global.role === 'agentAdmin') {
		// 	blueprints.push(followupassignment);
		// 	blueprints.push(followupsyncnotes);
		// }

		ro.renderBlueprintsManager(container, { blueprints });
	}
}
