import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    Rollout: any;
  }
}

@Component({
  selector: 'app-rollout-integration',
  templateUrl: './rollout-integration.component.html',
  styleUrls: ['./rollout-integration.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class RolloutIntegrationComponent implements OnInit {
  constructor(private auth: AuthService) {}

  async ngOnInit() {
    console.log('rollout comp loaded.');
    const container = document.querySelector('#rollout-container');

    const [token, options] = await Promise.all([
      this.auth.getRolloutToken(),
      this.auth.getRolloutOptions(),
    ]);

    const Rollout = window.Rollout;

    console.log('trying to use rollout token ', token);

    const ro = Rollout.init({
      token,
      apiBaseUrl: environment.rollout_base_url,
    });

    console.log('my token ' + token);

    const trigger = {
      appKey: environment.rollout_app,
      triggerKey: 'new-lead-trigger',
    };

    const blueprints = [];

    if (options.syncToFollowUpBoss) {
      blueprints.push(getFollowUpBossIntegration(trigger));
    }
    if (options.syncToSalesForce) {
      blueprints.push(getSalesForceIntegration(trigger));
    }
    if (options.syncToRealGeeks) {
      blueprints.push(getRealGeeksIntegration(trigger));
    }
    if (options.syncToCloze) {
      blueprints.push(getClozeIntegration(trigger));
    }
    if (options.syncToSierra) {
      blueprints.push(getSierraIntegration(trigger));
    }
    if (options.syncToHubspot) {
      blueprints.push(getHubspotIntegration(trigger));
    }   
    ro.renderBlueprintsManager(container, { blueprints });
  }
}

// This is the payload we send to Zapier / Rollout, both on lead creation and update. Keep in sync with the backend interface of the same name
export interface LeadEventPayload extends DisclosureWebhookPayload {
  listingId: string;
  listingAddress: string;
  listingPostalCode: string;
  listingRegion: string;
  listingCity: string;
  listingBeds: string;
  listingBaths: string;
  listingPrice: string;
  id: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  Question_Mortgage_Info_Wanted: string;
  Additional_Question_1: string;
  Additional_Answer_1: string;
  Additional_Question_2: string;
  Additional_Answer_2: string;
  Additional_Question_3: string;
  Additional_Answer_3: string;
  Notes: string;
  createdDay: string;
  createdTime: string;
  createdUnixTimestamp: string;
  firstName: string;
  lastName: string;
  source: string;
  verified: string;
  agentName: string;
  agentCompany: string;
  agentEmail: string;
  listingSaleStatus: string;
  listingMicrositeUrl: string;
  ageRange: string;
  avatar: string;
  bio: string;
  linkedin: string;
  location: string;
  organization: string;
  title: string;
  twitter: string;
}

export interface DisclosureWebhookPayload {
  disclosureName: string;
  disclosureAccepted: boolean;
  disclosureResponseLabel: string;
  disclosureDownloadURL: string;
}

interface RolloutBlueprint {
  blueprintKey: string;
  name: string;
  icon?: string;
  prefilled: {
    trigger: any;
    action: {
      appKey: string;
      actionKey?: string;
      inputParams?: Record<string, string>;
    };
  };
  renderFields: {
    trigger: boolean;
    action({
      ActionCredentialKeyField,
      ActionInputFields,
      ActionKeyField,
      Card,
      createElement,
    }): any;
  };
}

function getSalesForceIntegration(trigger): RolloutBlueprint {
  return {
    blueprintKey: 'syncToSalesForce',
    name: 'Salesforce',
    prefilled: {
      trigger,
      action: {
        appKey: 'salesforce',
      },
    },
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        ActionKeyField,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Select account and provide lead details',
          createElement(ActionKeyField),
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
  };
}

function getFollowUpBossIntegration(trigger): RolloutBlueprint {
  return {
    blueprintKey: 'syncToFollowUpBoss',
    name: 'Follow Up Boss',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Select account and provide contact details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'followup-boss',
        actionKey: 'create-contact',
        inputParams: {
          emailAddress: '{{email}}',
          firstName: '{{firstName}}',
          lastName: '{{lastName}}',
          phoneNumber: '{{phoneNumber}}',
          agentEmail: '{{agentEmail}}',
          // listing
          streetAddress: '{{listingAddress}}',
          city: '{{listingCity}}',
          stateProvince: '{{listingRegion}}',
          zipPostalCode: '{{listingPostalCode}}',

          price: '{{listingPrice}}',
          bedrooms: '{{listingBeds}}',
          bathrooms: '{{listingBaths}}',

          type: 'Property Inquiry',
          source: 'Open House',
        },
      },
    },
  };
}

function getRealGeeksIntegration(trigger): RolloutBlueprint {
  return {
    blueprintKey: 'syncToRealGeeks',
    name: 'RealGeeks',
    icon: '',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'realgeeks',
        actionKey: 'create-lead',
        inputParams: {
          email: '{{email}}',
          first_name: '{{firstName}}',
          last_name: '{{lastName}}',
          phone: '{{phoneNumber}}',
          notes: '{{Notes}}',
          source: 'Open House',
          agentEmail: '{{agentEmail}}',
          // listing
          street_address: '{{listingAddress}}',
          city: '{{listingCity}}',
          state: '{{listingRegion}}',
          zip: '{{listingPostalCode}}',
          listing_price: '{{listingPrice}}',
          beds: '{{listingBeds}}',
          full_baths: '{{listingBaths}}',
        },
      },
    },
  };
}

function getClozeIntegration(trigger): RolloutBlueprint {
  return {
    blueprintKey: 'syncToCloze',
    name: 'Cloze',
    icon: '',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'cloze',
        actionKey: 'create-contact',
        inputParams: {
          email: '{{email}}',
          first_name: '{{firstName}}',
          last_name: '{{lastName}}',
          phone: '{{phoneNumber}}',
          source: 'Open House',
          agentEmail: '{{agentEmail}}',
          // listing
          street_address: '{{listingAddress}}',
          city: '{{listingCity}}',
          state: '{{listingRegion}}',
          zip: '{{listingPostalCode}}',
          listing_price: '{{listingPrice}}',
          beds: '{{listingBeds}}',
          full_baths: '{{listingBaths}}',
        },
      },
    },
  };
}

function getSierraIntegration(trigger): RolloutBlueprint {
  return {
    blueprintKey: 'syncToSierra',
    name: 'Sierra',
    icon: '',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'sierra-interactive',
        actionKey: 'create-or-update-deal',
        inputParams: {
          email: '{{email}}',
          firstName: '{{firstName}}',
          lastName: '{{lastName}}',
          phone: '{{phoneNumber}}',
          source: 'Open House',
          streetAddress: '{{listingAddress}}',
          city: '{{listingCity}}',
          state: '{{listingRegion}}',
          zip: '{{listingPostalCode}}',
          note: '{{Notes}}',
        },
      },
    },
  };
}

function getHubspotIntegration(trigger): RolloutBlueprint {
  return {
    blueprintKey: 'syncToHubspot',
    name: 'hubspot',
    icon: '',
    renderFields: {
      trigger: false,
      action: ({
        ActionCredentialKeyField,
        ActionInputFields,
        Card,
        createElement,
      }) =>
        createElement(
          Card,
          null,
          'Configure account and integration details',
          createElement(ActionCredentialKeyField),
          ActionInputFields && createElement(ActionInputFields)
        ),
    },
    prefilled: {
      trigger,
      action: {
        appKey: 'hubspot',
        actionKey: 'create-contact',
        inputParams: {
          email: '{{email}}',
          firstName: '{{firstName}}',
          lastName: '{{lastName}}',
          phoneNumber: '{{phoneNumber}}',
        },
      },
    },
  };
}
