/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, NgZone } from '@angular/core';
import {
  NavParams,
  ModalController,
  AlertController,
  ActionSheetController,
  LoadingController,
  Platform,
  MenuController,
  PopoverController
} from '@ionic/angular';

import { AuthService } from '../services/auth.service';
import { CurbUser, User_global, Links } from '../../app/app.models';
import { NetworkProvider } from '../services/network';
import { ColorPickerPrimaryPage } from '../components/color-picker-primary/color-picker-primary';
import { UpdateProfileInformationPage } from '../components/update-profile-information/update-profile-information';
// import { CameraProvider } from '../services/camera';
import { CommonProvider } from './../services/common';
import { IntercomService } from '../services/intercom.service';
import { ImagesManagementService } from '../services/images-management.service';
import { UtilsService } from '../services/utils.service';
import { EditInputPage } from '../components/edit-input/edit-input';
import {
  MAX_SOCIAL_LINKS,
  DEFAULT_WELCOME_MESSAGE, DEFAULT_THANKS_MESSAGE, DEFAULT_PRIVACY_POLICY, PropertyLayout, BLANK_SOCIAL_LINKS, MAX_KIOSK_EVENT_LOGS, EMAIL_REGEX
} from '../constants';
import { combineLatest } from 'rxjs';
import { CompressorService } from '../services/compressor.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { EditLinksPage } from '../components/edit-links/edit-links';
import { environment } from '../../environments/environment';
import { Events } from '../services/events.service';

import { ViewChild } from '@angular/core';
import { IonReorderGroup } from '@ionic/angular';
import { ItemReorderEventDetail } from '@ionic/core';
import { Router } from '@angular/router';
import { I8nService } from '../services/i8nService';
import moment from 'moment';
import { SqlService } from '../services/sql.service';
import { ViewRawLeadsComponent } from '../components/view-raw-leads/view-raw-leads';
import * as _ from 'lodash';
import { EditReviewPage } from '../components/edit-review/edit-review';
import { createDefaultColorOptions, openDefaultEditor } from '@pqina/pintura';

import algoliasearch from 'algoliasearch';
import { ConnectionsService } from '../services/connections.service';
import { SMSService } from '../services/sms.service';
import { ProfileActionPopoverComponent } from '../components/profile-action/profile-action';

import { getDownloadURL, getStorage, ref, uploadBytesResumable } from '@firebase/storage';

const searchClient = algoliasearch(
  'N26A8PC608',
  '068c90fcb4bdad125a840343a2725949'
);

// import { traceUntilFirst } from '@firebase/performance';
import { Location } from '@angular/common';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';

declare const pintura;

//type
// 1 - name
// 2 - phone number
// 3 - email
// 4 - password
enum EditType {
  name = 1,
  phone = 2,
  email = 3,
  password = 4,
  company = 5,
  title = 6,
  license = 7,
  disclaimerText = 8,
  disclaimerURL = 9,
  disclaimerText2 = 10,
  disclaimerURL2 = 11,
  teamname = 12,
  website = 13,
  bank = 14,
  brokerOfRecord = 15
}

@Component({
  selector: 'app-my-profile',
  templateUrl: 'my-profile.html',
  styleUrls: ['my-profile.scss']
})
export class MyProfilePage {

  @ViewChild(IonReorderGroup) reorderGroup: IonReorderGroup;
  userDataSub: any;
  toggleStyle = 'ios';
  primaryColor: string;
  secondaryColor: string;
  primaryColorOpaque: string;
  secondaryColorOpaque: string;
  userSubs: any;
  myProfilePhoto: any = {};
  enableTouchFree: boolean;
  enableGuestSMSFollowUp: boolean;
  editingMode: string;
  socialLinks: any;
  userData: CurbUser;
  imageToEdit: any;
  env: any = {};
  sectionLinked = '';
  dataIsLoading = false;
  isLoggedOut = false;
  loadingInterval: any;
  isDoingFirstLoad = true;
  cordova: any;
  userRole: any;
  cropLimitToImageBounds = true;
  platformName: any;
  rolloutOptionsShow = true;
  EmptyStateMessage = 'Enter Team Admin\'s email to invite them';
  searchType = 'agentAdmin';
  loaded = false;
  selectedMortgageOption: any;
  allMortgageQuestions: any;
  disclosureDoc: any;

  // editorDefaults: any = getEditorDefaults();

  /*
  userData: CurbUser = {
    username: '',
    phoneNumber: '',
    email: '',
    primaryColor: '',
    secondaryColor: '',
    logoFile: '',
    logoImage: {},
    profilePhoto: []
  };
*/
  logoLoaded = false;
  logoFile: string;
  showPintura = false;
  uploadingLogo = false;
  introText: string;
  outroText: string;
  linkToPrivacy: string;
  linkPrivacyPlaceholder: string;
  aspectRatio: any = null;
  userFeatureLocks: any;

  startingMode = 'branding';

  thumbImages = [];
  defaultImage = [
    'assets/imgs/profile/1.png',
    'assets/imgs/profile/2.png',
    'assets/imgs/profile/3.png',
    'assets/imgs/profile/4.png',
    'assets/imgs/profile/5.png',
    'assets/imgs/profile/logos_Century21-logo.png',
    'assets/imgs/profile/6.png',
    'assets/imgs/profile/exp_logo.png',
    'assets/imgs/profile/berkshire_home_services.png',
    'assets/imgs/profile/realty_one_group.png',
    'assets/imgs/profile/better_home_gardens_realestate.png',
    'assets/imgs/profile/exit_logo.png',
    'assets/imgs/profile/windermere_real_estate.png',
    'assets/imgs/profile/corcoran_global_living.png',
    'assets/imgs/profile/homesmart.png',
    'assets/imgs/profile/charles_rutenberg_realty_logo.png',
    'assets/imgs/profile/john_l_scott.png',
    'assets/imgs/profile/baird_warner.png',
    'assets/imgs/profile/first_weber_logo.png',
    'assets/imgs/profile/era-real-estate-logo.png',
    'assets/imgs/profile/weichert-logo.png'
  ];
  defaultLogoImage = [
    'assets/imgs/profile/movement_mortgage.png',
    'assets/imgs/profile/caliber_mortgage.png',
    'assets/imgs/profile/fairway_mortgage.png',
    'assets/imgs/profile/newamerican_mortgage.png',
    'assets/imgs/profile/wellsfargo_mortgage.png',
    'assets/imgs/profile/guaranteedrate_mortgage.png',

    'assets/imgs/profile/cardinal_mortgage.png',
    'assets/imgs/profile/freedom_mortgage.png',
    'assets/imgs/profile/bofa_mortgage.png',
    'assets/imgs/profile/contour_mortgage.png',
    'assets/imgs/profile/finofamerica_mortgage.png',
    'assets/imgs/profile/mortgagefirm_mortgage.png',

    'assets/imgs/profile/loandepot_mortgage.png',
    'assets/imgs/profile/swmc_mortgage.png',
    'assets/imgs/profile/quicken_mortgage.png',
    'assets/imgs/profile/jpmchase_mortgage.png',
    'assets/imgs/profile/usbank_mortgage.png',
    'assets/imgs/profile/guild_mortgage.png',
    'assets/imgs/profile/primelending_mortgage.png',
    'assets/imgs/profile/crosscountry_mortgage.png'
  ];
  segmentValue = 'branding';
  maxBrandLogos = 8;
  userReviews: any;
  offlineMode = false;
  showZapierFull = true;
  isDisabled = false;
  isLogoEditable = false;
  agentAdminName: any;
  targetConnectUser: any;

  lockedElements = {
    website: false,
    bank: false,
    company: false,
    logoFile: false,
    primaryColor: false,
    secondaryColor: false,
    brokerOfRecord: false
  };

  unlockIcon = '../../assets/imgs/lock-open.svg';
  lockIcon = '../../assets/imgs/lock-closed.svg';

  lockMessage = 'This value will be used for all paired agents accounts and will overwrite existing values they\'ve added.';
  unlockMessage = 'When a field is unlocked, paired agents can update the field within their account.';
  lockedMessage = this.i8nService.messages.teamAdminLockedFeatureMessage;
  lockedMessageHeader = this.i8nService.messages.teamAdminLockedFeatureHeader;
  userRoleHeader = '';

  adminInvites: any;
  invitesSubscription: any;


  // algolia search to be put into own component
  config: any;
  showEmailSearchResults = false;
  isDesktop: any;
  inviteEmail = '';
  finalDate: any;
  recentlyActiveModel: any;
  recommendedAgentConfig: any;
  emailValid = false;
  searchValue = '';
  webhookSent = false;
  inviteProcessing = false;
  myRole: any;
  recommendSearchParams2: any;
  adminConnections: any;
  screenType: any;
  firstName = '';
  secondName = '';
  // end of component

  constructor(
    private platform: Platform,
    private utils: UtilsService,
    private zone: NgZone,
    public navCtrl: Router,
    public navParams: NavParams,
    private auth: AuthService,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public actionSheetCtrl: ActionSheetController,
    public modalCtrl: ModalController,
    public common: CommonProvider,
    // public cameraProvider: CameraProvider,
    private network: NetworkProvider,
    private intercomService: IntercomService,
    private imageService: ImagesManagementService,
    private compressor: CompressorService,
    private fileService: File,
    private menu: MenuController,
    public events: Events,
    public i8nService: I8nService,
    private sql: SqlService,

    private location: Location,
    private connectionsDataService: ConnectionsService,
    private messageService: SMSService,
    private popoverCtrl: PopoverController

  ) {
    console.log('my profile constructor');
    const self = this;
    this.env = environment;
    this.userData = User_global;
  }

  ionViewWillEnter() {
    this.platformName = this.platform.is('android') ? 'md' : 'ios';
  }

  ionViewDidEnter() {

    const self = this;

    console.log('allMortgageQuestions ' + JSON.stringify(this.allMortgageQuestions));
    this.cordova = this.platform.is('cordova');
    this.userData = User_global;
    this.env = environment;
    console.log('my profile ionViewDidEnter');

    this.toggleStyle = this.platform.is('android') ? 'md' : 'ios';

    this.screenType = this.platform.width() <= 567 ? 'blockBox' : '';

    if (this.auth.userInitialized) {
      this.initializeMyProfile();
    }
    else {
      self.loadingInterval = setInterval(() => {
        console.log('checking if user logged in');
        if (self.auth.userInitialized) {
          clearInterval(self.loadingInterval);
          self.initializeMyProfile();
        }
      }, 2000);
    }

    this.platform.resize.subscribe(() => {
      setTimeout(() => {
        this.screenType = this.platform.width() <= 567 ? 'blockBox' : '';
      }, 100);
    });

  }


  onMortgageSelect() {
    User_global.assignedMortgageQuestionId = this.selectedMortgageOption;
    this.auth.updateUserDataProperty({ assignedMortgageQuestionId: this.selectedMortgageOption });
  }


  async presentPopover(event) {

    event.stopPropagation();

    const options = {
      unpair: true,
    };

    const customMode = !this.platform.is('cordova') || this.platform.is('ios') ? 'ios' : 'md';
    const currentPopover = await this.popoverCtrl.create({
      component: ProfileActionPopoverComponent,
      event,
      cssClass: 'my-custom-popover',
      showBackdrop: true,
      mode: customMode,
      componentProps: { data: this.targetConnectUser, options: options }
    });

    await currentPopover.present();
    const { data } = await currentPopover.onWillDismiss();
    if (data === undefined || !data) {
      console.log('data is empty');
    }
    else {
      if (data === 'unpair') {
        this.confirmUnpair(event);
      }
    }
  }

  async confirmUnpair(evt) {
    const self = this;
    let type = '';

    const title = 'Unpair Team Admin';
    const message = 'Are you sure you want to unpair your team admin? They won\'t be able to setup branding and listings in your account.';
    type = 'agentAdmin';


    const myAlert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Unpair',
          role: 'OK',
          handler: data => {
            self.unpair(evt, type);
          }
        }
      ]
    });

    await myAlert.present();
  }

  unpair(event, type) {
    this.common.startLoading('Unpairing ' + 'Admin' + '...');

    this.auth.unpairConnection(this.targetConnectUser.id, null, type).then(res => {
      if (res) {

        setTimeout(() => {
          this.common.closeLoading();
          this.common.toast('Connection unpaired.');
          this.agentAdminName = '';
          this.targetConnectUser = null;
          //   this.algoliaUpdated.emit('UNPAIRED');
          //   this.inProgress = true;
        }, 2000);
      }
    });
  }

  openHelp() {

    if (!this.network.checkInternet()) {
      this.navCtrl.navigate(['/help']);
    }
    else {
      this.common.showHelpPage();
    }
  }

  async initializeMyProfile() {

    this.auth.getAuthToken();

    this.auth.getCustomMortgageKioskQuestions().subscribe((snapshot) => {
      //  console.log('custom questions mortgage snapshot: ' + JSON.stringify(snapshot));
      const questions = JSON.parse(JSON.stringify(snapshot));
      this.allMortgageQuestions = questions;

      const defaultOption = questions.find(q => q.id === User_global.assignedMortgageQuestionId);

      if (defaultOption) {
        this.selectedMortgageOption = defaultOption.id;
        console.log('ID of selected option:', this.selectedMortgageOption);
      } else {
        console.log('No default option found.');
      }
    });

    // PROD ALGOLIA IS DIFF
    if (environment.production) {
      // REGIONAL LENDERS NEED ADDITIONAL FILTERS
      this.config = {
        indexName: 'public_profiles_prod',
        searchClient,
        initialUiState: {
          public_profiles_prod: {
            refinementList: {
              role: ['agentAdmin'],
              enableLenderCommunication: ['true']
            }
          }
        }
      };
    }
    // DEV INDEX NAMES
    else if (!environment.production) {

      this.config = {
        indexName: 'public_profiles_dev',
        searchClient,
        initialUiState: {
          public_profiles_dev: {
            refinementList: {
              role: ['agentAdmin'],
              enableLenderCommunication: ['true']
            }
          }
        }
      };
    }

    // Prevent empty searches, including the one that trigger as soon as the component loads
    this.config.searchFunction = helper => {
      if (helper.state.query === '') {
        return;
      }
  
      helper.search();
    }

    this.userData = User_global;
    [this.firstName, this.secondName] = this.userData.username.split(' ');

    this.checkForUserRole();
    this.initProfileData();
    this.menu.close();
    const self = this;

    //   this.startingMode = this.route.snapshot.paramMap.get('section');

    const url = String(window.location);
    this.startingMode = url.split('?section=')[1];

    if (this.startingMode) {
      this.segmentValue = this.startingMode;
    }

    this.network.checkFirestoreDataConnection();
    // this.menu.close();
    // reset photo upload aspect
    self.aspectRatio = null;

    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }

    this.isDoingFirstLoad = true;

    this.userSubs = combineLatest(this.auth.userDataObserver(), (user) => ({ user })).subscribe((data) => {
      try {

        if (this.network.checkInternet()) {
          this.offlineMode = false;
        }
        else {
          this.offlineMode = true;
        }

        if (data && data.user) {
          self.userData = data.user;

          console.log('user subscribe update: \n\n ' + JSON.stringify(data.user));

          self.initLogos();
          self.primaryColor = self.userData.primaryColor;
          self.secondaryColor = self.userData.secondaryColor;
          self.introText = self.userData.customWelcome || DEFAULT_WELCOME_MESSAGE;
          self.outroText = self.userData.customThanks || DEFAULT_THANKS_MESSAGE;
          self.linkToPrivacy = self.userData.customPrivacyPolicy || '';
          self.linkPrivacyPlaceholder = DEFAULT_PRIVACY_POLICY;
          self.enableGuestSMSFollowUp = !self.userData.guestSMSoptOut;

          if (!self.userData.socialLinks || self.userData.socialLinks.length === 0) {
            self.userData.socialLinks = BLANK_SOCIAL_LINKS;
          }

          // populate missing social links
          this.populateSocialLinks();

          if (self.userData.socialLinks) {
            self.socialLinks = JSON.parse(JSON.stringify(self.userData.socialLinks));
          }
          else {
            self.socialLinks = BLANK_SOCIAL_LINKS;
          }

          // if (!_.isEqual(self.socialLinks, self.userData.socialLinks)) {
          //   //  if (JSON.stringify(self.socialLinks) !== JSON.stringify(self.userData.socialLinks)) {
          //   if (self.userData.socialLinks) {
          //     self.socialLinks = JSON.parse(JSON.stringify(self.userData.socialLinks));
          //   }
          //   else {
          //     self.socialLinks = BLANK_SOCIAL_LINKS;
          //   }
          // }

          if (!self.userData.profilePhoto) {
            self.userData.profilePhoto = [];
          }
          if (JSON.stringify(self.userReviews) !== JSON.stringify(self.userData.reviews)) {
            self.userReviews = JSON.parse(JSON.stringify(self.userData.reviews));
          }

          self.myProfilePhoto = (self.userData.profilePhoto && self.userData.profilePhoto[0]) ? self.userData.profilePhoto[0] : {};
          //  this.syncAllElementOrder(this.socialLinks);
          this.updateOpaque();
          this.loadUserLogo();
          console.log(' opt out is ' + self.userData.guestSMSoptOut);
          console.log('Phone Number Test', this.common.formatPhone(self.userData.phoneNumber));
          this.isDoingFirstLoad = false;
          console.log('social links are: ' + JSON.stringify(this.socialLinks));
        }
        else {
          this.isDoingFirstLoad = false;
        }
        this.isLogoEditable = this.showEditLogo();
      }
      catch (err) {
        this.auth.logErrors('error in profile data parse: ' + err + ' stringify:' + JSON.stringify(err));
        console.log('error in profile');
        this.isDoingFirstLoad = false;
      }
    });

    if (self.myProfilePhoto && self.myProfilePhoto.imagePath && !self.myProfilePhoto.imageURL) {
      self.syncImageToStorage();
    }

    if (this.platform.is('cordova') && self.myProfilePhoto.imageURL && self.myProfilePhoto.imagePath) {
      this.imageService.firebaseImageUrl(self.myProfilePhoto.imagePath, self.myProfilePhoto.imageURL).then((url2) => {
        this.imageService.cacheImage(url2, self.myProfilePhoto.imagePath);
      });
    }

    this.sectionLinked = this.navParams.get('section');

    if (this.navCtrl.getCurrentNavigation() && this.navCtrl.getCurrentNavigation().extras.queryParams) {
      this.sectionLinked = this.navCtrl.getCurrentNavigation().extras.queryParams.section;
    }

    if (this.sectionLinked === 'addBrokerage') {
      this.updateUserInformations(5);
    }
    else if (this.sectionLinked === 'addPhoto') {
      setTimeout(() => { this.addImageClicked('profile'); }, 500);
    }

    if (this.network.checkInternet()) {
      const rolloutOptions = await this.auth.getRolloutOptions();
      // console.log(JSON.stringify(rolloutOptions));


      // Checking if there are any true values in the object
      const hasTrueValues = Object.values(rolloutOptions).some(value => value === true);

      this.rolloutOptionsShow = hasTrueValues;
    }

    // user admin reads locks regardless of connection
    if (User_global.role === 'agentAdmin') {
      this.auth.subscribeFeatureLocks('userLocks').subscribe(res => {

        console.log('locks status is ' + JSON.stringify(res));
        this.userFeatureLocks = JSON.parse(JSON.stringify(res));

        if (this.userFeatureLocks) {
          console.log('userFeatureLocks: ' + JSON.stringify(this.userFeatureLocks));

          this.lockedElements = this.userFeatureLocks;
        }
      });

      this.loadDisclosureDoc();
    }
  }

  initLogos() {
    const self = this;
    self.thumbImages = [];
    if (User_global.role === 'lender') {
      if (User_global.logoFile === undefined || User_global.logoFile === '') {
        self.thumbImages = self.defaultLogoImage;
      }
      else {
        self.thumbImages = self.defaultLogoImage;
      }
      this.isDisabled = false;
      this.userRoleHeader = 'Lender';
    } else if (User_global.role === 'agent') {
      if (User_global.logoFile === undefined || User_global.logoFile === '') {
        self.thumbImages = self.defaultImage;
      }
      else {
        self.thumbImages = self.defaultImage;
      }
      this.isDisabled = true;
    } else if (User_global.role === 'agentAdmin') {
      if (User_global.logoFile === undefined || User_global.logoFile === '') {
        self.thumbImages = self.defaultImage;
      }
      else {
        self.thumbImages = self.defaultImage;
      }
      this.isDisabled = false;
      this.userRoleHeader = 'Team Admin';
    }

    if (this.platform.is('cordova')) {
      this.showZapierFull = false;
    }
    else {
      this.showZapierFull = true;
    }
  }

  getAdminInvites() {
    const self = this;

    if (User_global.role !== 'agent') {
      return;
    }

    this.invitesSubscription = this.connectionsDataService.connections().subscribe((data) => {
      console.log('admin invites: ' + JSON.stringify(data));

      self.adminInvites = data.filter(agent => (agent.status === 'invited' && agent.role === 'agentAdmin'));
      self.adminConnections = data.filter(agent => (agent.status === 'agent_admin'));
      if (self.adminConnections[0]) {
        this.initAdminConnectionProfile();
      }
      else {

        this.lockedElements = {
          website: false,
          bank: false,
          company: false,
          logoFile: false,
          primaryColor: false,
          secondaryColor: false,
          brokerOfRecord: false
        };

        this.loaded = true;
      }
    });
  }

  async initAdminConnectionProfile() {

    await this.auth.subscribeFeatureLocks('userLocks').subscribe(res => {

      console.log('locks status is ' + JSON.stringify(res));
      this.userFeatureLocks = JSON.parse(JSON.stringify(res));

      if (this.userFeatureLocks) {
        console.log('userFeatureLocks: ' + JSON.stringify(this.userFeatureLocks));

        this.lockedElements = this.userFeatureLocks;
      }
    });

    await this.connectionsDataService.public_profile(this.adminConnections[0].id).then(res => {
      if (res) {
        this.targetConnectUser = res;
        this.agentAdminName = res.username;

        this.lockedElements = this.userFeatureLocks;
        this.loaded = true;
      }
    });
  }

  sendEmailWebhook() {

    console.log('send email webhook with : ' + this.inviteEmail);
    if (!this.inviteEmail) {
      return;
    }

    this.inviteProcessing = true;
    this.webhookSent = true;

    this.messageService.sendEmailWebhook(this.inviteEmail, null, 'agentAdmin').then(res => {

      if (res) {
        this.inviteEmail = '';
        this.showEmailSearchResults = false;
        this.inviteProcessing = false;
        this.events.publish('invite:inviteUserSuccess');
        this.common.toast('Team admin invitation sent');
      }
      else {
        this.auth.logErrors('Error inviting admin 3: ' + this.inviteEmail);
        this.common.showDBWriteError();
        this.inviteProcessing = false;
        this.events.publish('invite:inviteUserFail');
        this.common.toast('Invitation failed to send');
      }
    }, (err) => {
      console.log('webhook error : ' + err);
      this.auth.logErrors('Error inviting admin 4: ' + this.inviteEmail + ' error: ' + err);
      this.common.showDBWriteError();
      this.events.publish('invite:inviteUserFail');
      this.common.toast('Invitation failed to send');
    });
  }

  //agentAdminName

  saveFeatureLocks() {

    if (User_global.role === 'agentAdmin') {
      console.log('this.userFeatureLocks: ' + this.userFeatureLocks);
      this.auth.syncFeatureLocks('userLocks', this.userFeatureLocks);
    }
  }

  // populate missing social links
  populateSocialLinks() {
    let item;

    // add missing items
    for (let i = 0; i < BLANK_SOCIAL_LINKS.length; i++) {
      item = this.userData.socialLinks.find(x => x.id === BLANK_SOCIAL_LINKS[i].id);
      if (!item) {
        this.userData.socialLinks.push(BLANK_SOCIAL_LINKS[i]);
      }
    }

    // fix order of items
    for (let i = 0; i < this.userData.socialLinks.length; i++) {
      if (!this.userData.socialLinks[i].id) {
        this.userData.socialLinks.splice(i, 1);
      }
      this.userData.socialLinks[i].order = i;
    }
  }


  ionViewWillLeave() {
    console.log('profile will leave. unsubscribe');

    clearInterval(this.loadingInterval);
    //  this.myProfilePhoto = false;
    //  this.auth.updateUserDataProperty({ socialLinks: this.socialLinks });
    if (this.userSubs) {
      this.userSubs.unsubscribe();
      this.userSubs = null;
    }
    this.isDoingFirstLoad = false;
  }

  initProfileData() {
    const self = this;
    console.log('init profile data.');
    this.thumbImages = [];
    if (User_global) {
      self.initLogos();

      this.primaryColor = User_global.primaryColor;
      this.secondaryColor = User_global.secondaryColor;
      this.introText = User_global.customWelcome || DEFAULT_WELCOME_MESSAGE;
      this.outroText = User_global.customThanks || DEFAULT_THANKS_MESSAGE;
      this.linkToPrivacy = User_global.customPrivacyPolicy || '';
      this.linkPrivacyPlaceholder = DEFAULT_PRIVACY_POLICY;
      this.userData = User_global;

      self.socialLinks = self.userData.socialLinks;

      if (!self.userData.profilePhoto) {
        self.userData.profilePhoto = [];
      }

      self.myProfilePhoto = (self.userData.profilePhoto && self.userData.profilePhoto[0]) ? self.userData.profilePhoto[0] : {};

      console.log(' opt out is ' + this.userData.guestSMSoptOut);
      this.updateOpaque();
      this.loadUserLogo();
      this.getAdminInvites();
    }
  }

  async openEditModal(value: any, link?: Links) {
    const self = this;
    console.log('openEditModal called');
    //link address message i
    let profileModal;

    if (value === 'editSocialLink') {

      profileModal = await self.modalCtrl.create({
        component: EditLinksPage, componentProps: {
          label: 'Edit ' + link.title,
          data: JSON.parse(JSON.stringify(link)),
          edit: false,
          showheader: false,
          showTitle: false,
          showFormTitle: true
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismissing edit modal ' + JSON.stringify(data));
      if (data === undefined || data === null) {
        return;
      }
      // let newLink = { id: link.id, header: link.title, URL: link.URL, order: link.order, enabled: link.enabled };
      console.log('index of edit link: ' + self.socialLinks.indexOf(link));
      self.socialLinks[self.socialLinks.indexOf(link)] = data;

      console.log('edit user data: ' + JSON.stringify(self.socialLinks));
      self.auth.updateUserDataProperty({ socialLinks: self.socialLinks });
    }
  }

  openZapierSync() {
    this.common.openLink('https://curbhe.ro/zapier-integration', '_system');
  }

  async openRolloutSync() {
    //  const token = await this.auth.getFirebaseToken();

    const token = this.auth.getUserAuthToken();

    console.log('token is ' + token);
    const link = environment.webapp + '/my-profile?token=' + token + '&section=integrations';
    console.log('link to open: ' + link);
    this.common.openLink(link, '_system');
  }

  segmentChanged(ev: string, element?) {
    console.log('Segment changed', ev);

    if (ev === 'integrations' && !this.network.checkInternet()) {

      this.network.noNetworkModal();
      return;
    }

    this.segmentValue = ev;

    this.location.replaceState('my-profile?section=' + this.segmentValue);

    if (element && this.platform.is('mobileweb')) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }


  async alertDisclosures(type) {

    if (type === 0) {
      this.common.openLink('https://curbhe.ro/disclosure-requests/');
    }
    if (type === 1) {
      const myAlert = await this.alertCtrl.create({
        header: 'Editing Disabled',
        message: this.i8nService.messages.disclosureEditingDisabled,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');


            }
          }
        ]
      });
      myAlert.present();
    }
    else if (type === 2) {

      this.common.openLink('https://curbhe.ro/disclosure-requests/');

      const myAlert = await this.alertCtrl.create({
        header: 'Export Disclosure',
        message: this.i8nService.messages.disclosureExportDisabled,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');
            }
          }
        ]
      });
      myAlert.present();
    }
  }

  async socialLinkChanged(link, index, event) {
    const self = this;
    const totalEnabled = this.socialLinks.filter((item) => item.enabled === true);

    if (event.checked === this.userData.socialLinks[index].enabled) {
      console.log('no change to link, returning');
      return;
    }

    if (link.enabled && totalEnabled.length > MAX_SOCIAL_LINKS) {
      const myAlert = await this.alertCtrl.create({
        header: 'Social Links Limit Reached',
        message: this.i8nService.messages.myProfileDisableExistingLinkToEnableAnother,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');

              self.socialLinks[link.order].enabled = false;
              // return;
            }
          }
        ]
      });
      myAlert.present();
    }
    else if (link.enabled && link.URL === '') {
      const myAlert = await this.alertCtrl.create({
        header: 'Empty URL',
        message: this.i8nService.messages.urlIsRequiredToEnableLink,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');

              try {
                self.socialLinks[link.order].enabled = false;
              }
              catch (err) {
                console.log('failed to disable clicked link');
                console.log(err);
              }
            }
          }
        ]
      });
      myAlert.present();
    }
    else {
      console.log('socialLinkChange ' + JSON.stringify(link));
      this.auth.updateUserDataProperty({ socialLinks: this.socialLinks });
    }
  }

  reorderSocialLinks(ev: CustomEvent<ItemReorderEventDetail>) {
    const tempOrder = ev.detail.from;

    console.log('reorder from: ' + ev.detail.from + '  to: ' + ev.detail.to);

    if (ev.detail.to === this.socialLinks.length) {
      ev.detail.to = ev.detail.to - 1;
    }
    this.socialLinks[ev.detail.from].order = this.socialLinks[ev.detail.to].order;
    this.socialLinks[ev.detail.to].order = tempOrder;

    const element = this.socialLinks[ev.detail.from];
    this.socialLinks.splice(ev.detail.from, 1);
    this.socialLinks.splice(ev.detail.to, 0, element);

    //    this.syncAllElementOrder(this.socialLinks);
    ev.detail.complete();
    this.auth.updateUserDataProperty({ socialLinks: this.socialLinks });
  }

  syncAllElementOrder(arr) {
    for (let i = 0; i < arr.length; i++) {
      arr[i].order = i;
    }
    console.log('list after order sync: ' + JSON.stringify(arr));
  }

  onFileChanged(event, type) {

    if (!event.target.files[0]) {
      return;
    }

    this.editingMode = type;
    // get the file size in standard format
    console.log('File size is: ' + event.target.files[0].size);

    this.editWebImage(event.target.files[0]);
  }

  // web file select event
  async editWebImage(img) {
    console.log('edit web image called ' + this.editingMode);
    const self = this;
    const photoId = this.imageService.generateImageID(25);
    let nativeImage;
    this.aspectRatio = 1;
    let options;
    let editor;

    this.intercomService.hideLauncher();

    if (self.editingMode === 'profile') {
      options = {
        utils: ['crop', 'filter', 'finetune', 'markup', 'resize', 'sticker', 'annotate'],

        imageWriter: {
          quality: 1,
          targetSize: {
            width: 1000,
            height: 1000
          }
        },
        imageCropAspectRatio: 1,
        imageCropLimitToImage: true
      };
    }
    else { // LOGO
      options = {

        utils: ['crop', 'filter', 'finetune', 'markup', 'resize', 'sticker', 'annotate', 'fill'],

        imageWriter: {
          quality: 1,
          targetSize: {
            width: 500,
            height: 500
          }
        },

        allowPreviewFitToView: true,
        cropShowSize: true,
        cropAllowInstructionZoom: true,
        labelCropInstructionZoom: 'Logos are reformatted to square dimensions. Scroll or pinch to zoom and adjust positioning.',
        labelButtonCropZoom: 'crop zoom',
        cropResizeMatchImageAspectRatio: true,
        cropAllowToggleLimit: true,

        imageCropAspectRatio: 1,
        imageCropLimitToImage: false,
        fillOptions: [
          // Using the default markup editor colors
          ...Object.values(createDefaultColorOptions())
        ]
      };
    }


    if (this.platform.is('cordova')) {

      // const type = 'profile' ? 'image/jpeg' : 'image/png';

      const imageWidth = await this.imageService.getImageWidth(this.imageService.imageData);
      console.log('imageWidth is: ' + imageWidth);
      if (imageWidth > 4096) {
        nativeImage = await this.compressor.compressDataUrl(this.imageService.imageData, 2000);
      }
      else {
        nativeImage = img;
      }

      editor = openDefaultEditor({
        src: nativeImage,
        ...options,
        // set these to false as only needed in Safari browser
        preventScrollBodyIfNeeded: false,
        preventFooterOverlapIfNeeded: false,
      });
    }
    else {
      editor = openDefaultEditor({
        src: img,
        ...options
      });
    }

    editor.on('loaderror', console.log);
    editor.on('load', (res) => {
      console.log('load', res);
    });

    editor.on('process', async (imageState) => {

      this.intercomService.showLauncher();

      if (imageState.error) {
        console.log('Error processing image:' + imageState.error);
        return;
      }

      const output = URL.createObjectURL(imageState.dest);

      if (!output) {
        return;
      }

      console.log('self.editingMode 1: ' + self.editingMode);

      this.common.startLoading();
      let type = 'image/png';

      if (self.editingMode === 'profile') {
        type = 'image/jpeg';
      }

      if (this.platform.is('cordova')) {
        this.processSelectedImage(imageState.dest);
        this.common.closeLoading();
        return;
      }

      console.log('Done editing image', output);

      const blob = await this.compressor.compressDataUrl(output, 1000, type);

      if (self.editingMode === 'logo') {
        self.uploadProfileLogoFile(photoId, 'logo', blob);
      }
      else if (self.editingMode === 'profile') {
        self.uploadProfileImage(photoId, 'profile', blob, output);
      }

    });
  }

  lockMyBrandLogo() {
    if (this.userRole === 'agentAdmin') {
      if (!this.lockedElements.logoFile) {
        this.showConfirmationMessage('Confirm Update', this.lockMessage, 'logoFile', true);
      }
      else {
        this.showConfirmationMessage('Confirm Update', this.unlockMessage, 'logoFile', false);
      }
    }
    else if (this.userRole === 'agent' && this.lockedElements.logoFile) {
      this.showAlert(this.lockedMessageHeader, this.lockedMessage);
    }
  }

  lockPrimaryColor(event) {
    if (event) {
      event.stopPropagation();
    }
    if (this.userRole === 'agentAdmin') {
      if (!this.lockedElements.primaryColor) {
        this.showConfirmationMessage('Confirm Update', this.lockMessage, 'primaryColor', true);
      }
      else {
        this.showConfirmationMessage('Confirm Update', this.unlockMessage, 'primaryColor', false);
      }
    }
    else if (this.userRole === 'agent' && this.lockedElements.primaryColor) {
      this.showAlert(this.lockedMessageHeader, this.lockedMessage);
    }
  }

  lockSecondaryColor(event) {
    if (event) {
      event.stopPropagation();
    }
    if (this.userRole === 'agentAdmin') {
      if (!this.lockedElements.secondaryColor) {
        this.showConfirmationMessage('Confirm Update', this.lockMessage, 'secondaryColor', true);
      }
      else {
        this.showConfirmationMessage('Confirm Update', this.unlockMessage, 'secondaryColor', false);
      }
    }
    else if (this.userRole === 'agent' && this.lockedElements.secondaryColor) {
      this.showAlert(this.lockedMessageHeader, this.lockedMessage);
    }
  }

  showMoreLogos() {
    this.maxBrandLogos = 21;
  }
  // UPLOAD PROFILE PHOTO
  uploadProfileImage(photoId, type, img, output) {
    const self = this;
    self.imageService.uploadProfilePhotoWeb(photoId, type, img).then(imgURL => {

      if (imgURL === '') {
        self.common.showSimpleError(this.i8nService.messages.errorUploadingPhoto);
        self.common.closeLoading();
        return;
      }

      const newImg = {
        id: photoId,
        imagePath: photoId + '_profile',
        imageURL: imgURL
      };

      console.log('imgURL is: ' + imgURL);

      if (self.userData.profilePhoto.length === 0) {
        self.userData.profilePhoto.push(newImg);
      }
      else {
        self.userData.profilePhoto[0] = newImg;
      }

      self.myProfilePhoto = newImg;
      console.log('self.myProfilePhoto: ' + JSON.stringify(self.myProfilePhoto));
      self.auth.updateUserDataProperty({ profilePhoto: self.userData.profilePhoto }).then(res => {
        self.syncImagesAcrossSessions();
        self.saveThumbnailPhoto(output, newImg);
        self.common.closeLoading();
      });
    });
  }

  // SAVE PROFILE THUMBNAIL
  async saveThumbnailPhoto(dt, imgData) {
    const self = this;

    console.log('in saveThumbnailPhoto');
    const photoId = this.imageService.generateImageID(25);
    // const fileName = `${photoId}_thumbnail`;

    const blob = await this.compressor.compressDataUrl(dt, 250);

    // compress the photo
    console.log('compressed thumbnail photo');
    // upload photo
    self.imageService.uploadProfilePhotoWeb(photoId, 'profileThumbnail', blob).then(imgURL => {
      console.log('thumbnail uploaded: ' + imgURL);
      imgData.thumbnailPath = '';
      imgData.thumbnailURL = imgURL;
      self.userData.profilePhoto[0] = imgData;

      // update DB
      self.auth.updateUserDataProperty({ profilePhoto: self.userData.profilePhoto });
      console.log('finished saving thumbnail');
    });

  }

  // UPLOAD LOGO
  uploadProfileLogoFile(photoId, type, img) {
    const self = this;
    self.imageService.uploadProfileLogoWeb(photoId, type, img).then(imgURL => {

      if (!imgURL) {
        self.common.showSimpleError(this.i8nService.messages.errorUploadingPhoto);
        this.common.closeLoading();
        return;
      }

      self.imageService.firebaseProfileUrl(imgURL).then((fullPath) => {

        if (!fullPath) {
          self.common.showSimpleError(this.i8nService.messages.errorUploadingPhoto);
          this.common.closeLoading();
          return;
        }

        console.log('full path resolved: ' + fullPath);
        self.userData.logoFile = fullPath;
        User_global.logoFile = fullPath;

        try {
          this.imageService.removeImage(this.auth.getUID() + '_qrCodeOnlylink_qr_profile');
          //  this.imageService.removeImage('qrCodeOnlylink_qr_profile');
        }
        catch (err) {
          console.log('err deleting file: ' + err);
        }

        self.auth.updateUserDataProperty({ logoFile: fullPath }).then(res => {
          if (res) {
            self.loadUserLogo();
            self.syncImagesAcrossSessions();
            setTimeout(() => { self.auth.resetDynamicQRCode(); }, 4000);
            setTimeout(() => { this.events.publish('refresh:qrCode'); }, 8000);
            self.common.closeLoading();
          }
        });
      });

      if (!this.network.checkInternet()) {
        self.common.closeLoading();
      }
    });
  }

  loadUserLogo() {
    // this.logoFile = null;
    if (this.userData && this.userData.logoFile) {
      this.imageService.
        realImageData({ imageURL: this.userData.logoFile, imagePath: this.utils.logoPath(this.userData.logoFile) })
        .then((url) => {

          console.log('setting logo url to: ' + url);
          // load right away first time
          if (this.logoFile === null || this.logoFile === undefined) {
            if (url) {
              this.logoFile = url + '?=' + Math.random();
            }
          }

          // refresh user logo
          setTimeout(() => {
            if (url) {
              this.logoFile = url + '?=' + Math.random();
              this.uploadingLogo = false;
              this.common.closeLoading();
            }
          }, 1500);

          this.logoLoaded = true;
          console.log('real image user logo : ' + this.logoFile);
        });
    } else {
      this.logoFile = null;
      this.uploadingLogo = false;
      this.common.closeLoading();
    }
  }

  updateOpaque() {
    const p = this.utils.convertColor(this.primaryColor);
    const s = this.utils.convertColor(this.secondaryColor);

    console.log('color p: ' + p);
    console.log('color s: ' + s);
    this.primaryColorOpaque = `rgba(${p.rChannel},${p.gChannel},${p.bChannel},0.2)`;
    this.secondaryColorOpaque = `rgba(${s.rChannel},${s.gChannel},${s.bChannel},0.2)`;

    console.log('color primaryColorOpaque: ' + this.primaryColorOpaque);
  }

  async pickColor(isPrimaryColor: boolean) {

    if (isPrimaryColor) {
      if (this.userRole === 'agent' && this.lockedElements.primaryColor) {
        this.showAlert(this.lockedMessageHeader, this.lockedMessage);
      }
      else {
        const primaryModal =
          await this.modalCtrl.create({
            component: ColorPickerPrimaryPage,
            componentProps: {
              color: this.primaryColor,
              title: 'Edit Primary Color',
              subtitle: 'This color will be used as the background to white text so it will need to be dark enough so that guests can read it.',
              isLocked: this.lockedElements.primaryColor
            }
          });
        await primaryModal.present();
        const { data } = await primaryModal.onWillDismiss();

        if (data !== undefined && data !== null) {
          this.primaryColor = `#${data}`;

          try {
            this.imageService.removeImage(this.auth.getUID() + '_qrCodeOnlylink_qr_profile');
            //  this.imageService.removeImage('qrCodeOnlylink_qr_profile');
          }
          catch (err) {
            console.log('err deleting file: ' + err);
          }

          User_global.primaryColor = this.primaryColor;

          this.updateOpaque();

          this.auth.updateUserDataProperty({ primaryColor: `#${data}` });
          setTimeout(() => { this.auth.resetDynamicQRCode(); }, 4000);
          setTimeout(() => { this.events.publish('refresh:qrCode'); }, 8000);

        }
      }
    } else {
      if (this.userRole === 'agent' && this.lockedElements.secondaryColor) {
        this.showAlert(this.lockedMessageHeader, this.lockedMessage);
      }
      else {
        const secondaryModal =
          await this.modalCtrl.create({
            component: ColorPickerPrimaryPage,
            componentProps: {
              color: this.secondaryColor,
              title: 'Edit Secondary Color',
              subtitle: 'This color will be used for design accents so it should be visible against a white background.',
              isLocked: this.lockedElements.secondaryColor
            }
          });
        await secondaryModal.present();
        const { data } = await secondaryModal.onWillDismiss();

        if (data !== undefined && data !== null) {
          this.secondaryColor = `#${data}`;
          this.auth.updateUserDataProperty({ secondaryColor: `#${data}` });
          this.updateOpaque();
        }
      }
    }
  }

  //type
  // 1 - name
  // 2 - phone number
  // 3 - email
  // 4 - password
  async updateUserInformations(type, isLock?, event?) {
    if (event) {
      event.stopPropagation();
    }
    if (type === EditType.email || type === EditType.password) {
      if (!this.network.checkInternet()) {
        this.network.noNetworkModal();
        return;
      }
    }
    if (type === EditType.website) {
      this.checkIfItemIsLocked(type, this.lockedElements.website, 'website', isLock);
    }
    else if (type === EditType.company) {
      this.checkIfItemIsLocked(type, this.lockedElements.company, 'company', isLock);
    }
    else if (type === EditType.brokerOfRecord) {
      this.checkIfItemIsLocked(type, this.lockedElements.brokerOfRecord, 'brokerOfRecord', isLock);
    }
    // else if (type === EditType.bank) {
    //   this.checkIfItemIsLocked(type, this.lockedElements.company, 'company', isLock);
    // }
    else {
      this.updateUserProperty(type, false);
    }
  }

  async checkIfItemIsLocked(type, lockElement, elementClass, isLock?) {
    if (this.userRole === 'agent' && lockElement) {
      this.showAlert(this.lockedMessageHeader, this.lockedMessage);
    }
    else if (this.userRole === 'agentAdmin') {
      if ((isLock !== undefined && isLock === 'lock')) {
        if (!lockElement) {
          this.showConfirmationMessage('Confirm Update', this.lockMessage, elementClass, true);
        }
        else {
          this.showConfirmationMessage('Confirm Update', this.unlockMessage, elementClass, false);
        }
      }
      else {
        this.updateUserProperty(type, lockElement);
      }
    }
    else {
      this.updateUserProperty(type, lockElement);
    }
  }

  async updateUserProperty(type, isElementLocked) {
    const updateUserInformationsModal =
      await this.modalCtrl.create({
        component: UpdateProfileInformationPage,
        componentProps: { type: type, userData: this.userData, blockEmpty: type === EditType.name, isLocked: isElementLocked }
      });
    await updateUserInformationsModal.present();
    const { data } = await updateUserInformationsModal.onWillDismiss();
    const value = data;

    // DISABLE TOGGLE IF FIELDS ARE EMPTY
    if (value === undefined || value === null) {
      if (type === EditType.license && !this.userData.license) {
        this.auth.updateUserDataProperty({ showLicense: false });
      }
      else if (type === EditType.title && !this.userData.title) {
        this.auth.updateUserDataProperty({ showTitle: false });
      }
      else if (type === EditType.disclaimerText && !this.userData.disclaimerText) {
        this.auth.updateUserDataProperty({ showDisclaimer: false });
      }
      else if (type === EditType.disclaimerURL && !this.userData.disclaimerURL) {
        this.auth.updateUserDataProperty({ showDisclaimer: false });
      }
      else if (type === EditType.disclaimerText2 && !this.userData.secondDisclaimerText) {
        this.auth.updateUserDataProperty({ showSecondDisclaimer: false });
      }
      else if (type === EditType.disclaimerURL2 && !this.userData.secondDisclaimerURL) {
        this.auth.updateUserDataProperty({ showSecondDisclaimer: false });
      }
      return;
    }
    if (type === EditType.name) {
      this.userData.username = value;
      this.auth.updateUserDataProperty({ username: value });
      this.intercomService.updateIntercomUser(this.userData.email, this.userData.username, this.userData.phoneNumber, this.auth.getUID());

    } else if (type === EditType.phone) {
      this.userData.phoneNumber = this.common.formatPhone(value);
      this.auth.updateUserDataProperty({ phoneNumber: value });
      this.intercomService.updateIntercomUser(this.userData.email, this.userData.username, this.userData.phoneNumber, this.auth.getUID());

    } else if (type === EditType.email) {
      if (value !== this.userData.email) {
        this.enterPasswordAlert(value, 'email');
      }
    } else if (type === EditType.password) {
      this.enterPasswordAlert(value, 'pass');
    }
    else if (type === EditType.company) {
      this.userData.company = value;
      this.auth.updateUserDataProperty({ company: value });
    }
    else if (type === EditType.brokerOfRecord) {
      this.userData.brokerOfRecord = value;
      this.auth.updateUserDataProperty({ brokerOfRecord: value });
    }
    else if (type === EditType.bank) {
      this.userData.company = value;
      this.auth.updateUserDataProperty({ company: value });
    }
    else if (type === EditType.website) {
      this.userData.website = data;
      this.auth.updateUserDataProperty({ website: data });
    }
    else if (type === EditType.title) {
      this.userData.title = value;
      this.auth.updateUserDataProperty({ title: value });
    }
    else if (type === EditType.teamname) {
      this.userData.teamname = value;
      this.auth.updateUserDataProperty({ teamname: value });
    }
    else if (type === EditType.license) {
      this.userData.license = value;
      this.auth.updateUserDataProperty({ license: value });
    }
    else if (type === EditType.disclaimerText) {
      this.userData.disclaimerText = value;
      this.auth.updateUserDataProperty({ disclaimerText: value });

      if (!this.userData.disclaimerURL) {
        this.updateUserInformations(EditType.disclaimerURL);
      }
    }
    else if (type === EditType.disclaimerURL) {
      this.userData.disclaimerURL = value;
      this.auth.updateUserDataProperty({ disclaimerURL: value });
    }
    else if (type === EditType.disclaimerText2) {
      this.userData.secondDisclaimerText = value;
      this.auth.updateUserDataProperty({ secondDisclaimerText: value });

      if (!this.userData.secondDisclaimerURL) {
        this.updateUserInformations(EditType.disclaimerURL2);
      }
    }
    else if (type === EditType.disclaimerURL2) {
      this.userData.secondDisclaimerURL = value;
      this.auth.updateUserDataProperty({ secondDisclaimerURL: value });
    }
  }

  updateShowTitle() {
    if (this.isDoingFirstLoad) { return; }
    // alert('updateShowTitle()');
    this.setDataLoading();
    this.auth.updateUserDataProperty({ showTitle: this.userData.showTitle });

    if (this.userData.showTitle && !this.userData.title) {
      this.updateUserInformations(EditType.title);
    }
  }

  updateShowLicense() {
    if (this.isDoingFirstLoad) { return; }
    // alert('updateShowLicense()');
    this.setDataLoading();
    this.auth.updateUserDataProperty({ showLicense: this.userData.showLicense });

    if (this.userData.showLicense && !this.userData.license) {
      this.updateUserInformations(EditType.license);
    }
  }

  updateShowDisclaimer(value) {
    this.setDataLoading();
    if (value === 1) {
      this.auth.updateUserDataProperty({ showDisclaimer: this.userData.showDisclaimer });

      if (this.userData.showDisclaimer && !this.userData.disclaimerText) {
        this.updateUserInformations(EditType.disclaimerText);
      }
    }
    else if (value === 2) {
      this.auth.updateUserDataProperty({ showSecondDisclaimer: this.userData.showSecondDisclaimer });

      if (this.userData.showSecondDisclaimer && !this.userData.secondDisclaimerText) {
        this.updateUserInformations(EditType.disclaimerText2);
      }
    }
  }

  // type can be email || pass
  // value can be new email or new password
  async enterPasswordAlert(value, type) {
    const self = this;
    const loading = await this.loadingCtrl.create({
      message: 'Please wait...'
    });

    const enterPassAlert = await this.alertCtrl.create({
      header: 'Password Required',
      message: 'Please enter your current password to make this change to your account',
      inputs: [
        {
          name: 'password',
          placeholder: 'Password',
          type: 'password'
        }
      ],
      buttons: [

        {
          role: 'cancel',
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },

        {
          text: 'Update',
          handler: data => {
            loading.present();
            if (type === 'email') {
              self.auth.updateUserEmail(value.toLowerCase(), data.password)
                .then(
                  success => {
                    loading.dismiss();
                    self.userData.email = value;
                    self.showAlert('', 'Email updated successfully');
                    this.intercomService.
                      updateIntercomUser(self.userData.email, self.userData.username, self.userData.phoneNumber, self.auth.getUID());
                  },
                  error => {
                    loading.dismiss();
                    if (error.code === 'auth/wrong-password') {
                      self.showAlert('', this.i8nService.messages.invalidEmailOrPassword);
                    }
                    else if (error.code === 'auth/email-already-in-use') {
                      self.showAlert('', this.i8nService.messages.invalidEmailAccountInUse);
                    }
                    else {
                      self.showAlert('', this.i8nService.messages.errorChangingEmail);
                    }
                    console.log(error);
                    self.auth.logErrors('Error changing email: ' + error);
                  }
                );
            } else if (type === 'pass') {
              this.auth.updateUserPassword(value, data.password)
                .then(
                  success => {
                    loading.dismiss();
                    self.showAlert('', 'Password updated successfully');
                  },
                  error => {
                    loading.dismiss();
                    const errorMsg = 'An incorrect password was entered. To update your password, the current password is required. Please try again or use the "Forgot Password" option on the Login screen to update your current password.';
                    self.showAlert('Password update error', errorMsg);
                    self.auth.logErrors('Error changing password: ' + JSON.stringify(error));
                  }
                );
            } else {
              console.log('something is not ok if you see this');
            }
          }
        },
      ]
    });
    enterPassAlert.present();
  }

  public async showAlert(title, subTitle) {

    const alert = await this.alertCtrl.create({
      header: title,
      message: subTitle,
      buttons: ['OK']
    });
    alert.present();
  }



  public async showConfirmationMessage(title, subTitle, elementClass, isLocked) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: subTitle,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: (data) => {
            console.log('elementClass: ' + elementClass);
            this.lockedElements[elementClass] = isLocked;
            console.log(this.lockedElements);
            if (User_global.role === 'agentAdmin') {
              this.userFeatureLocks = this.lockedElements;
              this.saveFeatureLocks();
            }
          }
        },
      ]
    });
    alert.present();
  }

  public goHomeLogin() {

    if (this.network.checkInternet()) {
      this.auth.canLogout().then((res) => {
        console.log('data');
        if (res) {
          this.promptLogout();
        } else {
          this.showModal();
        }
      });
    }
    else {
      this.cannotLogout();
    }
  }

  async promptDeleteAccount() {
    const alertBody = {
      header: 'Delete Account',
      message: 'Are you sure you want to delete your account?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Delete',
          handler: (data) => {
            this.startDeleteAccount();
          }
        },]
    };
    const alert = await this.alertCtrl.create(alertBody);
    alert.present();
  }

  startDeleteAccount() {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    this.common.openLink('https://curbhe.ro/delete-account');
  }

  async promptLogout() {
    const alertBody = {
      header: 'Logout',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Logout',
          handler: (data) => {
            this.logout();
          }
        },]
    };
    const alert = await this.alertCtrl.create(alertBody);
    alert.present();
  }

  async cannotLogout() {
    const alertBody = {
      header: 'Connection Error',
      message: this.i8nService.messages.logoutConnectionError,
      buttons: [
        {
          text: 'OK',
          role: 'cancel'
        }]
    };
    const alert = await this.alertCtrl.create(alertBody);
    alert.present();
  }

  async showModal() {
    let modal_title = '';
    let message = '';

    if (this.network.checkInternet()) {
      modal_title = 'Syncing In Progress';
      message = 'There is open house information that hasn’t been synced to your account. If you logout, you may lose this information. Please wait for a moment so this information can be synced to your online account.';
    } else {
      modal_title = 'Logout Warning';
      message = 'There is open house information that hasn’t been synced to your account because your device is offline. If you logout, you may lose this information. Turn on cellular data or use Wi-Fi so this information can be synced to your online account.';
    }
    const alertBody = {
      header: modal_title,
      message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Logout',
          handler: () => {
            this.logout();
          }
        },]
    };
    const alert = await this.alertCtrl.create(alertBody);
    alert.present();
  }

  guestSMSnotificationChange() {
    if (this.isDoingFirstLoad) { return; }
    // we are sending the reverse value because we want default in the DB to be false
    // the actuall db value is gueust opt out, but UI will show guest opt in
    this.setDataLoading();
    this.auth.updateUserDataProperty({ guestSMSoptOut: !this.enableGuestSMSFollowUp });
  }

  touchFreeSignInChange() {
    if (this.isDoingFirstLoad) { return; }
    // alert('touchFreeSignInChange()');
    this.setDataLoading();
    this.auth.updateUserDataProperty({ touchFreeSignIn: this.userData.touchFreeSignIn });
  }

  lenderCommunicationChange() {
    if (this.isDoingFirstLoad) { return; }
    // alert('lenderCommunicationChange()');
    this.setDataLoading();
    this.auth.updateUserDataProperty({ enableLenderCommunication: this.userData.enableLenderCommunication });
  }

  logout() {
    const self = this;

    if (this.userSubs) {
      this.userSubs.unsubscribe();
    }
    this.auth.signOutUser().then(() => {

      //  this.isLoggedOut = true;
      self.events.publish('profile:logout');
    });
  }

  public goToKioskMode() {
    const listing = {
      propertyImage: {
        imageURL: '/assets/imgs/profile_stub_photo.jpg'
      }
    } as any;
    if (this.platform.isPortrait()) {
      listing.propertyLayout = PropertyLayout.PORTRAIT_1;
    } else {
      listing.propertyLayout = PropertyLayout.LANDSCAPE_1;
    }

    const navigateExtras: NavigationOptions = {
      queryParams: {
        listing,
        showOverlay: true
      }
    };

    this.navCtrl.navigate(['/property-layout-preview'], navigateExtras);
  }

  // 1 - Sync Guests with CRM
  // 2 - Web Hook URL
  async activateInfoAlert(whichInfo, event?) {
    if (event) {
      event.stopPropagation();
    }

    if (whichInfo === 1) {
      const alertBody = {
        header: '',
        message: 'Using Zapier, you can sync your open house guest data from Curb Hero to most CRM Databases. That way all of your clients exist in one place without manual data entry. With Zapier, you can also connect Curb Hero to thousands of other applications to unlock limitless ways to benefit your business.',
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          },
          {
            text: 'Learn More',
            cssClass: 'no-transform',
            handler: () => {
              if (!this.network.checkInternet()) {
                this.network.noNetworkModal();
                return;
              }
              this.common.openLink('https://curbhe.ro/zapier-integration');
            }
          }]
      };
      const alert = await this.alertCtrl.create(alertBody);
      alert.present();

    }
    else if (whichInfo === 3) { // privacy link
      const alertBody = {
        header: 'Your Privacy Policy For Your Guests',
        message: 'Depending on your jurisdiction, you may either need to have a digital privacy policy for Open House guests or have a printed privacy policy available at the Open House. We\'ve added some quick ways to create an online privacy policy in our Help Section.',
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          },
          {
            text: 'Help Section',
            cssClass: 'no-transform',
            handler: () => {
              if (!this.network.checkInternet()) {
                this.network.noNetworkModal();
                return;
              }
              this.common.openLink('https://curbhe.ro/guest-privacy-policy/');
            }
          }
        ]
      };
      const alert = await this.alertCtrl.create(alertBody);
      alert.present();
    }
    else if (whichInfo === 5) { // touch free sign in

      const showMessage = 'When enabled, this feature displays a QR code on your Open House sign-in screen that can be scanned by any guests that\'d prefer not to use a touch screen sign-in. Look at our Help Section below for more info about this feature.' +
        ' Note: Publishing a listing microsite is required in order for the touch free option to be used in your Open House sign in. A tablet or laptop sized screen is required. This feature is not designed to work on a smartphone. ';

      const alertBody = {
        header: 'Enable Touch Free Open House sign-in',
        message: showMessage,
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          },
          {
            text: 'Help Section',
            cssClass: 'no-transform',
            handler: () => {
              if (!this.network.checkInternet()) {
                this.network.noNetworkModal();
                return;
              }
              this.common.openLink('https://juuj.me/enabling-a-touchless-open-house-sign-in');
            }
          }
        ]
      };
      const alert = await this.alertCtrl.create(alertBody);
      alert.present();
    }
    else if (whichInfo === 6) { // Lender Communication

      const showMessage = 'Paired lenders may want to contact you to collaborate on marketing, discuss prospects, and more. If you\'d prefer not to be contacted by them, you can disable this feature.';

      const alertBody = {
        header: 'Lender Communication',
        message: showMessage,
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          }]
      };
      const alert = await this.alertCtrl.create(alertBody);
      alert.present();
    }
    else if (whichInfo === 7) { // Lender Communication

      const showMessage = 'This log contains Open House Sign-in activity from this device and is meant for troubleshooting. It won\'t contain leads captured on other devices which means that leads that used a QR code or listing microsite to sign-in will not be visible here. This log overwrites itself when the log size exceeds ' + MAX_KIOSK_EVENT_LOGS + ' rows.';

      const alertBody = {
        header: 'Raw Log',
        message: showMessage,
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          }]
      };
      const alert = await this.alertCtrl.create(alertBody);
      alert.present();
    }
    else if (whichInfo === 9) {
      const alertBody = {
        header: 'Pairing a Team Admin',
        message: 'When you pair a team admin they will be able to save you time by setting up your account, branding and listings.',
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          }
        ]
      };
      const alert = await this.alertCtrl.create(alertBody);
      alert.present();
    }
    else if (whichInfo === 10) {
      const alertBody = {
        header: 'Mortgage Section Options',
        message: 'The mortgage section is displayed as part of the Open House Sign-in. For instructions on how to disable the mortgage section in your sign-in, visit the Help Section below.',
        buttons: [
          {
            text: 'OK',
            role: 'cancel'
          },
          {
            text: 'Help Section',
            cssClass: 'no-transform',
            handler: () => {
              if (!this.network.checkInternet()) {
                this.network.noNetworkModal();
                return;
              }
              this.common.openLink('https://juuj.me/unpair-lender');
            }
          }
        ]
      };
      const alert = await this.alertCtrl.create(alertBody);
      alert.present();
    }


    else { // option 2
      if (!this.network.checkInternet()) {
        this.network.noNetworkModal();
        return;
      }
      this.common.openLink('https://curbhe.ro/zapier-integration');
    }
  }

  async openAlert(title, subtitle) {

    const alert = await this.alertCtrl.create({
      header: title,
      message: subtitle,
      buttons: ['OK']
    });
    alert.present();
  }

  addImageClicked(type) {

    console.log('add image clicked ' + type);

    this.editingMode = type;
    this.aspectRatio = 1;

    console.log('editing mode: ' + this.editingMode);

    if (!this.platform.is('cordova')) {
      if (this.editingMode === 'logo') {
        if (this.userRole === 'agent' && this.lockedElements.logoFile) {
          this.showAlert(this.lockedMessageHeader, this.lockedMessage);
        }
        else {
          //  setTimeout(() => { document.getElementById('profileLogoFileInput').click(); }, 100);

          const fileInput = document.getElementById('profileLogoFileInput');
          const clickEvent = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          });
          fileInput.dispatchEvent(clickEvent);
        }
      }
      else if (this.editingMode === 'profile') {
        const fileInput = document.getElementById('micrositeProfileFileInput');
        const clickEvent = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        });
        fileInput.dispatchEvent(clickEvent);
      }
    }
    // native platforms
    else {

      if (this.userRole === 'agent'
        && this.editingMode === 'logo'
        && this.lockedElements.logoFile) {
        this.showAlert(this.lockedMessageHeader, this.lockedMessage);
        return;
      }

      // logo must be done online
      if (!this.network.checkInternet() && this.editingMode === 'logo') {
        this.network.noNetworkModal();
        return;
      }

      this.imageService.checkPhotoPermission().then((res) => {
        if (res) {

          this.imageService.selectPhoto()
            .then((data) => {
              if (data) {
                console.log('native image local data url: ' + data);
                this.imageToEdit = data;
                this.showPintura = true;
                this.editWebImage(data);

              }
            }).catch((error) => {
              this.common.closeLoading();
              if (error) {
                this.auth.logErrors('Error in image select: ' + error);
              }

              if (error === 'limit') {
                this.common.showImageLimit();
              }
            });
        }
      }).catch(err => {
        console.log('listing image service err: ' + err);
        if (err) {
          this.auth.logErrors('Error in checkPhotoPermission: ' + err);
        }
      });
    }
  }

  async confirmModal(image) {
    const me = this;
    const imageAlert = await this.alertCtrl.create({
      header: 'This will replace your current logo',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'OK',
          handler: () => {
            // if (!me.network.checkInternet()) {
            //   me.network.noNetworkModal();
            //   return;
            // }
            this.imageService.toDataUrl(image).then((data) => {
              me.saveImage(image, data);
            });
          }
        },

      ]
    });
    if (this.userRole === 'agent' && this.lockedElements.logoFile) {
      this.showAlert(this.lockedMessageHeader, this.lockedMessage);
    }
    else {
      imageAlert.present();
    }
  }

  onProfileImageDelete(photo) {
    this.deleteConfirmInfoModal(photo);
  }


  async deleteConfirmInfoModal(photo) {
    const self = this;

    const deleteConfirmInfoModal = await this.alertCtrl.create({
      header: 'Delete Profile Image',
      message: 'Are you sure you want to delete this Profile Image?',
      buttons: [
        {
          role: 'cancel',
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Delete',
          handler: data => {

            self.common.startLoading();

            if (this.platform.is('cordova')) {
              if (photo.thumbnailPath) {
                this.imageService.removeImage(photo.thumbnailPath);
              }
              if (photo.imagePath) {
                this.imageService.removeImage(photo.imagePath);
              }
            }

            self.imageService.deleteProfileImage(photo.thumbnailURL);
            self.imageService.deleteProfileImage(photo.imageURL).then(() => {
              console.log('deleted profile photo call finished.');
            });

            self.myProfilePhoto = {
              id: '',
              imagePath: '',
              imageURL: '',
              thumbnailPath: '',
              thumbnailURL: ''
            };

            self.auth.updateUserDataProperty({ profilePhoto: [] }).then(res => {
              self.syncImagesAcrossSessions();
            });

            self.common.closeLoading();

          }
        },
      ]
    });
    deleteConfirmInfoModal.present();
  }

  async processSelectedImage(img) {
    const self = this;
    console.log('processSelectedImage ' + this.editingMode);

    if (this.userRole === 'agent'
      && this.editingMode === 'logo'
      && this.lockedElements.logoFile) {
      this.showAlert(this.lockedMessageHeader, this.lockedMessage);
      return;
    }

    if (this.editingMode === 'logo') {
      this.uploadingLogo = true;
      const fileName = this.imageService.generateImageID(25) + '_logo';

      const storage = getStorage();

      const storageRef = ref(storage, 'users/' + this.auth.getUID() + '/logo/' + fileName);
      const uploadTask = uploadBytesResumable(storageRef, img);

      uploadTask.then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          this.common.closeLoading();
          this.saveImage(url, '');
        });
      });
    }

    else if (this.editingMode === 'profile') {
      const id = this.imageService.generateImageID(25);
      const fileName = `${id}_profile`;

      this.fileService.writeFile(this.fileService.dataDirectory, fileName, img, { replace: true });

      const newImg = {
        id,
        imagePath: fileName,
        imageURL: ''
      };

      if (self.userData.profilePhoto.length === 0) {
        self.userData.profilePhoto.push(newImg);
      }
      else {
        self.userData.profilePhoto[0] = newImg;
      }
      self.myProfilePhoto = newImg;
      self.auth.updateUserDataProperty({ profilePhoto: self.userData.profilePhoto }).then(res => {
        self.syncImagesAcrossSessions();
        self.syncImageToStorage();
      });
    }
  }

  // SYNC PROFILE IMAGE TO STORAGE
  syncImageToStorage() {
    const self = this;

    if (!this.platform.is('cordova')) {
      return;
    }

    console.log('syncImageToStorage called.');
    if (!self.myProfilePhoto.imagePath || self.myProfilePhoto.imagePath === '') {
      console.log('Image path does not exist. returning.');
      return;
    }

    self.imageService.realImageData(self.myProfilePhoto).then((data) => {
      self.imageService.toDataUrl(data).then((dt) => {

        const photoPath = 'profile' + '/' + self.myProfilePhoto.id;
        self.imageService.uploadImage(photoPath, 'profile', dt).then((imgURL) => {
          if (imgURL) {
            self.userData.profilePhoto[0].imageURL = imgURL.toString();
            self.myProfilePhoto.imageURL = imgURL;

            self.auth.updateUserDataProperty({ profilePhoto: self.userData.profilePhoto }).then(res => {
              console.log('done updating thumbnail');
              self.saveThumbnailPhoto(dt, self.myProfilePhoto);
            });
          }
          else {
            self.common.showSimpleError(this.i8nService.messages.errorUploadingPhoto);
          }
        }).catch((error) => {
          self.auth.logErrors('Error uploading file 2: ' + error + ' ', true);
          self.common.showSimpleError(this.i8nService.messages.errorUploadingPhoto);
        });
      });
    });
  }

  async changeIntro(event) {

    const editModal = await this.modalCtrl.create({
      component: EditInputPage, componentProps: {
        label: `Sign-in Intro`,
        data: this.introText,
        limit: 50
      }
    });

    await editModal.present();

    const { data } = await editModal.onWillDismiss();

    if (data === undefined || data === null) {
      return;
    }
    this.introText = data || DEFAULT_WELCOME_MESSAGE;
    this.auth.updateUserDataProperty({ customWelcome: this.introText });


    // ;
  }

  async changeOutro(event) {
    const editModal = await this.modalCtrl.create({
      component: EditInputPage, componentProps: {
        label: `Sign-in Outro`,
        data: this.outroText,
        limit: 50
      }
    });

    await editModal.present();
    const { data } = await editModal.onWillDismiss();

    if (data === undefined || data === null) {
      return;
    }
    this.outroText = data || DEFAULT_THANKS_MESSAGE;
    this.auth.updateUserDataProperty({ customThanks: this.outroText });

    // this.auth.updateUserDataProperty({ customThanks: event.target.value });
  }

  async changePolicy(event) {
    const editModal = await this.modalCtrl.create({
      component: EditInputPage, componentProps: {
        label: `Link to Privacy Policy`,
        data: this.linkToPrivacy || '',
        limit: 655
      }
    });
    await editModal.present();
    const { data } = await editModal.onWillDismiss();

    if (data === undefined || data === null) {
      return;
    }
    this.linkToPrivacy = data || '';
    this.auth.updateUserDataProperty({ customPrivacyPolicy: this.linkToPrivacy });
  }

  setDisclaimerPosition(value) {
    this.auth.updateUserDataProperty({ disclaimerPosition: value });
  }

  async saveImage(image, base64) {
    console.log('saveImage func called for logo: ' + image);
    const self = this;


    // USE STORAGE IMAGE LOGOS INSTEAD OF LOCAL
    if (image && image.startsWith('assets')) {
      const imgName = image.split('/');
      console.log(JSON.stringify(imgName));
      image = environment.storageLogos + imgName[3] + '?alt=media';
    }

    self.userData.logoFile = image;
    User_global.logoFile = image;

    try {
      this.imageService.removeImage(this.auth.getUID() + '_qrCodeOnlylink_qr_profile');
      //  this.imageService.removeImage('qrCodeOnlylink_qr_profile');
    }
    catch (err) {
      console.log('err deleting file: ' + err);
    }

    await self.utils.updateLogoFile(image);

    self.auth.updateUserDataProperty({ logoFile: image }).then(res => {
      self.syncImagesAcrossSessions();
      setTimeout(() => { self.auth.resetDynamicQRCode(); }, 3000);
      setTimeout(() => { this.events.publish('refresh:qrCode'); }, 8000);
    });

    self.loadUserLogo();
  }

  validImage(image, callback) {
    const tempImg = new Image();
    tempImg.src = image;
    tempImg.onload = () => {
      // Get image size and aspect ratio.
      const aspect = tempImg.width / tempImg.height;
      if (aspect >= 2.5 && aspect <= 3.5) {
        //valid image
        callback(true);
      }
      else {
        callback(false);
        //ratio does not match
      }
    };
  }

  /*
  getRandomImages(arr, n) {
    const result = new Array(n),
      len = arr.length,
      taken = new Array(len);
    if (n > len)
      throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
  */

  setDataLoading() {
    this.dataIsLoading = true;

    setTimeout(() => {
      this.dataIsLoading = false;
    }, 1000);
  }

  hideEditor() {
    this.zone.run(() => { this.showPintura = false; });
  }

  editorComplete(success) {
    this.hideEditor();
    if (success) {
      this.processSelectedImage(success.image);
    }
  }

  checkForUserRole() {
    this.userRole = User_global.role;
  }
  syncImagesAcrossSessions() {
    setTimeout(() => {

      // last updated photo as a number
      const lastUpdated = {
        profilePhotoLastUpdated: moment(new Date()).unix()
      };
      this.auth.updateUserDataProperty(lastUpdated);

      this.events.publish('refresh:userImages');

    }, 2000);
  }

  async showRawLeadLog() {
    const results = await this.sql.getKioskTrackingData(500);
    const offlineGuestsArray = await this.sql.getGuestData() as Array<any>;

    this.auth.logClientInfo('Raw Lead log opened by user: ' + JSON.stringify(results));
    this.auth.logClientInfo('All offline leads log opened by user: ' + JSON.stringify(offlineGuestsArray));

    const rawleadspopup = await this.modalCtrl.create({
      component: ViewRawLeadsComponent,
      componentProps: {
        title: 'Open House Sign-in Log',
        rawData: results,
        mode: 'openHouseActivity'
      }
    });
    await rawleadspopup.present();
    const { data } = await rawleadspopup.onWillDismiss();
  }

  async toggleReview(item, index, event) {
    console.log(' event.checked ' + event.checked + ' index ' + index + ' ' + JSON.stringify(this.userData.reviews[index]));
    if (event.checked === this.userData.reviews[index].review.enabled) {
      console.log('no change to review toggle, returning');
      return;
    }

    const totalEnabled = this.userData.reviews.filter((el) => el.review.enabled === true);
    if (item.enabled && totalEnabled.length === 2) {

      const myAlert = await this.alertCtrl.create({
        header: 'Limit Reached',
        message: this.i8nService.messages.reviewLinkLimitReached,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              this.userData.reviews[index].review.enabled = false;
              this.userReviews[index].review.enabled = false;
              item.review.enabled = false;
            }
          }
        ]
      });
      await myAlert.present();
      return;
    }

    if (item.reviewStars === 0) {
      this.openRatingWindow(item, event);
    }
    else {
      this.userData.reviews[item.order] = { review: item };
      console.log('updating toggle review: ' + JSON.stringify(this.userData.reviews));
      this.auth.updateUserDataProperty({ reviews: this.userData.reviews });
    }
  }

  async openRatingWindow(review, event) {
    // event.stopPropagation();

    const initialStatus = review.enabled;
    const self = this;
    const totalEnabled = this.userData.reviews.filter((el) => el.review.enabled === true);
    try {

      const profileModal = await self.modalCtrl.create({
        component: EditReviewPage,
        componentProps: {
          label: review.name,
          data: review,
          edit: true,
          showTitle: true
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismissing review modal ' + JSON.stringify(data));
      if (data === undefined || data === null) {

        if (!review.reviewCount || !review.reviewStars) {
          review.enabled = false;
        }

        this.userData.reviews[data.order] = { review };
        this.auth.updateUserDataProperty({ reviews: this.userData.reviews });

        return;
      }

      if (!data.reviewCount || !data.reviewStars) {
        data.enabled = false;
      }

      // only allow 2 enabled reviews

      if (!initialStatus && totalEnabled.length === 2) {
        data.enabled = false;
        review.enabled = false;
      }

      this.userData.reviews[data.order] = { review: data };

      this.auth.updateUserDataProperty({ reviews: this.userData.reviews });
    }
    catch (err) {
      console.log('error opening rating ' + err);
    }
  }


  async loadDisclosureDoc(): Promise<void> {
    const self = this;

    try {
      const res = await this.auth.getDisclosures();
      if (res && res.active === true) {
        self.disclosureDoc = JSON.parse(JSON.stringify(res));
        console.log('disclosure doc: ' + JSON.stringify(res));
      }
    } catch (err) {
      self.disclosureDoc = false;
      console.log('err in fetch doc: ' + err);
    }
  }


  getLockImage(isDisabled) {

    if (User_global.role === 'agent' && (!this.adminConnections || !this.adminConnections.length)) {
      return '';
    }

    if (isDisabled) {
      return 'assets/imgs/lock-closed.svg';
    } else {

      if (User_global.role === 'agent') {
        return '';
      }

      return 'assets/imgs/lock-open.svg';
    }

  }


  isLocked(isDisabled) {

    if (User_global.role === 'agent' && (!this.adminConnections || !this.adminConnections.length)) {
      return false;
    }

    if (User_global.role === 'agentAdmin' || User_global.role === 'lender') {
      return false;
    }

    return isDisabled || false;
  }

  async visitWebApp() {

    const token = this.auth.getUserAuthToken();
    const link = environment.webapp + '/?token=' + token;
    this.common.openLink(link, '_system');
  }

  showEditLogo() {
    if (this.userRole === 'agent' && this.isDisabled) {
      return true;
    }
    else if (this.userData.logoFile) {
      return true;
    }
  }

  algoliaFocusChange(event) {
    console.log('algoliaFocusChange: ' + JSON.stringify(event));
    if (!event) {
      setTimeout(() => {
        if (this.showEmailSearchResults === true) {
          this.showEmailSearchResults = event;
        }
      }, 200);
    }
  }

  algoliaSearchInputChanged(event) {
    console.log(JSON.stringify(event));

    this.inviteEmail = event.trim();

    this.emailValid = EMAIL_REGEX.test(event) ? true : false;

    if (event && event.trim() !== '') {
      this.showEmailSearchResults = true;
    }
    else {
      this.showEmailSearchResults = false;
    }
  }
}
