/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Inject, forwardRef, Optional, Output, Input, OnChanges, SimpleChanges, ViewChild, OnInit } from '@angular/core';
import {
  TypedBaseWidget,
  NgAisInstantSearch,
  NgAisIndex,
} from 'angular-instantsearch';

import connectSearchBox, {
  SearchBoxWidgetDescription,
  SearchBoxConnectorParams
} from 'instantsearch.js/es/connectors/search-box/connectSearchBox';
import { EventEmitter } from '@angular/core';
import { Events } from 'src/app/services/events.service';
import { CommonProvider } from 'src/app/services/common';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-search-box',
  templateUrl: './algolia-search-box.component.html',
  styleUrls: ['algolia-search-box.component.scss']
})

export class SearchBox extends TypedBaseWidget<SearchBoxWidgetDescription, SearchBoxConnectorParams>  implements OnInit{

  @ViewChild('input') mainInput: IonInput;
  @Output() readonly inputChanged = new EventEmitter<any>();
  @Output() readonly focusChanged = new EventEmitter<any>();
  @Input('type') type;

  lastFocus = false;
  inputValue;
  hintMessage: string;
  public state: SearchBoxWidgetDescription['renderState']; // Rendering options; // Rendering options

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch,
    private events: Events
  ) {
    super('SearchBox');

    events.subscribe('invite:inviteUserSuccess', (data) => {
      this.mainInput.value = '';
    });
  }
  ngOnInit() {
    this.createWidget(connectSearchBox, {
      // instance options
    });
    super.ngOnInit();
    if(this.type === 'lender'){
      this.hintMessage = 'Enter lender\'s name or email';
    }
    else if(this.type === 'agent'){
      this.hintMessage = 'Search for Agents';
    }
    else if(this.type === 'agentAdmin') {
      //agentAdmin
      this.hintMessage = 'Name or email';
    }
  }

  inputChange(value) {
    this.inputChanged.emit(value);
  }



  focusChange(isFocused) {
    if (this.lastFocus !== isFocused) {
      this.lastFocus = isFocused;
      this.focusChanged.emit(isFocused);
    }
  }
}
