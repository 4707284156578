/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
export const MAX_GUEST_COUNT = 3;
export const GUEST_NOTE_PLACEHOLDER = 'Add notes for this lead';
export const LATITUDE = -34.9290;
export const LONGITUDE = 138.6010;
export const IOS_INTERCOM_SECRET = 'U6-84KW3duPfeRKcHJRjTS8_lj4u0YNCYw2u2sDy';
export const ANDROID_INTERCOM_SECRET = 's4KBIMJReyAfjcwH2McK7FngxC42ArWEjFrJjSn9';
export const WEBAPP_INTERCOM_SECRET = 'FBghFaC1gtv8TsoHvXiwM3WuHqLeHGdty9nG543P';


export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const DEFAULT_WELCOME_MESSAGE = 'TAP ANYWHERE TO START';
export const DEFAULT_THANKS_MESSAGE = 'THANK YOU FOR STOPPING IN';
export const DEFAULT_PRIVACY_POLICY = 'https://yoursite.com/privacy-policy';
export const DEFAULT_WEB_SECTION_TITLE = 'Featured Properties';
export const DEFAULT_WEB_LAYOUT = 'full-grid';
export const SMS_SEND_THRESHOLD_TIME = 0.5;
export const ANDROID_KEYBOARD_HEADER = 60;
export const IOS_DOWNLOAD = 'https://apps.apple.com/us/app/block-party-open-house/id1455260281';
export const ANDROID_DOWNLOAD = 'https://play.google.com/store/apps/details?id=app.blockparty.openhouse';
export const MASK = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
export const PHONE_REGEX = /^(\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const MIN_USERNAME_LENGTH = 6;
export const MAX_FEATURED_LINKS = 8;
export const MAX_SOCIAL_LINKS = 6;
export const MAX_LISTINGS_LINKS = 9;

export const MAX_QR_UPLOAD_RETRY = 3;

export const MAX_APP_TIMEOUT = 20 * (60 * 1000); // 20 MIN
export const MAX_KIOSK_EVENT_LOGS = 5000; // MAXIMUM LOGS FOR KIOSK TRACKING, CLEAN UP AFTER THIS MANY RECORDS

// CONNECTIONS ACTIONS
export const CONNECTION_INVITE = 'connection_invite';
export const CONNECTION_ACCEPT = 'connection_accept';
export const CONNECTION_AGENT_DISMISSED = 'agent_dismissed';
export const CONNECTION_LENDER_DISMISSED = 'lender_dismissed';

export const MAX_LISTING_PHOTOS = 10; // MAX LISTING PHOTOS UPLOAD

export const BLANK_SOCIAL_LINKS = [
  { id: 'facebook', title: 'Facebook URL', URL: '', order: 0, enabled: false },
  { id: 'instagram', title: 'Instagram URL', URL: '', order: 1, enabled: false },
  { id: 'linkedin', title: 'LinkedIn URL', URL: '', order: 2, enabled: false },
  { id: 'twitter', title: 'Twitter URL', URL: '', order: 3, enabled: false },
  { id: 'yelp', title: 'Yelp URL', URL: '', order: 4, enabled: false },
  { id: 'youtube', title: 'YouTube URL', URL: '', order: 5, enabled: false },
  { id: 'zillow', title: 'Zillow URL', URL: '', order: 6, enabled: false },
  { id: 'tiktok', title: 'TikTok URL', URL: '', order: 7, enabled: false }];

export const BLANK_TOUR_LINKS = [{ id: 'virtualTour', title: 'Virtual Tour URL', URL: '', order: 0, enabled: false },
{ id: 'videoTour', title: 'Video Tour URL', URL: '', order: 1, enabled: false }];

export enum PropertyLayout {
  LANDSCAPE_1 = 1,
  LANDSCAPE_2 = 2,
  PORTRAIT_1 = 3,
  PORTRAIT_2 = 4,
}

export const EMAIL_ID_DOMAINS = [{ id: 1, title: '@gmail.com' }, { id: 2, title: '@yahoo.com' },
{ id: 3, title: '@outlook.com' }, { id: 4, title: '@aol.com' }];
