/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { SMS_SEND_THRESHOLD_TIME } from '../constants';
import { Injectable } from '@angular/core';
import { CommonProvider } from '../services/common';
import { environment } from '../../environments/environment';
import { NetworkProvider } from '../services/network';
import { ListingDataProviderService } from './listing-data-provider.service';
import { GuestService } from './guest.service';
import { AuthService } from './auth.service';
import { from, concat, Observable, asyncScheduler, map, mergeAll, toArray } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User_global, Listing } from '../../app/app.models';
import { ImagesManagementService } from './images-management.service';
// import { rejects } from 'assert';

@Injectable({
  providedIn: 'root'
})
export class SMSService {
  smsSendTo = [];
  constructor(
    private http: HttpClient,
    private listingService: ListingDataProviderService,
    private guestService: GuestService,
    private authService: AuthService,
    public common: CommonProvider,
    private networkProvider: NetworkProvider,
    private imageService: ImagesManagementService
  ) { }

  sendMessages(listings): Observable<any> {
    const ar = [];
    listings.forEach((listing) => {
      if (listing.guestFollowUp) {
        listing.guests.forEach((guest) => {
          const currentTime = this.common.getPSTTime().getTime();
          const hours = Math.abs(currentTime - guest.createdDate) / 36e5;
          const guestKey = `${guest.listingId}_${guest.id}`;
          if (this.smsSendTo.indexOf(guestKey) === -1 && hours < SMS_SEND_THRESHOLD_TIME && (!guest.messages || guest.messages.length === 0)) {
            this.smsSendTo.push(guestKey);
            ar.push(
              from(
                this.sendSMSInHalfAnHour(listing.id, guest.guest.phoneNumber, guest.guestId)
              )
            );
          }
        });
      }
    });
    return from(ar, asyncScheduler);
  }

  addMessageInfo(guestId: string, messageData: any, uid: any) {
    this.guestService.update(guestId, { messages: messageData });
    return Promise.resolve();
  }

  sendSMSInHalfAnHour(
    listingId: any,
    phoneNumber: any,
    guestId: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const uid = this.authService.getUID();
      this.listingService.listing(listingId).then((currentListing: any) => {
        if (currentListing.guestFollowUp) {
          const messageArr = [];
          if (currentListing.textMessage) {
            messageArr.push({
              type: 'textMessage',
              message: currentListing.textMessage
            });
          }
          if (currentListing.linkToInfo) {
            messageArr.push({
              type: 'linkToInfo',
              message: currentListing.linkToInfo
            });
          }

          if (messageArr.length > 0) {
            const messages = messageArr.map((element) =>
              from(this.sendSMS(element.message, phoneNumber)).pipe(
                map((res) => ({
                  type: element.type,
                  isSendSuccessFull: res,
                  sentDate: this.common.getPSTTime().getTime(),
                }))
              )
            );
            const mesObs = concat(messages).pipe(mergeAll(), toArray());
            mesObs.subscribe({
              next: (guestMsg) => {
                this.addMessageInfo(guestId, guestMsg, uid)
                  .then((res) => {
                    console.log('message add successfully.');
                    resolve('ok');
                  })
                  .catch((error) => {
                    console.log(error);
                    reject(error);
                  });
              },
            });
          }
          else {
            resolve(`no messages: listing: ${listingId} guest: ${guestId}`);
          }

        } else {
          resolve(`no follow up: listing: ${listingId} guest: ${guestId}`);
        }
      });
    });
  }

  sendSMS(message: string, to: string): Promise<any> {
    if (!this.networkProvider.checkInternet()) {
      return Promise.resolve(false);
    }
    const body = new FormData();
    //body.append('To', '+917691072682');
    body.append('To', '+1' + to);
    body.append('From', environment.twilio.twilioFromNumber);
    body.append('Body', message);

    const headers = new HttpHeaders();
    headers.append('Authorization', 'Basic ' + btoa(environment.twilio.twilioAccountSId + ':' + environment.twilio.twilioAuthToken));
    return new Promise((resolve, reject) => {
      this.http.post(environment.twilio.twilioApiUrl + environment.twilio.twilioAccountSId + '/Messages.json', body, { headers: headers }).subscribe((d) => {
        resolve(true);
      }, () => {
        resolve(false);
      });
    });
  }

  async sendEmailWebhook(email, listing: Listing = null, type: 'lender' | 'agentAdmin' = 'lender'): Promise<any> {
    console.log('sendEmailWebhook called.');
    const user = User_global;
    const body: any = {};

    if (!this.networkProvider.checkInternet()) {
      return false;
    }

    body.lenderEmail = email;
    body.agentEmail = user.email;
    body.name = user.username;
    body.company = user.company;
    body.phoneNumber = user.phoneNumber;

    if (listing) {
      body.listingAddress = listing.address.title;
      body.listingState = listing.address.state;
    }
    else {
      body.listingAddress = '';
      body.listingState = '';
    }

    // get full firebase url link
    console.log('sending email webhook: ' + email + ' ' + JSON.stringify(body));
    let inviteURL;

    if (type === 'lender') {
      inviteURL = environment.connectionInviteLender;
    }
    else if (type === 'agentAdmin') {
      inviteURL = environment.inviteAgentAdmin;
      body.adminEmail = email;
    }

    if (!inviteURL) {
      return;
    }

    // if there is a user image
    if (user.profilePhoto?.[0]?.imageURL) {
      const img = await this.imageService.firebaseUrl(user.profilePhoto[0].imageURL)

      console.log('photo fetche: ' + img);
      body.profilePhoto = img;      
    }

    return this.authService.sendUserInvite(inviteURL, body);
  }
}
