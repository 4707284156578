/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked, HostListener, ViewEncapsulation } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, LoadingController } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { NetworkProvider } from '../../services/network';
import { SMSService } from '../../services/sms.service';
import { environment } from '../../../environments/environment';
import { CommonProvider } from '../../services/common';
import { AuthService } from '../../services/auth.service';
import Swiper, { Navigation, Pagination, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
Swiper.use([Pagination, Navigation]);


@Component({
  selector: 'app-enhanced-modal',
  templateUrl: 'enhanced-modal.html',
  styleUrls: ['enhanced-modal.scss']
})
export class EnhancedModalPage implements OnInit, AfterViewChecked {

  // @ViewChild('enhancedModalSlides') slides: IonSlides;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  slideIndex: any;
  loanOfficer: any;
  loanOfficerReviews: any;
  currentPage = 'Intro';
  // Since a couple of slides are shared for both inviting lenders and admins, we track which version are we supposed to show
  cameFrom: 'lender' | 'agentAdmin' = 'lender';
  inviteEmail = '';
  listing: any;
  webhookSent = false;
  showAllSlides = true;
  swiper1: Swiper;

  config: SwiperOptions = {
    navigation: true,
    pagination: { clickable: true },
    scrollbar: { draggable: true },
  };

  constructor(
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalCtrl: ModalController,
    public viewCtrl: ModalController,
    public plt: Platform,
    public network: NetworkProvider,
    public file: File,
    public loadingCtrl: LoadingController,
    private messageService: SMSService,
    private common: CommonProvider,
    private auth: AuthService
  ) {

  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  ngOnInit(): void {
    this.slideIndex = this.navParams.get('data');
    this.loanOfficer = this.navParams.get('loanOfficer');
    this.loanOfficerReviews = this.navParams.get('loanOfficerReviews');
    this.listing = this.navParams.get('listing');
    this.inviteEmail = '';
    this.webhookSent = false;

    const self = this;
    console.log('show enhanced modal for listing: ' + JSON.stringify(this.listing));

    console.log('loanOfficerReviews: ' + JSON.stringify(this.loanOfficerReviews));

    // attach img path to logos
    for (let i = 0; i < self.loanOfficerReviews.length; i++) {
      if (!self.loanOfficerReviews[i].review.reviewLogo.startsWith('assets')) {
        self.loanOfficerReviews[i].review.reviewLogo = environment.lenderReviewImages + self.loanOfficerReviews[i].review.reviewLogo;
      }
    }

    //alert(JSON.stringify(this.loanOfficer.objectID) + ' ' + this.loanOfficer.regionalLender);
    if (this.loanOfficer && this.loanOfficer.name !== '' && this.slideIndex === 'Intro') {

      if (this.loanOfficer.regionalLender === true) {
        this.showAllSlides = true;
        this.currentPage = 'Intro';
      }
      else {
        this.showAllSlides = false;
        this.currentPage = 'Intro';
      }


    }
    else {
      this.currentPage = 'EnableLender';
    }

    // this.swiper1 = new Swiper('.swiper', {
    //   slidesPerView: 'auto',
    //   initialSlide: 0,
    //   resistanceRatio: 0,
    //   slideToClickedSlide: true,
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true
    //   },
    // });
  }

  ngAfterViewChecked() {
    if (this.swiper && this.swiper.swiperRef) {
      this.swiper.swiperRef.update();
    }
  }

  changeEnhancedMode(value) {
    this.viewCtrl.dismiss({ type: 'enhancedMode', enhancedMode: value });
  }

  nextSlide() {
    this.swiper.swiperRef.slideNext();
  }

  tellMeMore() {
    this.viewCtrl.dismiss({ type: 'tellMeMore' });
  }

  disableLender() {
    this.currentPage = this.loanOfficer.regionalLender ? 'DisableRegionalLender' : 'DisableLender';
  }

  openLoanOfficerWebsite() {
    console.log('open website: ' + this.loanOfficer.website);
    if (this.loanOfficer.website) {
      this.common.openLink(this.loanOfficer.website);
    }
  }

  openExternalLink(link) {
    if (link === 1) {
      this.common.openLink('https://juuj.me/all-about-the-expert-lender-concierge');
    }
    else {
      this.common.openLink('https://juuj.me/what-are-enhanced-features');
    }
  }

  dismiss() {
    console.log('dismiss enhanced details');
    this.viewCtrl.dismiss();
  }

  invite(roleToInvite: 'lender' | 'agentAdmin') {
    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    this.cameFrom = roleToInvite;
    this.currentPage = 'Invite';
  }

  async sendEmailWebhook() {
    this.webhookSent = true;
    const debugEmail = JSON.stringify(this.inviteEmail);

    try {
      const res = await this.messageService.sendEmailWebhook(this.inviteEmail, this.listing, this.cameFrom);

      if (!res) {
        this.auth.logErrors('Error inviting lender 1: ' + this.inviteEmail + ' listing :' + JSON.stringify(this.listing));
        this.common.showDBWriteError();
      }
      else {
        this.inviteEmail = '';
        this.currentPage = 'ThankYouPage';
      }
    } catch (error) {
      console.log('webhook error : ' + error);
      this.auth.logErrors('Error inviting lender 2: ' + debugEmail + ' error: ' + error);
      this.common.showDBWriteError();
    }
  }
}
