/* eslint-disable @typescript-eslint/dot-notation */
import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { NavParams, ModalController, Platform, IonInput, IonTextarea, IonButton, AlertController } from '@ionic/angular';
import { I8nService } from 'src/app/services/i8nService';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from '../../services/auth.service';
import { NetworkProvider } from 'src/app/services/network';
import { CommonProvider } from 'src/app/services/common';

const DEFAULT_LIMIT = 160;
@Component({
  selector: 'app-edit-input',
  templateUrl: 'edit-input.html',
  styleUrls: ['edit-input.scss']
})

export class EditInputPage implements OnInit {

  @ViewChild('ionInput') ionInput: IonInput;
  @ViewChild('ionTextarea') ionTextarea: IonTextarea;

  @ViewChild('ionButtonCreate') ionButtonCreate: IonButton;
  @ViewChild('ionButtonSave') ionButtonSave: IonButton;

  public isFullLabel: boolean;
  public inputLabel: any;
  public inputData;
  public countdown = 0;
  public limitNum = 160;
  public minLimit = 1;
  public isDark = true;
  public rows = 1;
  public inputType;

  showFormTitle = false;
  showTitleOnTop = true;

  isDisabled = false;
  micrositeUsernameInput = false;
  timeout: any;
  usernameAvailable = true;
  originalText = '';
  userTyping = false;
  showDeleteButton = false;
  numericInput = false;
  loaded = false;
  debounceValue = 0;
  showChoice = false;
  choiceDetails = { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' };
  featureFlags;
  placeholder = '';
  helperText = '';
  usernameErrorDisplay = '';

  constructor(
    private auth: AuthService,
    private elementRef: ElementRef,
    public plt: Platform,
    public navParams: NavParams,
    public viewCtrl: ModalController,
    public i8nService: I8nService,
    public utils: UtilsService,
    private alertCtrl: AlertController,
    private networkProvider: NetworkProvider,
    private common: CommonProvider
  ) {

    this.isFullLabel = this.navParams.get('isFullLabel');
    this.inputLabel = this.navParams.get('label');
    this.inputData = this.navParams.get('data');
    this.limitNum = this.navParams.get('limit') || DEFAULT_LIMIT;
    this.minLimit = this.navParams.get('minLimit') || 1;
    this.showDeleteButton = this.navParams.get('showDeleteButton') || false;
    this.numericInput = this.navParams.get('numericInput') || false;

    this.placeholder = this.navParams.get('placeholder') || '';
    this.helperText = this.navParams.get('helperText') || '';

    this.inputType = this.navParams.get('inputType') || '';
    this.featureFlags = this.navParams.get('featureFlags') || {};

    this.userTyping = false;
    this.rows = this.navParams.get('rows') || 1;
    this.micrositeUsernameInput = this.navParams.get('micrositeUsernameInput') || false;
    this.originalText = String(this.inputData.toString());

    if (this.micrositeUsernameInput) {
      this.debounceValue = 500;
    }

    this.showChoice = this.inputType === 'multiChoice'; // change to read type

    if (this.showChoice) {
      if (this.featureFlags?.answerTypeChoiceWithDetails === false) {
        this.showChoice = false;
      }

      this.choiceDetails = this.navParams.get('details') || { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' };
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.dismiss();
  }

  setChoiceType(selection) {
    this.choiceDetails.selectionType = selection;
  }

  limitText() {
    if (this.inputData) {
      if (this.inputData.length > this.limitNum) {
        this.isDisabled = true;
        this.isDark = false;
        this.countdown = this.limitNum - this.inputData.length;

        // this.inputData = this.inputData.substring(0, this.limitNum);
      } else {
        this.isDisabled = false;
        this.countdown = this.limitNum - this.inputData.length;
        this.isDark = true;
      }
    } else {
      this.countdown = this.limitNum;
    }

    // verify usernames for microsite
    // strip all the special characters
    if (this.micrositeUsernameInput) {

      setTimeout(() => {

        if (this.inputData) {
          this.userTyping = true;

          console.log('Input data before cleaned:', this.inputData);
          // Clean up input data
          this.inputData = this.inputData.trim().toLowerCase().replace(/[\n\r\s\t]+/g, '').replace(/[^a-z0-9]/g, '');

          console.log('Input data cleaned:', this.inputData);
          this.verifyUsername();
        }
      }, 2000);
    }
  }

  onPasteHandler(value) {
    console.log('on paste handler called with value: ' + value);
    this.limitText();
  }


  ngOnInit(): void {

    this.limitText();
    this.loaded = true;
    setTimeout(() => {
      if (this.ionInput != null) {
        this.ionInput.setFocus();
      }
      else if (this.ionTextarea != null) {
        this.ionTextarea.setFocus();
      }

    }, 1500);
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

  validateBeforeDone() {
    if (this.ionButtonCreate != null && !this.ionButtonCreate.disabled) {
      return this.done();
    }
    else if (this.ionButtonSave != null && !this.ionButtonSave.disabled) {
      return this.done();
    }

    return false;
  }

  done() {

    if (this.showChoice) {
      const data = { answer: this.inputData, details: this.choiceDetails };
      this.viewCtrl.dismiss(data);
      return;
    }

    this.viewCtrl.dismiss(this.inputData);
  }

  // verify username for microsite
  verifyUsername() {
    const self = this;

    clearTimeout(self.timeout);

    self.timeout = setTimeout(() => {

      self.inputData = this.inputData.trim().toLowerCase().replace(/[\n\r\s\t]+/g, '').replace(/[^a-z0-9]/g, '');

      if (!self.micrositeUsernameInput || self.inputData.trim() === '' || self.inputData.length < this.minLimit) {
        return;
      }

      console.log('check username for ' + self.inputData);
      self.auth.checkUsernameAvailable(self.inputData).then(res => {
        console.log('response: ' + res);
        if (res.data.status === 'USERNAME_AVAILABLE') {
          self.usernameAvailable = true;
        }
        else if (res.data.status === 'USERNAME_EXISTS') {
          self.usernameAvailable = false;
          self.usernameErrorDisplay = this.i8nService.messages.usernameTaken;
        }
        else if (res.data.status === 'EMPTY_USERNAME') {
          self.usernameAvailable = false;
          self.usernameErrorDisplay = 'Username cannot be empty.';
        }
        else {
          // Handle unexpected response
          console.error('Unexpected response:', res);
          self.usernameAvailable = false;
          self.usernameErrorDisplay = 'Error while checking username.';
          this.auth.logErrors(res, false);
        }

        self.userTyping = false;
      }).catch(error => {
        console.error('Error checking username:', error);
        this.auth.logErrors(error, true);
        self.usernameAvailable = false; // Set availability to false on error
        self.userTyping = false; // Update typing state
        self.usernameErrorDisplay = 'Error while checking username';
      });
    }, 1500);
  }

  deleteOption() {
    this.viewCtrl.dismiss('##DELETE-CHOICE##');
  }

  async showInformationWindow() {
    const callbackURL = 'https://juuj.me/choice-questions';

    const myAlert = await this.alertCtrl.create({
      header: 'Choice Options',
      message: this.i8nService.messages.addChoicesMessage,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Help Section',
          handler: data => {
            if (!this.networkProvider.checkInternet()) {
              this.networkProvider.noNetworkModal();
              return;
            }
            this.common.openLink(callbackURL);
          }
        }
      ]
    });
    myAlert.present();

  }
}
