/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { Component, Input, Output, AfterViewInit, EventEmitter, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ImagesManagementService } from '../../services/images-management.service';
import { Subscription } from 'rxjs';

import { ConnectionsService } from '../../services/connections.service';
import { AuthService } from '../../services/auth.service';
import { CONNECTION_ACCEPT, CONNECTION_AGENT_DISMISSED, CONNECTION_LENDER_DISMISSED } from '../../constants';
import { ProfileActionPopoverComponent } from '../profile-action/profile-action';
import { CommonProvider } from '../../services/common';
import { BLANK_SOCIAL_LINKS } from '../../constants/general.constants';
import { Hit } from 'instantsearch.js/es/types';
import moment from 'moment';
import { PairedAgentContactInfoComponent } from '../paired-agent-contact-info/paired-agent-contact-info';
import { User_global } from 'src/app/app.models';
import { environment } from 'src/environments/environment';
import { NetworkProvider } from 'src/app/services/network';
import { AlgoliaInsightService } from 'src/app/services/algolia-insight.service';
import { Events } from 'src/app/services/events.service';
import { JsonPipe } from '@angular/common';

export const GUEST_ICON_WIDTH = 40;
export enum ItemEvents {
  KIOSK,
  EDIT,
  GUESTS,
  SHARE,
  SAVEQR,
  CREATE_MICROSITE,
  DELETE,
  DETAILS
}

@Component({
  selector: 'app-public-profile-item',
  templateUrl: 'public-profile-item.component.html',
  styleUrls: ['public-profile-item.scss']
})
export class PublicProfileItemComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('user') user: any;
  @Input('content') content: any;
  @Input('showControls') showControls: boolean;
  @Input('showInvite') showInvite: boolean;
  @Input('regionalLenderView') regionalLenderView: boolean;
  @Input('pairedAgentData') pairedAgentData: Hit;
  @Input('showContact') showContact: boolean;
  @Input('showSocial') showSocial?: boolean;
  @Input('searchInviteMode') searchInviteMode?: boolean;
  @Input('publicProfileType') publicProfileType?: any;

  @Output('onEvents') onEvents = new EventEmitter();
  @Output('edit') edited = new EventEmitter<boolean>();
  @Output('share') shared = new EventEmitter<boolean>();
  @Output('launch') launched = new EventEmitter<boolean>();
  @Output('create') created = new EventEmitter<boolean>();
  @Output('save') saved = new EventEmitter<boolean>();
  @Output('algoliaUpdated') algoliaUpdated = new EventEmitter<string>();
  @Output('inviteClicked') inviteClicked = new EventEmitter<any>();

  hasPropertyImage: boolean;
  propertyImage: any;
  countColor: string;
  resizeSubs: Subscription;
  userData: any = {};
  loaded = false;
  userLogo: any = false;
  socialLinks: any;
  showSocialLinks = false;
  currentPopover: any;
  isRecentlyActive = false;
  updatedDate;
  inProgress = false;
  inviteLoading = false;
  myRole;

  constructor(
    private images: ImagesManagementService,
    private change: ChangeDetectorRef,
    public popoverCtrl: PopoverController,
    private userConnectionsService: ConnectionsService,
    private imageService: ImagesManagementService,
    private auth: AuthService,
    private common: CommonProvider,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private plt: Platform,
    private network: NetworkProvider,
    private insight: AlgoliaInsightService,
    private events: Events
  ) {

    this.userData = {};
    this.userData.data = {};

  }

  itemClicked(event) {
    if (this.searchInviteMode) {
      console.log('public profile in search mode clicked!');
      this.inviteUser(event);
    }
  }

  ngOnDestroy() {
    if (this.currentPopover) {
      this.currentPopover.dismiss();
    }
  }

  ngOnInit(): void {

    this.myRole = User_global.role;

    if (this.showContact === undefined) {
      this.showContact = false;
    }
    if (this.pairedAgentData) {
      this.user = { data: {} };

      this.user.data.username = this.pairedAgentData.username;
      this.user.data.company = this.pairedAgentData.company || '--';
      this.user.data.logoFile = this.pairedAgentData.logoFile;
      this.user.data.profilePhoto = this.pairedAgentData.profilePhoto;
      this.user.data.socialLinks = this.pairedAgentData.socialLinks;

      this.user.id = this.pairedAgentData.objectID;
      this.user.data.role = this.pairedAgentData.role;

      this.userData = this.user;

      console.log('paired agent data: ' + JSON.stringify(this.user));
      this.loadUserData(this.user.data);
      this.setIsRecentlyActive(Number(this.pairedAgentData.mostRecentLeadCreatedAt) * 100);
    }
    else {
      this.initPublicProfileData();
    }
    if (this.showSocial === undefined) {
      this.showSocialLinks = true;
    }
    else {
      this.showSocialLinks = this.showSocial;
    }
  }

  initPublicProfileData() {

    const self = this;

    console.log('ng init, user: ' + JSON.stringify(this.user));
    if (!this.user) {
      return;
    }

    this.userConnectionsService.public_profile(this.user.id).then(res => {
      if (res) {
        self.user.data = res;
        self.userData = self.user;

        this.loadUserData(res);
      }
    });
  }

  loadUserData(res) {
    const self = this;
    self.user.data = res;

    console.log('public profile user data: ' + JSON.stringify(res));
    self.socialLinks = self.user.data.socialLinks || BLANK_SOCIAL_LINKS;
    self.addSocialIcons();

    console.log('social links after icons: ' + JSON.stringify(self.socialLinks));

    if (self.user.data.profilePhoto && self.user.data.profilePhoto[0]) {
      self.imageService.realImageData(self.user.data.profilePhoto[0]).then((data) => {
        if (data) {
          self.propertyImage = data;
          self.hasPropertyImage = true;
          self.loaded = true;
        }
        else {
          self.hasPropertyImage = false;
          self.loaded = true;
        }
      });
    }
    if (this.user.data.logoFile) {
      console.log('loading user logo from app component. ' + this.user.data.logoFile);
      this.imageService.realImageData({ imageURL: this.user.data.logoFile, imagePath: this.user.id + '_logo' }).then((data) => {
        if (data) {
          this.userLogo = data + '?=' + Math.random();
        }
      });
    }


    // DONT RUN FOR REGIONAL LENDERS
    if (!User_global.regionalLender) {
      if (!this.userData.status) {
        this.userData.status = this.userConnectionsService.myConnections.filter(agent => (agent.id === this.user.id))[0]?.status || '';
      }

      if (this.userData.status === 'agent_invited_lender' || this.userData.status === 'lender_invited_agent' || this.userData.status === 'invited') {
        this.userData.date_updated = this.userConnectionsService.myConnections.filter(agent => (agent.id === this.user.id))[0]?.date_updated || false;
      }

      if (this.userData.date_updated) {
        console.log('this.user: ' + JSON.stringify(this.user));
        console.log('this.user.date_updated ' + this.userData.date_updated.seconds);
        this.updatedDate = new Date(Number(this.userData.date_updated.seconds) * 1000);
        console.log('updatedDate' + this.updatedDate);
      }
    }

    console.log('this.userData.status ' + this.userData.status);

    setTimeout(() => {
      self.loaded = true;
    }, 1000);
  }

  setIsRecentlyActive(mostRecentLeadCreatedAt: number) {

    if (mostRecentLeadCreatedAt === null || mostRecentLeadCreatedAt === undefined) { return; }
    const now = moment();
    const then = moment(mostRecentLeadCreatedAt / 100); // Unix epoch time
    const days = moment(now).diff(then, 'days');
    console.log('*** Diff days between now and ' + then.format('YYYY-MM-DD') + ' : ' + days);
    this.isRecentlyActive = (days > -1 && days < 7);
    console.log('isRecentlyActive: ' + this.isRecentlyActive);
  }

  openSocialLink(link) {
    this.common.openLink(link);
  }

  addSocialIcons() {
    try {
      for (let i = 0; i < this.socialLinks.length; i++) {

        //   console.log('iterate social links ' + JSON.stringify(this.socialLinks[i]));

        switch (this.socialLinks[i].id) {
          case 'facebook':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-facebook.svg';
            break;
          case 'instagram':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-instagram.svg';;
            break;
          case 'linkedin':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-linkedin.svg';
            break;
          case 'twitter':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-twitter.svg';
            break;
          case 'yelp':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-yelp.svg';
            break;
          case 'youtube':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-youtube.svg';
            break;
          case 'zillow':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-zillow.svg';
            break;
          case 'tiktok':
            this.socialLinks[i].icon = 'assets/imgs/icons/logo-tiktok.svg';
            break;
        }
        if (this.socialLinks[i].enabled) {
          this.showSocialLinks = true;
        }
      }
    }
    catch (err) {
      console.log('error adding social icons ' + err);
    }
  }

  userImg(user) {
    if (!user) {
      return new Date().getTime();
    }
    if (user.data.profilePhoto) {
      return user.data.profilePhoto[0].imageURL;
    }
    return '';
  }

  ngAfterViewInit() {
    if (this.hasPropertyImage) {
      this.loadImage();
    }
  }

  loadImage() {
    this.images.realImageData(this.user.data.profilePhoto[0]).then((url) => {
      console.log('setting user item image url: ' + url);
      this.propertyImage = url;
      this.change.detectChanges();
    });
  }

  async confirmUnpair(evt) {
    const self = this;
    let title;
    let message;
    let type = '';

    if (self.user.data.role === 'lender') {
      title = 'Unpair Lender';
      message = 'Are you sure you want to unpair this lender? They will no longer appear on Open House Sign-ins, property marketing, and will no longer have access to new leads that have mortgage questions.';
    }

    if (self.user.data.role === 'agent') {
      title = 'Unpair Agent';
      message = 'Are you sure you want to unpair this agent? You will no longer appear on their Open House Sign-ins or property marketing. Any new leads that have mortgage questions will be directed to other lenders.';
    }


    if (self.user.data.role === 'agent' && User_global.role === 'agentAdmin') {
      //agentAdmin
      title = 'Confirm Unpairing';
      message = 'If this agent is the "primary agent" on any listings, these listings will be removed from all team accounts. Previous leads will remain, but any new leads from this agent will not be added to your account.';
      type = 'agentAdmin';
    }

    const myAlert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Unpair',
          role: 'OK',
          handler: data => {
            self.unpair(evt, type);
          }
        }
      ]
    });

    await myAlert.present();
  }

  unpair(event, type) {
    this.common.startLoading('Unpairing ' + this.user.data.role + '...');


    this.auth.unpairConnection(this.user.id, null, type).then(res => {
      if (res) {
        if (User_global.role === 'agent') {
          this.auth.assignDefaultMortgageQuestion();
        }

        setTimeout(() => {
          this.common.closeLoading();
          this.common.toast('Connection unpaired.');
          this.algoliaUpdated.emit('UNPAIRED');
          this.inProgress = true;
        }, 15000);
      }
    })
      .catch(error => {
        // Handle errors here
        console.error('Error occurred during unpairing:', error);
        this.common.closeLoading(); // Close loading in case of error
        this.common.toast(JSON.parse(error).body); // Notify user about the error
        this.auth.logErrors(error, true);
      });
  }

  async confirmConnect(evt, type = 'lender') {

    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    if (User_global.role === 'agentAdmin' && User_global.subscriptionStatus !== 'active') {
      this.warnNoSubscription(evt);
      return;
    }

    if (this.userData.status === 'agent_invited_agentadmin') {
      type = 'agentAdmin';
    }

    const self = this;
    let header = 'Confirm Lender Pairing';
    let message = 'This lender will appear on Open House Sign-ins, property marketing, and will be given access to leads that have mortgage questions.';

    if (type === 'agentAdmin') {
      header = 'Confirm Admin Pairing';
      message = 'While paired, a team admin can edit listings and view the leads that appear in your account.';
    }

    // when admin is clicking confirm
    if (User_global.role === 'agentAdmin') {
      header = 'Confirm Agent Pairing';
      message = 'This agent will be paired and any team branding and marketing settings will be applied to their account.';
    }

    const myAlert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [

        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Pair',
          role: 'OK',
          handler: data => {
            self.acceptConnection(type);
          }
        }
      ]
    });

    await myAlert.present();
  }

  acceptConnection(type) {

    let role = 'Agent';
    switch (this.user.data.role) {
      case 'agent':
        role = 'Agent';
        break;
      case 'lender':
        role = 'Lender';
        break;
      case 'agentAdmin':
        role = 'Admin';
        break;
      default:
        role = 'Agent';
    }

    this.common.startLoading('Pairing ' + role + '...');
    this.auth.acceptConnectionInvite(this.user.id, CONNECTION_ACCEPT, type).then(res => {
      console.log('accept connection res: ' + JSON.stringify(res));
      if (res) {
        if (User_global.role === 'agent' && type === 'lender') {
          this.auth.assignDefaultMortgageQuestion();
        }

        setTimeout(() => {
          this.common.closeLoading();
          this.common.toast('Connection confirmed.');
          this.algoliaUpdated.emit('PAIRED');
          this.userData.status = 'active';

        }, 15000);
      }
    }).catch(err => {

      console.log('accept connection err: ' + err);
      this.common.closeLoading();
      this.common.displayServiceError(JSON.parse(err));
    });
  }

  async confirmConnectLender(evt) {
    const self = this;

    if (User_global.subscriptionStatus !== 'active') {
      this.warnNoSubscription(evt);
      return;
    }

    const myAlert = await this.alertCtrl.create({
      header: 'Confirm Agent Pairing',
      message: 'You will appear on this agent\'s Open House Sign-ins, property marketing, and will be given access to leads that have mortgage questions.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Pair',
          role: 'OK',
          handler: data => {
            self.acceptConnectionLender();
          }
        }

      ]
    });

    await myAlert.present();
  }

  async warnNoSubscription(evt) {

    const self = this;

    const myAlert = await this.alertCtrl.create({
      header: 'Subscription Needed',
      message: 'Pairing an agent requires a subscription plan.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Select Plan',
          role: 'OK',
          handler: data => {
            this.common.openSubscription(environment.stripeSubscription + this.auth.getUserAuthToken());
          }
        }
      ]
    });

    await myAlert.present();
  }

  async inviteUser(evt) {
    const self = this;

    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }
    this.inviteClicked.emit('INVITE_CLICKED');
    //alert('event sent');

    if (User_global.role === 'lender' && User_global.subscriptionStatus !== 'active') {
      this.warnNoSubscription(evt);
      return;
    }
    else if (User_global.role === 'agent') {
      //agent
    }
    else if (User_global.role === 'agentAdmin' && User_global.subscriptionStatus !== 'active') {
      //agentAdmin
      this.warnNoSubscription(evt);
      return;
    }

    if (!this.userConnectionsService.canInviteUser() && !this.searchInviteMode) {
      this.userConnectionsService.showLimitWarning();
      return;
    }

    //this.insight.sendInsightClick(this.auth.getUID(), 'public_profiles_dev', 'recommendationClick', this.user.id);

    let header = '';
    let message = '';

    if (User_global.role === 'lender') {
      header = 'Invite Agent';
      message = 'Make sure your My Profile screen is up-to-date so the agent knows who you are.';
    }
    else if (this.userData.data.role === 'agentAdmin') {
      header = 'Invite Team Admin';
      message = 'Your Team Admin will need to accept your invitation within their Curb Hero account';
    }
    else if (User_global.role === 'agent') {
      header = 'Invite Lender';
      message = 'Make sure your My Profile screen is up-to-date so the lender knows who you are.';
    }
    else if (User_global.role === 'agentAdmin') {
      //agentAdmin
      header = 'Invite Agent';
      message = 'Make sure your My Profile screen is up-to-date so the agent knows who you are.';
    }

    const myAlert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Invite',
          role: 'OK',
          handler: data => {


            try {
              if (!this.searchInviteMode) {
                this.userConnectionsService.incrementUserInviteCount();
              }

            }
            catch (err) {
              console.log('error in increment!' + err);
            }

            this.events.publish('invite:inviteUserStarted'); // profile loaded event

            self.inviteLoading = true;

            if (User_global.role === 'lender') {

              self.auth.sendAgentConnectionInvite('', this.user.id).then(res => {
                if (res === 'SUCCESS') {

                  setTimeout(() => {
                    self.inviteLoading = false;

                    this.events.publish('invite:inviteUserSuccess'); // profile loaded event

                    this.common.toast('Invitation sent!');
                    this.userData.status = 'lender_invited_agent';
                    this.updatedDate = new Date();
                    this.auth.updateInviteTimeStamp();
                  }, 2000);
                }
                else {
                  this.common.toast('Invitation failed to send.');
                  this.failureToSendInvite();
                  this.events.publish('invite:inviteUserFail'); // profile loaded event
                }
              });
            }
            else if (User_global.role === 'agent') {

              // invite is to admin
              if (self.user.data.role === 'agentAdmin') {
                this.common.startLoading();

                self.auth.sendAgentAdminConnectionInvite('', this.user.id).then(res => {
                  if (res === 'SUCCESS') {


                    setTimeout(() => {
                      this.common.closeLoading();

                      this.events.publish('invite:inviteUserSuccess'); // profile loaded event
                      self.inviteLoading = false;

                      this.common.toast('Team admin invitation sent!');
                      this.userData.status = 'agent_invited_agentadmin';
                      this.updatedDate = new Date();

                    }, 2000);
                  }
                  else {
                    this.common.toast('Invitation failed to send.');
                    this.failureToSendInvite();
                    this.events.publish('invite:inviteUserFail'); // profile loaded event
                  }
                });
              }
              else {

                self.auth.sendLenderConnectionInvite('', this.user.id).then(res => {
                  if (res === 'SUCCESS') {


                    setTimeout(() => {
                      this.events.publish('invite:inviteUserSuccess'); // profile loaded event
                      self.inviteLoading = false;

                      //       this.common.toast('Invitation sent!');
                      this.userData.status = 'agent_invited_lender';
                      this.updatedDate = new Date();

                    }, 2000);
                  }
                  else {
                    this.common.toast('Invitation failed to send.');
                    this.failureToSendInvite();
                    this.events.publish('invite:inviteUserFail'); // profile loaded event
                  }
                });
              }
            }
            else if (User_global.role === 'agentAdmin') {
              //agentAdmin
              self.auth.sendAgentConnectionInvite('', this.user.id).then(res => {
                if (res === 'SUCCESS') {

                  setTimeout(() => {
                    self.inviteLoading = false;

                    this.events.publish('invite:inviteUserSuccess'); // profile loaded event
                    this.common.toast('Invitation sent!');

                    this.userData.status = 'agentadmin_invited_agent';
                    this.updatedDate = new Date();
                    this.auth.updateInviteTimeStamp();
                  }, 2000);
                }
                else {
                  this.common.toast('Invitation failed to send.');
                  this.failureToSendInvite();
                  this.events.publish('invite:inviteUserFail'); // profile loaded event
                }
              });
            }
          }
        }
      ]
    });

    await myAlert.present();

  }

  async failureToSendInvite() {

    const self = this;

    self.inviteLoading = false;
    self.showInvite = true;

    const myAlert = await this.alertCtrl.create({
      header: 'There was a Problem',
      message: 'There\'s been an issue. It\'s not your fault, but please make sure your data connection is active and retry the previous action.If this issue persists, please use our Help Section to chat with our Support Team(#31)',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Help Section',
          role: 'help',
          handler: data => {
            self.common.openLink('https://juuj.me/how-does-lender-pairing-work');
          }
        }

      ]
    });

    await myAlert.present();

  }

  acceptConnectionLender() {
    this.common.startLoading('Pairing ' + this.user.data.role + '...');
    this.auth.acceptConnectionInviteLender(this.user.id, CONNECTION_ACCEPT).then(res => {
      console.log('accept connection res: ' + JSON.stringify(res));
      if (res) {
        if (User_global.role !== 'lender') {
          this.auth.assignDefaultMortgageQuestion();
        }

        setTimeout(() => {
          this.common.closeLoading();
          this.common.toast('Connection confirmed.');
          this.algoliaUpdated.emit('PAIRED');
          this.userData.status = 'active';

        }, 15000);
      }
    }).catch(err => {

      console.log('accept connection err: ' + err);
      this.common.closeLoading();
      this.common.displayServiceError(JSON.parse(err));
    });
  }

  declineConnectionLender() {
    this.common.startLoading();
    this.auth.declineConnectionInviteLender(this.user.id, CONNECTION_LENDER_DISMISSED).then(res => {
      setTimeout(() => {
        this.common.closeLoading();
        this.inProgress = true;
      }, 3000);
    }).catch(err => {

      console.log('decline connection err: ' + err);
      this.common.closeLoading();
      this.common.displayServiceError(JSON.parse(err));
    });
  }

  async dismissPrompt() {

    if (!this.network.checkInternet()) {
      this.network.noNetworkModal();
      return;
    }

    const self = this;

    const myAlert = await this.alertCtrl.create({
      header: 'Dismiss Invitation',
      message: 'Are you sure you want to dismiss this invitation?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Dismiss Invite',
          role: 'OK',
          handler: data => {
            self.declineConnection();
          }
        }

      ]
    });

    await myAlert.present();

  }

  declineConnection() {
    this.common.startLoading();
    this.auth.declineConnectionInvite(this.user.id, CONNECTION_AGENT_DISMISSED).then(res => {
      setTimeout(() => {
        this.common.closeLoading();
        this.inProgress = true;
      }, 3000);
    }).catch(err => {

      console.log('decline connection err: ' + err);
      this.common.closeLoading();
      this.common.displayServiceError(JSON.parse(err));
    });
  }

  async presentPopover(event) {

    event.stopPropagation();

    const options = {
      unpair: true,
      impersonate: await this.auth.canCurrentUserImpersonate()
    };

    const customMode = !this.plt.is('cordova') || this.plt.is('ios') ? 'ios' : 'md';
    this.currentPopover = await this.popoverCtrl.create({
      component: ProfileActionPopoverComponent,
      event,
      cssClass: 'my-custom-popover',
      showBackdrop: true,
      mode: customMode,
      componentProps: { data: this.user, options: options, userToImpersonate: this.user }
    });

    await this.currentPopover.present();
    const { data } = await this.currentPopover.onWillDismiss();
    if (data === undefined || !data) {
      console.log('data is empty');
    }
    else {
      if (data === 'unpair') {
        this.confirmUnpair(event);
      }
    }
  }

  async showContactOverlay() {
    const contactData = (this.pairedAgentData) ? this.pairedAgentData : this.user.data;

    const modal = await this.modalCtrl.create({
      component: PairedAgentContactInfoComponent,
      componentProps: {
        pairedAgentData: contactData
      }
    });

    await modal.present();
  }

  manualSync() {
    this.algoliaUpdated.emit('REFRESH');
  }
}
