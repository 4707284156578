/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { AlertController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { Component, Input, Output, AfterViewInit, EventEmitter, OnInit, ChangeDetectorRef, OnChanges, OnDestroy, ViewChild, ElementRef, AfterContentChecked } from '@angular/core';
import { ImagesManagementService } from '../../services/images-management.service';
import { Colors, MAX_GUEST_COUNT } from '../../constants';
import { Subscription } from 'rxjs';
import { ListingActionPopoverComponent } from '../listing-action/listing-action';
import { ConnectionsService } from '../../services/connections.service';
import { MyLeadsDetailsComponent } from '../my-leads-detail/my-leads-details';
import { CommonProvider } from 'src/app/services/common';
import { NetworkProvider } from 'src/app/services/network';
import { environment } from '../../../environments/environment';
import { I8nService } from 'src/app/services/i8nService';
import { User_global } from 'src/app/app.models';
import { AuthService } from 'src/app/services/auth.service';
import { UtilsService } from 'src/app/services/utils.service';

export const GUEST_ICON_WIDTH = 40;
export enum ItemEvents {
  KIOSK,
  EDIT,
  GUESTS,
  SHARE,
  SAVEQR,
  CREATE_MICROSITE,
  DELETE,
  DETAILS
}

@Component({
  selector: 'app-paired-listing-item',
  templateUrl: 'paired-listing-item.component.html',
  styleUrls: ['paired-listing-item.component.scss']
})
export class PairedListingItemComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy, AfterContentChecked {

  @ViewChild('listingBody') listingBody: ElementRef;
  @Input('listing') listing: any;
  @Input('listingId') listingId: any;
  @Output('onEvents') onEvents = new EventEmitter();
  @Input('content') content: any;

  @Output('edit') edited = new EventEmitter<boolean>();
  @Output('share') shared = new EventEmitter<boolean>();
  @Output('launch') launched = new EventEmitter<boolean>();
  @Output('create') created = new EventEmitter<boolean>();
  @Output('save') saved = new EventEmitter<boolean>();

  loaded = false;
  hasPropertyImage: boolean;
  propertyImage: any;
  agentImage: any;
  public maxGuestCount = MAX_GUEST_COUNT;
  countColor: string;
  resizeSubs: Subscription;
  currentPopover: any;
  listingAgent: any = { username: '', company: '' };
  addedByUserText = 'you';
  userRole;

  constructor(
    private images: ImagesManagementService,
    private change: ChangeDetectorRef,
    private plt: Platform,
    public popoverCtrl: PopoverController,
    private connectionsService: ConnectionsService,
    public modalCtrl: ModalController,
    public common: CommonProvider,
    public networkProvider: NetworkProvider,
    public i8nService: I8nService,
    public alertCtrl: AlertController,
    private auth: AuthService,
    private utils: UtilsService
  ) {
    this.countColor = '#' + Colors[Math.floor(Math.random() * Colors.length)];
  }

  ngOnInit(): void {
    if (!this.listing.username) {
      this.listing.username = 'N/A';
    }
    if (!this.listing.company) {
      this.listing.company = 'N/A';
    }
    if (this.listing.guests) {
      this.listing.guests = this.listing.guests.filter(guest => !guest.archived);
    }

    if (this.listing.propertyImage) {
      this.listing.hasPropertyImage = this.listing.propertyImage.imageURL || this.listing.propertyImage.imagePath || false;
    }
    this.hasPropertyImage = this.listing.hasPropertyImage;

    this.resizeSubs = this.plt.resize.subscribe(() => {
      this.maxGuestCount = Math.floor(this.listingBody.nativeElement.clientWidth / GUEST_ICON_WIDTH) - 1;
    });
    this.listing['id'] = this.listingId;


    if (!this.listing.avatarName) {
      this.listing.avatarName = this.auth.createAvatarName(this.listing.address.title);
      this.listing.color = this.utils.avatarColor(this.listing.avatarName);
      this.listing.date = (new Date(this.listing.createdAt)).toDateString();
    }

    console.log('paired listing item: ' + JSON.stringify(this.listing));

    this.userRole = User_global.role;
  }

  ngOnDestroy() {
    if (this.resizeSubs && !this.resizeSubs.closed) {
      this.resizeSubs.unsubscribe();
    }
    if (this.currentPopover) {
      this.currentPopover.dismiss();
    }
  }

  ngOnChanges(changes) {
    if (this.listing.guests) {
      this.listing.guests = this.listing.guests.filter(guest => !guest.archived);
    }

    //  TODO: FIGURE OUT WHY hasPropertyImage IS BEING USED AT ALL
    if (this.listing.propertyImage) {
      this.listing.hasPropertyImage = this.listing.propertyImage.imageURL || this.listing.propertyImage.imagePath || false;
    }
    this.hasPropertyImage = this.listing.hasPropertyImage;

    if (this.hasPropertyImage && !this.propertyImage || this.listingImg(changes.listing.currentValue) !== this.listingImg(changes.listing.previousValue)) {
      this.loadImage();
    }
  }

  listingImg(listing) {
    if (!listing) {
      return new Date().getTime();
    }
    if (listing.propertyImage) {
      return listing.propertyImage.imageURL;
    }
    return '';
  }

  ngAfterContentChecked() {
    try {
      if (this.listing.guests) {
        this.listing.guests = this.listing.guests.filter(guest => !guest.archived);
      }
      this.maxGuestCount = Math.floor(this.listingBody.nativeElement.clientWidth / GUEST_ICON_WIDTH) - 1;
    }
    catch (err) {
      this.maxGuestCount = MAX_GUEST_COUNT;
      // supress
    }
  }

  async ngAfterViewInit() {

    console.log('retrieved listing agent: ' + JSON.stringify(this.listingAgent));

    if (User_global.role === 'lender') {
      if (this.listing.listingAssigned && this.listing.assignedByUser[0] === this.auth.getUID()) {
        this.addedByUserText = 'you';
      }
      // team admin
      else if (this.listing.listingAssigned && this.listing.assignedByUser[0] === this.listing.agentAdmin) {
        const creator = await this.connectionsService.getConnectionDetails(this.listing.agentAdmin);
        this.addedByUserText = creator.username;
      }
      else {
        this.listingAgent = await this.connectionsService.getConnectionDetails(this.listing.listingAgent);
        this.addedByUserText = this.listingAgent.username;
      }
    }

    else if (User_global.role === 'agent') {
      //agent
    }
    else if (User_global.role === 'agentAdmin') {
      //agentAdmin
      if (this.listing.listingAssigned && this.listing.assignedByUser[0] === this.auth.getUID()) {
        this.addedByUserText = 'you';
      }
      // lender
      else if (this.listing.listingAssigned && this.listing.assignedByUser[0] === this.listing.loanOfficer) {
        const creator = await this.connectionsService.getConnectionDetails(this.listing.loanOfficer);
        this.addedByUserText = creator.username;
      }
      else {
        this.listingAgent = await this.connectionsService.getConnectionDetails(this.listing.listingAgent);
        this.addedByUserText = this.listingAgent.username;
      }
    }

    if (this.listingAgent && this.listingAgent.profilePhoto && this.listingAgent.profilePhoto.length > 0) {
      this.images.realImageData(this.listingAgent.profilePhoto[0]).then((url) => {
        if (url) {
          console.log('setting agent image url: ' + url);
          this.agentImage = url;
          this.change.detectChanges();
        }
      });
    }

    if (this.listing.guests) {
      this.listing.guests = this.listing.guests.filter(guest => !guest.archived);
    }
    console.log('listing agent is : ' + JSON.stringify(this.listingAgent));
    if (this.hasPropertyImage) {
      this.loadImage();
    }
    else {
      setTimeout(() => {
        this.loaded = true;
      }, 1500);
    }
  }

  loadImage() {
    const self = this;

    if (this.listing.propertyImage) {
      this.images.realImageData(this.listing.propertyImage).then((url) => {
        if (url) {
          console.log('setting listing item image url: ' + url);
          this.propertyImage = url;
          this.change.detectChanges();
        }
        setTimeout(() => {
          self.loaded = true;
        }, 1500);
      }).catch(err => {
        console.log('err loading image ' + err);
        self.loaded = true;
        self.hasPropertyImage = false;
      });
    }
    else {
      setTimeout(() => {
        self.loaded = true;
        self.hasPropertyImage = false;
      }, 1500);
    }
  }

  goToOpenHouseMode(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.KIOSK });
  }
  goEditMode(event, mode?) {
    if (mode && mode === 'marketing-services') {
      this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.EDIT, displayMode: mode });
    } else {
      this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.EDIT });
    }
  }
  goToDetails(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.DETAILS });
  }
  goToMyGuests(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.GUESTS });
  }
  share(event, eventProp?) {
    if (eventProp) {
      eventProp.stopPropagation();
    }

    if (!this.listing.webPublished) {
      this.warnNotPublished();
    }
    else {
      this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.SHARE });
    }
  }
  saveQR(event) {
    if (event) {
      event.stopPropagation();
    }
    if (!this.listing.webPublished) {
      this.warnNotPublished();
    }
    else {
      this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.SAVEQR });
    }
  }
  createMicrosite(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.CREATE_MICROSITE });
  }
  deleteListing(event) {
    this.onEvents.emit({ ev: event, listing: this.listing, event: ItemEvents.DELETE });
  }

  async presentPopover(mode, event) {
    event.stopPropagation();
    let options;
    if (mode === 'desktop') {
      options = {
        edit: true,
        launch: true,
        delete: true,
        link: true
      };
    }
    else {
      options = {
        edit: true,
        launch: true,
        share: true,
        save: true,
        delete: true,
        link: true,
      };
    }
    const customMode = !this.plt.is('cordova') || this.plt.is('ios') ? 'ios' : 'md';
    this.currentPopover = await this.popoverCtrl.create({
      component: ListingActionPopoverComponent,
      event,
      cssClass: 'my-custom-popover',
      showBackdrop: true,
      mode: customMode,
      componentProps: { data: this.listing, options }
    });
    await this.currentPopover.present();

    const { data } = await this.currentPopover.onWillDismiss();
    console.log(data);

    if (data === undefined || !data) {
      console.log('data is empty');
    }
    else {
      if (data === 'edit') {
        this.goEditMode(event);
      }
      else if (data === 'launch') {
        this.goToOpenHouseMode(event);
      }
      else if (data === 'share') {
        this.share(event);
      }
      else if (data === 'save') {
        this.saveQR(event);
      }
      else if (data === 'create') {
        this.createMicrosite(event);
      }
      else if (data === 'delete') {
        this.deleteListing(event);
      }
      else if (data === 'link') {
        this.openSlugLink(this.listing, event);
      }
    }
  }

  async viewListingDetails(event) {
    console.log('view listing details clicked');
    event.stopPropagation();

    if (this.listing.listingAssigned
      && this.listing.assignedByUser
      && this.auth.getUID() === this.listing.assignedByUser[0]) {
      this.goEditMode(event);
    }
    else if (this.auth.getUID() === this.listing.agentAdmin) {
      this.goEditMode(event);
    }
    else {
      const data = { listings: this.listing, guest: false };
      const modal = await this.modalCtrl.create({ component: MyLeadsDetailsComponent, componentProps: { data } });
      modal.onDidDismiss().then(dismissData => {
        if (dismissData.data === 'marketing-services') {
          this.goEditMode(event, dismissData.data);
        }
      });
      await modal.present();
    }

  }

  copyListing(listing) {
    console.log('Listing here', this.listing);
    this.common.copyToClipboard(environment.blockparty + listing.slug, 'Details copied', 1000);
  }

  async openSlugLink(listing, event?) {

    if (event) {
      event.stopPropagation();
    }
    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }
    else if (!listing.webPublished) {
      this.warnNotPublished();
    }
    else {
      this.common.openLink(environment.blockparty + listing.slug);
    }
  }
  async warnNotPublished() {
    const callbackURL = 'https://juuj.me/creating-a-listing-microsite';
    const myAlert = await this.alertCtrl.create({
      header: 'Listing Microsite Required',
      message: this.i8nService.messages.listingNeedsPublishingBeforeSharing,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Help Section',
          handler: data => {
            if (!this.networkProvider.checkInternet()) {
              this.networkProvider.noNetworkModal();
              return;
            }
            this.common.openLink(callbackURL);
          }
        }
      ]
    });
    myAlert.present();
  }
}
