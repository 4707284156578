/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable max-len */
// import { Timestamp } from 'firebase/firestore';
import { BLANK_SOCIAL_LINKS, DEFAULT_WEB_SECTION_TITLE, DEFAULT_WELCOME_MESSAGE, DEFAULT_THANKS_MESSAGE, DEFAULT_WEB_LAYOUT } from '../app/constants/';

export interface CurbUser {
  username?: string;
  phoneNumber?: string;
  email?: string;
  firstTimeListing?: boolean;
  password?: string;
  logoFile?: string;
  zapierToken?: string;
  primaryColor?: string;
  secondaryColor?: string;
  customWelcome?: string;
  customThanks?: string;
  customPrivacyPolicy?: string;
  logoImage?: any;
  guestSMSoptOut?: boolean;
  profilePhoto?: Array<ProfileImage>;
  micrositeUserId?: string;
  featuredLinks?: Array<Links>;
  socialLinks?: Array<Links>;
  webListings?: Array<Links>;
  micrositeFeatureEnabled?: boolean;
  webPublished?: boolean;
  company?: string;
  teamname?: string;
  title?: string;
  license?: string;
  disclaimerText?: string;
  disclaimerURL?: string;
  secondDisclaimerText?: string;
  secondDisclaimerURL?: string;
  showCompany?: boolean;
  showTitle?: boolean;
  showLicense?: boolean;
  showDisclaimer?: boolean;
  showSecondDisclaimer?: boolean;
  disclaimerPosition?: string;
  webSectionTitle?: string;
  webLayout?: string;
  createdAt?: any;
  recreatedAccount?: any;
  touchFreeSignIn?: boolean;
  synced?: any;
  role?: string;
  referralCode?: string;
  website?: string;
  subscriptionStatus?: string;
  reviews?: Array<any>;
  totalLeadsLookups?: number;
  mortgageQuestionOptimization?: boolean;
  assignedMortgageQuestionId?: string;
  firstPromoterTid?: string;
  lastLogin?: any;
  lastInviteTimestamp?: any;
  userAgent?: string;
  profilePhotoLastUpdated?: number;
  address?: Address;
  isRatingShown?: boolean;
  ratingTime?: any;
  ratingOptionSelected?: string;

  // new since algolia
  enableLenderCommunication?: boolean;
  _geoloc?: Array<any>;
  leadCount?: number;
  regionalLender?: boolean;
  pairedUserId?: Array<string>;
  mostRecentListingEnhancedModeStatus?: boolean;
  mostRecentLeadCreatedAt?: number;
  enableAgentsListingsResultsFiltering?: boolean;
  qrCodeOnlylink?: string;
  locations?: any;
  listingAutoPopulate?: boolean;
  intercom?: Intercom;

  brokerOfRecord?: string;

  isSuperUser?: boolean;
}

export const User_global = {

  username: '',
  phoneNumber: '',
  email: '',
  firstTimeListing: false,
  password: '',
  logoFile: '',
  zapierToken: '',
  secondaryColor: '',
  primaryColor: '',
  customWelcome: '',
  customThanks: '',
  customPrivacyPolicy: '',
  logoImage: {},
  profilePhoto: [],
  micrositeUserId: '',
  featuredLinks: [],
  customButtonShape: '',
  socialLinks: [],
  webListings: [],
  micrositeFeatureEnabled: true,
  webPublished: false,
  company: '',
  teamname: '',
  title: '',
  license: '',
  disclaimerText: '',
  disclaimerURL: '',
  secondDisclaimerText: '',
  secondDisclaimerURL: '',
  showCompany: true,
  showTitle: false,
  showLicense: false,
  showDisclaimer: false,
  showSecondDisclaimer: false,
  disclaimerPosition: 'nothing',
  webSectionTitle: '',
  webLayout: '',
  createdAt: {},
  guestSMSoptOut: false,
  touchFreeSignIn: true,
  synced: {},
  role: '',
  referralCode: '',
  website: '',
  subscriptionStatus: '',
  reviews: [{
    review: {
      id: 'yelp',
      order: 0,
      name: 'Yelp',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      reviewSnippet: '',
      maxReviewStars: 5,
      reviewLogo: ''
    }
  }, {
    review: {
      id: 'googleMyBusiness',
      order: 1,
      name: 'Google My Business',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: ''
    }
  }, {
    review: {
      id: 'zillow',
      order: 2,
      name: 'Zillow',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: ''
    }
  }, {
    review: {
      id: 'facebook',
      order: 3,
      name: 'Facebook',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: ''
    }
  }, {
    review: {
      id: 'trustPilot',
      order: 4,
      name: 'Trust Pilot',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: ''
    }
  }, {
    review: {
      id: 'redfin',
      order: 5,
      name: 'Redfin',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: ''
    },
  }


  ],
  totalLeadsLookups: 0,
  mortgageQuestionOptimization: true,
  assignedMortgageQuestionId: '',
  firstPromoterTid: '',
  profilePhotoLastUpdated: -1,
  address:
  {
    title: '',
    placeId: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    lat: '',
    lng: '',
  },
  // new since algolia
  enableLenderCommunication: true,
  _geoloc: [],
  leadCount: 0,
  regionalLender: false,
  pairedUserId: [],
  mostRecentListingEnhancedModeStatus: false,
  mostRecentLeadCreatedAt: -1,
  enableAgentsListingsResultsFiltering: false,
  onlylinkTakeover: {},
  firstPromoterReferralCode: '',
  qrCodeOnlylink: '',
  isRatingShown: false,
  ratingTime: '',
  ratingOptionSelected: '',
  locations: { lvl0: [], lvl1: [], lvl2: [] },
  listingAutoPopulate: true,
  intercom: { companyId: '', companyName: '' },

  brokerOfRecord: '',

  isSuperUser: false,
};

export const BlankUser = {
  username: '',
  phoneNumber: '',
  email: '',
  firstTimeListing: true,
  password: '',
  logoFile: '',
  zapierToken: '',
  secondaryColor: '#9A999A',
  primaryColor: '#14294F',
  customWelcome: DEFAULT_WELCOME_MESSAGE,
  customThanks: DEFAULT_THANKS_MESSAGE,
  customPrivacyPolicy: '',
  logoImage: {},
  guestSMSoptOut: false,
  profilePhoto: [],
  micrositeUserId: '',
  featuredLinks: [],
  socialLinks: BLANK_SOCIAL_LINKS,
  webListings: [],
  micrositeFeatureEnabled: true,
  webPublished: false,
  company: '',
  teamname: '',
  title: '',
  license: '',
  disclaimerText: '',
  disclaimerURL: '',
  secondDisclaimerText: '',
  secondDisclaimerURL: '',
  showCompany: true,
  showTitle: false,
  showLicense: false,
  showDisclaimer: false,
  showSecondDisclaimer: false,
  disclaimerPosition: 'nothing',
  webSectionTitle: DEFAULT_WEB_SECTION_TITLE,
  webLayout: DEFAULT_WEB_LAYOUT,
  createdAt: {},
  touchFreeSignIn: true,
  synced: {},
  role: '',
  referralCode: '',
  website: '',
  subscriptionStatus: '',
  reviews: [{
    review: {
      id: 'yelp',
      order: 0,
      name: 'Yelp',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      reviewSnippet: '',
      maxReviewStars: 5,
      reviewLogo: 'YelpLogo.png'
    }
  }, {
    review: {
      id: 'googleMyBusiness',
      order: 1,
      name: 'Google My Business',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: 'GoogleLogo.png'
    }
  }, {
    review: {
      id: 'zillow',
      order: 2,
      name: 'Zillow',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: 'ZillowLogo.png'
    }
  }, {
    review: {
      id: 'facebook',
      order: 3,
      name: 'Facebook',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: 'FacebookLogo.png'
    }
  }, {
    review: {
      id: 'trustPilot',
      order: 4,
      name: 'Trust Pilot',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: 'TrustPilotLogo.png'
    }
  }, {
    review: {
      id: 'redfin',
      order: 5,
      name: 'Redfin',
      enabled: false,
      reviewCount: '',
      reviewStars: 0,
      maxReviewStars: 5,
      reviewSnippet: '',
      reviewLogo: 'RedfinLogo.png'
    },
  }
  ],
  totalLeadsLookups: 0,
  mortgageQuestionOptimization: true,
  assignedMortgageQuestionId: '',
  firstPromoterTid: '',
  profilePhotoLastUpdated: -1,
  address:
  {
    title: '',
    placeId: '',
    city: '',
    zip: '',
    state: '',
    country: '',
    lat: '',
    lng: '',
  },

  // new since algolia
  enableLenderCommunication: true,
  _geoloc: [],
  leadCount: 0,
  regionalLender: false,
  pairedUserId: [],
  mostRecentListingEnhancedModeStatus: false,
  mostRecentLeadCreatedAt: -1,
  enableAgentsListingsResultsFiltering: false,
  onlylinkTakeover: {},
  qrCodeOnlylink: '',
  locations: {},
  listingAutoPopulate: true,
  intercom: { companyId: '', companyName: '' },

  brokerOfRecord: '',

  isSuperUser: false,
};

export interface Links {
  id?: string;
  title?: string;
  URL?: string;
  order?: number;
  enabled?: boolean;
  icon?: string;
  landingPage?: string;
  fullScreenTakeover?: boolean;
  // type?: string;
}

export interface Intercom {
  companyId?: string;
  companyName?: string;
}

export interface Address {
  title?: string;
  placeId?: string;
  city?: string;
  zip?: string;
  state?: string;
  country?: string;
  lat?: string;
  lng?: string;
}

export interface Question {
  isActive?: boolean;
  question?: string;
  details?: any;
  // type can be:
  // text
  // number
  // yesNo
  type?: string;
  answers?: string[];
  conditionalTriggerAnswer?: boolean;
  conditionalToggle?: boolean;
}

export interface Listing {
  createdAt?: any;
  id?: string;
  address?: Address;
  guestsCount?: number;
  textMessage?: string;
  linkToInfo?: string;
  loanOfficer?: string;
  kioskQuestions?: Array<Question>;
  guestFollowUp?: boolean;
  time?: string;
  propertyImage?: ListingImage;
  propertyLayout?: number;
  enhancedMode?: boolean;
  listingImages?: Array<ListingImage>;
  listingSuggestionImages?: Array<ListingImage>;
  listingDetails?: ListingDetails;
  webPublished?: boolean;
  micrositeLinks?: Array<MicrositeLinks>;
  slug?: string;
  tourLinks?: Array<Links>;
  hasPropertyImage?: boolean;
  synced?: any;
  kioskStarted?: any;
  qrCodeImage?: string;
  qrCodeImageKiosk?: string; // qr code for kiosk page
  qrCodeAgentSignIn?: string; // agent specific qr code
  archived?: boolean;
  sampleListing?: boolean;
  assignedByUser?: Array<String>;
  listingAssigned?: boolean;
  pairedStatus?: string;
  listingAgent?: string;
  pairedListingAgents?: Array<any>;
  startingMode?: string;
  agentAdmin?: string;
}

export interface LoanOfficer {
  calendarLink?: any;
  company?: string;
  email?: string;
  IsActive?: boolean;
  name?: string;
  phone?: number;
  states?: Array<string>;
  website?: string;
  zipCodes?: Array<number>;
  reviews?: Array<LoanOfficerReview>;
  image?: string;
}

export interface LoanOfficerReview {
  averageRating?: number;
  reviewCount?: number;
  reviewScore?: string;
  reviewSiteUrl?: string;
  reviewSnippet?: string;
  reviewSource?: string;
  reviewIcon?: string;
  reviewRatingStars?: Array<string>;
}

export interface MortgageQuestion {
  MortgageQuestion?: string;
  PrivacyPolicy?: string;
  GuestDisclaimer?: string;
  yesAnswer?: string;
  noAnswer?: string;
}

export interface SMSOptIn {
  enableKioskOptIn?: boolean;
  isOptInChecked?: boolean;
  kioskSmsDisclaimerText?: string;
}

export interface ListingImage {
  id?: string;
  base64?: string;
  imagePath?: string;
  imageURL?: string;
  orientation?: string;
  thumbnailPath?: string;
  thumbnailURL?: string;
}

export interface ProfileImage {
  id?: string;
  base64?: string;
  imagePath?: string;
  imageURL?: string;
  orientation?: string;
}

export interface ListingDetails {
  price?: number;
  beds?: number;
  baths?: number;
  squareFootage?: number;
  listingImages?: any;
  description?: string;
  saleStatus?: string;
  moreInfoURL?: string;
}

export interface MicrositeLinks {
  id?: string;
  order?: string;
  active?: boolean;
  url?: string;
}

export interface Guest {
  createdAt: string;
  fullName?: string;
  phoneNumber?: string;
  email?: string;
  notes?: string;
  verified?: string;
  source?: string;
  mortgageAnswer?: string;
  assignedMortgageQuestionId?: string;
  userId?: string;
  loanOfficer?: string;
  archived?: boolean;
  listingId?: string;
  offlineSync?: boolean;
  partialLead?: boolean;
  phoneCountry: string;
  phoneCountryCode: string;
}

export interface QuestionsAnswers {
  question?: string;
  answer?: any;
  details?: any;
  type?: string;
  divId?: any;
  conditionalTriggerAnswer?: boolean;
  conditionalToggle?: boolean;
  multipleChoiceOptions?: MultipleChoiceKioskOption[];
}

export interface MultipleChoiceKioskOption {
  value: string;
  isSelected: boolean;
  details: any;
}

export interface SurveyData {
  listingId: string;
  guest?: Guest;
  questionsAnswers?: Array<QuestionsAnswers>;
  archived?: boolean;
  createdAt?: string;
  createdDate?: any;
  time?: string;
  disclosureDoc?: string;
  disclosureSigned?: string; // text that was selected
  disclosureSelection?: string; // fieldName selected
  disclosureFulfilledDownloadUrl?: string // download url for completed disclosure
}

export interface FirebaseImage {
  id: number;
  fbPath?: string;
  localName?: string;
}

export interface FeaturedLink {
  id?: string;
  title?: string;
  URL?: string;
  order?: number;
  enabled?: boolean;
  icon?: string;
  animation?: string;
}

// Saved in /users/{userId}/disclosures
export interface DisclosureDoc {
  active: boolean;
  confirmButton: string;
  declineButton: string;
  fields: Array<{
    field: string;
    value?: any;
    x: number;
    y: number
  }>;
  id: string;
  kioskQuestionLocked1: boolean;
  message: string;
  title: string;
  url: string;
}

export interface RolloutOptions {
  syncToSalesForce: boolean;
  syncToFollowUpBoss: boolean;
  syncToRealGeeks: boolean;
  syncToCloze: boolean;
  syncToSierra: boolean;
  syncToCINC: boolean;
  syncToHubspot: boolean;
  syncToPedrive: boolean;
}