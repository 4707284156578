/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN?: boolean;
  }
}

self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CookieModule } from 'ngx-cookie';

// import { DigitOnlyModule } from '@uiowa/digit-only';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

//import { SocialSharing } from './services/social-share.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
// import { MyApp } from './app.component';
import { LoginPage } from './login/login';
import { SignUpPage } from './sign-up/sign-up';
import { MyProfilePage } from './my-profile/my-profile';
import { MyMicrositePage } from './my-microsite/my-microsite';
import { MyListingsPage } from './my-listings/my-listings';
// import { MyGuestsPage } from './my-guests/my-guests';
import { HelpPage } from './help/help';
// import { OpenHousePage } from './open-house/open-house';
import { ListingDetailPage } from './listing-detail/listing-detail';
// import { MyGuestDetailsPage } from './my-guest-details/my-guest-details';
// import { EnhancedDetailsPage } from './components/enhanced-details/enhanced-details';
import { KioskModePage } from './kiosk-mode/kiosk-mode';

import { EditInputFollowUpPage } from './components/edit-input-followup/edit-input-followup';
import { MyPairedAgentsPage } from './my-paired-agents/my-paired-agents';

import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SkeletonItemComponent } from './components/skeleton-item/skeleton-item';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { CenterCropThumbnailComponent } from './components/center-crop-thumbnail/center-crop-thumbnail';
import { CustomScreensInputComponent } from './components/custom-screens-input/custom-screens-input';
import { SkeletonComponent } from './components/skeleton/skeleton-component.component';
import { TextAvatarComponent } from './components/text-avatar/text-avatar.component';
import { GuestItemComponent } from './components/guest-item/guest-item.component';

import { PropertyLayoutComponent } from './components/property-layout/property-layout.component';
import { PropertyLayoutPreviewPage } from './components/property-layout-preview/property-layout-preview';
import { PropertyLayoutSelectorComponent } from './components/property-layout-selector/property-layout-selector.component';
import { ColorPickerPrimaryPage } from './components/color-picker-primary/color-picker-primary';

import { UpdateProfileInformationPage } from './components/update-profile-information/update-profile-information';
import { EditInputPage } from './components/edit-input/edit-input';
import { EditInputAddressPage } from './components/edit-input-address/edit-input-address';

import { EditNotesPage } from './components/edit-notes/edit-notes';
import { ListingItemComponent } from './components/listing-item/listing-item.component';
import { IonicRatingService } from './services/ionic-rating.service';

//----------- Nativer Plugins----------------

import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
// import { Base64 } from '@awesome-cordova-plugins/base64/ngx';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { Intercom } from '@awesome-cordova-plugins/intercom/ngx';

//----------- Providers----------------

import { IntercomService } from './services/intercom.service';
import { ImagesManagementService } from './services/images-management.service';
import { DokaEditorComponent } from './components/doka-editor/doka-editor';

import { EditMicrositePage } from './components/edit-microsite/edit-microsite';
import { EditLinksPage } from './components/edit-links/edit-links';

import { EnhancedModalPage } from './components/enhanced-modal/enhanced-modal';
import { ListingActionPopoverComponent } from './components/listing-action/listing-action';
import { QRPopupComponent } from './components/qr-popup/qp-popup';
import { AnalyticsPopupComponent } from './components/analytics-popup/analytics-popup';


import { MyLeadsPage } from './my-leads/my-leads';
import { MyPairedListingsPage } from './my-paired-listings/my-paired-listings';
import { MyPairedLendersPage } from './my-paired-lenders/my-paired-lenders';

import { PublicProfileItemComponent } from './components/public-profile-item/public-profile-item.component';
import { ProfileActionPopoverComponent } from './components/profile-action/profile-action';
import { PairedListingItemComponent } from './components/paired-listing-item/paired-listing-item.component';
import { ProfileImageItemComponent } from './components/profile-image-item/profile-image-item';
import { MyLeadsDetailsComponent } from './components/my-leads-detail/my-leads-details';
import { PairedAgentContactInfoComponent } from './components/paired-agent-contact-info/paired-agent-contact-info';

import { EditReviewPage } from './components/edit-review/edit-review';
// import { MyLeadLookupPage } from './my-lead-lookup/my-lead-lookup';
import { CryptoService } from './services/crypto.service';
// Import the AngularFire Module
import { initializeApp } from '@firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from '@firebase/app-check';

import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';

import { getFunctions } from '@firebase/functions';
import { initializeFirestore, persistentLocalCache, CACHE_SIZE_UNLIMITED } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { initializeAnalytics } from '@firebase/analytics';
import { initializeAuth, Auth, indexedDBLocalPersistence } from '@firebase/auth';

//import { AngularFirestoreModule, SETTINGS } from '@firebase/firestore-compat';


import { environment } from 'src/environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';


import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NetworkProvider } from './services/network';
import { AuthService } from './services/auth.service';
import { GuestService } from './services/guest.service';
import { CompressorService } from './services/compressor.service';
import { ConnectionsService } from './services/connections.service';
import { ListingService } from './services/listing.service';
import { UtilsService } from './services/utils.service';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';

import { CommonProvider } from './services/common';
import { SMSService } from './services/sms.service';
import { ListingDataProviderService } from './services/listing-data-provider.service';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { PipesModule } from './pipes/pipes.module';
import { SplashComponent } from './components/splash/splash.component';

import { ServiceWorkerModule } from '@angular/service-worker';
// firebase.firestore.setLogLevel('debug');

import { NumberOnlyDirective } from './services/number-only-directive';
import { NgAisModule } from 'angular-instantsearch';
import { HierarchicalMenu } from './components/hierarchical-menu/hierarchical-menu';
import { I8nService } from './services/i8nService';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { SqlService } from './services/sql.service';
import { ViewRawLeadsComponent } from './components/view-raw-leads/view-raw-leads';
// import { IonicInputMaskModule } from '@thiagoprz/ionic-input-mask';
import { OfflineModalPage } from './components/offline-modal/offline-modal';
import { SwiperModule } from 'swiper/angular';
import { FilterLeadsPopupComponent } from './components/filter-leads-popup/filter-leads-popup.component';
import { ProfileComponentComponent } from './components/profile-component/profile-component.component';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { ServiceItemsComponent } from './components/marketplace-service-items/marketplace-service-items.component';


import { AppRatingService } from './services/app-rating.service';
import { FloatingPairedItemComponent } from './components/floating-paired-item/floating-paired-item.component';
import { SearchBox } from './components/algolia-search-box/algolia-search-box.component';

import { AngularPinturaModule } from '@pqina/angular-pintura';
import { AutoPopulateItemComponent } from './components/auto-populate-item/auto-populate-item.component';
import { SlicePipe } from '@angular/common';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { IonicRatingComponentModule } from './components/ionic-rating-component/ionic-rating.module';

import { SMS } from '@awesome-cordova-plugins/sms/ngx';

import { RolloutIntegrationComponent } from './components/rollout-integration/rollout-integration.component';
import { AnalyticsService } from './services/analytics.service';
import { CustomProgressComponent } from './components/custom-progress/custom-progress.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { OpenAIService } from './services/openai.service';
import { PdfEditorService } from './services/pdf.service';
import { HTTPService } from './services/http.service';
import { AlgoliaSearchComponent } from './components/algolia-search/algolia-search';

//import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

// Include gapi mock inline
window['gapi'] = {
  load: (name: string) => Promise.resolve(),
  iframes: {
    getContext: () => ({
      iframe: {
        contentWindow: {
          postMessage: (message: any) => {
            console.log('gapi iframe message:', message);
          },
        },
      },
    }),
  },
} as any;

const app = initializeApp(environment.firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.

try {
  if (environment.appCheckEnabled) {

    self.FIREBASE_APPCHECK_DEBUG_TOKEN = environment.appCheckDevLocal;
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = environment.appCheckDevLocal;

    const appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(environment.captcha_site_key),

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true
    });
  }
}
catch (err) {
  console.log('error in app check ', err);
}

const firestore = initializeFirestore(app, {
  localCache: persistentLocalCache({
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
  }),
  experimentalForceLongPolling: true
});

const auth: Auth = initializeAuth(app, { persistence: indexedDBLocalPersistence });

auth.setPersistence(indexedDBLocalPersistence);

// Initialize Analytics
const analytics = initializeAnalytics(app);
// Initialize Storage
const storage = getStorage(app);
// Initialize Functions
const functions = getFunctions(app);



@NgModule({
  declarations: [AppComponent,
    HierarchicalMenu,
    NumberOnlyDirective,
    LoginPage,
    SignUpPage,
    MyProfilePage,
    MyMicrositePage,
    MyListingsPage,
    // MyGuestsPage,
    MyLeadsPage,
    MyPairedAgentsPage,
    MyPairedListingsPage,
    MyPairedLendersPage,
    HelpPage,
    //  OpenHousePage,
    ListingDetailPage,
    //MyGuestDetailsPage,
    // MyLeadLookupPage,
    MyLeadsDetailsComponent,
    PairedAgentContactInfoComponent,
    SplashComponent,
    // EnhancedDetailsPage,
    EnhancedModalPage,
    OfflineModalPage,
    KioskModePage,
    ProgressBarComponent,
    SkeletonItemComponent,
    SkeletonComponent,
    TextAvatarComponent,
    GuestItemComponent,
    ProfileImageItemComponent,
    PublicProfileItemComponent,
    FloatingPairedItemComponent,
    ServiceItemsComponent,
    AutoPopulateItemComponent,
    ThumbnailComponent,
    CenterCropThumbnailComponent,
    CustomScreensInputComponent,
    DokaEditorComponent,
    ColorPickerPrimaryPage,
    UpdateProfileInformationPage,
    EditInputPage,
    EditLinksPage,
    EditReviewPage,
    FilterLeadsPopupComponent,
    EditMicrositePage,
    EditInputAddressPage,
    EditInputFollowUpPage,
    EditNotesPage,
    ViewRawLeadsComponent,
    PropertyLayoutComponent,
    PropertyLayoutPreviewPage,
    PropertyLayoutSelectorComponent,
    ListingItemComponent,
    PairedListingItemComponent,
    FloatingPairedItemComponent,
    ServiceItemsComponent,
    AutoPopulateItemComponent,
    ListingActionPopoverComponent,
    ProfileActionPopoverComponent,
    ProfileComponentComponent,
    QRPopupComponent,
    AnalyticsPopupComponent,
    RolloutIntegrationComponent,
    SearchBox,
    CustomProgressComponent,
    AlgoliaSearchComponent
  ],
  // entryComponents: [
  //   LoginPage,
  //   SignUpPage,
  //   MyProfilePage,
  //   MyMicrositePage,
  //   MyListingsPage,
  //   //MyGuestsPage,
  //   MyLeadsPage,
  //   MyPairedAgentsPage,
  //   MyPairedLendersPage,
  //   MyPairedListingsPage,
  //   ListingActionPopoverComponent,
  //   ProfileActionPopoverComponent,
  //   ProfileComponentComponent,
  //   SplashComponent,
  //   HelpPage,
  //   //  OpenHousePage,
  //   ListingDetailPage,
  //   //MyGuestDetailsPage,
  //   MyLeadsDetailsComponent,
  //   PairedAgentContactInfoComponent,
  //   // MyLeadLookupPage,
  //   // EnhancedDetailsPage,
  //   EnhancedModalPage,
  //   OfflineModalPage,
  //   KioskModePage,
  //   ColorPickerPrimaryPage,
  //   UpdateProfileInformationPage,
  //   EditInputPage,
  //   EditLinksPage,
  //   EditReviewPage,
  //   FilterLeadsPopupComponent,
  //   EditMicrositePage,
  //   EditInputAddressPage,
  //   ViewRawLeadsComponent,
  //   EditInputFollowUpPage,
  //   EditNotesPage,
  //   PropertyLayoutPreviewPage,
  //   QRPopupComponent,
  //   AnalyticsPopupComponent,
  //   TextAvatarComponent,
  //   GuestItemComponent,
  //   SearchBox
  // ],
  imports: [BrowserModule, IonicModule.forRoot(),
    NgAisModule.forRoot(),
    BrowserAnimationsModule,
    AngularPinturaModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    CookieModule.forRoot(),
    ReactiveFormsModule,
    TextMaskModule,
    // PdfViewerModule,
    //NgxExtendedPdfViewerModule,
    // DigitOnlyModule,
    IonicRatingComponentModule,
    // IonicInputMaskModule,
    PipesModule,
    SwiperModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      //  enabled: environment.production,
      enabled: true,

      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy
    },
    CurrencyPipe,
    SlicePipe,
    TruncatePipe,
    CallNumber,
    Camera,
    // Authentication
    // {
    //   provide: 'AppInstance',
    //   useValue: app,
    // },
    // // Firestore
    // {
    //   provide: 'FirestoreInstance',
    //   useFactory: () => {
    //     const firestore = getFirestore(app);
    //     return firestore;
    //   },
    // },
    // {
    //   provide: 'AuthInstance',
    //   useFactory: () => {
    //     return initializeAuth(app, {
    //       persistence: indexedDBLocalPersistence,
    //     });
    //   },
    // },
    // Storage
    // {
    //   provide: 'StorageInstance',
    //   useFactory: () => {
    //     return getStorage(app);
    //   },
    // },

    // // Functions
    // {
    //   provide: 'FunctionsInstance',
    //   useFactory: () => {
    //     return getFunctions(app);
    //   },
    // },

    // // AnalyticsInstance
    // {
    //   provide: 'AnalyticsInstance',
    //   useFactory: () => {
    //     return getAnalytics(app);
    //   },
    // },



    // {
    //   provide: SETTINGS, useValue: {
    //     // cacheSizeBytes: 500 * 1024 * 1024, // 500mb
    //     experimentalAutoDetectLongPolling: true,
    //     ignoreUndefinedProperties: true,
    //     merge: true,
    //     cacheSizeBytes: CACHE_SIZE_UNLIMITED
    //   }
    // },

    FirebaseAnalytics,
    HTTPService,
    AuthService,
    GuestService,
    CompressorService,
    ConnectionsService,
    ListingService,
    IonicRatingService,
    UtilsService,
    AppRatingService,
    AnalyticsService,
    NetworkProvider,
    Network,
    OpenNativeSettings,
    InAppBrowser,
    EmailComposer,
    SafariViewController,
    CommonProvider,
    OpenAIService,
    PdfEditorService,
    SMSService,
    Keyboard,
    Insomnia,
    ScreenOrientation,
    ImagesManagementService,
    Intercom,
    SocialSharing,
    IntercomService,
    CryptoService,
    ImagePicker,
    ListingDataProviderService,
    GuestService,
    FileTransfer,
    StatusBar,
    SplashScreen,
    FormBuilder,
    NavParams,
    I8nService,
    SqlService,
    SQLite,
    Clipboard,
    SMS
  ],
  exports: [NumberOnlyDirective],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

}
