/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { of, from, toArray, Observable } from 'rxjs';
import { mergeMap, concatMap } from 'rxjs/operators';
// import { Colors } from '../constants';
import { UtilsService } from './utils.service';
import {
  DocumentData, getFirestore, collection, doc, getDoc,
  getDocs, limit, onSnapshot, orderBy, query, updateDoc, where
} from '@firebase/firestore';
import { API } from '../constants';
import { Platform } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';


@Injectable({
  providedIn: 'root'
})
export class GuestService {
  public uid: string;
  lastGuestsQuerySnapshot;

  constructor(
    private utils: UtilsService,
    private auth: AuthService,
    private platform: Platform,
    private network: Network
  ) {

    this.uid = this.auth.getUID();
  }

  // public getMyGuests() {
  //   return this.afs.collection('users').doc(`${this.uid}/`).collection('guests');
  // }

  public checkInternet(): boolean {

    if (this.platform.is('cordova')) {
      return this.network.type !== null && this.network.type !== 'none';
    }

    return window.navigator.onLine;
  }

  public getGuest(guestId: string): Promise<any> {
    const firestore = getFirestore();

    return new Promise(async (resolve, reject) => {
      try {
        const guestRef = doc(firestore, 'users', `${this.auth.getUID()}/`, 'guests', `${guestId}`);
        const snapshot = await getDoc(guestRef);
        if (snapshot.exists()) {
          console.log('guest found: ' + JSON.stringify(snapshot.data()));
          resolve(snapshot.data() as DocumentData);
        } else {
          reject('No guest found!');
        }
      } catch (error) {
        reject(error);
      }
    });
  }




  public async getGuestCount(): Promise<number> {
    const firestore = getFirestore();
    try {
      const guestsCollectionRef = collection(firestore, 'users', `${this.auth.getUID()}/`, 'guests');
      const snapshot = await getDocs(guestsCollectionRef);
      return snapshot.size as number;
    } catch (error) {
      throw error;
    }
  }

  // DOESN'T USE THE LIMIT GUESTS. GETS ENTIRE COLLECTION
  // public allGuests() {

  //   const firestore = getFirestore();

  //   const guestsCollectionRef = collection(firestore, `${API.users_endpoint}/${this.auth.getUID()}/${API.guests_endpoint}`);
  //   const guestsQuery = query(guestsCollectionRef, orderBy('createdDate', 'desc'));

  //   return from(getDocs(guestsQuery)).pipe(
  //     mergeMap((snapshot) => {
  //       return from(snapshot.docs).pipe(
  //         concatMap((guest) => {
  //           try {
  //             const data: any = guest.data();
  //             const id = guest.id;

  //             console.log('fromCache ' + guest.metadata.fromCache);
  //             const avatarName = this.auth.createAvatarName(data.guest.fullName);
  //             let guestAvatar = data.guest.avatar || '';
  //             if (data.fullContactGuestDetails && data.fullContactGuestDetails.avatar) {
  //               guestAvatar = data.fullContactGuestDetails.avatar;
  //             }

  //             // PROTECT AGAINST BOOLEAN STRINGS
  //             let archived = data.archived;
  //             if (archived === 'false') {
  //               archived = false;
  //             } else if (archived === 'true') {
  //               archived = true;
  //             }

  //             const guestNew = {
  //               id,
  //               guestId: id,
  //               color: this.utils.avatarColor(avatarName),
  //               avatarName: avatarName,
  //               avatarImg: guestAvatar,
  //               signInTime: new Date(Number(data.createdDate)),
  //               fromCache: guest.metadata.fromCache,
  //               archived: archived,
  //               //  signInTime: moment(data.createdDate).format('MMMM DD, YYYY'),
  //               ...data
  //             };
  //             return of(guestNew);
  //           } catch (ex) {
  //             console.log('error in guests: ' + ex);
  //             return of({});
  //           }
  //         }),
  //         toArray()
  //       );
  //     })
  //   );
  // }


  public guests(): Observable<any[]> {
    console.log('guests query init.');
    const firestore = getFirestore();
    const guestsCollectionRef = collection(firestore, `${API.users_endpoint}/${this.auth.getUID()}/${API.guests_endpoint}`);
    const guestsQuery = query(guestsCollectionRef, orderBy('createdDate', 'desc'), limit(600));

    return new Observable<any[]>((subscriber) => {
      const unsubscribe = onSnapshot(guestsQuery, (snapshot) => {
        const guests = [];
        let isOnline = true;

        if ((!snapshot || !snapshot.docs.length)
          && this.lastGuestsQuerySnapshot
          && this.platform.is('cordova')
          && !this.checkInternet()) {
          console.log('device is cordova and offline, use last known guests');
          snapshot = this.lastGuestsQuerySnapshot;
          isOnline = false;
        }

        if (snapshot.docs.length > 0) {
          this.lastGuestsQuerySnapshot = snapshot;
        }

        console.log('guests query snapshot. ' + JSON.stringify(snapshot));
        snapshot.forEach((guest) => {
          try {
            const data = guest.data();
            const id = guest.id;

            //  console.log('fromCache ' + guest.metadata.fromCache);
            const avatarName = this.auth.createAvatarName(data.guest.fullName);
            let guestAvatar = data.guest.avatar || '';
            if (data.fullContactGuestDetails && data.fullContactGuestDetails.avatar) {
              guestAvatar = data.fullContactGuestDetails.avatar;
            }

            // PROTECT AGAINST BOOLEAN STRINGS
            let archived = data.archived;
            if (archived === 'false') {
              archived = false;
            } else if (archived === 'true') {
              archived = true;
            }

            const guestNew = {
              id,
              guestId: id,
              color: this.utils.avatarColor(avatarName),
              avatarName: avatarName,
              avatarImg: guestAvatar,
              signInTime: new Date(Number(data.createdDate)),
              fromCache: guest.metadata.fromCache && !isOnline,
              archived: archived,
              ...data
            };

            guests.push(guestNew);
          } catch (ex) {
            console.log('error in guests 1: ' + ex);
          }
        });

        subscriber.next(guests);
      }, (error) => {
        console.log('error in guests 2: ' + error);
        subscriber.error(error);
      });

      return () => unsubscribe();
    });
  }


  public guestsLender() {
    const firestore = getFirestore(); // Get the Firestore instance using getFirestore()

    const guestsCollection = collection(firestore, 'users', this.auth.getUID(), 'guests');
    const guestsQuery = query(
      guestsCollection,
      //   where('guest.mortgageAnswer', 'in', ['Yes']),
      orderBy('createdDate', 'desc'),
      limit(600)
    );

    return from(getDocs(guestsQuery)).pipe(
      mergeMap((guestsSnapshot) =>
        from(guestsSnapshot.docs).pipe(
          concatMap((guest) => {
            try {
              const data = guest.data();
              const id = guest.id;

              console.log('fromCache ' + guest.metadata.fromCache);
              const avatarName = this.auth.createAvatarName(data.guest.fullName);
              let guestAvatar = data.guest.avatar || '';
              if (data.fullContactGuestDetails && data.fullContactGuestDetails.avatar) {
                guestAvatar = data.fullContactGuestDetails.avatar;
              }

              // PROTECT AGAINST BOOLEAN STRINGS
              let archived = data.archived;
              if (archived === 'false') {
                archived = false;
              } else if (archived === 'true') {
                archived = true;
              }

              const guestNew = {
                id,
                guestId: id,
                color: this.utils.avatarColor(avatarName),
                avatarName: avatarName,
                avatarImg: guestAvatar,
                signInTime: new Date(Number(data.createdDate)),
                fromCache: guest.metadata.fromCache,
                archived: archived,
                //  signInTime: moment(data.createdDate).format('MMMM DD, YYYY'),
                ...data,
              };
              return of(guestNew);
            } catch (ex) {
              console.log('error in guests: ' + ex);
              return of({});
            }
          })
        )
      ),
      toArray()
    );
  }


  public update(guestId: string, data: any) {
    const firestore = getFirestore();
    console.log('update guest for user: ' + this.auth.getUID() + ' and guest: ' + guestId);
    const guestRef = doc(collection(firestore, 'users', `${this.auth.getUID()}/`, 'guests'), guestId);
    return updateDoc(guestRef, data);
  }

  public archiveGuestData(guestId: string, data: any) {
    const firestore = getFirestore();
    console.log('update guest for user: ' + this.auth.getUID() + ' and guest: ' + guestId);
    const guestRef = doc(collection(firestore, 'users', `${this.auth.getUID()}/`, 'guests'), guestId);

    // Update the fields
    const updatedData = {
      archived: true,
      'guest.archived': true,
    };

    return updateDoc(guestRef, updatedData);
  }

  updateGuest(guestId: string, guestData: any) {
    return this.update(guestId, { guest: guestData });
  }

  archiveGuest(guestId: string, guestData: any) {
    // TO DO
    // delete sign in time which should not get saved
    // need to refactor the archive guest to only save 1 field and not the entire record
    delete guestData.signInTime;
    return this.archiveGuestData(guestId, guestData);
  }

  JSONToCSVConvertor(JSONData, ShowLabel) {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    const arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    let CSV = '';        //Set Report title in first row or line
    let row = '';
    //   CSV += ReportTitle ;    //This condition will generate the Label/Header
    if (ShowLabel) {
      row = '';
      //This loop will extract the label from 1st index of on array
      // eslint-disable-next-line guard-for-in
      for (const index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ',';
      }
      row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + '\n';
    }
    //1st loop is to extract each row
    for (let i = 0; i < arrData.length; i++) {
      let row2 = '';
      //2nd loop will extract each column and convert it in string comma-seprated
      // eslint-disable-next-line guard-for-in
      for (const index2 in arrData[i]) {
        row2 += '"' + arrData[i][index2] + '",';
      }
      row2.slice(0, row.length - 1);
      //add a line break after each row
      CSV += row2 + '\n';
    }
    return CSV;
  }
}
