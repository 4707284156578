/* eslint-disable object-shorthand */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Platform, LoadingController, AlertController, ModalController, ToastController, IonApp } from '@ionic/angular';
import { NetworkProvider } from './network';
import { QRPopupComponent } from '../components/qr-popup/qp-popup';
import { InAppBrowser, InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { I8nService } from './i8nService';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { OfflineModalPage } from '../components/offline-modal/offline-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { User_global } from '../app.models';
import { Clipboard } from '@awesome-cordova-plugins/clipboard/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { CurrencyPipe } from '@angular/common';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { IntercomService } from '../services/intercom.service';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CommonProvider {
  loading: any;
  loadingSecond: any;
  states: any;
  statesLongnames: any;
  loadingTimer: any;
  dbErrorAlert: any;
  hideSideMenu = false

  constructor(
    public navCtrl: Router,
    public plt: Platform,
    public loadingCtrl: LoadingController,
    public alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private networkProvider: NetworkProvider,
    private iab: InAppBrowser,
    private toastCtrl: ToastController,
    public i8nService: I8nService,
    private emailComposer: EmailComposer,
    private clipboard: Clipboard,
    private callNumber: CallNumber,
    private currencyPipe: CurrencyPipe,
    private sms: SMS,
    private intercom: IntercomService,
    private safariViewController: SafariViewController
  ) {
    this.states = ['AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'FM', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MH', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'PW', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY', 'Other'];
    this.statesLongnames = ['Alaska', 'Alabama', 'Arkansas', 'American Samoa', 'Arizona', 'California', 'Colorado', 'Connecticut', 'District of Columbia', 'Delaware', 'Florida', 'Federated States of Micronesia', 'Georgia', 'Guam', 'Hawaii', 'Iowa', 'Idaho', 'Illinois', 'Indiana', 'Kansas', 'Kentucky', 'Louisiana', 'Massachusetts', 'Maryland', 'Maine', 'Marshall Islands', 'Michigan', 'Minnesota', 'Missouri', 'Northern Mariana Islands', 'Mississippi', 'Montana', 'North Carolina', 'North Dakota', 'Nebraska', 'New Hampshire', 'New Jersey', 'New Mexico', 'Nevada', 'New York', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Palau', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'Wisconsin', 'West Virginia', 'Wyoming', 'Other'];
  }

  getPSTTime() {
    //  var utcDate = new Date(date.toUTCString());
    //  utcDate.setHours(utcDate.getHours() - 8);
    //  return new Date(utcDate);
    return new Date();
  }

  public openPhoneNumber(phone) {
    if (!this.plt.is('cordova')) {
      window.open('tel:' + phone, '_self');
      return;
    }
    else {
      const options = {
        replaceLineBreaks: false, // true to replace \n by a new line, false by default
        android: {
          intent: 'INTENT'  // send SMS with the native android SMS messaging
        }
      };
      this.sms.send(phone, '', options);
    }
  }

  async showPairedLenderError() {
    const pairAlert = await this.alertCtrl.create({
      header: 'A Paired Lender Is Required',
      message: this.i8nService.messages.listingPairedLenderRequired,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: data => {

          }
        }
      ]
    });
    await pairAlert.present();
  }

  public sendEmail(toEmail) {

    if (!this.plt.is('cordova') || this.plt.is('android')) {

      window.open('mailto:' + toEmail, '_self');
      return;
    }

    try {
      this.emailComposer.getClients().then((apps: []) => {
        // Returns an array of configured email clients for the device
        console.log('apps available ' + JSON.stringify(apps));
      });

      this.emailComposer.hasPermission().then(app => {
        console.log('permission for email: ' + app);
      });

      this.emailComposer.open({
        to: toEmail
      });
    }
    catch (err) {
      console.log('error sending email' + err);
      alert('Error opening email client');
    }
  }

  public formatDate(date) {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${monthNames[monthIndex]} ${day} ${year}`;
  }


  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  async showImageLimit() {
    const alert = await this.alertCtrl.create({
      header: this.i8nService.messages.imageTooBig,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: data => {

          }
        }
      ]
    });
    await alert.present();
  }

  async withLoading<T>(callback: () => Promise<T>, options: { loadingText?: string, duration?: number } = {}): Promise<Awaited<T>> {
    let result: Awaited<T>;

    try {
      await this.startLoading(options.loadingText, options.duration);
      result = await callback()
    } catch (error) {}

    this.closeLoading()

    return result;
  }

  async startLoading(message = 'Please wait...', timer = 60000) {
    const self = this;
    try {
      if (!this.loading) {
        this.loading = await this.loadingCtrl.create({ message: message });
        await this.loading.present();

        self.loadingTimer = setTimeout(() => {
          clearTimeout(self.loadingTimer);
          if (self.loading) {
            self.loading.dismiss();
            self.loading = false;
          }

        }, timer);
      }
    }
    catch (err) {
      console.log('err opening loading window');
    }
  }

  closeLoading() {
    console.log('close loading called');
    clearTimeout(this.loadingTimer);

    try {
      if (this.loading) {
        this.loading.dismiss();
        this.loading = false;
      }
    }
    catch (err) {
      console.log('loading win not found');
    }
  }

  async startLoadingSecond() {
    if (!this.loadingSecond) {
      this.loadingSecond = await this.loadingCtrl.create({ message: 'Please wait...' });
      await this.loadingSecond.present();
    }
  }

  async showSimpleError(errorMessage, reload = false) {

    const alert = await this.alertCtrl.create({
      header: 'App Error',
      message: errorMessage,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: data => {
            if (reload) {
              window.location.reload();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async showOfflineError(reload = false) {

    const alert = await this.alertCtrl.create({
      header: 'Offline Error',
      message: this.i8nService.messages.offlineError,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
          handler: data => {
            if (reload) {
              window.location.reload();
            }
          }
        }
      ]
    });
    await alert.present();
  }

  // DISPLAY
  async displayServiceError(err) {
    const self = this;

    const alert = await this.alertCtrl.create({
      header: err.header,
      message: err.body,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
        {
          text: err.button1Text,
          role: 'button1',
          handler: data => {
            self.openLink(err.button1Link);
          }
        }
      ]
    });
    await alert.present();
  }

  async showDBWriteError(reload = false) {
    console.log('show db write error!');

    const callbackURL = 'https://juuj.me/troubleshooting-app-issues';
    const errTitle = 'Oops. There\'s a Problem.';
    const errorMessage = this.i8nService.messages.dbWriteError;

    if (!this.dbErrorAlert) {

      this.dbErrorAlert = await this.alertCtrl.create({
        header: errTitle,
        message: errorMessage,
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: data => {
              try {
                this.dbErrorAlert = false;
                if (reload) {
                  localStorage.clear();
                  window.location.reload();
                }
                // setTimeout(() => { window.location.reload(); }, 1000);
              }
              catch (err) {
                console.log('err closing popup: ' + err);
              }
            }
          },
          {
            text: 'Help Section',
            handler: data => {
              console.log('help clicked');
              if (!this.networkProvider.checkInternet()) {
                this.networkProvider.noNetworkModal();
                return;
              }
              this.dbErrorAlert = false;
              this.openLink(callbackURL);
            }
          }
        ]
      });
      await this.dbErrorAlert.present();
    }
  }

  async warnLowDiskSpace(reload = false) {
    console.log('show low disk space!');
    const self = this;
    const errTitle = 'Storage Error';
    const errorMessage = this.i8nService.messages.lowDiskSpaceWarning;

    if (!self.dbErrorAlert) {

      self.dbErrorAlert = await self.alertCtrl.create({
        header: errTitle,
        message: errorMessage,
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: data => {
              try {
                self.dbErrorAlert = false;
                if (reload) {
                  localStorage.clear();
                  window.location.reload();
                }
              }
              catch (err) {
                console.log('err closing popup: ' + err);
              }
            }
          },
          {
            text: 'Settings',
            handler: data => {
              self.dbErrorAlert = false;
              if (this.networkProvider.openNativeSettings) {
                console.log('openNativeSettings is active');
                this.networkProvider.openNativeSettings.open('wifi').then(() => { });
              } else {
                console.log('openNativeSettings is not active!');
              }
            }
          }
        ]
      });
      await this.dbErrorAlert.present();
    }
  }

  showHelpPage() {
    this.openLink('https://juuj.me/help-home');
  }


  closeLoadingSecond() {

    if (this.loadingSecond) {
      this.loadingSecond.dismiss();
      this.loadingSecond = false;
    }
  }

  createLoopRange(limit: number): Array<any> {
    const result = [];

    for (let i = 0; i < limit; i++) {
      result.push(i);
    }

    return result;
  }

  public formatPhone(phone: string, countryCode?: string): string {
    if (!phone) {
      return '';
    }

    // Remove any non-digit characters from the phone number
    const cleanedPhone = phone.replace(/\D/g, '');

    // Use a regular expression to format the phone number
    //  const formattedPhone = cleanedPhone.replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3');

    // Add the country code at the front with a "+" sign
    const phoneWithCountryCode = countryCode ? `+${countryCode}${cleanedPhone}` : phone.replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3');

    return phoneWithCountryCode;
  }


  public cleanPhoneNumber(formattedPhone: string): string {
    if (!formattedPhone) {
      return '';
    }
    return (formattedPhone.replace(/[^0-9]+/g, ''));
  }

  public formatNumber(value: number, unit: string = ''): string {
    if (value) {
      return unit + value.toString().split(/(?=(?:\d{3})+(?:\.|$))/g).join(',');
    }
    return '--';
  }

  public formatCurrency(currencyValue: number, currencyCode: string = 'USD') {

    if (currencyValue) {
      return this.currencyPipe.transform(currencyValue, currencyCode, 'symbol', '1.0-0');
    }
    else {
      return '--';
    }
  }

  public async copyToClipboard(str: string, message?: string, msgduration?: number) {
    console.log('copy to clipboard: ' + str);

    // if (this.plt.is('cordova')) {
    //   this.clipboard.copy(str);
    //   this.toast(message ? message : 'Link copied to your clipboard.', msgduration);
    // } else {
    try {
      await navigator.clipboard.writeText(str);
      this.toast(message ? message : 'Link copied to your clipboard.', msgduration);
    } catch (error) {
      console.error('Copy to clipboard failed:', error);
    }
    //   }
  }

  async toast(message: string, duration: number = 4000, position: any = 'top', delay: number = 25) {
    setTimeout(async () => {
      const toast = await this.toastCtrl.create({
        message: message,
        duration: duration,
        position: position,
        color: 'light',
        translucent: true,
        cssClass: 'custom-toast'
      });
      await toast.present();
    }, delay);
  }

  openSubscription(url) {
    this.iab.create(url, '_self', 'location=yes');
  }

  openLink(url, target = '_blank') {
    const self = this;

    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      url = 'https://' + url;
    }

    // if (this.plt.is('cordova') && target !== '_system') {
    //   this.intercom.hideLauncher();
    // }


    if (this.plt.is('ios') && target !== '_system') {
      self.intercom.hideLauncher();

      this.safariViewController.isAvailable().then((available: boolean) => {
        if (available) {


          this.safariViewController.show({
            url: url,
            hidden: false,
            animated: false,
            transition: 'curl',
            enterReaderModeIfAvailable: false,
            tintColor: '#14294f',
            barColor: '#14294f',
            controlTintColor: '#ffffff'
          }).subscribe((result: any) => {
            if (result.event === 'opened') {
              console.log('opened');
            } else if (result.event === 'loaded') {
              console.log('loaded');
            } else if (result.event === 'closed') {
              console.log('closed');
              self.intercom.showLauncher();
            }
          }, (error: any) => {
            console.error(error);
          });
        } else {
          // Safari View Controller is not available, fallback to opening the URL in the system browser
          window.open(url, target, 'location=yes');
        }
      });
    } else {
      // If platform is not iOS, fallback to opening the URL in the system browser

      const browser: InAppBrowserObject = this.iab.create(url, target, 'location=no');

      // Listen for the 'exit' event
      // if (this.plt.is('cordova') && target !== '_system') {
      //   browser.on('exit').subscribe(() => {
      //     console.log('In-app browser was closed');
      //     this.intercom.showLauncher();
      //     // Additional actions you want to perform when the in-app browser is closed
      //   });
      // }
    }
  }

  async showQRCode(environment, selectedListing) {
    console.log('show listing QR: ' + selectedListing.qrCodeImage);
    if (selectedListing.slug) {
      const url = environment.blockparty + selectedListing.slug;
      const modal = await this.modalCtrl.create({
        component: QRPopupComponent, componentProps: {
          url: url,
          common: this,
          slug: selectedListing.slug,
          listingQR: selectedListing.qrCodeImage,
          listingQRKiosk: selectedListing.qrCodeImageKiosk,
          qrOnlylink: null,
          cordova: this.plt.is('cordova'),
          type: 'download-qr',
          listing: selectedListing
        }
      });

      await modal.present();
      // this.downloadFile(url, selectedListing.slug);
    }
    else {

      const msg = User_global.role === 'agent' ? this.i8nService.messages.listingNeedsPublishingBeforeSharing : this.i8nService.messages.listingNeedsPublishingBeforeSharingLender;
      const callbackURL = 'https://juuj.me/creating-a-listing-microsite';
      const modal = await this.alertCtrl.create({
        header: 'Listing Microsite Required',
        message: msg,
        buttons: [
          {
            role: 'cancel',
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Help Section',
            handler: data => {
              if (!this.networkProvider.checkInternet()) {
                this.networkProvider.noNetworkModal();
                return;
              }
              this.openLink(callbackURL, '_blank');
            }
          }
        ]
      });
      await modal.present();
    }
  }

  reloadPage() {
    window.location.reload();
  }

  shorten(text: string, maxLength: number) {
    if (!text || (text && text.length === 0)) { return null; }
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  }

  async openOfflineModal() {
    const modal = await this.modalCtrl.create({
      component: OfflineModalPage,
      componentProps: {}
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    console.log(data);

    if (data.openHelp === true) {
      this.navCtrl.navigate(['/help']);
    }
  }

  isDeepEqual(obj1, obj2) {
    return _.isEqual(obj1, obj2, customComparator);
  }
}

function customComparator(objValue, othValue) {
  if (_.isArray(objValue) && _.isArray(othValue)) {
    return _.isEqual(_.sortBy(objValue, JSON.stringify), _.sortBy(othValue, JSON.stringify));
  }
}