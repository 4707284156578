/* eslint-disable prefer-const */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, ViewChild, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { NavParams, AlertController, ModalController, Platform, PopoverController, NavController, IonRouterOutlet, IonBackButton } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { EditInputPage } from '../components/edit-input/edit-input';
import { Listing, ListingImage, Question } from '../app.models';
import { EditInputAddressPage } from '../components/edit-input-address/edit-input-address';

import { NetworkProvider } from '../services/network';
import { EditInputFollowUpPage } from '../components/edit-input-followup/edit-input-followup';
import { CommonProvider } from '../services/common';
import { LATITUDE, LONGITUDE, BLANK_TOUR_LINKS, MAX_QR_UPLOAD_RETRY } from '../constants';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { ImagesManagementService } from '../services/images-management.service';
import { ListingDataProviderService } from '../services/listing-data-provider.service';
import { Subject, Subscription, combineLatest } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { CurbUser, User_global } from '../app.models';
import { EditMicrositePage } from '../components/edit-microsite/edit-microsite';
import { CompressorService } from '../services/compressor.service';
import { environment } from '../../environments/environment';

import { IntercomService } from '../services/intercom.service';
import { ListingActionPopoverComponent } from '../components/listing-action/listing-action';

import { ConnectionsService } from '../services/connections.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Events } from '../services/events.service';
import { PropertyLayout } from '../constants';
import { I8nService } from '../services/i8nService';
import { ItemReorderEventDetail } from '@ionic/core';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { EnhancedModalPage } from '../components/enhanced-modal/enhanced-modal';
import { ListingService } from '../services/listing.service';
import algoliasearch from 'algoliasearch';
import aa from 'search-insights';
import { QrCodeService } from '../services/qr-code.service';
import { openDefaultEditor } from '@pqina/pintura';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { PinturaEditorComponent } from '@pqina/angular-pintura';
import { OpenAIService } from '../services/openai.service';
// import { InfoModalComponent } from '../components/info-modal/info-modal.component';
import { getFunctions, httpsCallable } from '@firebase/functions';

declare let google;

const searchClient = algoliasearch(
  'N26A8PC608',
  '068c90fcb4bdad125a840343a2725949'
);

@Component({
  selector: 'app-listing-detail',
  templateUrl: 'listing-detail.html',
  styleUrls: ['listing-detail.scss'],

  animations: [
    trigger('fadeOut', [
      state('divState', style({ opacity: 1 })),
      transition(':leave', [
        animate('0.3s', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ListingDetailPage implements OnDestroy {

  @ViewChild(IonBackButton, { static: false }) backButton: IonBackButton;
  @ViewChild('navBar') navBar;
  @ViewChild('map') mapElement: ElementRef;
  userDataSub: any;
  userData: CurbUser;
  initialized = false;
  isNetwork = true;
  navListing: any;
  isMapVisible = false;
  imageToEdit: string;
  imageType: string;
  currentImageEditing: ListingImage;
  errorTimeout: any;
  offlineMode = false;
  config;
  role = 'agent';
  map; // google map
  uid; // user uid
  uploadInProgress: Set<any>; // upload photos progress
  listingDetailsTimeout;
  totalPairedAgents = [];

  kioskQuestions = [
    {
      isActive: false,
      question: 'mortgage',
      type: 'yesNo',
      // conditionalTriggerAnswer: false
    },
    // {
    //   isActive: false,
    //   question: 'Are you currently working with a real estate agent?',
    //   type: 'yesNo',
    //   // conditionalTriggerAnswer: false
    // },
    {
      isActive: false,
      question: 'Who is your real estate agent?',
      type: 'multipleChoice',
      answers: ['Share agent info', 'I am an agent', 'I\'m not working with an agent'],
      details: [
        { selectionType: 'ChoiceWithDetails', textPrompt: 'Provide Details' },
        { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' },
        { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' }
      ]
    },
    {
      isActive: false,
      question: '',
      type: 'text',
      // conditionalTriggerAnswer: false
    },
    {
      isActive: false,
      question: '',
      type: 'text',
      // conditionalTriggerAnswer: false
    }
  ];

  guestFollowUp = false;

  currentListing: Listing = {
    createdAt: this.common.getPSTTime().getTime(),     //firebase.firestore.FieldValue.serverTimestamp(),
    address: {
      title: '',
      placeId: '',
      zip: '',
      city: '',
      state: '',
      country: ''
    },
    loanOfficer: '',
    linkToInfo: '',
    textMessage: '',
    guestsCount: 0,
    kioskQuestions: [
      {
        isActive: false,
        question: 'mortgage',
        type: 'yesNo',

      },
      {
        isActive: false,
        question: 'Who is your real estate agent?',
        type: 'multipleChoice',
        answers: ['Share agent info', 'I am an agent', 'I\'m not working with an agent'],
        details: [
          { selectionType: 'ChoiceWithDetails', textPrompt: 'Provide Details' },
          { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' },
          { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' }
        ]
      },
      {
        isActive: false,
        question: '',
        type: 'text',

      },
      {
        isActive: false,
        question: '',
        type: 'text',

      }
    ],
    propertyLayout: this.utils.mapPropertyLayout(PropertyLayout.LANDSCAPE_1),
    propertyImage: {
      id: '',
      base64: '',
      imagePath: '',
      imageURL: ''
    },
    enhancedMode: false,
    listingImages: [],
    listingDetails: {
      price: null,
      beds: null,
      baths: null,
      squareFootage: null,
      description: '',
      saleStatus: 'For Sale',
      moreInfoURL: ''
    },
    micrositeLinks: [],
    webPublished: false,
    tourLinks: BLANK_TOUR_LINKS,
    hasPropertyImage: false,
    qrCodeImage: '',
    qrCodeImageKiosk: '',
    qrCodeAgentSignIn: '',
    archived: false,
    sampleListing: false,
    assignedByUser: [],
    listingAssigned: false,
    listingAgent: '',
    pairedListingAgents: [],
    agentAdmin: ''
  };

  showDoka = false;
  loanOfficer: any;
  pairedAgent: any;
  loanOfficerReviews: any;
  enhancedOpen = false;
  copySlugTitle: string;
  env: any = environment;
  segmentValue: string;
  linkPlaceholder = 'This can be any text you want';

  public buttonColor: string;
  public active = 'active';
  itemExpandHeight = 100;

  mapSubject: Subject<any>;
  markerSubject: Subject<any>;
  mapSubs: Subscription;
  markers: any;
  geocoder: any;
  // userId: string;
  toggleMode: string;
  backBtnHandler: any;
  loanOfficerRefresh: any;
  loanOfficerLoading = false;
  listingSubscription: any;
  resizeEvent: any;
  micrositeUpdated = false;
  startingMode = 'openhouse';
  deletedListing = false;
  firstLoad = true;
  dataIsLoading = false;
  loaded = false;
  loadInterval: any;
  retryQRUpload = 0;
  isPaired = '';
  openHouse = 'Open House Sign-in';
  restrictMarketingServiceOnly = false; // RESTRICT OTHER MENU OPTIONS
  suggestionsSubscription; // listing auto population suggestions subscription
  suggestions; // autopopoulation suggestions
  syncSuggestedImagesTimeout; //save timeout

  newImage = {
    id: '',
    base64: '',
    imagePath: '',
    imageURL: '',
    thumbnailPath: '',
    thumbnailURL: ''
  };
  number_of_buttons: number;
  currentPopover: any;
  isPairedListing = false;
  ifListingIsAvailable = false;
  isInternetAvailable = false;
  mapSrc;

  beds: any;
  baths: any;
  price: any;
  squareFootage: any;
  suggestedlistingImages: any;
  noSuggestions = false;
  footerText = '';
  featureFlags;
  lenderOverride = false;
  disclosureDoc: any;
  disclosureLock = false;
  selectedDisclosure = 'addDisclosure';

  private kioskLockQuestion1 = {
    isActive: true,
    question: 'Who is your real estate agent?',
    type: 'multipleChoice',
    answers: ['Share agent info', 'I am an agent', 'I\'m not working with an agent'],
    details: [
      { selectionType: 'ChoiceWithDetails', textPrompt: 'Provide Details' },
      { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' },
      { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' }
    ]
  };

  constructor(
    private fileService: File,
    public navCtrl: Router,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    private auth: AuthService,
    public plt: Platform,
    public networkProvider: NetworkProvider,
    public alertCtrl: AlertController,
    public events: Events,
    public common: CommonProvider,
    private imageService: ImagesManagementService,
    private screenOrientation: ScreenOrientation,
    private zone: NgZone,
    private utils: UtilsService,
    private compressor: CompressorService,
    public popoverCtrl: PopoverController,
    private connectionService: ConnectionsService,
    private route: ActivatedRoute,
    public i8nService: I8nService,
    public nav: NavController,
    private location: Location,
    private ionRouterOutlet: IonRouterOutlet,
    private intercomService: IntercomService,
    private listingService: ListingService,
    public listingDataProvider: ListingDataProviderService,
    private qrService: QrCodeService,
    private openaiService: OpenAIService
  ) {
    console.log('Listing Details Constructor');
    this.env = environment;
  }

  ionViewDidEnter() {
    this.toggleMode = this.plt.is('android') ? 'md' : 'ios';
    console.log('listing detail did enter');
    this.openSignInSegment();
    if (this.auth.userInitialized) {
      this.initListingDetailPage();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initListingDetailPage();
        }
      }, 2000);
    }
    this.isInternetAvailable = this.networkProvider.checkInternet();
  }

  async loadFeatureFlags() {
    if (this.networkProvider.checkInternet()) {
      this.featureFlags = await this.auth.getFeatureFlags();
      console.log('featureglags: ' + JSON.stringify(this.featureFlags));
    }
  }

  async loadDisclosureDoc(): Promise<void> {
    const self = this;

    if (self.disclosureDoc) {
      return;
    }

    try {
      if (User_global.role === 'agent') {
        const res = await this.auth.getDisclosures();
        if (res && res.active === true) {
          self.disclosureDoc = JSON.parse(JSON.stringify(res));
          self.selectedDisclosure = self.disclosureDoc.title;
          console.log('disclosure doc: ' + JSON.stringify(res));

          self.forceDefaultQuestion();
        }
      }
      // lender and admin
      else {

        const functions = getFunctions();
        const callable = httpsCallable(functions, 'checkActiveDisclosureCallable');
        // Call the callable function with the prompt as data
        console.log('looking for disclosure for: ' + this.currentListing.listingAgent);
        callable({ userId: this.currentListing.listingAgent })
          .then(result => {
            if (result) {

              const res = JSON.parse(JSON.stringify(result.data));
              self.disclosureDoc = res.disclosure;
              self.selectedDisclosure = self.disclosureDoc.title;
              console.log('disclosure doc: ' + JSON.stringify(res));

              self.forceDefaultQuestion();
            }
          })
          .catch(error => {
            // Handle errors
            console.error('Error:', error);
            // Reject the Promise with the error

          });

      }

    } catch (err) {
      self.disclosureDoc = false;
      console.log('err in fetch doc: ' + err);
    }
  }


  async onDisclosureChange(event: any) {


    if (event.detail.value === 'addDisclosure') {
      this.common.openLink('https://curbhe.ro/disclosure-requests/');
    }
  }

  addDisclosure() {
    if (this.selectedDisclosure === 'addDisclosure') {
      this.common.openLink('https://curbhe.ro/disclosure-requests/');
    }
  }


  forceDefaultQuestion() {
    const self = this;

    if (self.disclosureDoc &&
      self.disclosureDoc.kioskQuestionLocked1 === true &&
      self.currentListing.kioskQuestions[1]) {

      self.disclosureLock = true;
      self.currentListing.kioskQuestions[1] = self.kioskLockQuestion1;
      self.kioskQuestions[1] = self.kioskLockQuestion1;
    }
  }

  loadMarketingServices() {
    if (environment.production) {
      this.config = {
        indexName: 'marketplace_prod',
        searchClient,
        insightsClient: (window as any).aa,
        initialUiState: {
          marketplace_prod: {
            refinementList: {
              'active': ['true']
            }
          }
        }
      };
    }
    else {
      this.config = {
        indexName: 'marketplace_dev',
        searchClient,
        insightsClient: (window as any).aa,
        initialUiState: {
          marketplace_dev: {
            refinementList: {
              'active': ['true']
            }
          }
        }
      };
    }
  }

  initListingDetailPage() {
    const self = this;

    this.userData = User_global;
    this.role = User_global.role;

    this.loadFeatureFlags();

    this.uploadInProgress = new Set();
    this.uid = this.auth.getUID();
    if (User_global.role === 'lender') {
      // this.isPaired = 'isPaired';
    }
    else if (User_global.role === 'agent') {
      //agent
    }
    else if (User_global.role === 'agentAdmin') {
      //agentAdmin
      this.isPaired = 'isPairedAdmin';
    }

    this.micrositeUpdated = false;
    this.mapSubject = new Subject<any>();
    this.markerSubject = new Subject<any>();
    // this.navListing = this.navCtrl.getCurrentNavigation().extras.queryParams.listing;
    //  this.startingMode = this.route.snapshot.paramMap.get('mode');

    const url = String(window.location);
    this.startingMode = url.split('mode=')[1];

    const id = this.route.snapshot.paramMap.get('id');

    console.log('listing mode ', this.startingMode);
    console.log('trying to load listing details for : ' + id);
    if (id === 'new') {
      this.navListing = 'new';
    }

    console.log('this.navListing : ' + JSON.stringify(this.navListing));
    this.markers = [];
    // this.userId = localStorage.getItem("uid");
    this.toggleMode = this.plt.is('android') ? 'md' : 'ios';

    this.copySlugTitle = 'Copy';

    this.loanOfficer = {
      calendarLink: '',
      company: '',
      email: '',
      IsActive: false,
      name: '',
      phone: 0,
      states: [],
      website: '',
      zipCodes: [],
      reviews: []
    };
    this.loanOfficerReviews = [];

    this.pairedAgent = {
    };

    if (this.auth.userInitialized) {
      this.initializeListing();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initializeListing();
        }
      }, 2000);
    }

    this.backButton.onClick = () => {
      // handle custom back action here
      console.log('on click');
      this.handleBack();
      return;
    };

    this.onResize();
    if (this.startingMode === 'marketing-services') {
      this.currentListing.startingMode = this.startingMode;
    }
    // const listingId = id.toString();

    self.forceDefaultQuestion();
  }



  cancelImageEvent(event) {
    const self = this;
    console.log('cancelImageEvent ' + event);
    const indexToRemove = self.suggestedlistingImages.findIndex(image => image.id === event.id);

    this.suggestedlistingImages.splice(indexToRemove, 1);
    this.syncSuggestedImages();
  }

  // only save once all is done
  syncSuggestedImages() {
    clearTimeout(this.syncSuggestedImagesTimeout);
    this.syncSuggestedImagesTimeout = setTimeout(() => {
      this.auth.updateListingSuggestions(this.currentListing.id, {
        listingImages: this.suggestedlistingImages
      }).catch(err => {
        console.log('error removing sugggestion: ' + err);
      });

    }, 2000);
  }

  async selectImageEvent(event) {

    const self = this;

    console.log('selectImageEvent ' + JSON.stringify(event));
    console.log('full suggested array: ' + JSON.stringify(this.suggestedlistingImages));
    const imageUrl = event.selectedValue; // Replace with the URL of your image

    // Check if the image is already being uploaded
    if (this.uploadInProgress && this.uploadInProgress.has(imageUrl)) {
      console.log('Image already being uploaded. Skipping...');
      return;
    }

    // Add the image URL to the set to indicate it's being uploaded
    this.uploadInProgress.add(imageUrl);

    try {
      const dataUrl = await self.imageService.getImageDataURL(imageUrl);

      console.log('Data URL:', dataUrl);

      // Upload the image data to the server
      self.uploadToServer(dataUrl);

      // Find the index of the item with matching imageUrl

      // Create a closure to capture the current value of indexToRemove
      const removeImageWithDelay = (id) => {
        setTimeout(() => {
          const indexToRemove = self.suggestedlistingImages.findIndex(image => image.id === id);
          console.log('index to remove: ' + indexToRemove);

          if (indexToRemove !== -1) {
            self.suggestedlistingImages.splice(indexToRemove, 1);
          }
          // Update the listing suggestions
          self.syncSuggestedImages();
        }, 2500);
      };

      // Call the closure with the indexToRemove value
      removeImageWithDelay(event.id);

    } catch (error) {
      console.error('Error:', error);
      // Handle the error
    } finally {
      // Remove the image URL from the set when the upload is completed or failed
      this.uploadInProgress.delete(imageUrl);
    }
  }


  selectEvent(event) {
    const self = this;

    let data;
    const existingdetails = this.currentListing.listingDetails;
    switch (event.key) {
      case 'beds':
        data = {
          'beds': event.selectedValue,
        };
        this.currentListing.listingDetails.beds = event.selectedValue;
        break;
      case 'baths':
        data = {
          'baths': event.selectedValue,
        };
        this.currentListing.listingDetails.baths = event.selectedValue;
        break;
      case 'price':
        data = {
          'price': event.selectedValue,
        };
        this.currentListing.listingDetails.price = event.selectedValue;
        break;
      case 'squareFootage':
        data = {
          'squareFootage': event.selectedValue,
        };
        this.currentListing.listingDetails.squareFootage = event.selectedValue;
        break;

      default:
        break;
    }

    this.updateListingDetailsWithTimeout(existingdetails);

  }

  updateListingDetailsWithTimeout(existingdetails) {
    const self = this;

    if (self.listingDetailsTimeout) {
      clearTimeout(self.listingDetailsTimeout);
    }

    self.listingDetailsTimeout = setTimeout(() => {
      self.auth.updateListing(self.currentListing, {
        listingDetails: self.currentListing.listingDetails
      }).catch(err => {
        self.currentListing.listingDetails = existingdetails;
      });

      self.listingDetailsTimeout = false;
    }, 10000);
  }

  cancelEvent(event) {
    switch (event.key) {
      case 'beds':
        this.beds.splice(event.selectedIndex, 1);
        break;
      case 'baths':
        this.baths.splice(event.selectedIndex, 1);
        break;
      case 'price':
        this.price.splice(event.selectedIndex, 1);
        break;
      case 'squareFootage':
        this.squareFootage.splice(event.selectedIndex, 1);
        break;
      // case 'listingImages':
      //   this.listingImages.splice(event.listingImages, 1);
      //   break;
      default:
        break;
    }

    this.suggestions.beds = this.beds;
    this.suggestions.baths = this.baths;
    this.suggestions.price = this.price;
    this.suggestions.squareFootage = this.squareFootage;
    //  this.suggestions.listingImages = this.suggestedlistingImages;

    this.auth.updateListingSuggestions(this.currentListing.id, {
      listingDetails: this.suggestions
    }).catch(err => {
      console.log('error removing sugggestion: ' + err);
    });
  }

  extractNumbersFromArray(strings: any[]): number[] {
    const regex = /-?\d+(?:\.\d+)?/g; // Matches positive and negative decimal numbers

    if (!strings) {
      return strings;
    }

    return strings.reduce((result: number[], str: any) => {
      const matches = String(str).match(regex);
      if (matches) {
        result.push(...matches.map(match => parseFloat(match))); // Convert the matched strings to numbers
      }
      return result;
    }, []);
  }


  // fetchDataforAutoPopulation(listingId): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.listingDataProvider.autoPopulatedData(listingId).then((data) => {
  //       resolve(data);
  //     },
  //     error => {
  //       reject(error);
  //     });
  //   });
  // }

  initializeListing() {




    this.retryQRUpload = 0;
    this.auth.getLenderOverride().then(res => {
      this.lenderOverride = res;
      // alert(this.lenderOverride);
    });

    const id = this.route.snapshot.paramMap.get('id');
    // kioskQuestion type can be:
    // text
    // number
    // yesNo
    // multipleChoice
    if (this.navListing === 'new') {

      // this.currentListing.listingImages.push(this.newImage);
      if (this.networkProvider.checkInternet() && this.navListing === 'new') {
        console.log('online mode setting enhanced mode true by default!');
        this.currentListing.kioskQuestions[0].isActive = true;
        this.currentListing.enhancedMode = true;
        // this.syncEnhancedModeFlag();
      };
      this.loaded = true;
      this.initializeListingDetail();

      if (User_global.role === 'agent' && !this.currentListing.listingAgent) {
        this.currentListing.listingAgent = this.auth.getUID();
        this.loadDisclosureDoc();
        //     alert(this.currentListing.listingAgent);
      }
    } else {

      this.ifListingIsAvailable = true;
      this.listingDataProvider.listing(id).then((listing) => {

        console.log('got listing: ' + JSON.stringify(listing));

        this.currentListing = listing;

        this.loadDisclosureDoc();
        this.loadListingFields(this.currentListing);

        this.currentListing.id = id;
        this.loaded = true;
        console.log('listing on load: ' + JSON.stringify(this.currentListing));

        //checking id the listing is paired listing or not
        if (listing.pairedStatus === 'paired') {
          this.isPairedListing = true;
          this.connectionService.public_profile(this.currentListing.listingAgent).then(res => {
            this.pairedAgent = res;
          });
        }
        else {
          this.isPairedListing = false;
        }

        // NEED TO MAKE SURE NEW IMAGES ARE BACKWARDS COMPATIBLE

        // IF FIRST TIME USE ON NEW SYSTEM
        console.log('this.currentListing.listingDetails');
        console.log(this.currentListing.listingDetails);
        // if (!this.currentListing.listingDetails) {
        //   console.log('listing details do not exist, creating.');
        //   this.currentListing.listingDetails = {
        //     price: null,
        //     beds: null,
        //     baths: null,
        //     squareFootage: null,
        //     description: '',
        //     saleStatus: 'For Sale',
        //     moreInfoURL: ''
        //   };
        // }

        if (!this.currentListing.listingImages) {
          this.currentListing.listingImages = [];
          this.currentListing.hasPropertyImage = false;

          if (this.currentListing.propertyImage.imageURL && this.currentListing.propertyImage.imageURL !== '') {
            this.currentListing.listingImages.push(this.currentListing.propertyImage);
            this.currentListing.hasPropertyImage = true;
          }
          else if (this.currentListing.propertyImage.imagePath
            && this.currentListing.propertyImage.imagePath !== '') {
            this.currentListing.listingImages.push(this.currentListing.propertyImage);
            this.currentListing.hasPropertyImage = true;
          }

          this.auth.updateListing(this.currentListing, {
            listingImages: this.currentListing.listingImages,
            hasPropertyImage: this.currentListing.hasPropertyImage
          });
        }
        // MOVE OLD IMAGE INTO NEW ARRAY
        if (this.currentListing.listingImages && this.currentListing.listingImages.length === 0) {

          if ((this.currentListing.propertyImage.imageURL && this.currentListing.propertyImage.imageURL !== '') ||
            (this.currentListing.propertyImage.imagePath && this.currentListing.propertyImage.imagePath !== '')) {
            console.log('pushing old image into the array!');
            this.currentListing.listingImages.push(this.currentListing.propertyImage);
            this.currentListing.hasPropertyImage = true;


            this.auth.updateListing(this.currentListing, {
              listingImages: this.currentListing.listingImages,
              hasPropertyImage: this.currentListing.hasPropertyImage
            });
          }
        }
        // COPY FROM NEW ARRAY INTO DEFAULT
        else if (this.currentListing.listingImages
          && this.currentListing.listingImages.length === 1
          && this.currentListing.propertyImage.imageURL
          && this.currentListing.propertyImage.imageURL !== this.currentListing.listingImages[0].imageURL) {
          this.currentListing.propertyImage = this.currentListing.listingImages[0];
          this.currentListing.hasPropertyImage = true;

          this.auth.updateListing(this.currentListing, {
            listingImages: this.currentListing.listingImages,
            hasPropertyImage: this.currentListing.hasPropertyImage
          });
        }

        // cleanup old issue of empty item in array
        if (this.currentListing.listingImages
          && this.currentListing.listingImages.length > 0
          && !this.currentListing.listingImages[0].imageURL
          && !this.currentListing.listingImages[0].imagePath) {

          this.currentListing.listingImages.splice(0, 1);
          console.log('images after cleanup : ' + JSON.stringify(this.currentListing.listingImages));
          this.currentListing.hasPropertyImage = true;

          this.auth.updateListing(this.currentListing, {
            listingImages: this.currentListing.listingImages,
            hasPropertyImage: this.currentListing.hasPropertyImage
          });
        }

        // save the default if empty
        if (this.currentListing.listingImages &&
          this.currentListing.listingImages.length > 0 && this.currentListing.listingImages[0].imageURL !== '') {
          if (this.currentListing.propertyImage.imageURL === '') {
            console.log('syncing default listing image');
            this.currentListing.propertyImage.id = this.currentListing.listingImages[0].id;
            this.currentListing.propertyImage.imageURL = this.currentListing.listingImages[0].imageURL;
            this.currentListing.propertyImage.thumbnailURL = this.currentListing.listingImages[0].thumbnailURL;
            this.currentListing.propertyImage.imagePath = this.currentListing.listingImages[0].imagePath;
            this.currentListing.propertyImage.thumbnailPath = this.currentListing.listingImages[0].thumbnailPath;

            this.auth.updateListing(this.currentListing, {
              propertyImage: this.currentListing.propertyImage
            });
          }
        }
        // sync enhanced mode with old mortgage question
        // this.currentListing.enhancedMode = this.currentListing.kioskQuestions[0].isActive;

        if (!this.currentListing.tourLinks || this.currentListing.tourLinks.length === 0) {
          this.currentListing.tourLinks = BLANK_TOUR_LINKS;
          console.log('tour links are : ' + JSON.stringify(this.currentListing.tourLinks));
          this.auth.updateListing(this.currentListing, { tourLinks: this.currentListing.tourLinks });
        }

        this.initializeListingDetail();
        this.checkAddress();


      }).catch((error) => {
        console.log('error parsing listing ' + error);

      });
    }
  }

  ngOnDestroy() {
    this.unsubscribeListing();
  }

  openHelp() {
    if (!this.networkProvider.checkInternet()) {
      this.navCtrl.navigate(['/help']);
    }
    else {
      this.common.showHelpPage();
    }
  }

  async tourLinkChanged(link) {
    const self = this;

    if (link.enabled && link.URL === '') {
      const myAlert = await this.alertCtrl.create({
        header: 'Empty URL',
        message: this.i8nService.messages.urlIsRequiredToEnableLink,
        buttons: [
          {
            text: 'OK',
            handler: data => {
              console.log('Cancel clicked');

              self.currentListing.tourLinks[link.order].enabled = false;
              return;
            }
          }
        ]
      });
      await myAlert.present();

    } else if (link.enabled) {
      // disable other links when enabling new one
      for (let i = 0; i < this.currentListing.tourLinks.length; i++) {
        if (i !== link.order) {
          this.currentListing.tourLinks[i].enabled = false;
        }
        else {
          this.currentListing.tourLinks[i].enabled = true;
        }
      }

      this.auth.updateListing(this.currentListing, { tourLinks: this.currentListing.tourLinks });
    } else {
      this.micrositeUpdated = true;
      console.log('tour links after change: ' + JSON.stringify(this.currentListing.tourLinks));

      this.auth.updateListing(this.currentListing, { tourLinks: this.currentListing.tourLinks });
    }
  }

  copySlug(event?) {
    event.stopPropagation();
    const self = this;

    if (!this.currentListing.enhancedMode && !this.lenderOverride) {
      this.common.showPairedLenderError();
      return;
    }

    self.common.copyToClipboard(environment.blockparty + self.currentListing.slug);

    self.copySlugTitle = 'Copied';
    setTimeout(() => {

      self.copySlugTitle = 'Copy';
    }, 2000);
  }

  openSlugLink(event?) {
    if (event) {
      event.stopPropagation();
    }

    if (!this.currentListing.enhancedMode && !this.lenderOverride) {
      this.common.showPairedLenderError();
      return;
    }

    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }
    else {
      this.common.openLink(environment.blockparty + this.currentListing.slug, '_system');
    }
  }

  async handleBack() {
    const self = this;
    console.log('handle back called');
    clearTimeout(this.loanOfficerRefresh);
    this.loanOfficerLoading = false;
    this.common.closeLoading();
    this.common.closeLoadingSecond();

    if (this.isListingValid()) {

      this.navigateBack();
      return;
    } else {
      const addressAlert = await this.alertCtrl.create({
        header: 'Address Required',
        message: this.i8nService.messages.listingAddressRequired,
        buttons: [
          {
            text: 'Add Address',
            role: 'OK',
            handler: data => {
              this.openEditModal('address');
            }
          },
          {
            text: 'Delete Listing',
            role: 'cancel',
            handler: data => {
              console.log('just go back');
              //   this.navigateBack();
              setTimeout(() => { self.navigateBack(); }, 100);
              return;
            }
          }
        ]
      });
      await addressAlert.present();
    }
  }

  navigateBack() {
    if (this.ionRouterOutlet.canGoBack()) {
      this.nav.back();
    }
    else {
      this.nav.navigateBack('/my-listings');
    }
  }

  initializeListingDetail() {

    console.log('initialize listing data');
    this.firstLoad = true;
    this.networkProvider.checkFirestoreDataConnection();

    const self = this;

    this.deletedListing = false;
    this.userData = User_global;
    // this.startingMode = this.route.snapshot.paramMap.get('mode');

    this.route.queryParams.subscribe(
      params => {
        this.startingMode = params.mode;

        if (this.startingMode === 'microsite') {
          this.segmentValue = 'microsite';
        }
        else if (this.startingMode === 'marketing') {
          this.loadMarketingServices();
          this.segmentValue = 'marketing';
        }
        // SAME AS MARKETING BUT DISABLE OTHER ITEMS
        else if (this.startingMode === 'marketing-services') {
          this.restrictMarketingServiceOnly = true;
          this.loadMarketingServices();
          this.segmentValue = 'marketing';
        }
        else {
          this.segmentValue = 'openhouse';
        }
      }
    );

    // overwrite for web
    // if (!this.plt.is('cordova')) {
    //   this.startingMode = 'microsite';
    //   this.location.replaceState('listing-detail/' + this.currentListing.id + '?mode=' + 'microsite');
    // }

    console.log('segmentValue set to: ' + this.segmentValue);

    if (this.currentListing.address.title === '') {
      this.openEditModal('address');
    }

    if (this.networkProvider.checkInternet()) {
      this.isNetwork = true;
    } else {
      this.isNetwork = false;

      this.isMapVisible = false;
    }

    document.addEventListener('ionBackButton', (ev) => {

      if (this.currentListing.enhancedMode === true
        && this.networkProvider.checkInternet()) {
        if (!this.currentListing.webPublished && this.micrositeUpdated) {

          const myAlert = this.alertCtrl.create({
            header: 'Listing Microsite Not Published',
            message: this.i8nService.messages.listingMicrositeNotPublished,
            buttons: [
              {
                text: 'Leave unpublished',
                role: 'cancel',
                handler: data => {
                  this.handleBack();
                  return;
                }
              },
              {
                text: 'Publish',
                role: 'publish',
                handler: data => {
                  self.currentListing.webPublished = true;
                  self.verifyUserWebPublished();
                  this.auth.updateListing(this.currentListing, { webPublished: true });
                  self.handleBack();
                  return;
                }
              }
            ]
          });
          // myAlert.present();
        }
        else {
          this.handleBack();
          return;
        }
      }
      else {
        this.handleBack();
        return;
      }
    });


    self.plt.ready().then(() => {
      console.log('before subscribe');
      self.resizeEvent = self.plt.resize.subscribe(() => {
        console.log('resized');
        this.openSignInSegment();
        if (self.enhancedOpen) {
          const content = document.getElementById('expandedEnhancedContent');
          content.style.maxHeight = content.scrollHeight + 'px';
        }
        this.onResize();
      });
    });

    if (this.currentListing.address.title !== '') {
      setTimeout(() => { this.getLender(); }, 1000);
    }

    if (this.navListing !== 'new' && this.currentListing.id && this.currentListing.id !== '') {
      setTimeout(() => { this.subscribeListingUpdates(); }, 500);
    }

    try {
      this.loadGoogleMap();
    }
    catch (err) {
      console.log(err);
    }
  }

  subscribeToSuggestions() {

    if (this.suggestionsSubscription) {
      return;
    }

    this.suggestionsSubscription = this.listingDataProvider.autoPopulatedData(this.currentListing.id).subscribe(res => {
      console.log('subscription to suggestions response: ' + this.currentListing.id + ' ' + JSON.stringify(res));

      try {
        if (res && (res.listingDetails || res.listingImages)) {
          const data = res.listingDetails;
          const imageData = res.listingImages;
          this.suggestions = data;

          this.suggestions.beds = this.extractNumbersFromArray(this.suggestions.beds);
          this.suggestions.baths = this.extractNumbersFromArray(this.suggestions.baths);
          this.suggestions.price = this.extractNumbersFromArray(this.suggestions.price);
          this.suggestions.squareFootage = this.extractNumbersFromArray(this.suggestions.squareFootage);

          this.suggestions.beds = this.suggestions.beds.filter((entry) => entry !== 0);
          this.suggestions.baths = this.suggestions.baths.filter((entry) => entry !== 0);
          this.suggestions.price = this.suggestions.price.filter((entry) => entry !== 0);
          this.suggestions.squareFootage = this.suggestions.squareFootage.filter((entry) => entry !== 0);
          //   this.suggestions.listingImages = imageData;

          this.noSuggestions = false;
          this.beds = this.suggestions.beds !== null ? this.suggestions.beds : null;
          this.baths = this.suggestions.baths !== null ? this.suggestions.baths : null;
          this.price = this.suggestions.price !== null ? this.suggestions.price : null;
          this.squareFootage = this.suggestions.squareFootage !== null ? this.suggestions.squareFootage : null;

          if (!this.suggestedlistingImages || !this.common.isDeepEqual(this.suggestedlistingImages, imageData)) {
            this.suggestedlistingImages = imageData || [];
          }

        }
        else {
          this.beds = null;
          this.baths = null;
          this.price = null;
          this.squareFootage = null;
          this.suggestedlistingImages = null;
          this.noSuggestions = true;
        }
      }
      catch (err) {
        console.log('failed to load suggestions ' + err);

        this.beds = null;
        this.baths = null;
        this.price = null;
        this.squareFootage = null;
        this.noSuggestions = true;
      }
    });

  }

  openSignInSegment() {
    if (window.innerWidth < 550) {
      this.openHouse = 'Open House';
    }
    else {
      this.openHouse = 'Open House Sign-in';
    }
  }

  async unsubscribeListing() {
    try {
      if (this.listingSubscription) {
        this.listingSubscription.unsubscribe();
      }
    }
    catch (err) {
      console.log('error trying to unsubscribe listing' + err);
      this.auth.logErrors('Error trying to unsubscribe listing: ' + err, false);
    }
  }

  subscribeListingUpdates() {
    const self = this;
    console.log('subscribe to listing updates for listing: ' + this.currentListing.id);

    if (!this.currentListing.id) {
      return;
    }

    // alert('subscribe to listing: ' + this.currentListing.id);
    this.unsubscribeListing();

    this.listingSubscription = this.auth.listingUpdateObserver(this.currentListing.id).subscribe((listingUpdate) => {

      if (listingUpdate) {

        setTimeout(() => { this.subscribeToSuggestions(); }, 500);

        if (this.networkProvider.checkInternet()) {
          this.offlineMode = false;
        }
        else {
          this.offlineMode = true;
        }

        try {
          if (this.common.isDeepEqual(listingUpdate, this.currentListing)) {
            console.log('listing update json is unchanged. returning');
            return;
          }

          console.log('listing subscription, listing changed.');

          if (listingUpdate.kioskQuestions) {
            if (!this.kioskQuestions ||
              (this.kioskQuestions && this.kioskQuestions.length && this.kioskQuestions.length < 4)) {
              this.currentListing.kioskQuestions = listingUpdate.kioskQuestions;
              this.kioskQuestions = JSON.parse(JSON.stringify(listingUpdate.kioskQuestions));
            }

            if (!this.common.isDeepEqual(listingUpdate.kioskQuestions, this.kioskQuestions)
              && listingUpdate.kioskQuestions
              && listingUpdate.kioskQuestions.length) {
              for (let i = 0; i < listingUpdate.kioskQuestions.length; i++) {
                if (!this.common.isDeepEqual(listingUpdate.kioskQuestions[i], this.kioskQuestions[i])) {
                  this.currentListing.kioskQuestions[i] = listingUpdate.kioskQuestions[i];
                  this.kioskQuestions[i] = JSON.parse(JSON.stringify(listingUpdate.kioskQuestions[i]));
                }
              }
            }

            self.forceDefaultQuestion();
          }

          this.currentListing.slug = listingUpdate.slug;
          this.currentListing.webPublished = listingUpdate.webPublished;

          if (JSON.stringify(listingUpdate.qrCodeImage) !== JSON.stringify(this.currentListing.qrCodeImage)) {
            this.currentListing.qrCodeImage = listingUpdate.qrCodeImage || '';
          }
          if (JSON.stringify(listingUpdate.qrCodeImageKiosk) !== JSON.stringify(this.currentListing.qrCodeImageKiosk)) {
            this.currentListing.qrCodeImageKiosk = listingUpdate.qrCodeImageKiosk || '';
          }

          if (JSON.stringify(listingUpdate.qrCodeAgentSignIn) !== JSON.stringify(this.currentListing.qrCodeAgentSignIn)) {
            this.currentListing.qrCodeAgentSignIn = listingUpdate.qrCodeAgentSignIn || '';
          }

          if (JSON.stringify(listingUpdate.micrositeLinks) !== JSON.stringify(this.currentListing.micrositeLinks)) {
            this.currentListing.micrositeLinks = listingUpdate.micrositeLinks;
          }

          if (this.guestFollowUp !== listingUpdate.guestFollowUp) {
            this.guestFollowUp = listingUpdate.guestFollowUp;
          }


          // IF IMAGES ARE CHANGED ONLY LOAD THOSE

          if (JSON.stringify(listingUpdate.propertyImage) !== JSON.stringify(this.currentListing.propertyImage)) {
            this.currentListing.propertyImage = listingUpdate.propertyImage;
          }

          if (JSON.stringify(listingUpdate.hasPropertyImage) !== JSON.stringify(this.currentListing.hasPropertyImage)) {
            this.currentListing.hasPropertyImage = listingUpdate.hasPropertyImage;
          }

          console.log(this.common.isDeepEqual(listingUpdate.listingImages, this.currentListing.listingImages));
          if (!this.common.isDeepEqual(listingUpdate.listingImages, this.currentListing.listingImages)) {

            console.log('listing images changed!');
            console.log('first:' + JSON.stringify(listingUpdate.listingImages));
            console.log('second' + JSON.stringify(this.currentListing.listingImages));

            if (!this.currentListing.listingImages || !this.currentListing.listingImages.length) {
              this.currentListing.listingImages = listingUpdate.listingImages;
            }
            else {
              for (let i = 0; i < listingUpdate.listingImages.length; i++) {
                if (!this.common.isDeepEqual(listingUpdate.listingImages[i], this.currentListing.listingImages[i])) {
                  console.log('listing obj does not match. saving new');
                  this.currentListing.listingImages[i] = listingUpdate.listingImages[i];
                }
              }
            }
          }

          const tempId = this.currentListing.id;
          listingUpdate.id = tempId;

          let recheckLoanOfficer = false;

          console.log('this.currentListing.loanOfficer ' + this.currentListing.loanOfficer);
          this.translateOldLenderId();

          listingUpdate.loanOfficer = this.getNewLenderId(listingUpdate.loanOfficer);
          if (this.currentListing.loanOfficer !== listingUpdate.loanOfficer) {
            recheckLoanOfficer = true;
          }

          if (JSON.stringify(listingUpdate.address) !== JSON.stringify(this.currentListing.address)) {
            this.currentListing.address = listingUpdate.address;
          }

          if (JSON.stringify(listingUpdate.propertyLayout) !== JSON.stringify(this.currentListing.propertyLayout)) {
            this.currentListing.propertyLayout = listingUpdate.propertyLayout;
          }

          if (!this.common.isDeepEqual(listingUpdate.listingDetails, this.currentListing.listingDetails)) {
            // only update if field is not dirty
            if (!this.listingDetailsTimeout) {
              this.currentListing.listingDetails = listingUpdate.listingDetails;
            }
          }

          // IF FIRST TIME USE ON NEW SYSTEM
          // if (!this.currentListing.listingDetails) {
          //   console.log('listing details do not exist, creating.');
          //   this.currentListing.listingDetails = {
          //     price: null,
          //     beds: null,
          //     baths: null,
          //     squareFootage: null,
          //     description: '',
          //     saleStatus: 'For Sale',
          //     moreInfoURL: ''
          //   };
          // }

          if (JSON.stringify(listingUpdate.tourLinks) !== JSON.stringify(this.currentListing.tourLinks)) {
            this.currentListing.tourLinks = listingUpdate.tourLinks;
          }

          if (JSON.stringify(listingUpdate.guestFollowUp) !== JSON.stringify(this.currentListing.guestFollowUp)) {
            this.currentListing.guestFollowUp = listingUpdate.guestFollowUp;
          }

          if (JSON.stringify(listingUpdate.enhancedMode) !== JSON.stringify(this.currentListing.enhancedMode)) {
            this.currentListing.enhancedMode = listingUpdate.enhancedMode;
          }

          if (!this.currentListing.listingImages) {
            this.currentListing.listingImages = [];
          }

          console.log('listing update subscription: ' + JSON.stringify(listingUpdate));
          this.firstLoad = false;

          // upload qr code image if one isn't saved
          let extension = '';

          // if (User_global.role === 'agent' && this.auth.getUID() !== listingUpdate.listingAgent) {
          //   extension = 'u=' + this.auth.getUID().slice(-4);
          // }

          this.createQRCodes(listingUpdate, extension);

          this.currentListing.loanOfficer = listingUpdate.loanOfficer;
          this.currentListing.linkToInfo = listingUpdate.linkToInfo;
          this.currentListing.textMessage = listingUpdate.textMessage;
          this.currentListing.guestsCount = listingUpdate.guestsCount;

          console.log('recheckLoanOfficer: ' + recheckLoanOfficer);
          if (recheckLoanOfficer) {
            this.getLender();
          }

        }
        catch (err) {
          this.auth.logErrors('Error processing listing update: ' + err, false);
        }
      }
      else {
        console.log('listing update is null');
        // this.auth.logErrors('listingSubscription throw err', false);
        this.auth.logClientInfo('listingSubscription response is empty: ' + this.currentListing.id);
        //  this.common.showDBWriteError();
        //  self.navCtrl.navigate(['/my-listings']);
      }
    }, error => {
      console.log('Error in listing subscription ' + error);

      // if user logged out, just go back to home screen
      // my listings page will kick back to login page
      if (error.code === 'permission-denied') {
        self.navCtrl.navigate(['/my-listings']);
        return;
      }

      this.auth.logErrors('listingSubscription error 2 ' + error, false);
      this.common.showDBWriteError(false);
      self.navCtrl.navigate(['/my-listings']);

    },
      () => {
        console.log('COMPLETE');
      });

    this.initialized = true;
  }


  createQRCodes(listingUpdate, extension) {
    const self = this;
    console.log('creating qr code with extension', extension);
    if (listingUpdate.slug && (!listingUpdate.qrCodeImage || !listingUpdate.qrCodeImageKiosk || !listingUpdate.qrCodeAgentSignIn)) {
      if (this.networkProvider.checkInternet && this.retryQRUpload < MAX_QR_UPLOAD_RETRY) {
        this.retryQRUpload++;

        // KIOSK SPECIFIC QR CODE
        if (!listingUpdate.qrCodeImageKiosk) {
          const url1 = this.env.blockparty + listingUpdate.slug + '/?display=touch-free-sign-in' + (extension ? '&' + extension : '');
          const fileName = this.currentListing.id + '/' + listingUpdate.slug + '_kiosk';

          self.qrService.generateQRCode(url1, User_global.primaryColor, User_global.logoFile).then(blob => {
            self.imageService.uploadQRCodeBloblListing(blob, fileName, false).then(res => {
              listingUpdate.qrCodeImageKiosk = String(res);
              this.currentListing.qrCodeImageKiosk = String(res);

              this.auth.updateListing(this.currentListing, {
                qrCodeImageKiosk: this.currentListing.qrCodeImageKiosk
              });
            });
          });
        }

        // REGULAR QR CODE
        if (!listingUpdate.qrCodeImage) {
          const url2 = this.env.blockparty + listingUpdate.slug + (extension ? '/?' + extension : '');
          const fileName2 = this.currentListing.id + '/' + listingUpdate.slug;

          self.qrService.generateQRCode(url2, User_global.primaryColor, User_global.logoFile).then(blob => {
            self.imageService.uploadQRCodeBloblListing(blob, fileName2, false).then(res => {
              listingUpdate.qrCodeImage = String(res);
              self.currentListing.qrCodeImage = String(res);

              if (self.plt.is('cordova')) {
                // CACHE QR IMAGE DATA
                self.cacheQRImages();
              }

              this.auth.updateListing(this.currentListing, {
                qrCodeImage: self.currentListing.qrCodeImage
              });
            });
          });
        }

        // AGENT SIGN-IN QR
        if (!listingUpdate.qrCodeAgentSignIn) {
          const url3 = this.env.blockparty + listingUpdate.slug + '/?display=agent-sign-in' + (extension ? '&' + extension : '');
          const filename3 = this.currentListing.id + '/' + listingUpdate.slug + '_agentsignin';

          self.qrService.generateQRCode(url3, User_global.primaryColor, User_global.logoFile).then(blob => {
            self.imageService.uploadQRCodeBloblListing(blob, filename3, false).then(res => {
              listingUpdate.qrCodeAgentSignIn = String(res);
              this.currentListing.qrCodeAgentSignIn = String(res);

              if (this.plt.is('cordova')) {
                // CACHE QR IMAGE DATA
                this.cacheQRImages();
              }

              this.auth.updateListing(this.currentListing, {
                qrCodeAgentSignIn: this.currentListing.qrCodeAgentSignIn
              });
            });
          });
        }
      }
    }
    else if (this.plt.is('cordova') && listingUpdate.slug && listingUpdate.qrCodeImage) {
      this.cacheQRImages();
    }
  }

  cacheQRImages() {

    if (this.currentListing.qrCodeImage) {
      this.imageService.realImageData({
        imagePath: this.currentListing.qrCodeImage,
        imageURL: this.currentListing.qrCodeImage
      }).then((data) => {
        console.log('get qr code real image data result: ' + data);
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.auth.logErrors('cacheQR code image error: ' + error, false);
      });
    }

    if (this.currentListing.qrCodeImageKiosk) {
      this.imageService.realImageData({
        imagePath: this.currentListing.qrCodeImageKiosk,
        imageURL: this.currentListing.qrCodeImageKiosk
      }).then((data) => {
        console.log('get qr code real image data result: ' + data);
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.auth.logErrors('cacheQR code image error: ' + error, false);
      });
    }

    if (this.currentListing.qrCodeAgentSignIn) {
      this.imageService.realImageData({
        imagePath: this.currentListing.qrCodeAgentSignIn,
        imageURL: this.currentListing.qrCodeAgentSignIn
      }).then((data) => {
        console.log('get qr code real image data result: ' + data);
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.auth.logErrors('cacheQR code image error: ' + error, false);
      });
    }
  }

  parsePairedAgents() {
    if (this.currentListing.pairedListingAgents) {
      this.totalPairedAgents = this.currentListing.pairedListingAgents.filter(x => (x.enabled === true));
    }
  }

  loadListingFields(listingUpdate) {

    try {
      console.log('load listing fields !' + JSON.stringify(listingUpdate));
      this.currentListing = {};
      this.currentListing.createdAt = listingUpdate.createdAt;
      this.currentListing.time = listingUpdate.time;
      this.currentListing.sampleListing = listingUpdate.sampleListing || false;

      if (listingUpdate.listingAssigned) {
        this.currentListing.assignedByUser = listingUpdate.assignedByUser || [];
        this.currentListing.listingAssigned = listingUpdate.listingAssigned || false;
        this.currentListing.pairedStatus = listingUpdate.listingAssigned ? 'paired' : '';
        this.currentListing.listingAgent = listingUpdate.listingAgent || '';
        this.currentListing.pairedListingAgents = listingUpdate.pairedListingAgents || [];
        this.parsePairedAgents();
        this.setFooterText();
      }

      this.currentListing.id = listingUpdate.id;
      this.currentListing.address = listingUpdate.address;

      this.currentListing.loanOfficer = listingUpdate.loanOfficer;
      this.currentListing.linkToInfo = listingUpdate.linkToInfo;
      this.currentListing.textMessage = listingUpdate.textMessage;
      this.currentListing.guestsCount = listingUpdate.guestsCount;
      this.currentListing.agentAdmin = listingUpdate.agentAdmin || '';


      this.currentListing.kioskQuestions = listingUpdate.kioskQuestions;
      this.kioskQuestions = JSON.parse(JSON.stringify(listingUpdate.kioskQuestions));
      this.currentListing.propertyLayout = listingUpdate.propertyLayout;

      this.currentListing.propertyImage = listingUpdate.propertyImage;
      this.currentListing.listingDetails = listingUpdate.listingDetails;

      this.currentListing.webPublished = listingUpdate.webPublished;
      this.currentListing.hasPropertyImage = listingUpdate.hasPropertyImage;
      this.currentListing.tourLinks = listingUpdate.tourLinks;

      this.currentListing.guestFollowUp = listingUpdate.guestFollowUp;
      this.guestFollowUp = this.currentListing.guestFollowUp;

      this.currentListing.enhancedMode = listingUpdate.enhancedMode;

      this.currentListing.slug = listingUpdate.slug;

      this.currentListing.qrCodeImage = listingUpdate.qrCodeImage;
      this.currentListing.qrCodeAgentSignIn = listingUpdate.qrCodeAgentSignIn;
      this.currentListing.qrCodeImageKiosk = listingUpdate.qrCodeImageKiosk;

      this.currentListing.micrositeLinks = listingUpdate.micrositeLinks;

      this.currentListing.listingImages = listingUpdate.listingImages;
      this.currentListing.listingAgent = listingUpdate.listingAgent;

    }
    catch (err) {
      console.log('error loading fields ' + err);
      this.auth.logErrors('Error loading listing fields: ' + err, false);
    }
  }

  createAutoFollowUp(): string {
    let followUp = this.currentListing.textMessage;
    let name = this.userData.username.split(' ')[0] + '!';
    let address = this.currentListing.address.title.split(',')[0] + ' ';

    // FOR LENDER USE AGENT'S NAME
    if (User_global.role === 'lender' && this.pairedAgent) {
      name = this.pairedAgent.username.split(' ')[0] + '!';
    }
    else if (User_global.role === 'agent') {
      //agent
    }
    else if (User_global.role === 'agentAdmin' && this.pairedAgent) {
      //agentAdmin
      name = this.pairedAgent.username.split(' ')[0] + '!';
    }

    if (address === ' ') {
      address = '';
    }

    if (followUp === '') {
      followUp = 'Hi this is ' + name + ' Thanks for coming by the ' + address + 'open house! Here are details on the property.';
    }

    if (followUp.length > 160) {
      followUp = 'Thanks for coming by the ' + address + 'open house! Here are details on the property.';
    }

    console.log('follow up ' + followUp);
    return followUp;
  }

  async publishToMicrosite(value) {
    const self = this;

    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }

    if (this.currentListing.address.title === '') {
      const myAlert = await this.alertCtrl.create({
        message: this.i8nService.messages.listingEnterAddressBeforePublishing,
        buttons: [
          {
            text: 'Close',
            role: 'cancel',
            handler: data => {

            }
          }
        ]
      });
      await myAlert.present();
    }
    else if (!this.currentListing.enhancedMode && !this.lenderOverride) {
      this.common.showPairedLenderError();
    }
    else if (!value) {
      // TEMP DISABLE UNPUBLISH
      /*
      this.alertCtrl.create({
        title: 'Unpublish Your Microsite?',
        message: 'Unpublishing your microsite will remove your microsite, and will redirect any traffic. You can re-publish the site at any time.',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: data => {
            }
          },
          {
            text: 'Unpublish',
            role: 'yes',
            handler: data => {
              console.log('unpublish confirmed.');
              this.removeListingFromMicrosite();
              this.currentListing.webPublished = value;
              this.verifyUserWebPublished();
              this.updateListing('publish');
            }
          }
        ]
      }).present();
      */
    }
    else if (User_global.role === 'lender') {
      // this.actionDisabled();

      const myAlert = await this.alertCtrl.create({
        header: 'Publishing to the Web',
        message: 'Published Microsites are visible to anyone who has the URL which could include search engines. We recommend checking with your paired agent before publishing.',
        buttons: [

          {
            text: 'Cancel',
            role: 'cancel',
            handler: data => {

            }
          },
          {
            text: 'Publish',
            role: 'publish',
            handler: data => {

              self.common.startLoading('Publishing...');

              // GIVE SOME TIME BEFORE AND AFTER PUBLISH IS INITIATED
              setTimeout(() => { self.auth.updateListing(self.currentListing, { webPublished: true }); }, 500);
              setTimeout(() => { self.common.closeLoading(); }, 5000);

            }
          }
        ]
      });
      await myAlert.present();

    }
    else if (User_global.role === 'agent') {
      this.common.startLoading('Publishing...');

      //  this.currentListing.webPublished = value;

      // GIVE SOME TIME BEFORE AND AFTER PUBLISH IS INITIATED
      setTimeout(() => { this.auth.updateListing(this.currentListing, { webPublished: true }); }, 500);
      setTimeout(() => { this.common.closeLoading(); }, 5000);

      try {
        if (!value) {
          this.listingService.removeListingFromMicrosite(this.currentListing, this.userData);
        }
        this.verifyUserWebPublished();
      }
      catch (err) {
        console.log('error publishing listing: ' + err);
      }
    }
    else if (User_global.role === 'agentAdmin') {
      //agentAdmin
      this.common.startLoading('Publishing...');

      //  this.currentListing.webPublished = value;

      // GIVE SOME TIME BEFORE AND AFTER PUBLISH IS INITIATED
      setTimeout(() => { this.auth.updateListing(this.currentListing, { webPublished: true }); }, 500);
      setTimeout(() => { this.common.closeLoading(); }, 5000);
    }
  }


  // verify that user has the webpublished flag
  // needed by Polina to trigger update
  verifyUserWebPublished() {
    if (this.userData.webPublished === undefined || this.userData.webPublished === null) {
      this.auth.updateUserDataProperty({ webPublished: false });
    }
  }

  doEditKiosk() {
    console.log('do edit kisok.');

    if (this.plt.is('cordova')) {
      //  this.common.startLoading();
      console.log('do edit kisok. selectPhoto');
      this.imageService.selectPhoto().then((data) => {
        if (data) {
          this.common.closeLoading();
          this.imageType = 'property';
          this.imageToEdit = data;
          this.editWebImage(data);
        }
        //  this.currentImageEditing = data;
      }).catch((error) => {
        this.common.closeLoading();
        console.log('error editing kiosk image ' + error);
        if (error) {
          this.auth.logErrors('Error in image select: ' + error);
        }

        if (error === 'limit') {
          this.common.showImageLimit();
        }
        this.utils.unlockOrientation();
      });
    }
    else {
      document.getElementById('curbHeroListingFileInput').click();
      console.log('do edit kiosk for web.');
    }
  }

  expandCollapseContent() {
    console.log('expand / collapse section');
    const content = document.getElementById('expandedEnhancedContent');

    if (content.style.maxHeight) {
      content.style.maxHeight = null;
      this.enhancedOpen = false;
    } else {
      content.style.maxHeight = content.scrollHeight + 'px';
      this.enhancedOpen = true;
    }
  }

  makeImageDefault(event) {
    console.log('listing details makeDefault');
    const data = this.currentListing.listingImages;
    console.log('make default image: ' + JSON.stringify(event));
    if (data.indexOf(event) > 0) {
      data.splice(data.indexOf(event), 1);
      data.unshift(event);
    }
    this.currentListing.propertyImage = data[0];

    this.auth.updateListing(this.currentListing, {
      propertyImage: this.currentListing.propertyImage,
      listingImages: this.currentListing.listingImages
    });
  }

  editKioskImage(event) {
    try {
      if (this.startingMode === 'marketing-services') {
        this.actionDisabled();
      } else if (this.plt.is('cordova')) {
        this.imageService.checkPhotoPermission().then((data) => {
          if (data) {
            this.screenOrientation.lock(this.screenOrientation.type);

            this.doEditKiosk();
            console.log(data);
          }
        }).catch(err => {
          console.log('listing image service err: ' + err);
          this.auth.logErrors('Edit kiosk image error: ' + err);
        });
      }
      else {
        this.doEditKiosk();
      }
    }
    catch (error) {
      console.log('listing image service err: ' + error);
      this.auth.logErrors('Edit kiosk image error 2: ' + error);
      this.doEditKiosk();
    }
  }

  selectPropertyLayout(layoutId) {
    console.log('selectPropertyLayout');
    if (layoutId !== this.currentListing.propertyLayout) {
      this.currentListing.propertyLayout = layoutId;

      this.auth.updateListing(this.currentListing, { propertyLayout: this.currentListing.propertyLayout });
    }
  }

  async openLayoutPreview() {

    if (!this.plt.is('cordova') && this.plt.is('mobile')) {
      const myAlert = await this.alertCtrl.create({
        message: this.i8nService.messages.featureNotAvailableOnWeb,
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
            handler: data => {

            }
          }
        ]
      });
      await myAlert.present();

      return;
    }

    this.navCtrl.navigate(['/property-layout-preview']);
    /*
    this.navCtrl.navigateForward(PropertyLayoutPreviewPage, {
      listing: this.currentListing
    });
    */
  }

  async removeKioskImage(photo) {

    const self = this;

    if (this.currentListing.sampleListing) {

      this.sampleListingEditWarning();
      return;
    }

    const deleteConfirmInfoModal = await this.alertCtrl.create({
      header: 'Delete Image',
      message: 'Are you sure you want to delete this image?',
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Delete',
          handler: data => {
            self.continueDeleteKioskImage(photo);
          }
        },
      ]
    });

    deleteConfirmInfoModal.present();
  }

  async sampleListingEditWarning() {
    const alert = await this.alertCtrl.create({
      header: 'Sample Listing',
      message: 'Editing this information is disabled because this is a sample listing. When you create a new listing you can customize this information.',
      buttons: [
        {
          text: 'OK',
          handler: data => {

          }
        }
      ]
    });

    alert.present();
    return;
  }

  continueDeleteKioskImage(photo) {

    const self = this;
    // this.common.startLoading();

    if (this.plt.is('cordova')) {
      if (photo.thumbnailPath) {
        this.imageService.removeImage(photo.thumbnailPath);
      }
      if (photo.imagePath) {
        this.imageService.removeImage(photo.imagePath);
      }
    }

    try {
      this.imageService.deleteImage(photo.imageURL).then(() => {
        self.common.closeLoading();
      }).catch((error) => {
        console.log('error deleting photo: ' + error);
        self.common.closeLoading();
      });

      self.imageService.deleteImage(photo.thumbnailURL);
    }
    catch (err) {
      console.log('error deleting photo 2: ' + err);
      self.common.closeLoading();
    }

    for (let i = 0; i < this.currentListing.listingImages.length; i++) {
      if (this.currentListing.listingImages[i].imageURL === photo.imageURL) {

        this.currentListing.listingImages.splice(i, 1);

        if (this.currentListing.propertyImage.imageURL === photo.imageURL) {
          if (photo.imageURL !== '') {
            this.currentListing.propertyImage = this.newImage;
          }
        }

        if (this.currentListing.listingImages.length > 0) {
          this.currentListing.propertyImage = this.currentListing.listingImages[0];
        }

        break;
      }

      /*
      if (this.currentListing.propertyImage.imagePath === photo.imagePath) {
        this.currentListing.propertyImage.imagePath = '';
        this.currentListing.propertyImage.imageURL = '';
        this.currentListing.propertyImage.base64 = '';
      }
      */
    }

    // there are no images
    if (this.currentListing.listingImages.length === 0) {
      this.currentListing.propertyImage = this.newImage;
      this.currentListing.hasPropertyImage = false;
    }

    this.auth.updateListing(this.currentListing, {
      propertyImage: this.currentListing.propertyImage,
      listingImages: this.currentListing.listingImages,
      hasPropertyImage: this.currentListing.hasPropertyImage
    });
  }

  checkAddress() {
    if (this.currentListing.address.placeId) {
      console.log('Defined', this.currentListing.address.placeId);
      this.isMapVisible = true;
    } else {
      this.isMapVisible = false;
    }
  }

  ionViewWillLeave() {

    // RESET BACK BUTTON ON CLICK
    this.backButton.onClick = () => { };
    document.removeAllListeners('ionBackButton');

    //CLEAR ANY ERROR TIMEOUTS
    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
    }


    if (this.listingSubscription) {
      this.listingSubscription.unsubscribe();
      this.listingSubscription = null;
    }

    if (this.suggestionsSubscription) {
      this.suggestionsSubscription.unsubscribe();
    }

    // CLEAR ANY INTERVAL CHECKS
    clearInterval(this.loadInterval);
    console.log('ion will leave listing');
    //  this.networkProvider.checkFirestoreDataConnection();


    // if (this.mapSubs) {
    //   this.mapSubs.unsubscribe();
    // }
    if (this.resizeEvent) {
      this.resizeEvent.unsubscribe();
    }

    clearTimeout(this.loanOfficerRefresh);
    this.loanOfficerLoading = false;
    // this.backBtnHandler();

    // this.imageService.cancelPermissions();
  }

  loadGoogleMap() {
    const me = this;

    if (this.networkProvider.checkInternet()) {
      if (!!google) {
        console.log('map');

        try {
          me.loadMap();
        }
        catch (err) {
          console.log(err);
        }

      } else {
        // this.errorAlert('Error', 'Something went wrong with the Internet Connection. Please check your Internet.')
        console.log('Someting went wrong with map');
      }
    } else {
      me.loadMap();
      //  this.isMapVisible = false;
      this.isNetwork = false;
    }
  }

  hideEditor() {
    this.zone.run(() => { this.showDoka = false; });
    this.utils.unlockOrientation();
  }

  // editor complete event for native devices
  nativePhotoSave(file: any) {
    const self = this;

    console.log('editorComplete');

    // Check if file is valid
    if (!file || typeof file !== 'string') {
      console.error('Invalid file provided.');
      this.common.closeLoading();
      this.hideEditor();
      return;
    }

    // Convert the data URL to a Blob
    const blob = this.imageService.dataURIToBlob(file);

    if (!blob) {
      console.error('Failed to convert file to Blob.');
      this.common.closeLoading();
      this.hideEditor();
      return;
    }

    console.log('file: ' + file);

    // Generate a unique ID for the image
    const id = this.imageService.generateImageID(25);

    if (!id) {
      console.error('Failed to generate image ID.');
      this.common.closeLoading();
      this.hideEditor();
      return;
    }

    const fileName = `${id}_property`;
    console.log('fileName: ' + fileName + ' dataDirectory: ' + this.fileService.dataDirectory);

    // Check if dataDirectory is available
    if (!this.fileService.dataDirectory) {
      console.error('Data directory is not available.');
      this.common.closeLoading();
      this.hideEditor();
      return;
    }

    this.fileService.writeFile(this.fileService.dataDirectory, fileName, blob)
      .then((entry) => {
        console.log('entry: ' + entry);

        if (!this.currentListing || !Array.isArray(this.currentListing.listingImages)) {
          console.error('Invalid current listing or listingImages.');
          this.common.closeLoading();
          this.hideEditor();
          return;
        }

        const newImg = {
          id,
          imagePath: fileName,
          imageURL: ''
        };

        this.currentListing.listingImages.push(newImg);

        // Check if listingImages exists and has fewer than 2 images
        if (this.currentListing.listingImages.length < 2) {
          this.currentListing.propertyImage = {
            id,
            imagePath: fileName,
            imageURL: ''
          };
        }

        // Check if updateListing method exists
        if (this.auth && typeof this.auth.updateListing === 'function') {
          this.auth.updateListing(this.currentListing, {
            listingImages: this.currentListing.listingImages,
            propertyImage: this.currentListing.propertyImage
          });
        } else {
          console.error('Failed to update listing: updateListing method not found.');
        }

        this.common.closeLoading();
      })
      .catch((error) => {
        // Handle the error here
        console.error('Error writing the file: ', error);
        this.auth.logErrors('Error writing the file: ' + JSON.stringify(error));
        this.common.closeLoading();
      });

    // Close loading and hide editor even if file is invalid
    this.common.closeLoading();
    this.hideEditor();

    // Check if networkProvider and checkInternet exist
    if (this.networkProvider && typeof this.networkProvider.checkInternet === 'function' && this.networkProvider.checkInternet()) {
      if (this.intercomService && typeof this.intercomService.showLauncher === 'function') {
        this.intercomService.showLauncher();
      } else {
        console.error('Failed to show Intercom launcher: showLauncher method not found.');
      }
    } else {
      console.error('Network check failed or networkProvider not available.');
    }
  }


  scrollToPublish() {
    const buttonElement = document.getElementById('publishMicrositeButton');
    buttonElement.scrollIntoView({ behavior: 'smooth' });
  }

  onFileChanged(event, type) {

    if (!event.target.files[0]) {
      return;
    }
    // get the file size in standard format
    console.log('File size is: ' + event.target.files[0].size);
    this.editWebImage(event.target.files[0]);
  }

  // web file select event
  async editWebImage(img) {
    const self = this;
    let nativeImage;
    let editor;
    let options;
    const listingId = self.currentListing.id.toString();
    const photoId = this.imageService.generateImageID(25);

    this.intercomService.hideLauncher();

    options = {
      outputData: true,
      utils: ['crop', 'filter', 'finetune', 'markup', 'resize', 'sticker', 'annotate'],

      stickers: [
        [
          // required group label
          'Stickers',

          // group stickers, can be an empty array
          ['assets/stickers/equal-housing.svg', 'assets/stickers/enter-to-win.svg', 'assets/stickers/giveaway.svg', 'assets/stickers/realtor.svg', 'assets/stickers/register-now.svg'],

          // optional group properties
          {},
        ],
        [
          // group label
          'Emoji',

          // group stickers
          ['🎉', '😄', '🍕'],

          // group properties
          {
            // a group icon
            icon: '<g><!-- SVG here --></g>',

            // hide the group label
            hideLabel: true,

            // disable the group
            disabled: true,
          },
        ],
      ],

      imageWriter: {
        quality: 1,
        targetSize: {
          width: 2048,
          height: Math.round(2048 * (3 / 4))
        }
      },
      imageCropAspectRatio: 4 / 3,
      imageCropLimitToImage: true
    };


    if (this.plt.is('cordova')) {

      const imageWidth = await this.imageService.getImageWidth(this.imageService.imageData);
      console.log('imageWidth is: ' + imageWidth);
      if (imageWidth > 4096) {
        nativeImage = await this.compressor.compressDataUrl(this.imageService.imageData, 4096);
      }
      else {
        nativeImage = img;
      }

      editor = openDefaultEditor({
        src: nativeImage,
        ...options,

        preventScrollBodyIfNeeded: false,
        preventFooterOverlapIfNeeded: false
      });
    }
    else {
      editor = openDefaultEditor({
        src: img,
        ...options
      });
    }

    editor.on('loaderror', console.log);
    editor.on('load', (res) => {
      console.log('load', res);
    });

    editor.on('process', async (imageState) => {
      this.intercomService.showLauncher();
      const output = URL.createObjectURL(imageState.dest);
      console.log('imageState.dest: ' + imageState.dest);
      console.log('output: ' + output);

      self.common.startLoading();

      if (this.plt.is('cordova')) {

        this.imageService.objectUrlToImageData(output)
          .then(imageData => {
            if (imageData) {
              this.uploadToServer(imageData);
            }
          });
      }
      else {
        this.uploadToServer(output);
      }

      if (!this.networkProvider.checkInternet()) {
        self.common.closeLoading();
      }
    });
  }

  async uploadToServer(output) {
    const self = this;
    const listingId = self.currentListing.id.toString();
    const photoId = this.imageService.generateImageID(25);

    if (!output) {
      self.common.closeLoading();
      return;
    }
    console.log('Done editing image', output);
    const jpegBlob = await this.compressor.compressDataUrl(output, 2048);

    if (this.plt.is('cordova')) {
      this.nativePhotoSave(jpegBlob);
      this.common.closeLoading();
      return;
    }

    self.imageService.uploadListingPhotoWeb(photoId, listingId, jpegBlob, 'property').then(imgURL => {

      if (imgURL === '') {
        self.common.showSimpleError(this.i8nService.messages.errorUploadingTryAgain);
        self.common.closeLoading();

        return;
      }

      const newImg = {
        id: photoId,
        imagePath: photoId + '_property',
        imageURL: imgURL
      };

      self.currentListing.listingImages.push(newImg);
      self.currentListing.hasPropertyImage = true;

      if (self.currentListing.listingImages.length < 2) {
        self.currentListing.propertyImage = {
          id: photoId,
          imagePath: photoId + '_property',
          imageURL: imgURL
        };
      }

      this.auth.updateListing(this.currentListing, {
        listingImages: this.currentListing.listingImages,
        propertyImage: this.currentListing.propertyImage,
        hasPropertyImage: this.currentListing.hasPropertyImage
      }).then(res => {

        self.common.closeLoading();
        self.intercomService.showLauncher();
      });

      self.saveThumbnailPhoto(output, newImg, listingId);

    });

    self.common.closeLoading();
  }

  saveThumbnailPhoto(img, imgData, listingId) {
    const self = this;

    console.log('in saveThumbnailPhoto');
    const photoId = this.imageService.generateImageID(25);
    //  const fileName = `${photoId}_propertyThumbnail`;

    // compress the photo
    self.compressor.compressDataUrl(img, 600).then(res => {

      console.log('compressed thumbnail photo');
      // upload photo
      self.imageService.uploadListingPhotoWeb(photoId, listingId, res, 'propertyThumbnail').then(imgURL => {
        console.log('thumbnail uploaded: ' + imgURL);
        imgData.thumbnailPath = '';
        imgData.thumbnailURL = imgURL;

        self.currentListing.listingImages[self.currentListing.listingImages.length - 1] = imgData;

        this.auth.updateListing(this.currentListing, {
          listingImages: this.currentListing.listingImages,
          //   propertyImage: this.currentListing.propertyImage,
          //  hasPropertyImage: this.currentListing.hasPropertyImage
        });

        console.log('finished saving thumbnail');
      });
    });
  }

  loadMap() {

    setTimeout(() => {
      //   if (this.networkProvider.checkInternet()) {
      const markerLocation = `${this.currentListing.address.lat},${this.currentListing.address.lng}`;
      const apiKey = environment.maps_key;

      this.mapSrc = `https://maps.googleapis.com/maps/api/staticmap?center=${markerLocation}&zoom=15&size=2048x2048&scale=2&maptype=roadmap&markers=${markerLocation}&key=${apiKey}&style=feature:administrative.land_parcel|visibility:off&style=feature:poi|element:labels.icon|visibility:off&style=feature:poi|element:labels.text|visibility:off&style=feature:transit.station.airport|visibility:off&style=feature:transit.station.bus|visibility:off&style=feature:transit.station.rail|visibility:off`;

      console.log('mapSrc ' + this.mapSrc);

      // } else {
      //   this.isMapVisible = false;
      //   this.isNetwork = false;
      // }
    }, 50);
  }

  isListingValid() {
    return this.currentListing.address.placeId || this.currentListing.address.title;
  }

  launchOpenHouse() {

    // kiosk mode is not available on mobile web
    if (!this.plt.is('cordova') && this.utils.isMobilePhone()) {
      this.networkProvider.downloadNativeApp(User_global.role);
      return;
    }

    if (this.isListingValid()) {

      const navigationExtras: NavigationExtras = {
        queryParams: {
          listingLayout: this.currentListing.propertyLayout
        }
      };

      console.log('listing id: ' + this.currentListing.id + ' params ' + JSON.stringify(navigationExtras));
      if (this.plt.is('cordova')) {
        if (this.networkProvider.checkInternet() || !this.currentListing.guestFollowUp) {
          // one last save before leaving
          this.navCtrl.navigate(['kiosk-mode/' + `${this.currentListing.id}`], navigationExtras);
        } else {
          this.networkProvider.showModal(this.currentListing.id, this.currentListing);
        }
      }
      else {
        if (User_global.role === 'agent') {
          this.navCtrl.navigate(['kiosk-mode/' + `${this.currentListing.id}`], navigationExtras);
        }
        else {
          this.actionDisabled();
        }
      }
    } else {
      this.showAddressRequired();
    }
  }

  async showAddressRequired() {
    const addressAlert = await this.alertCtrl.create({
      header: 'Address Required',
      message: this.i8nService.messages.enterNameOrAddressToLaunchListing,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Add Address',
          handler: data => {
            this.openEditModal('address');
          }
        },

      ]
    });
    await addressAlert.present();
  }

  signUpFreeLIstReports() {
    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }

    this.common.openLink('https://rebrand.ly/list-reports');
  }

  segmentChanged(ev: string, element?) {
    console.log('Segment changed', ev);

    if (element && this.plt.is('mobileweb') && !this.plt.is('cordova')) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    if (ev === 'marketing') {
      if (this.networkProvider.checkInternet() && this.loanOfficer.name !== null && this.currentListing.webPublished) {
        this.segmentValue = ev;
        this.location.replaceState('listing-detail/' + this.currentListing.id + '?mode=' + ev);
        this.loadMarketingServices();
      }
      else if (!this.networkProvider.checkInternet()) {
        this.networkProvider.noNetworkModal();
      }
      else if (!this.lenderOverride && this.currentListing.enhancedMode === false || (this.role === '' && this.pairedAgent.username === null)) {
        this.warnEnableMicrosite();
      }
      else if (!this.currentListing.webPublished) {
        this.micrositeRequired();
      }
    }
    else if (ev === 'marketing-services') {
      console.log('marketing');
      if (this.networkProvider.checkInternet()) {
        this.segmentValue = 'marketing';
        this.location.replaceState('listing-detail/' + this.currentListing.id + '?mode=' + this.segmentValue);
        this.loadMarketingServices();
      }
      else {
        this.warnEnableMicrosite();
      }
    }
    // else if(this.startingMode === 'marketing-services'){
    //   this.warnEnableMicrosite();
    // }
    else {
      this.segmentValue = ev;
      this.location.replaceState('listing-detail/' + this.currentListing.id + '?mode=' + ev);
    }
  }

  async openEditModal(value: any, item?: any) {

    // if (this.currentListing.id &&
    //   value === 'address' &&
    //   User_global.role === 'agentAdmin' &&
    //   this.currentListing.assignedByUser &&
    //   this.currentListing.assignedByUser[0] !== this.auth.getUID()) {

    //   this.listingCreatedByAnotherUser();
    //   return;
    // }
    // else if (this.currentListing.id &&
    //   value === 'address' &&
    //   User_global.role === 'agentAdmin' &&
    //   !this.currentListing.assignedByUser) {

    //   this.listingCreatedByAnotherUser();
    //   return;
    // }

    console.log('open edit: ' + value);
    //link address message i
    let profileModal;

    if (value === 'link') {
      console.log('editing type is link');
      profileModal = await this.modalCtrl.create({
        component: EditInputFollowUpPage, componentProps: {
          label: item,
          data: this.currentListing.linkToInfo
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismissing profile modal ' + data);
      if (data === undefined || data === null) {
        return;
      }

      // DONT COUNT TEXT MESSAGE 2 AS AN UPDATE
      if (item !== 'Text Message 2') {
        this.micrositeUpdated = true;
      }
      this.currentListing.linkToInfo = data;
      this.auth.updateListing(this.currentListing, { linkToInfo: this.currentListing.linkToInfo });

    } else if (value === 'address') {

      if (User_global.role === 'lender' && this.currentListing.address.title) {
        this.actionDisabled();
        return;
      }
      if (this.currentListing.sampleListing === true) {
        this.sampleListingEditWarning();
        return;
      }

      if (!this.currentListing.pairedListingAgents) {
        this.currentListing.pairedListingAgents = [];
      }

      profileModal = await this.modalCtrl.create({
        component: EditInputAddressPage, cssClass: 'app-edit-input-address', componentProps: {
          label: 'Address',
          add: !this.isListingValid(),
          title: this.currentListing.address.title.trim(),
          placeId: JSON.parse(JSON.stringify(this.currentListing?.address?.placeId)) || '',
          state: JSON.parse(JSON.stringify(this.currentListing?.address?.state)) || '',
          pairedListingAgents: JSON.parse(JSON.stringify(this.currentListing?.pairedListingAgents)) || [],
          assignedByUser: ((User_global.role === 'lender' || User_global.role === 'agentAdmin') && this.currentListing.listingAssigned) ? JSON.parse(JSON.stringify(this.currentListing.assignedByUser)) : [],
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('address modal dismissed ' + JSON.stringify(data));
      console.log('data ' + JSON.stringify(data));

      if (!data && !this.currentListing.address.title) {
        this.ifListingIsAvailable = false;
        this.handleBack();
        return;
      }

      if (data) {
        this.ifListingIsAvailable = true;
        if (data.placeId !== undefined || data.title) {

          if (this.currentListing.address.placeId === data.placeId && this.currentListing.address.state === data.state) {
            console.log('place id is unchanged. do not send address change.');

            // only change listing agent if the admin/lender change it
            if ((User_global.role === 'agentAdmin' || User_global.role === 'lender') && data.listingAgent) {
              console.log('old agents ', this.currentListing.pairedListingAgents);
              console.log('new agents ', data.pairedListingAgents);

              if (!this.common.isDeepEqual(this.currentListing.pairedListingAgents, data.pairedListingAgents)) {
                console.log('agents have changed!');
                this.currentListing.listingAgent = data.listingAgent;
                this.currentListing.pairedListingAgents = data.pairedListingAgents;
                if (data.assignedByUser) {
                  this.currentListing.listingAssigned = true;
                  this.currentListing.assignedByUser = data.assignedByUser;
                }

                this.parsePairedAgents();

                if (!this.currentListing.agentAdmin && User_global.role === 'agentAdmin') {
                  this.currentListing.agentAdmin = this.auth.getUID();
                }

                this.auth.updateListing(this.currentListing, { listingAgent: data.listingAgent, pairedListingAgents: data.pairedListingAgents });
              }
            }

            return;
          }

          if ((User_global.role === 'agentAdmin' || User_global.role === 'lender') && data.listingAgent) {
            console.log('old agents ', this.currentListing.pairedListingAgents);
            console.log('new agents ', data.pairedListingAgents);

            if (!this.common.isDeepEqual(this.currentListing.pairedListingAgents, data.pairedListingAgents)) {

              console.log('agents have changed!');

              this.currentListing.listingAgent = data.listingAgent;
              this.currentListing.pairedListingAgents = data.pairedListingAgents;

              if (data.assignedByUser) {
                this.currentListing.listingAssigned = true;
                this.currentListing.assignedByUser = data.assignedByUser;
              }

              this.parsePairedAgents();

              if (!this.currentListing.agentAdmin && User_global.role === 'agentAdmin') {
                this.currentListing.agentAdmin = this.auth.getUID();
              }
            }
          }
          // if this is an edit, don't change assigned by
          if (!this.currentListing.id) {
            this.currentListing.assignedByUser = data.assignedByUser || [];
            this.currentListing.listingAssigned = data.listingAssigned || false;
          }

          this.currentListing.pairedStatus = data.listingAssigned ? 'paired' : '';

          if (data.listingAssigned) {
            this.isPairedListing = true;
            this.connectionService.public_profile(this.currentListing.listingAgent).then(res => {
              this.pairedAgent = res;
            });
          }
          if (data.placeId === undefined || data.placeId === '') {
            this.isMapVisible = false;
            this.currentListing.address = {
              title: data.title.trim(),
              state: data.state,
              placeId: ''
            };
            this.updateListing('address');

            return;
          } else {
            this.isMapVisible = true;
            console.log(' Map True');
            const newAddress = {
              title: data.title,
              placeId: data.placeId || '',
              country: data.country || '',
              state: data.state || '',
              zip: data.zip || '',
              city: data.city || '',
              lat: data.lat || 0,
              lng: data.lng || 0
            };
            this.currentListing.address = Object.assign({}, newAddress);
            console.log('address passed to details: ' + JSON.stringify(this.currentListing.address));

            try {
              this.loadMap();
              //  this.updateMarkerOnMap(data.placeId);
            }
            catch (err) {
              console.log(err);
            }
            this.updateListing('address');
          }
        }
      }
    } else if (value === 'message') {
      profileModal = await this.modalCtrl.create({
        component: EditInputFollowUpPage, componentProps: {
          label: item,
          data: this.currentListing.textMessage
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismiss listing message sms ' + data);
      if (data === undefined || data === null) {
        return;
      }
      this.currentListing.textMessage = data;


      this.auth.updateListing(this.currentListing, {
        textMessage: this.currentListing.textMessage
      });

    } else if (value === 'microsite') {

      console.log('microsite dialog clicked ' + this.currentListing.enhancedMode);
      if (this.currentListing.enhancedMode !== true && !this.lenderOverride) {
        console.log('warn to enable microsite');
        this.warnEnableMicrosite();
        return;
      }

      console.log('this.currentListing.listingDetails ' + JSON.stringify(this.currentListing.listingDetails));
      profileModal = await this.modalCtrl.create({
        component: EditMicrositePage, componentProps: {
          label: 'Property Info',
          data: JSON.parse(JSON.stringify(this.currentListing.listingDetails))
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      if (data === undefined || !data) {
        return;
      }
      this.micrositeUpdated = true;
      this.currentListing.listingDetails = data;

      this.updateListingDetailsWithTimeout(data);
    }
    else if (value === 'propertyDescription') {
      if (this.currentListing.enhancedMode !== true && !this.lenderOverride) {
        this.warnEnableMicrosite();
        return;
      }

      profileModal = await this.modalCtrl.create({
        component: EditInputPage, componentProps: {
          label: 'Property Description',
          data: this.currentListing.listingDetails.description,
          limit: 5000,
          rows: 10
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismiss ' + data);
      if (data === undefined || data === null) {
        return;
      }
      this.micrositeUpdated = true;
      this.currentListing.listingDetails.description = data;


      this.updateListingDetailsWithTimeout(this.currentListing.listingDetails);
    }

    else if (value === 'propertyURL') {
      if (this.currentListing.enhancedMode !== true && !this.lenderOverride) {
        this.warnEnableMicrosite();
        return;
      }

      profileModal = await this.modalCtrl.create({
        component: EditInputPage, componentProps: {
          label: 'More Info URL',
          data: this.currentListing.listingDetails.moreInfoURL,
          limit: 655
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismiss ' + data);
      if (data === undefined || data === null) {
        return;
      }
      this.micrositeUpdated = true;
      this.currentListing.listingDetails.moreInfoURL = data;

      this.updateListingDetailsWithTimeout(this.currentListing.listingDetails);

    }
    else if (value === 'tourLink') {
      profileModal = await this.modalCtrl.create({
        component: EditInputPage, componentProps: {
          label: item.title,
          data: item.URL,
          limit: 1000
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      console.log('dismiss ' + data);
      if (data === undefined || data === null) {
        return;
      }
      this.micrositeUpdated = true;
      this.currentListing.tourLinks[item.order].URL = data;

      this.auth.updateListing(this.currentListing, {
        tourLinks: this.currentListing.tourLinks
      });
    }
    else {

      if (value === 1 && this.disclosureLock) {

        this.adminLocked();

        return;
      }

      profileModal = await this.modalCtrl.create({
        component: EditInputPage, componentProps: {
          label: `Question ${value}`,
          data: this.currentListing.kioskQuestions[value].question
        }
      });

      await profileModal.present();
      const { data } = await profileModal.onWillDismiss();

      // If the user cancels any changes, but it was previously toggled ON already with some text.
      if (this.currentListing.kioskQuestions[value].isActive &&
        this.currentListing.kioskQuestions[value].question &&
        this.currentListing.kioskQuestions[value].question.length > 0 &&
        (data === undefined || !data)) {
        // alert('cancelled existing, so do nothing');
        return;
      }

      if (data === undefined || !data) {
        this.currentListing.kioskQuestions[value].isActive = false;

        this.auth.updateListing(this.currentListing, {
          kioskQuestions: this.currentListing.kioskQuestions
        });

        return;
      }

      // Force active because of timing bug.
      this.currentListing.kioskQuestions[value].isActive = true;
      this.currentListing.kioskQuestions[value].question = data;

      this.auth.updateListing(this.currentListing, {
        kioskQuestions: this.currentListing.kioskQuestions
      });
    }
  }

  async warnEnableMicrosite() {

    const alertWin = await this.alertCtrl.create({
      header: 'A Paired Lender Is Required',
      message: this.i8nService.messages.listingPairedLenderRequired,
      buttons: [
        {
          text: 'Close',
          handler: data => {

          }
        }
      ]
    });
    await alertWin.present();
  }


  async adminLocked() {
    const alert = await this.alertCtrl.create({
      header: this.i8nService.messages.teamAdminLockedFeatureHeader,
      message: this.i8nService.messages.teamAdminLockedFeatureMessage,
      buttons: ['OK']
    });

    alert.present();
  }

  async clickInfoText() {

    // const modal = await this.modalCtrl.create({
    //   component: InfoModalComponent,
    //   componentProps: {
    //     title: this.i8nService.messages.primaryAgentInfoTitle1,
    //     content: `
    //       <p>${this.i8nService.messages.primaryAgentInfoMessage1}</p>
    //       <ul>
    //         <li>Make other edits to the listing which will be updated in all assigned agents' accounts</li>
    //         <li>Collect new leads for the listing which will be visible in all assigned agents' accounts</li>
    //         <li>View all leads collected for this listing for as long as you're assigned to it</li>
    //       </ul>
    //     `,
    //     button: 'OK'
    //   },
    // });

    // await modal.present();

    this.primaryAgentCannotChangeWarning();
  }


  updateListing(field = '') {

    this.setDataLoading();

    console.log('update listing called: ' + field);
    const listingRequest = this.prepareListingRequestEnhancedMode(JSON.parse(JSON.stringify(this.currentListing)));

    if (field === 'saleStatus') {
      this.micrositeUpdated = true;
      this.updateListingDetailsWithTimeout(this.currentListing.listingDetails);
      return;
    }

    if (field === 'enhancedMode') {
      if (this.currentListing.enhancedMode === false) {
        this.listingService.removeListingFromMicrosite(this.currentListing, this.userData);
      }
      this.auth.updateListing(this.currentListing, { enhancedMode: this.currentListing.enhancedMode });
      return;
    }

    console.log('listing original after prepare ' + JSON.stringify(this.currentListing));
    console.log('Update Current Listing ' + this.currentListing.id);

    if (this.currentListing.id) {

      let saveData = {};

      if (this.networkProvider.checkInternet()) {

        if (field === 'address') {

          listingRequest.loanOfficer = 'updating';
          saveData = { loanOfficer: listingRequest.loanOfficer, address: this.currentListing.address };
        }
      }
      else if (field === 'address') {
        saveData = { address: this.currentListing.address };
      }

      this.auth.updateListing(this.currentListing, saveData).then(() => {
        // this.listingDataProvider.updateListing(this.currentListing, { ...listingRequest }).then(() => {

        this.events.publish('listing:menu');

      }).catch((error) => {
        this.auth.logErrors('error updating listing: ' + error);
        this.common.showDBWriteError();
        console.log(error);
      });

    } else {

      if (this.isListingValid()) {
        console.log('listing has no ID, creating');
        if (this.networkProvider.checkInternet()) {
          listingRequest.loanOfficer = 'updating';
        }
        try {
          this.currentListing.id = this.listingDataProvider.addListing({ ...listingRequest });
          this.location.replaceState('listing-detail/' + this.currentListing.id + '?mode=' + this.segmentValue);
        }
        catch (err) {
          this.auth.logErrors('error in listingDataProvider.addListing. Unable to create listing: ' + JSON.stringify(this.currentListing) + ' listingRequest: ' + JSON.stringify(listingRequest) + 'err: ' + err);
          this.common.showDBWriteError();
        }

        if (this.currentListing.id && this.currentListing.id !== '') {

          this.subscribeListingUpdates();
        }
        else {
          this.events.publish('listing:menu');
        }
      }
    }
  }

  // don't send loan officer from UI
  prepareListingRequest(listingRequest) {

    console.log('this.currentListing before prepare: ' + JSON.stringify(this.currentListing));

    delete listingRequest.loanOfficer;
    //  delete listingRequest.enhancedMode;

    console.log('prepared listing json: ' + JSON.stringify(listingRequest));
    return listingRequest;
  }

  // don't send loan officer from UI
  prepareListingRequestEnhancedMode(listingRequest) {

    console.log('this.currentListing before prepare: ' + JSON.stringify(this.currentListing));

    delete listingRequest.loanOfficer;
    console.log('prepared listing json: ' + JSON.stringify(listingRequest));
    return listingRequest;
  }

  // gets connected listing lender
  getLender() {
    console.log('get lender called');
    // still matching
    if (this.currentListing.loanOfficer === 'updating' || !this.currentListing.loanOfficer) {
      return;
    }

    const self = this;
    this.translateOldLenderId();

    this.connectionService.public_profile(this.currentListing.loanOfficer).then(res => {
      self.loanOfficer = res;

      self.loanOfficer.name = self.loanOfficer.teamname || self.loanOfficer.username;
      self.loanOfficer.shortname = self.loanOfficer.username.split(' ')[0];

      console.log('user data: ' + JSON.stringify(res));

      self.imageService.realImageData(self.loanOfficer.profilePhoto[0]).then((data) => {
        self.loanOfficer.image = data;
      });
      console.log('this.loanOfficer: ' + self.loanOfficer);

    });


    this.checkLenderElegibility();

  }

  // CHECK IF LENDER IS ELIGIBLE FOR MORTGAGE QUESTION RANDOMIZATION
  checkLenderElegibility() {

    if (User_global.role === 'lender') {
      return;
    }
    else if (User_global.role === 'agent') {
      //agent
    }
    else if (User_global.role === 'agentAdmin') {
      //agentAdmin
      return;
    }
    console.log('check lender eligibility called.');
    const self = this;
    // check lender elegibility
    if (this.auth.needsNewAssignedQuestion()) {
      this.auth.getEligibleLender(this.currentListing.loanOfficer).then(res => {
        console.log('lender eligibility res: ' + JSON.stringify(res));
        if (res) {
          if (!User_global.mortgageQuestionOptimization) {
            this.auth.assignDefaultMortgageQuestion();
          }
          else {
            this.auth.assignRandomMortgageQuestion();
          }
        }
        else {
          console.log('lender is not eligible');
          this.auth.assignDefaultMortgageQuestion();
        }
      }).catch(err => {
        console.log('lender is not eligible');
        this.auth.assignDefaultMortgageQuestion();
      });
    }
  }

  // translate legacy lender ids to new ones
  translateOldLenderId() {
    if (this.currentListing.loanOfficer === environment.lender_julie_old) {
      this.currentListing.loanOfficer = environment.lender_julie_new;
    }
    else if (this.currentListing.loanOfficer === environment.lender_wendy_old) {
      this.currentListing.loanOfficer = environment.lender_wendy_new;
    }
  }

  getNewLenderId(loanOfficer) {
    if (loanOfficer === environment.lender_julie_old) {
      return environment.lender_julie_new;
    }
    else if (loanOfficer === environment.lender_wendy_old) {
      return environment.lender_wendy_new;
    }
    else {
      return loanOfficer;
    }
  }

  syncEnhancedModeFlag(listingRequest) {

    delete listingRequest.loanOfficer;
    delete listingRequest.webPublished;
    delete listingRequest.slug;

    console.log('sync enhanced mode flag for ' + this.currentListing.id);
    if (this.currentListing.id) {
      this.auth.updateListing(listingRequest, { enhancedMode: this.currentListing.enhancedMode });
      console.log('sync enhanced mode complete ' + JSON.stringify(listingRequest));
    }
  }

  deleteListing() {
    if (User_global.role === 'lender') {
      this.actionDisabled();
    }
    else if (User_global.role === 'agent') {
      this.deleteConfirmInfoModal();
    }
    else if (User_global.role === 'agentAdmin') {
      //agentAdmin


      if (User_global.role === 'agentAdmin'
        && this.currentListing.assignedByUser
        && this.currentListing.assignedByUser[0] === this.auth.getUID()) {
        this.deleteConfirmInfoModal();
        return;
      }
      else {
        this.actionDisabled();
      }
    }
  }

  toggleChanged(item, index, event) {


    if (event.checked === this.currentListing.kioskQuestions[index].isActive) {
      console.log('no change to toggle, returning');
      return;
    }

    //alert('toggleChanged');

    this.currentListing.kioskQuestions[index].isActive = item.isActive;

    if (!item.question) {
      this.openEditModal(index);
    }
    else {
      this.auth.updateListing(this.currentListing, { kioskQuestions: this.currentListing.kioskQuestions });
    }
  }

  checkEnhancedToggle() {
    return (!this.networkProvider.checkInternet() || this.currentListing.loanOfficer === undefined || this.currentListing.loanOfficer === '');
  }

  guestFollowUpChanged(event) {

    if (event.checked === this.currentListing.guestFollowUp) {
      console.log('no change to toggle, returning');
      return;
    }

    if (!this.currentListing.enhancedMode && !this.lenderOverride) {

      this.common.showPairedLenderError();

      setTimeout(() => {
        this.guestFollowUp = false;
        this.currentListing.guestFollowUp = false;
      }, 2000);

      return;
    }


    this.currentListing.guestFollowUp = this.guestFollowUp;
    this.currentListing.textMessage = this.createAutoFollowUp();
    this.auth.updateListing(this.currentListing, {
      guestFollowUp: this.currentListing.guestFollowUp,
      textMessage: this.currentListing.textMessage
    });
  }


  // toggleConditional(item, index, event) {
  //   console.log('Connditional toggle', this.currentListing.kioskQuestions[index].conditionalToggle);
  //   console.log('current item before if', item);

  //   if (event.checked === this.currentListing.kioskQuestions[index].conditionalToggle) {
  //     console.log('current item', item);
  //     return;
  //   }
  //   console.log('Checked eventt', event.checked);
  //   item.conditionalTriggerAnswer = event.checked;
  //   this.currentListing.kioskQuestions[index].conditionalToggle = event.checked;
  //   this.auth.updateListing(this.currentListing, {
  //     kioskQuestions: this.currentListing.kioskQuestions,
  //   });
  // }

  // async showConditionalModal() {
  //   const callbackURL = 'https://juuj.me/conditional-logic';
  //   const conditionalModal = await this.alertCtrl.create({
  //     header: 'Conditional Logic',
  //     message: `If you want to ask a follow up question only when a guest provides a specific answer to this question, enable conditional logic`,
  //     buttons: [
  //       {
  //         text: 'Help Section',
  //         handler: data => {
  //           if (!this.networkProvider.checkInternet()) {
  //             this.networkProvider.noNetworkModal();
  //             return;
  //           }
  //           this.common.openLink(callbackURL);
  //         }
  //       },
  //       {
  //         role: 'cancel',
  //         text: 'OK',
  //         handler: data => {
  //           console.log('Conditonal ok clicked');
  //         }
  //       }
  //     ]
  //   });
  //   await conditionalModal.present();
  // }

  // conditionalResponse(listing, i, event) {
  //   console.log('listing', listing);

  //   console.log('Event', event.target.innerHTML);
  //   if (event.target.innerHTML === 'Yes') {
  //     listing.conditionalTriggerAnswer = true;
  //     this.currentListing.kioskQuestions[i].conditionalTriggerAnswer = true;
  //     this.auth.updateListing(this.currentListing, {
  //       kioskQuestions: this.currentListing.kioskQuestions,
  //     });
  //   }
  //   else {
  //     listing.conditionalTriggerAnswer = false;
  //     this.currentListing.kioskQuestions[i].conditionalTriggerAnswer = false;
  //     this.auth.updateListing(this.currentListing, {
  //       kioskQuestions: this.currentListing.kioskQuestions,
  //     });
  //   }
  // }

  questionTypeSelected(question: any, index, questionType: any) {
    if (questionType === 'multipleChoice' && this.currentListing.enhancedMode !== true && !this.lenderOverride) {
      this.warnEnableMicrosite();
      return;
    }

    if (index === 1 && this.disclosureLock) {
      this.adminLocked();
      return;
    }

    question.type = questionType;
    this.currentListing.kioskQuestions[index].type = questionType;

    if (questionType === 'multipleChoice') {
      if (!question.answers) {
        question.answers = [''];
        this.currentListing.kioskQuestions[index].answers = question.answers;
      }
      if (!question.details) {
        question.details = [{ selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' }];
        this.currentListing.kioskQuestions[index].details = question.details;
      }
    }
    this.auth.updateListing(this.currentListing, {
      kioskQuestions: this.currentListing.kioskQuestions
    });
  }

  async showFirstTimeUserInfoModal() {
    const firstTimeInfoModal = await this.alertCtrl.create({
      header: 'Data Disclaimer',
      message: `We respect your business data. We wont share it with other real estate agents or brokerages without your explicit permission.
      We can connect open house guests, that ask for mortgage information, with a five star loan officer. If that is not a service you want to provide to your guests, you can disable this feature under Advanced Guest Options.
      `,
      buttons: [
        {
          text: 'OK',
          handler: data => {
            this.auth.updateUserDataProperty({ firstTimeListing: false });
          }
        }
      ]
    });
    await firstTimeInfoModal.present();
  }


  async deleteConfirmInfoModal() {
    const self = this;
    const deleteConfirmInfoModal = await this.alertCtrl.create({
      header: 'Delete Listing',
      message: 'Are you sure you want to delete this listing on Curb Hero?',
      buttons: [
        {
          text: 'Cancel',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Delete',
          handler: data => {
            console.log('Current Delete ID: ' + this.currentListing.id);
            if (this.listingSubscription) {
              this.listingSubscription.unsubscribe();
            }

            // dont delete for sample listing
            if (!this.currentListing.sampleListing) {
              this.listingService.deleteImages(this.currentListing);
            }

            self.deletedListing = true;
            self.listingService.removeListingFromMicrosite(this.currentListing, this.userData);
            self.listingService.deleteListing(this.currentListing.id).then(
              success => {
                /**update listing in sqlite */
                self.events.publish('listing:menu');
                self.navCtrl.navigate(['/my-listings']);

              },
              error => console.log(error)
            );
          }
        },
      ]
    });
    await deleteConfirmInfoModal.present();
  }


  async openMicrositeInfo(event, override) {
    event.stopPropagation();

    if (!override && !this.currentListing.enhancedMode && !this.lenderOverride) {
      this.common.showPairedLenderError();
      return;
    }

    const callbackURL = 'https://juuj.me/creating-a-listing-microsite';
    const myAlert = await this.alertCtrl.create({
      header: 'Listing Microsite URL Info',
      message: 'Once published, this microsite URL can be included in social media and other digital marketing. Microsite contact form submissions are added to My Leads. Publishing the microsite for a listing will also enable a Touch Free Open House Sign-in option for devices with tablet size screens or larger.',
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Help Section',
          handler: data => {
            console.log('help clicked');
            if (!this.networkProvider.checkInternet()) {
              this.networkProvider.noNetworkModal();
              return;
            }

            this.common.openLink(callbackURL);
          }
        }
      ]
    });
    await myAlert.present();
  }

  async listReportsInfo() {

    const alert = await this.alertCtrl.create({
      header: 'Sign Up For ListReports',
      message: 'ListReports provides beautiful marketing material for property listings. It’s free of charge for agents who are paired with a lender that has a ListReports account. Luckily our five star, local lenders are happy to pair.',
      buttons: [
        {
          text: 'Not now',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Pair',
          handler: data => {
            if (!this.networkProvider.checkInternet()) {
              this.networkProvider.noNetworkModal();
              return;
            }
            this.signUpFreeLIstReports();
          }
        }
      ]
    });
    await alert.present();
  }

  updateMarkerOnMap(placeId) {
    return;
    if (placeId && placeId !== '') {

      setTimeout(() => {
        if (this.networkProvider.checkInternet()) {
          if (!this.geocoder) {
            this.geocoder = new google.maps.Geocoder();
          }

          this.geocoder.geocode({ placeId }, (results, status) => {
            console.log('Results', results, 'Status', status);
            if (status === 'OK' && results[0]) {
              this.markerSubject.next(results[0].geometry.location);
            }
          });
        }
      }, 1500);
    }
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
  }

  /**
   * Navigate to My Profile Page
   */
  /*
  openMyProfile() {
    this.navCtrl.setRoot(MyProfilePage)
  }
  */

  openMyProfile() {
    console.log('open my profile');
    this.navCtrl.navigate(['/my-profile']);
  }

  contactLoanOfficer() {

    if (this.plt.is('cordova') && this.networkProvider.checkInternet()) {
      this.common.openLink(this.loanOfficer.website);
    }
    else if (!this.plt.is('cordova')) {
      this.common.openLink(this.loanOfficer.website);
    } else {
      this.networkProvider.noNetworkModal();
    }
  }
  /*
    async showEnhancedDetails(index = 0) {
      const modal = await this.modalCtrl.create({ component: EnhancedDetailsPage, componentProps: { data: index } });
      modal.present();
    }
  */


  // enhancedMode toggle
  async enhancedClicked(value) {
    const self = this;

    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }

    // no lender found
    if (!this.currentListing.loanOfficer || this.currentListing.loanOfficer === '') {

      const alertWin = await self.alertCtrl.create({
        header: 'Paired Lender is Not Available',
        message: this.i8nService.messages.listingPairedLenderNotSupportedInRegion,
        buttons: [
          {
            text: 'OK',
            handler: data => {

            }
          }
        ]
      });
      await alertWin.present();
    }
    else {

      // remove all multiple choice Q back to Text
      if (!value) {
        this.currentListing.kioskQuestions.forEach((question) => {
          if (question.type === 'multipleChoice') {
            question.type = 'text';
          }
        });


        this.kioskQuestions = JSON.parse(JSON.stringify(this.currentListing.kioskQuestions));

        this.auth.updateListing(this.currentListing, {
          kioskQuestions: this.currentListing.kioskQuestions
        });
      }

      self.currentListing.enhancedMode = value;
      self.currentListing.kioskQuestions[0].isActive = value;
      self.listingDataChanged();
    }
  }

  listingDataChanged() {
    console.log('listing changed! saving!');
    //  this.currentListing.kioskQuestions[0].isActive = this.currentListing.enhancedMode;
    this.updateListing('enhancedMode');
  }


  async showEnhancedModal(index = 'Intro') {

    // if (User_global.role === 'agent'
    //   && this.currentListing.listingAgent
    //    && this.auth.getUID() !== this.currentListing.listingAgent) {

    // const myAlert = await this.alertCtrl.create({
    //   header: 'Paired Lender',
    //   message: 'Changing the paired lender isn\'t possible since you\'re not the primary agent on this listing. Contact the primary agent or your team admin to make this change',
    //   buttons: [
    //     {
    //       role: 'cancel',
    //       text: 'OK',
    //       handler: res => {
    //         console.log('Cancel clicked');
    //       }
    //     },
    //   ]
    // });
    // myAlert.present();

    //  this.primaryAgentCannotChangeWarning();

    // const modal2 = await this.modalCtrl.create({
    //   component: InfoModalComponent,
    //   componentProps: {
    //     title: this.i8nService.messages.primaryAgentInfoTitle2,
    //     content: `
    //       <p>${this.i8nService.messages.primaryAgentInfoMessage2}</p>
    //       <ul>
    //         <li>Make other edits to the listing which will be updated in all assigned agents' accounts</li>
    //         <li>Collect new leads for the listing which will be visible in all assigned agents' accounts</li>
    //         <li>View all leads collected for this listing for as long as you're assigned to it</li>
    //       </ul>
    //     `,
    //     button: 'OK'
    //   },
    // });

    // await modal2.present();

    // return;
    // }

    const self = this;
    this.intercomService.hideLauncher();

    const modal = await this.modalCtrl.create({
      component: EnhancedModalPage, componentProps:
        { data: index, loanOfficer: this.loanOfficer, loanOfficerReviews: this.loanOfficer.reviews, listing: this.currentListing },
      cssClass: 'custom-modal-css'
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

    this.intercomService.showLauncher();

    if (!data || data === undefined) {
      return;
    }
    if (data.type === 'tellMeMore') {
      //   self.showEnhancedDetails();
    }
    if (data.type === 'enhancedMode') {

      self.enhancedClicked(data.enhancedMode);
    }
  }

  async listingCreatedByAnotherUser() {

    const myAlert = await this.alertCtrl.create({
      header: 'Limited Listing Edits',
      message: 'This listing was created by another user so the address and the agent assignments aren\'t editable. You can edit other fields and you\'ll still have full lead visibility. If you need to assign this listing to multiple agents, then create another version of the listing.',
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
      ]
    });
    myAlert.present();

  }

  async primaryAgentAlert() {
    const myAlert = await this.alertCtrl.create({
      header: 'Paired Agent',
      message: this.i8nService.messages.pairedAgentInfo,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
      ]
    });
    myAlert.present();
  }

  async primaryAgentCannotChangeWarning() {
    const myAlert = await this.alertCtrl.create({
      header: 'Primary Agent',
      message: 'The primary agent on a listing can\'t be changed. They can make changes to the listing that will be autoupdated to other agents assigned to the listing. It\'s common to make the primary agent match the actual listing agent, but it\'s not a requirement.',
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
      ]
    });
    myAlert.present();
  }


  async openPairedAgentInfoAlert() {

    if (User_global.role === 'agentAdmin') {

      if (this.currentListing.id &&
        User_global.role === 'agentAdmin' &&
        this.currentListing.assignedByUser &&
        this.currentListing.assignedByUser[0] !== this.auth.getUID()) {

        this.listingCreatedByAnotherUser();
        return;
      }
      else if (this.currentListing.id &&

        User_global.role === 'agentAdmin' &&
        !this.currentListing.assignedByUser) {

        this.listingCreatedByAnotherUser();
        return;
      }

      this.openEditModal('address');

      return;
    }

    this.primaryAgentAlert();
  }

  async presentPopover(event) {
    event.stopPropagation();

    const options = {
      edit: false,
      launch: false,
      share: true,
      save: true,
      create: this.segmentValue !== 'microsite',
      sync: this.segmentValue === 'microsite',
      delete: true,
      role: this.role
    };

    const customMode = !this.plt.is('cordova') || this.plt.is('ios') ? 'ios' : 'md';
    const popover = await this.popoverCtrl.
      create({
        component: ListingActionPopoverComponent,
        event,
        componentProps: { data: this.currentListing, options },
        cssClass: 'my-custom-popover',
        showBackdrop: true,
        mode: customMode,
      });

    await popover.present();
    const { data } = await popover.onWillDismiss();

    if (data === undefined || !data) {
      console.log('data is empty');
    }
    else {
      //  if (data === 'edit') {
      //    this.doEditKiosk();
      //  }
      if (data === 'share') {
        this.shareListing(this.currentListing, event);
      }
      else if (data === 'save') {
        if (!this.currentListing.enhancedMode && !this.lenderOverride) {
          this.common.showPairedLenderError();
          return;
        }

        this.common.showQRCode(environment, this.currentListing);
      }
      else if (data === 'create') {
        // this.segmentValue = 'microsite';
        this.segmentChanged('microsite');
      }
      else if (data === 'sync') {
        this.manualSync();
      }
      else if (data === 'delete') {
        this.deleteListing();
      }
    }
  }

  saveQR() {
    if (!this.currentListing.enhancedMode && !this.lenderOverride) {
      this.common.showPairedLenderError();
      return;
    }
    this.common.showQRCode(environment, this.currentListing);
  }

  async shareListing(selectedListing: Listing, event) {

    if (!this.currentListing.enhancedMode && !this.lenderOverride) {
      this.common.showPairedLenderError();
      return;
    }

    if (selectedListing.slug) {
      this.common.openLink(environment.blockparty + selectedListing.slug + '/?display=sharing', '_system');
    }
    else {
      await this.micrositeRequired();
    }
  }

  async micrositeRequired() {
    const callbackURL = 'https://juuj.me/creating-a-listing-microsite';
    const modal = await this.alertCtrl.create({
      header: 'Listing Microsite Required',
      message: this.i8nService.messages.listingNeedsPublishingBeforeSharing,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
        {
          text: 'Help Section',
          handler: data => {
            if (!this.networkProvider.checkInternet()) {
              this.networkProvider.noNetworkModal();
              return;
            }
            this.common.openLink(callbackURL);
          }
        }

      ]
    });

    await modal.present();
  }

  manualSync(visible = true, timeout = 500) {
    console.log('manual sync called');

    if (visible) {
      this.common.startLoading('Syncing...');
    }

    this.currentListing.synced = new Date();
    // GIVE SOME TIME BEFORE AND AFTER SYNC
    setTimeout(() => {
      this.auth.updateListing(this.currentListing,
        { synced: this.currentListing.synced });
    }, timeout);

    if (visible) {
      setTimeout(() => { this.common.closeLoading(); }, 5000);
    }
  }

  setDataLoading() {
    this.dataIsLoading = true;

    setTimeout(() => {
      this.dataIsLoading = false;
    }, 1000);
  }

  openMarketingUrl(val: number) {
    let url = '';
    switch (val) {
      case 1: url = 'https://juuj.me/customizable-social-posts'; break;
      case 2: url = 'https://juuj.me/done-for-you-graphics'; break;
      case 3: url = 'https://juuj.me/promote-listings-on-social-media'; break;
    }
    if (!url) { return; }
    this.common.openLink(url);
  }

  reorderMultipleChoiceQuestionAnswers(question: Question, ev: CustomEvent<ItemReorderEventDetail>, index: number) {
    this.currentListing.kioskQuestions[index].answers = ev.detail.complete(question.answers);

    try {
      // Swap the details
      this.currentListing.kioskQuestions[index].details.splice(ev.detail.to, 0, this.currentListing.kioskQuestions[index].details.splice(ev.detail.from, 1)[0]);
    }
    catch (err) {
      console.log(err);
    }

    console.log('From index:', ev.detail.from);
    console.log('to index:', ev.detail.to);

    this.auth.updateListing(this.currentListing, {
      kioskQuestions: this.currentListing.kioskQuestions
    });
  }


  deleteAnswer(question: Question, j: number, index: number) {
    if (!question.answers) { return; }

    question.answers.splice(j, 1);

    if (question.details && question.details[j]) {
      question.details.splice(j, 1);
    }

    this.currentListing.kioskQuestions[index] = question;

    this.auth.updateListing(this.currentListing, {
      kioskQuestions: this.currentListing.kioskQuestions
    });
  }

  async addMultipleChoiceAnswer(question: Question, index: number) {
    if (this.currentListing.enhancedMode !== true && !this.lenderOverride) {
      this.warnEnableMicrosite();
      return;
    }

    if (index === 1 && this.disclosureLock) {
      this.adminLocked();
      return;
    }

    if (question.answers[0] === '') {
      this.openEditModalMultipleChoice(question, 0, index);
      return;
    }

    const modal = await this.modalCtrl.create({
      component: EditInputPage, componentProps: {
        label: 'Add Choice',
        isFullLabel: true,
        data: '',
        limit: 30,
        inputType: 'multiChoice',
        featureFlags: this.featureFlags
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

    // On return from modal, if we've got something, add item + keep it, otherwise get outta here.
    if (data === undefined || data === null) {
      return;
    }

    if (this.featureFlags && this.featureFlags.answerTypeChoiceWithDetails) {

      if (!question.answers) {
        question.answers = [];
        question.details = [];
      }
      question.details.push(data.details);
      question.answers.push(data.answer);
    }
    else {
      if (!question.answers) {
        question.answers = [data];
      }
      else {
        question.answers.push(data);
      }
    }

    this.currentListing.kioskQuestions[index] = question;

    this.auth.updateListing(this.currentListing, {
      kioskQuestions: this.currentListing.kioskQuestions
    });
  }

  async openEditModalMultipleChoice(question: Question, j: number, index: number) {
    if (!question.answers[j]) { question.answers[j] = ''; };

    if (this.currentListing.enhancedMode !== true && !this.lenderOverride) {
      this.warnEnableMicrosite();
      return;
    }

    if (index === 1 && this.disclosureLock) {
      this.adminLocked();
      return;
    }

    if (!question.details) {
      question.details = [];
      question.answers.forEach(answer => {
        question.details.push({ selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' });
      });
    }

    // if (!question.details[j]) {
    //   question.details[j] = { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' };
    // }

    for (let i = 0; i < question.answers.length; i++) {
      const answer = question.answers[i];

      // Check if corresponding details exist or not
      if (!question.details[i]) {
        // If missing, create and push corresponding details
        question.details[i] = { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' };
      }
    }

    const modal = await this.modalCtrl.create({
      component: EditInputPage, componentProps: {
        label: 'Edit Choice',
        isFullLabel: true,
        data: JSON.parse(JSON.stringify(question.answers[j])),
        details: JSON.parse(JSON.stringify(question.details[j])),
        limit: 30,
        showDeleteButton: (question.answers.length > 1),
        inputType: 'multiChoice',
        featureFlags: this.featureFlags
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data === undefined || data === null) {
      return;
    }

    if (data === '##DELETE-CHOICE##') {
      return this.deleteAnswer(question, j, index);
    }

    console.log(JSON.stringify(this.currentListing.kioskQuestions));
    console.log(JSON.stringify(question));

    if (this.featureFlags && this.featureFlags.answerTypeChoiceWithDetails) {

      question.details[j] = data.details;
      question.answers[j] = data.answer;
    }
    else {
      if (!question.answers) {
        question.answers = [data];
      }
      else {
        question.answers[j] = data;
      }
    }

    this.currentListing.kioskQuestions[index] = question;


    console.log(JSON.stringify(this.currentListing.kioskQuestions));
    this.auth.updateListing(this.currentListing, {
      kioskQuestions: this.currentListing.kioskQuestions
    });
  }

  shareSlugLink(event) {
    console.log('share slug link function clicked! ' + event.target);
    event.stopPropagation();

    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }
    this.common.openLink(environment.blockparty + this.currentListing.slug + '/?display=sharing', '_system');
  }

  onResize(event?) {
    const elem = document.getElementById('overflow_menu');
    if (elem !== null) {
      const element_width = elem.offsetWidth;
      const percentage_width_available = 75;
      const width_of_more_button = 40;
      const each_button_width = 48;
      console.log(element_width);
      const percentage_area = ((percentage_width_available * element_width) / 100) - width_of_more_button;
      console.log(percentage_area);
      this.number_of_buttons = Math.floor(percentage_area / each_button_width);
    }
    else {
      setTimeout(() => {
        this.onResize();
      }, 1000);
    }
  }
  async presentMorePopover(event) {

    event.stopPropagation();

    const options = {
      copy: (this.number_of_buttons < 1) ? true : false,
      shareMicrosite: (this.number_of_buttons < 2) ? true : false,
      save: (this.number_of_buttons < 3) ? true : false,
      whatIsAMicrosite: true,

      edit: false,
      launch: false,
      share: false,
      view: false,
      create: false,
      sync: false,
      delete: false,
      role: this.role
    };

    const customMode = !this.plt.is('cordova') || this.plt.is('ios') ? 'ios' : 'md';
    this.currentPopover = await this.popoverCtrl.create({
      component: ListingActionPopoverComponent,
      event,
      componentProps: { data: this.currentListing, options },
      cssClass: 'my-custom-popover',
      showBackdrop: true,
      mode: customMode,
    });

    await this.currentPopover.present();
    const { data } = await this.currentPopover.onWillDismiss();

    if (data === undefined || !data) {
      console.log('data is empty');
    }
    else {
      if (data === 'copy') {
        this.copySlug(event);
      }
      else if (data === 'save') {
        if (!this.currentListing.enhancedMode && !this.lenderOverride) {
          this.common.showPairedLenderError();
          return;
        }

        this.common.showQRCode(environment, this.currentListing);
      }
      else if (data === 'shareMicrosite' || data === 'share') {
        this.shareSlugLink(event);
      }
      else if (data === 'whatIsAMicrosite') {
        this.openMicrositeInfo(event, true);
      }
    }
  }


  selectItemParentClick(event) {
    event.stopPropagation();

  }

  async actionDisabled() {
    const myAlert = await this.alertCtrl.create({
      header: 'Disabled Action',
      message: this.i8nService.messages.disabledActionMessage,
      buttons: [
        {
          role: 'cancel',
          text: 'OK',
          handler: data => {
            console.log('Cancel clicked');
          }
        },
      ]
    });
    myAlert.present();
  }

  setFooterText() {
    console.log('this.currentListing.loanOfficer:' + this.loanOfficer);
    if (User_global.role === 'lender') {
      this.footerText = 'Multiple agents paired to this listing';
    }
    else if (User_global.role === 'agentAdmin') {
      this.connectionService.public_profile(this.currentListing.listingAgent).then(res => {
        this.pairedAgent = res;
      });

    }
    else if (this.currentListing.listingAgent === this.auth.getUID()) {
      return;
    }

    else {
      this.connectionService.public_profile(this.currentListing.pairedListingAgents[0].id).then(res => {

        let name = res.teamname || res.username;
        this.footerText = name + ' is the primary agent for this listing';
      });
    }
  }

  async generateContent() {
    if (this.currentListing.textMessage) {
      try {
        const result = await this.openaiService.openai('generateVariations', 'Provide with a good variation of this text: \n\n' + this.currentListing.textMessage);
        console.log(result); // Log the generated variations to the console
        alert(result);
        // Alternatively, assign the result to a variable and use it in your UI
        // this.generatedVariations = result;
      } catch (error) {
        console.error('Error:', error);
        // Handle errors
      }
    }
  }

}
