/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { CryptoService } from './crypto.service';
import { Injectable } from '@angular/core';
import { Intercom } from '@awesome-cordova-plugins/intercom/ngx';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Subject, concat, from, delay, concatMap } from 'rxjs';
import { AuthService } from './auth.service';
import { User_global } from '../app.models';

declare global {
  interface Window { Intercom: any }
}

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  visibleSubject: Subject<string>;
  constructor(
    private intercom: Intercom,
    private platform: Platform,
    private cryptoService: CryptoService,
    private auth: AuthService
  ) {

  }

  init() {
    this.visibleSubject = new Subject<string>();

    this.visibleSubject.pipe(delay(500), concatMap((vis) => {
      console.log(`Intercom vis update ${vis}`);
      return concat(
        from(this.intercom.setLauncherVisibility(vis)),
        from(this.intercom.setInAppMessageVisibility(vis)),
      );
    })).subscribe((vis) => {
      console.log(vis);
    });
  }

  async registerUser(email: string, name?: string, phone?: string, user_id?: string, role?: string) {

    try {
      console.log('register user with role: ' + role);
      console.log('register user with uid: ' + user_id);
      const userHash = this.cryptoService.getHmacHash(user_id);
      console.log('register user with userHash: ' + userHash);
      if (this.platform.is('cordova')) {
        console.log('cordova!');
        this.intercom.registerForPush();

        console.log('registerForPush!');
        this.intercom.registerIdentifiedUser({ email: email, userId: user_id }).then(res => {
          console.log('intercom register: ' + res);
        }).catch(error1 => {
          console.log('error1 in intercom: ' + error1);
          this.auth.logErrors('error1 in intercom: ' + error1);
        });
        console.log('registerIdentifiedUser!');
        this.intercom.setUserHash(userHash);

        console.log('setUserHash ' + userHash);

        setTimeout(() => {
          this.intercom.updateUser({
            name: name,
            email: email,
            user_id: user_id,
            userId: user_id,
            phone: phone,
            account_role: role,
            ...(User_global.intercom && User_global.intercom.companyId !== '' && User_global.intercom.companyName !== '' ? {
              company: {
                id: User_global.intercom.companyId,
                name: User_global.intercom.companyName
              }
            } : {}),
            custom_attributes: {
              registered: true,
              email: email,
              name: name,
              phone: phone,
              user_id: user_id,
              account_role: role
            }
          });

          console.log('update intercom after timeout!');
        }, 2000);
      }
      else { // web

        setTimeout(() => {
          window.Intercom('boot', {
            app_id: environment.intercom.app_id,
            registered: true,
            email: email, // Email address
            user_hash: userHash,
            phone: phone,
            name: name,
            user_id: user_id,
            userId: user_id,
            account_role: role,
            hide_default_launcher: false,
            ...(User_global.intercom && User_global.intercom.companyId !== '' && User_global.intercom.companyName !== '' ? {
              company: {
                id: User_global.intercom.companyId,
                name: User_global.intercom.companyName
              }
            } : {}),
            //Website visitor so may not have any user related info
          });
        }, 250);
      }
    }
    catch (err) {
      this.auth.logErrors('Error in register intercom : ' + err);
      console.log('error in intercom register: ' + err);
    }
  }

  async loginIntercomUser(email: string, user_id: string) {

    try {


      console.log('login with intercom called');
      const userHash = this.cryptoService.getHmacHash(user_id);

      if (this.platform.is('cordova')) {
        await this.intercom.registerForPush();
        await this.intercom.registerIdentifiedUser({ email: email, userId: user_id });
        await this.intercom.setUserHash(userHash);

        this.intercom.updateUser({
          user_id: user_id,
          email: email,
          ...(User_global.intercom && User_global.intercom.companyId !== '' && User_global.intercom.companyName !== '' ? {
            company: {
              id: User_global.intercom.companyId,
              name: User_global.intercom.companyName
            }
          } : {}),
          custom_attributes: {
            registered: true,
            //  email: email
          }
        });
      }
      else { // web

        setTimeout(() => {
          window.Intercom('update', {
            app_id: environment.intercom.app_id,
            registered: true,
            userId: user_id,
            user_id: user_id,
            email: email, // Email address
            user_hash: userHash,
            hide_default_launcher: false,
            ...(User_global.intercom && User_global.intercom.companyId !== '' && User_global.intercom.companyName !== '' ? {
              company: {
                id: User_global.intercom.companyId,
                name: User_global.intercom.companyName
              }
            } : {}),
            //Website visitor so may not have any user related info
          });
        }, 250);
      }
    }
    catch (err) {
      this.auth.logErrors('Error in login intercom : ' + err);
      console.log('error login intercom ' + err);
    }
  }

  updateIntercomUser(email: string, name?: string, phone?: string, user_id?: string) {

    const userHash = this.cryptoService.getHmacHash(user_id);

    // don't update if user id is empty
    if (!user_id) {
      return;
    }

    if (this.platform.is('cordova')) {
      this.intercom.updateUser({
        user_id: user_id,
        email: email,
        name: name,
        phone: phone,
        ...(User_global.intercom && User_global.intercom.companyId !== '' && User_global.intercom.companyName !== '' ? {
          company: {
            id: User_global.intercom.companyId,
            name: User_global.intercom.companyName
          }
        } : {}),
        custom_attributes: {
          email: email,
          name: name,
          phone: phone
        }
      });
    }
    else { // web
      setTimeout(() => {
        window.Intercom('update', {
          app_id: environment.intercom.app_id,
          user_id: user_id,
          email: email, // Email address
          phone: phone,
          name: name,
          user_hash: userHash,
          hide_default_launcher: false,
          ...(User_global.intercom && User_global.intercom.companyId !== '' && User_global.intercom.companyName !== '' ? {
            company: {
              id: User_global.intercom.companyId,
              name: User_global.intercom.companyName
            }
          } : {}),

        });
      }, 500);
    }
  }

  registerUnidentifiedUser() {
    console.log('Register Intercom unidentified user.');
    if (this.platform.is('cordova')) {
      this.intercom.registerUnidentifiedUser(null);
    }
    else { // web
      window.Intercom('boot', {
        app_id: environment.intercom.app_id,
        hide_default_launcher: false
        //Website visitor so may not have any user related info
      });

      setTimeout(() => {
        window.Intercom('update', {
          hide_default_launcher: false
        });
      }, 500);
    }
  }

  showFeedbackChat() {

    if (this.platform.is('cordova')) {
      this.intercom.displayMessageComposerWithInitialMessage('Here\'s what I\'d like to improve: \n\n\n');
    }
  }

  logout() {
    if (this.platform.is('cordova')) {
      this.intercom.reset();
    }
    else {
      window.Intercom('shutdown');

      setTimeout(() => {
        window.Intercom('boot', {
          // app_id: environment.intercom.app_id
          hide_default_launcher: false
          //Website visitor so may not have any user related info
        });
      }, 1500);
    }
  }

  showLauncher() {
    setTimeout(() => {
      if (this.platform.is('cordova')) {
        this.setLauncherVisibility('VISIBLE');
      }
      else {
        window.Intercom('update', {
          hide_default_launcher: false
        });
      }
    }, 1500);
  }

  hideLauncher() {
    if (this.platform.is('cordova')) {
      this.setLauncherVisibility('GONE');
    }
    else {
      window.Intercom('update', {
        hide_default_launcher: true
      });
    }
  }

  displayIntercomMessage(id) {
    console.log('trying to open carousel: ' + id);
    this.intercom.displayCarousel(id);
  }

  displayMessage() {
    this.intercom.displayMessageComposerWithInitialMessage('Hi, what do you think we can do better. Brutal feedback especially appreciated!');
  }

  private setLauncherVisibility(visibility: string) {
    try {
      this.visibleSubject.next(visibility);
    }
    catch (err) {
      console.log('err in intercom ' + err);
    }
  }
}
