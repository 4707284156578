/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { API } from '../../app/constants/api.constants';
import { Observable } from 'rxjs';
import { User_global } from '../app.models';
import { getFirestore, collection, orderBy, query, onSnapshot, where, limit, doc } from '@firebase/firestore';
import { ImagesManagementService } from './images-management.service';


@Injectable({
  providedIn: 'root'
})
export class ListingService {
  public uid: string;

  constructor(
    private auth: AuthService,
    private imageService: ImagesManagementService
  ) { }


  public listings(): Observable<any[]> {

    console.log('listings query called with uid ' + this.auth.getUID());
    const firestore = getFirestore();
    const listingsCollection = collection(firestore, `${API.users_endpoint}/${this.auth.getUID()}/${API.listings_endpoint}`);
    const listingsQuery = query(listingsCollection, orderBy('createdAt', 'desc'));

    return new Observable<any[]>((subscriber) => {
      const unsubscribe = onSnapshot(listingsQuery, (snapshot) => {
        const listings = [];
        console.log('listing query snapshot: ' + JSON.stringify(snapshot));
        snapshot.forEach((doc1) => {
          const data = doc1.data();
          const id = doc1.id;
          listings.push({ id, ...data });
        });
        subscriber.next(listings); // Emit the list of listings when there's a change
      }, (error) => {
        console.log('listing query error: ' + error);
        subscriber.error(error);
      });

      return () => unsubscribe();
    });
  }


  // latest 40 listings
  public latestListings() {
    const firestore = getFirestore();

    const userId = this.auth.getUID();
    const webPublishedQuery = where('webPublished', '==', true);
    const orderByCreatedAt = orderBy('createdAt', 'desc');
    const limitTo40 = limit(40);

    let listingsQuery;
    if (User_global.role === 'lender') {
      const pairedStatusQuery = where('pairedStatus', '==', 'paired');
      listingsQuery = query(
        collection(firestore, `${API.users_endpoint}/${userId}/${API.listings_endpoint}`),
        webPublishedQuery,
        pairedStatusQuery,
        orderByCreatedAt,
        limitTo40
      );
    } else if (User_global.role === 'agent') {
      listingsQuery = query(
        collection(firestore, `${API.users_endpoint}/${userId}/${API.listings_endpoint}`),
        webPublishedQuery,
        orderByCreatedAt,
        limitTo40
      );
    }
    else if (User_global.role === 'agentAdmin') {
      listingsQuery = query(
        collection(firestore, `${API.users_endpoint}/${userId}/${API.listings_endpoint}`),
        webPublishedQuery,
        orderByCreatedAt,
        limitTo40
      );
    }

    return new Observable<any>((subscriber) => {
      const unsubscribe = onSnapshot(listingsQuery, (snapshot) => {
        const listings = [];
        snapshot.forEach((doc1) => {
          const data = doc1.data();
          const id = doc1.id;
          listings.push({ id, ...data });
        });
        subscriber.next(listings);
      }, (error) => {
        console.log(error);
        subscriber.error(error);
      });

      return () => unsubscribe();
    });
  }


  /*
  public getMyListeners(id) {
    this.uid = firebase.auth().currentUser.uid;
    return this.afs.collection('users').doc(`${this.uid}/`).collection('listings').doc(id)
  }
  */

  // remove this listing from microsite
  removeListingFromMicrosite(currentListing, userData) {
    console.log('remove listing from microsite called.');
    for (let i = 0; i < userData.webListings.length; i++) {
      if (currentListing.id === userData.webListings[i].id) {
        console.log('removing this listing from microsite.');
        userData.webListings.splice(i, 1);
        this.auth.updateUserDataProperty({ webListings: userData.webListings });
        return;
      }
    }
  }

  deleteImages(currentListing) {
    // delete all images
    try {
      for (let i = 0; i < currentListing.listingImages.length; i++) {
        this.imageService.deleteImage(currentListing.listingImages[i].thumbnailURL);
        this.imageService.deleteImage(currentListing.listingImages[i].imageURL);
      }
      if (currentListing.qrCodeImage) {
        this.imageService.deleteImage(currentListing.qrCodeImage);
      }
      if (currentListing.qrCodeImageKiosk) {
        this.imageService.deleteImage(currentListing.qrCodeImageKiosk);
      }
    }
    catch (err) {
      console.log('error deleting listing images');
    }
  }

  deleteListing(id) {
    return this.auth.deleteListing(id);
  }

  // public autoPopulatedFields(listingId) {
  //   return new Promise((resolve, reject) => {
  //     this.afs.collection(`${API.users_endpoint}`)
  //       .doc(this.auth.getUID()).collection(API.listings_endpoint).doc(listingId)
  //       .collection(API.processedData).doc(API.suggestions).ref.get().then(res => {
  //       if (res.exists) {
  //         resolve(res.data().listingDetails);
  //       } else {
  //         reject('no doc was found!');
  //       }
  //     },
  //       error => reject(error)
  //     );
  //   });
  // }


  public autoPopulatedFields(listingId): Observable<any> {
    const firestore = getFirestore();
    const userId = this.auth.getUID();
    const listingDocRef = doc(
      collection(firestore, `${API.users_endpoint}/${userId}/${API.listings_endpoint}`),
      listingId
    );
    const processedDataDocRef = doc(
      collection(listingDocRef, API.processedData),
      API.suggestions
    );

    return new Observable<any>((subscriber) => {
      const unsubscribe = onSnapshot(processedDataDocRef, (doc1) => {
        if (doc1.exists()) {
          subscriber.next(doc1.data());
        } else {
          subscriber.next(null);
        }
      }, (error) => {
        console.log(error);
        subscriber.error(error);
      });

      return () => unsubscribe();
    });
  }


}
