/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { ChangeDetectorRef, Component } from '@angular/core';
import { Platform, AlertController, ModalController, MenuController } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { AuthService } from './services/auth.service';

import { CurbUser, User_global } from './app.models';
import { NetworkProvider } from './services/network';

import { IntercomService } from './services/intercom.service';
import { CommonProvider } from './services/common';
import { UtilsService } from './services/utils.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { Subscription, merge, pluck, map } from 'rxjs';
import {
  DEFAULT_WELCOME_MESSAGE, DEFAULT_THANKS_MESSAGE, BLANK_SOCIAL_LINKS,
  CONNECTION_INVITE, MAX_APP_TIMEOUT, ANDROID_KEYBOARD_HEADER, MAX_QR_UPLOAD_RETRY
} from './constants';

import { environment } from '../environments/environment';
import { ImagesManagementService } from './services/images-management.service';
import { ConnectionsService } from './services/connections.service';
import { ActivatedRoute, NavigationError, NavigationExtras, Router } from '@angular/router';
import { Events } from './services/events.service';
import { I8nService } from './services/i8nService';
import { SqlService } from './services/sql.service';
import { Keyboard, KeyboardResizeMode } from '@awesome-cordova-plugins/keyboard/ngx';

import { QRPopupComponent } from './components/qr-popup/qp-popup';
import QRCodeStyling from 'qr-code-prettier';
import { QrCodeService } from './services/qr-code.service';
import { PdfEditorService } from './services/pdf.service';
import { DomSanitizer } from '@angular/platform-browser';

declare let window: any;
// TURN OFF LOGS FOR PRODUCTION
if (environment.production === true) {
  //  enableProdMode();
  console.log = () => { };
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  showSplash = true;
  rootPage: any;
  initialized = false;
  userDataSub: any;
  resumeSub: any;
  formattedUserPhone: any;
  userMainImage: any = '';
  // userMainImage: any = 'assets/imgs/colorLogo.png';
  showAddProfileImage = false;
  userLogoImage: any;
  appClosedTime: Date;
  syncUserDataTimeout: any = {};
  isLoggedIn = false;
  routeSubscription: any;
  dataTimerTest: any;
  qrCodeUploading = false;
  qrCodeImageData: any;

  userData: CurbUser = {
    username: '',
    phoneNumber: ''
  };

  listing = {
    listingId: '',
    guestFollowUp: false,
    listingLayout: null,
    loanOfficer: '',
    data: null,
    enhancedMode: false
  };

  sqlstorage: any = null;
  lastListingSubs: Subscription;
  isPortrait = false;
  isLandscape = false;
  networkAlert: any;
  roleAttempts = 0;

  keyboardSubs: Subscription;
  keyboardVisible = false;
  keyboardHeight = 0;
  cordova;


  public pages = [
    // { title: '', url: '/open-house', icon: '/paper-plane', enabled: false },
    { title: 'My Paired Lender', url: 'my-paired-lender', icon: 'person-add', enabled: true },
    { title: 'My Listings', url: 'my-listings', icon: 'reader', enabled: true },
    { title: 'My Leads', url: 'my-leads', icon: 'people', enabled: true },
    { title: 'My Link', url: 'my-link', icon: 'qr-code', enabled: true }
  ];

  public isQRCodeAvailable = 'qr-code-disabled';
  public showReferralButton = true;
  public referralLink = '';
  public showShare;
  public impersonatingUserEmail: string | undefined;

  constructor(
    public platform: Platform,
    public statusBar: StatusBar,
    public networkProvider: NetworkProvider,
    public splashScreen: SplashScreen,
    public nav: Router,
    private auth: AuthService,
    private intercomService: IntercomService,
    private common: CommonProvider,
    private alertCtrl: AlertController,
    public utils: UtilsService,
    private screen: ScreenOrientation,
    private imageService: ImagesManagementService,
    private connectionsService: ConnectionsService,
    private events: Events,
    private route: ActivatedRoute,
    public i8nService: I8nService,
    private sql: SqlService,
    private keyboard: Keyboard,
    private changeDetector: ChangeDetectorRef,
    private modalController: ModalController,
    private plt: Platform,
    private menu: MenuController,
    private qrService: QrCodeService,
    private pdfEditorService: PdfEditorService
  ) {
    this.impersonatingUserEmail = this.auth.isUserImpersonating();

    document.onkeydown = async (e) => {
      // CTRL + i
      if (e.code == "KeyI" && e.ctrlKey && await this.auth.isCurrentUserSuperUser() && !this.auth.isUserImpersonating()) {
        const userId = prompt("Enter ID of user to impersonate");

        if (!userId) {
          return;
        }

        const didSucceed = await this.common.withLoading(() => this.auth.impersonateUser(userId), { loadingText: "Accessing Account" });

        if (didSucceed) {
          window.location.reload();
        } else {
          this.common.toast('Failed to logging as user');
        }
      }
    };

    if (document.location.pathname === '/download-disclosure') {
      console.log('Starting app in lightweight mode');

      this.showSplash = false
      // Don't intialize anything since this view doesn't require it
      return;
    }

    this.intercomService.init()

    this.networkProvider.initializeNetworkService();
    // TURN OFF LOGS FOR PRODUCTION
    if (environment.production === true) {
      //  enableProdMode();
      console.log = () => { };
    }
    // try {
    //   if (!this.platform.is('cordova')) {

    //     const appCheck = firebase.appCheck();
    //     // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
    //     // key is the counterpart to the secret key you set in the Firebase console.
    //     appCheck.activate(
    //       environment.captcha_site_key,

    //       // Optional argument. If true, the SDK automatically refreshes App Check
    //       // tokens as needed.
    //       true);
    //   }
    // }
    // catch (err) {
    //   console.log('error with captcha: ' + err);
    // }

    this.cordova = this.platform.is('cordova');



    console.log('app constructor!');

    // EVENTS SUBSCRIBE

    events.subscribe('open:kioskmode', (data) => {
      // const navigationExtras: NavigationExtras = {
      //  queryParams: { listingId: data.id, listing: data.data }
      // };

      const navigationExtras: NavigationExtras = {
        queryParams: {
          listingLayout: data.data.propertyLayout
        }
      };

      this.nav.navigate(['kiosk-mode/' + `${data.id}`], navigationExtras);
    });

    events.subscribe('open:newListing', (data) => {
      const navigationExtras: NavigationExtras = {
        queryParams: { listing: 'new' }
      };
      this.nav.navigate(['listing-detail/new'], navigationExtras);
    });

    events.subscribe('open:pairAgents', (data) => {
      this.nav.navigate(['/my-paired-agents'], {});
    });

    events.subscribe('open:myProfile', () => {

      this.nav.navigate(['/my-profile'], {});
    });

    events.subscribe('profile:logout', () => {

      console.log('logout event captured');
      this.logoutUser();
    });

    events.subscribe('refresh:userData', (userId) => {
      console.log('refresh:userData');
      //  this.auth.subscribeToUserData(userId);
    });

    events.subscribe('refresh:userImages', () => {
      console.log('refresh:userImages');
      this.loadImageAndLogo();
    });

    events.subscribe('refresh:qrCode', () => {
      console.log('refresh:qrCode');
      this.downloadQRCode(User_global);
    });

    events.subscribe('login:userLogin', (data) => {
      console.log('login:userLogin event recieved for uid: ' + data);
      // alert('login event!');
      localStorage.setItem('uid', data);
      this.startUserSubscription();
    });

    if (this.platform.is('cordova')) {
      setTimeout(this.initializeApp.bind(this), 1500);
      // this.initializeApp();
    }
    else { // the web app needs a delay to read the user global info
      setTimeout(this.initializeApp.bind(this), 1500);
    }
    this.updateShareAndEarn();
  }

  async initAgentRole(userData) {
    console.log('init agent role called!');

    if (userData.firstPromoterReferralCode) {
      User_global.firstPromoterReferralCode = userData.firstPromoterReferralCode;
      this.showReferralButton = false;
      this.referralLink = environment.blockparty + '?via=' + userData.firstPromoterReferralCode;
    }

    User_global.ratingTime = userData.ratingTime || '';
    User_global.leadCount = userData.leadCount;

    if (userData.touchFreeSignIn === undefined) {
      userData.touchFreeSignIn = true;
    }
    if (userData.showCompany === undefined) {
      User_global.showCompany = true;
    }


    User_global.username = userData.username;
    this.formattedUserPhone = this.common.formatPhone(userData.phoneNumber); // format phone

    User_global.phoneNumber = userData.phoneNumber;
    User_global.email = userData.email;
    User_global.intercom = userData.intercom || {};
    User_global.brokerOfRecord = userData.brokerOfRecord || '';

    User_global.firstTimeListing = userData.firstTimeListing;


    if (userData.password) {
      User_global.password = '';
    }

    User_global.logoFile = userData.logoFile;
    User_global.zapierToken = userData.zapierToken;
    User_global.secondaryColor = userData.secondaryColor;
    User_global.primaryColor = userData.primaryColor;
    User_global.customWelcome = userData.customWelcome || DEFAULT_WELCOME_MESSAGE;
    User_global.customThanks = userData.customThanks || DEFAULT_THANKS_MESSAGE;

    User_global.customPrivacyPolicy = userData.customPrivacyPolicy || '';
    User_global.profilePhoto = userData.profilePhoto || [];
    User_global.micrositeUserId = userData.micrositeUserId || '';
    User_global.featuredLinks = userData.featuredLinks || [];
    User_global.socialLinks = userData.socialLinks || BLANK_SOCIAL_LINKS;

    User_global.webListings = userData.webListings || [];
    User_global.address = userData.address || {};
    User_global.locations = userData.locations || {};
    // enable for everyone
    userData.micrositeFeatureEnabled = true;
    User_global.micrositeFeatureEnabled = userData.micrositeFeatureEnabled;
    User_global.mostRecentLeadCreatedAt = userData.mostRecentLeadCreatedAt;

    // role for old accounts
    if (!userData.role) {
      //  this.auth.updateUserDataProperty({ role: 'agent' });
      userData.role = 'agent';
      User_global.role = 'agent';
    }
    else {
      User_global.role = userData.role;
    }
    console.log('App setup Role', userData.role);
    localStorage.setItem('user_role', userData.role);

    // add web published false for undefined
    if (userData.webPublished === undefined || userData.webPublished === null) {
      userData.webPublished = false;
      User_global.webPublished = false;
    }
    else {
      User_global.webPublished = userData.webPublished;
    }

    // add enableLenderCommunication true for undefined
    if (userData.enableLenderCommunication === undefined || userData.enableLenderCommunication === null) {
      userData.enableLenderCommunication = true;
      User_global.enableLenderCommunication = true;
      this.auth.updateUserDataProperty({ enableLenderCommunication: true });
    }
    else {
      User_global.enableLenderCommunication = userData.enableLenderCommunication;
    }

    // IF NO AUTO POPULATE EXISTS CREATE WITH TRUE
    if (userData.listingAutoPopulate === undefined) {
      userData.listingAutoPopulate = true;
      User_global.listingAutoPopulate = true;
      this.auth.updateUserDataProperty({ listingAutoPopulate: true });
    }

    User_global.synced = userData.synced || new Date();
    User_global.company = userData.company || '';
    User_global.title = userData.title || '';
    User_global.license = userData.license || '';
    User_global.showCompany = userData.showCompany; // enabled by default
    User_global.showTitle = userData.showTitle || false;
    User_global.showLicense = userData.showLicense || false;
    User_global.webLayout = userData.webLayout || 'full-grid';
    User_global.webSectionTitle = userData.webSectionTitle || 'Featured Properties';

    if (userData.guestSMSoptOut === undefined) {
      userData.guestSMSoptOut = false;
    }

    User_global.guestSMSoptOut = userData.guestSMSoptOut;
    User_global.touchFreeSignIn = userData.touchFreeSignIn;
    User_global.assignedMortgageQuestionId = userData.assignedMortgageQuestionId || '';

    if (userData.mortgageQuestionOptimization === undefined || userData.mortgageQuestionOptimization === null) {
      userData.mortgageQuestionOptimization = true;
    }

    User_global.mortgageQuestionOptimization = userData.mortgageQuestionOptimization;

    //setTimeout(() => { this.initQRCodeData(userData); }, 1000);
    this.initQRCodeData(userData);

    // GET CUSTOM MORTGAGE QUESTIONS

    this.auth.getCustomMortgageKioskQuestions().subscribe((snapshot) => {
      //  console.log('custom questions mortgage snapshot: ' + JSON.stringify(snapshot));
      const questions = JSON.parse(JSON.stringify(snapshot));
      this.auth.allMortgageQuestions = questions.filter(q => q.active === true);

      if (this.auth.allMortgageQuestions) {
        console.log('allMortgageQuestions: ' + JSON.stringify(this.auth.allMortgageQuestions));
      }

      if (!User_global.mortgageQuestionOptimization) {
        //  alert('assign default q!');
        this.auth.assignDefaultMortgageQuestion();
      }
      else if (this.auth.isAssignedQuestionInvalid()) {
        // alert('assign random Q!');
        // this.auth.assignRandomMortgageQuestion();
        this.auth.assignDefaultMortgageQuestion();
      }
    });

    if (this.platform.is('cordova') && this.networkProvider.checkInternet()) {
      if (userData.logoFile) {
        this.utils.updateLogoFile(userData.logoFile, true);
      }
    }

    if (!this.initialized) {

      this.loadImageAndLogo();
      this.initialized = true;
      this.auth.userInitialized = true;
      let lenderOverride = false;

      this.pages = [
        {
          title: 'My Paired Lender',
          url: 'my-paired-lender',
          icon: lenderOverride ? 'lock-closed' : 'person-add',
          enabled: !lenderOverride
        },
        {
          title: 'My Listings',
          url: 'my-listings',
          icon: 'reader',
          enabled: true
        },
        {
          title: 'My Leads',
          url: 'my-leads',
          icon: 'people',
          enabled: true
        },
        {
          title: 'My Link',
          url: 'my-link',
          icon: 'qr-code',
          enabled: true
        }
      ];

      console.log('app initialized true');
      setTimeout(() => { this.checkInviteURL(); }, 500);

      this.common.closeLoading();

      if (this.networkProvider.checkInternet()) {
        this.intercomService.showLauncher();
        this.intercomService.loginIntercomUser(User_global.email, this.auth.getCurrentUser().uid);
      }
      this.auth.userInitialized = true;

      this.auth.getLenderOverride().then(res => {
        lenderOverride = res;

        const pairedLenderPage = this.pages.find(page => page.url === 'my-paired-lender');
        if (pairedLenderPage && lenderOverride) {
          pairedLenderPage.enabled = !lenderOverride;
          pairedLenderPage.icon = 'lock-closed';
        }
      });
    }



    /*
    if (this.lastListingSubs) {
      this.lastListingSubs.unsubscribe();
    }
    */

    /*
    this.lastListingSubs = this.auth.getMyLatestListings().subscribe((res) => {
      if (res.success) {
        // open kisok mode
        this.listing.listingId = res.listingId;
        this.listing.data = res.listingData;
        this.listing.listingLayout = res.listingData.propertyLayout || 1;
        this.listing.guestFollowUp = (res.listingData.guestFollowUp) ? res.listingData.guestFollowUp : false;

        if (!this.platform.is('cordova')) {
          this.pages[0].enabled = false; // don't show first page for web
        }
        else if (res.listingData.address != null
          && res.listingData.address.title !== '') { this.pages[0].title = 'Launch Open House for ' + res.listingData.address.title; }
        else {
          const listingDate = new Date(res.listingData.createdAt);
          const formattedDate = (listingDate.getMonth() + 1) + '/' + listingDate.getDate() + '/' + listingDate.getFullYear();
          this.pages[0].title = 'Listing Created ' + formattedDate;
        }
      } else {
        // open add property window
        this.pages[0].title = 'Add Property Listing';
        this.listing.listingId = '0';
      }
    });
    */
  }

  async navigationClick(item) {

    if (!item.enabled && item.url === 'my-paired-lender') {

      const alertWin = await this.alertCtrl.create({
        header: this.i8nService.messages.teamAdminLockedFeatureHeader,
        message: this.i8nService.messages.teamAdminLockedFeatureMessage,
        buttons: [
          {
            text: 'OK',
            handler: data => {

            }
          }
        ]
      });
      await alertWin.present();
    }
  }

  async preLoadDisclosure() {

    if (!this.platform.is('cordova')) {
      return;
    }

    console.log('preLoadDisclosure called');
    const self = this;

    try {
      const res = await this.auth.getDisclosures();
      if (res && res.active === true) {
        const disclosureDoc = JSON.parse(JSON.stringify(res));
        console.log('disclosure doc: ' + JSON.stringify(res));

        await self.pdfEditorService.fetchAndModifyPdf(disclosureDoc.url, null);
      }
    } catch (err) {

      console.log('err in fetch doc: ' + err);
    }

  }


  loadImageAndLogo() {
    console.log('reload image and logo event called!');
    this.userMainImage = false;
    // LOAD USER IMAGE FIRST, OR LOGO, OR Curb Hero
    if (this.userData.profilePhoto && this.userData.profilePhoto[0]) {
      this.imageService.realImageData(this.userData.profilePhoto[0]).then((data) => {
        if (data) {
          this.showAddProfileImage = false;
          this.userMainImage = data;
          this.auth.loadedProfileImage = data;
          this.events.publish('refresh:userProfileImageLoaded'); // profile loaded event
        }
        else {
          this.showAddProfileImage = true;
          //  this.userMainImage = 'assets/imgs/colorLogo.png';
        }
      });
    }
    else {
      this.showAddProfileImage = true;
      //  this.userMainImage = 'assets/imgs/colorLogo.png';
    }

    if (this.userData.logoFile) {

      User_global.logoFile = this.userData.logoFile;
      console.log('loading user logo from app component. ' + this.userData.logoFile);
      setTimeout(() => {
        this.imageService.realImageData({ imageURL: this.userData.logoFile, imagePath: this.utils.logoPath(this.userData.logoFile) }).then((data) => {
          this.userLogoImage = data + '?=' + Math.random();
        });
      }, 1000);
    }

    /*
    setTimeout(() => {
      if (!this.userMainImage) {
        this.userMainImage = "assets/imgs/colorLogo.png";
      }
    }, 5000);
    */
  }

  initLenderRole(userData) {

    // alert('init lender: ' + JSON.stringify(userData));
    console.log('init lender role called!');

    User_global.username = userData.username;
    this.formattedUserPhone = this.common.formatPhone(userData.phoneNumber); // format phone

    User_global.role = userData.role;
    localStorage.setItem('user_role', User_global.role);
    User_global.regionalLender = userData.regionalLender || false;
    User_global.phoneNumber = userData.phoneNumber;
    User_global.email = userData.email;
    User_global.intercom = userData.intercom || {};

    User_global.leadCount = userData.leadCount;
    User_global.logoFile = userData.logoFile;
    User_global.zapierToken = userData.zapierToken;
    User_global.secondaryColor = userData.secondaryColor;
    User_global.primaryColor = userData.primaryColor;

    User_global.customPrivacyPolicy = userData.customPrivacyPolicy || '';
    User_global.profilePhoto = userData.profilePhoto || [];
    User_global.micrositeUserId = userData.micrositeUserId || '';
    User_global.featuredLinks = userData.featuredLinks || [];
    User_global.socialLinks = userData.socialLinks || BLANK_SOCIAL_LINKS;
    User_global.webListings = userData.webListings || [];

    User_global.enableAgentsListingsResultsFiltering = userData.enableAgentsListingsResultsFiltering || false;
    User_global.subscriptionStatus = userData.subscriptionStatus || false;
    User_global.referralCode = userData.referralCode || '';

    User_global.micrositeFeatureEnabled = userData.micrositeFeatureEnabled;
    User_global.webPublished = userData.webPublished;

    User_global.synced = userData.synced || new Date();
    User_global.company = userData.company || '';
    User_global.title = userData.title || '';
    User_global.license = userData.license || '';

    User_global.showCompany = userData.showCompany; // enabled by default
    User_global.showTitle = userData.showTitle || false;
    User_global.showLicense = userData.showLicense || false;

    User_global.guestSMSoptOut = userData.guestSMSoptOut;
    User_global.touchFreeSignIn = userData.touchFreeSignIn;

    User_global.address = userData.address || {};

    this.initQRCodeData(userData);

    if (this.platform.is('cordova') && this.networkProvider.checkInternet()) {
      if (userData.logoFile) {
        this.utils.updateLogoFile(userData.logoFile, true);
      }
    }

    if (!this.initialized) {

      this.pages = [
        { title: 'My Paired Agents', url: '/my-paired-agents', icon: 'person-add', enabled: true },
        { title: 'My Paired Listings', url: '/my-paired-listings', icon: 'reader', enabled: true },
        { title: 'My Leads', url: '/my-leads', icon: 'people', enabled: true },
        { title: 'My Link', url: '/my-link', icon: 'qr-code', enabled: true },
        //   { title: 'My Subscription', url: '/subscription', icon: 'card', enabled: true }
      ];

      this.loadImageAndLogo();

      console.log('app initialized true');
      this.initialized = true;
      this.auth.userInitialized = true;

      //  this.pages[0].enabled = true; // loging as agent disables it

      // DISABLE LENDER LOGIN ON NATIVE FOR NOW
      if (this.platform.is('cordova')) {
        this.pages = [];
        this.nav.navigate(['/my-profile'], { replaceUrl: true });
        return;
      }
      // this.nav.setRoot(MyLenderProfilePage);
      this.auth.startTokenSubscription();

      this.checkGuestUrl();
      this.common.closeLoading();

      if (this.networkProvider.checkInternet()) {
        this.intercomService.showLauncher();
        this.intercomService.loginIntercomUser(User_global.email, this.auth.getCurrentUser().uid);
      }
    }

    this.checkBillingUrl();
  }

  initAgentAdminRole(userData) {

    // alert('init lender: ' + JSON.stringify(userData));
    console.log('init agentAdmin role called!');

    User_global.username = userData.username;
    this.formattedUserPhone = this.common.formatPhone(userData.phoneNumber); // format phone

    User_global.role = userData.role;
    localStorage.setItem('user_role', User_global.role);
    User_global.regionalLender = userData.regionalLender || false;
    User_global.phoneNumber = userData.phoneNumber;
    User_global.email = userData.email;
    User_global.intercom = userData.intercom || {};

    User_global.logoFile = userData.logoFile;
    User_global.zapierToken = userData.zapierToken;
    User_global.secondaryColor = userData.secondaryColor;
    User_global.primaryColor = userData.primaryColor;

    User_global.customPrivacyPolicy = userData.customPrivacyPolicy || '';
    User_global.profilePhoto = userData.profilePhoto || [];
    User_global.micrositeUserId = userData.micrositeUserId || '';
    User_global.featuredLinks = userData.featuredLinks || [];
    User_global.socialLinks = userData.socialLinks || BLANK_SOCIAL_LINKS;
    User_global.webListings = userData.webListings || [];

    User_global.enableAgentsListingsResultsFiltering = userData.enableAgentsListingsResultsFiltering || false;
    User_global.subscriptionStatus = userData.subscriptionStatus || false;
    User_global.referralCode = userData.referralCode || '';

    User_global.micrositeFeatureEnabled = userData.micrositeFeatureEnabled;
    User_global.webPublished = userData.webPublished;

    User_global.synced = userData.synced || new Date();
    User_global.company = userData.company || '';
    User_global.title = userData.title || '';
    User_global.license = userData.license || '';

    User_global.showCompany = userData.showCompany; // enabled by default
    User_global.showTitle = userData.showTitle || false;
    User_global.showLicense = userData.showLicense || false;

    User_global.guestSMSoptOut = userData.guestSMSoptOut;
    User_global.touchFreeSignIn = userData.touchFreeSignIn;

    User_global.address = userData.address || {};

    this.initQRCodeData(userData);

    if (this.platform.is('cordova') && this.networkProvider.checkInternet()) {
      if (userData.logoFile) {
        this.utils.updateLogoFile(userData.logoFile, true);
      }
    }

    if (!this.initialized) {

      this.pages = [
        { title: 'Agents', url: '/my-paired-agents', icon: 'person-add', enabled: true },
        { title: 'Listings', url: '/my-paired-listings', icon: 'reader', enabled: true },
        { title: 'Leads', url: '/my-leads', icon: 'people', enabled: true },
        //   { title: 'My Link', url: '/my-link', icon: 'qr-code', enabled: true },
        //   { title: 'My Subscription', url: '/subscription', icon: 'card', enabled: true }
      ];

      this.loadImageAndLogo();

      console.log('app initialized true');
      this.initialized = true;
      this.auth.userInitialized = true;

      //  this.pages[0].enabled = true; // loging as agent disables it

      // DISABLE LENDER LOGIN ON NATIVE FOR NOW
      if (this.platform.is('cordova')) {
        this.pages = [];
        this.nav.navigate(['/my-profile'], { replaceUrl: true });
        return;
      }
      // this.nav.setRoot(MyLenderProfilePage);


      this.checkGuestUrl();
      this.common.closeLoading();

      if (this.networkProvider.checkInternet()) {
        this.intercomService.showLauncher();
        this.intercomService.loginIntercomUser(User_global.email, this.auth.getCurrentUser().uid);
      }
      this.auth.startTokenSubscription();
    }

    this.checkBillingUrl();
  }

  initQRCodeData(userData) {

    if (User_global.qrCodeOnlylink === userData.qrCodeOnlylink
      && userData.qrCodeOnlylink !== '') {
      console.log('qr code already loaded!');
      return;
    }

    console.log('initQRCodeData');

    User_global.qrCodeOnlylink = userData.qrCodeOnlylink || '';
    // ONLYLINK QR CODE IS MISSING, ADD IT
    if (!userData.qrCodeOnlylink && userData.micrositeUserId && userData.webPublished && !this.qrCodeUploading) {
      this.uploadQRCode(userData);
    }
    else if (userData.qrCodeOnlylink) {
      this.downloadQRCode(userData);
    }
  }

  // UPLOAD ONLYLINK QR CODE
  async uploadQRCode(userData) {

    const self = this;
    const fileName = 'profile' + '/' + this.auth.getUID() + '_qrCodeOnlylink';

    if (self.networkProvider.checkInternet() && userData.primaryColor) {
      const url1 = environment.onlylink + userData.micrositeUserId;
      const img = userData.logoFile || '';
      const primaryColor = userData.primaryColor || '';

      this.qrCodeUploading = true;

      await self.qrService.generateQRCode(url1, primaryColor, img, 'dot').then(blob => {
        if (blob) {
          self.imageService.uploadQRCodeBlobProfile(blob, fileName, false).then(res => {
            if (res) {
              User_global.qrCodeOnlylink = String(res);
              this.qrCodeUploading = false;
              self.auth.updateUserDataProperty({ qrCodeOnlylink: User_global.qrCodeOnlylink });
              self.downloadQRCode(User_global);
            }
          });
        }
        else {
          console.log('error creating qr code');
        }
      });
    }
    else {
      this.qrCodeUploading = false;
    }
  }

  downloadQRCode(userData) {
    const now = new Date();

    if (userData.qrCodeOnlylink) {
      this.isQRCodeAvailable = 'qr-code-enabled';
      this.imageService.realImageData({ imagePath: userData.qrCodeOnlylink, imageURL: userData.qrCodeOnlylink }).then((data) => {
        console.log('get qr code real image data result: ' + data);
        this.qrCodeImageData = data + '/?rand=' + now.getMilliseconds();
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.qrCodeImageData = null;
      });
    }
  }

  goToOnlylink(event) {
    event.stopPropagation();

    this.nav.navigate(['my-link']);
    this.menu.close();
  }

  showUpdateNoNetwork() {
    if (this.platform.is('cordova') && !this.networkProvider.checkInternet()) {
      this.networkAlert = this.alertCtrl.create({
        header: 'Network Connection Required For Update',
        message: this.i8nService.messages.pendingUpdatesTurnOnDataConnection,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Settings',
            handler: data => {
              if (this.networkProvider.openNativeSettings) {
                console.log('openNativeSettings is active');
                this.networkProvider.openNativeSettings.open('wifi').then(() => { });
              } else {
                console.log('openNativeSettings is not active!');
              }
            }
          }
        ]
      });

      const subs = this.networkProvider.network.onConnect().subscribe(() => {
        this.networkAlert.dismiss();
        this.common.startLoading();
      });
      this.networkAlert.onDidDismiss(() => {
        subs.unsubscribe();
      });
      this.networkAlert.present();
    }
  }

  updateOrientation() {
    this.isLandscape = [this.screen.ORIENTATIONS.LANDSCAPE_PRIMARY, this.screen.ORIENTATIONS.LANDSCAPE_SECONDARY, this.screen.ORIENTATIONS.LANDSCAPE].indexOf(this.screen.type) !== -1;
    const app = document.getElementsByTagName('ion-app')[0];
    const clz = app.getAttribute('class').split(' ').filter((cl) => !cl.startsWith('orientation'));
    if (this.isLandscape) {
      clz.push('orientation-landscape');
    } else {
      clz.push('orientation-portait');
    }
    app.setAttribute('class', clz.join(' '));
  }

  startUserSubscription() {
    console.log('start user subscription called');

    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.turnOnAirplaneMode();
    }

    setTimeout(() => { this.createUserData(); }, 250);

  }

  async createUserData() {
    const self = this;
    console.log('create user data called');
    //   this.common.closeLoading();
    //  this.userDataSub = this.auth.userDataObserver().subscribe((userData) => {
    const userLoggedin = await this.auth.isLoggedIn();

    console.log('user logged in: ' + JSON.stringify(userLoggedin));
    // alert('user logged in: ' + JSON.stringify(userLoggedin));
    // this.userDataSub = this.auth.subscribeToUser(userLoggedin.uid);

    if (this.userDataSub) {
      // this.auth.userData.unsubscribe();
      this.userDataSub.unsubscribe();
      this.userDataSub = null;
    }

    // DATA CHECK FOR IN APP BROWSER
    if (this.dataTimerTest) {
      clearTimeout(this.dataTimerTest);
    }
    self.dataTimerTest = setTimeout(async () => {
      this.common.closeLoading();
      if (this.utils.isInAppBrowser()) {
        const dbErrorAlert = await this.alertCtrl.create({
          header: 'Browser Error',
          message: this.i8nService.messages.inAppBrowserPluginIssue,
          buttons: [
            {
              text: 'OK',
              handler: data => {
                this.manualLogout();
              }
            }
          ]
        });
        await dbErrorAlert.present();
      }
    }, 8000);

    this.userDataSub = this.auth.subscribeToUser(this.auth.getUID()).subscribe((res) => {
      clearTimeout(self.dataTimerTest);

      // alert('subscribe result: ' + res);
      const userData = res;

      let updateImages = false;
      // if (this.userData &&
      //   (this.userData.profilePhotoLastUpdated === undefined ||
      //     this.userData.profilePhotoLastUpdated === null)) {

      //   updateImages = true;
      // }

      if (this.userData &&
        this.userData.profilePhotoLastUpdated !== undefined
        && res.profilePhotoLastUpdated !== undefined
        && this.userData.profilePhotoLastUpdated < res.profilePhotoLastUpdated) {
        // alert("Compared: " + this.userData.profilePhotoLastUpdated + " to " + res.profilePhotoLastUpdated);
        updateImages = true;
      }

      this.userData = userData;
      if (this.userData) {

        //    console.log('user data sub: ' + JSON.stringify(userData));
        if (userData) {
          // alert('user data: ' + JSON.stringify(userData));
          if (this.syncUserDataTimeout) {
            clearTimeout(this.syncUserDataTimeout);
          }

          //  console.log(`User data updated: ${JSON.stringify(userData)}`);
          if (this.networkAlert) {
            this.networkAlert.dismiss();
            this.networkAlert = null;
          }
          //  this.userData = userData;
          if (!this.userData.enableAgentsListingsResultsFiltering) {
            this.connectionsService.loadConnections();
          }
          else if (!this.userData.regionalLender) {
            this.connectionsService.loadConnections(false);
          }

          // hacky timeout for role not yet being available after signup process.
          if (this.userData.role === undefined || !this.userData.role) {
            this.roleAttempts++;
            if (this.roleAttempts < 5) {
              return setTimeout(() => {
                this.createUserData();
              }, 2000);
            }
            // Give up retry attempts and just go with agent (as per Neven instruction).
            this.userData.role = 'agent';
            this.finishInitWithRole(userData, updateImages);
          }
          else {
            this.finishInitWithRole(userData, updateImages);
          }

        } else {
          // alert('user not available');
          if (userData === undefined || userData === null) {

            // if data is unavailable for a few seconds, try to sync it
            this.syncUserDataTimeout = setTimeout(() => {
              this.auth.logErrors('USER DATA IS UNAVAILABLE');
              this.auth.recreateUserInDB();
            }, 5000);

            console.log('USER DATA IS NOT AVAILABLE');
          }
        }
      };
    }, (err) => {
      console.log('err in subscribe: ' + err);
      //window.location.reload();
    });
  }

  finishInitWithRole(userData: any, updateImages: boolean) {

    if (this.userData.role === 'lender') {
      this.initLenderRole(userData);
      this.isLoggedIn = true;
    }
    else if (this.userData.role === 'agent') {
      this.initAgentRole(userData);
      this.isLoggedIn = true;
    }
    else if (this.userData.role === 'agentAdmin') {
      //agentAdmin
      this.initAgentAdminRole(userData);
      this.isLoggedIn = true;
    }

    if (updateImages) {
      this.events.publish('refresh:userImages');
    }

    this.preLoadDisclosure(); // offline pdf disclosure
  }

  async checkUserTokenLogin() {

    const url = String(window.location);
    let firebaseToken = url.split('token=')[1];
    // const firebaseToken = this.route.snapshot.queryParamMap.get('token');

    // Check if "&section=integrations" exists in the string
    if (firebaseToken && firebaseToken.includes('&section=integrations')) {
      // If it exists, remove it using the replace method
      firebaseToken = firebaseToken.replace('&section=integrations', '');
    }

    console.log('token:' + firebaseToken);


    if (firebaseToken && !this.auth.getUID()) {
      try {
        const session = await this.auth.signInWithToken(firebaseToken);
        if (session) {
          if (session.user.uid) {
            this.startUserSubscription();
          }

        }
      }
      catch (err) {
        console.log('err with login token: ', err);
      }
    }
  }

  initializeApp() {

    // this.keyboardHeight = 450;
    const self = this;
    this.platform.ready().then(() => {


      // this.splashScreen.show();

      this.checkUserTokenLogin();

      this.connectionsService.initSettings();
      console.log('navigator agent: ' + navigator.userAgent);


      // const promise = window.indexedDB.databases();
      // promise.then((databases) => {
      //   console.log(databases);
      // });

      // 60 SEC MAX TIMEOUT FOR FILE UPLOADS
      // NEW 600/800 SECONDS MAX
      //  firebase.storage().setMaxOperationRetryTime(600000);
      //  firebase.storage().setMaxUploadRetryTime(800000);

      this.initErrorHandler();
      this.sql.initDb();

      this.keyboardSubs = merge(
        this.keyboard.onKeyboardShow().pipe(pluck('keyboardHeight')),
        this.keyboard.onKeyboardHide().pipe(map(() => 0))
      ).subscribe((keybVisible) => {
        console.log(`Keyboard visible: ${keybVisible}`);
        this.keyboardVisible = keybVisible !== 0;
        this.keyboardHeight = keybVisible;

        if (this.platform.is('android')) {
          this.utils.keyboardHeight = this.keyboardHeight + ANDROID_KEYBOARD_HEADER;
        } else {
          this.utils.keyboardHeight = this.keyboardHeight;
        }

        this.changeDetector.detectChanges();
      });


      // WARN PEOPLE WHEN CLOSING WEB APP ON BROWSER IN CERTAIN CASES
      window.addEventListener('beforeunload', (e) => {

        if (!this.networkProvider.checkInternet() && !this.platform.is('cordova')) {
          // Cancel the event
          e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
          // Chrome requires returnValue to be set
          e.returnValue = '';
        }
        else if (!this.platform.is('cordova')) {
          this.auth.canLogout().then((res) => {
            console.log('can logout');
            if (res) {
              return;
            } else {
              // Cancel the event
              e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
              // Chrome requires returnValue to be set
              e.returnValue = '';
            }
          });
        }
      });

      // alert('platform ready');
      setTimeout(() => { this.showSplash = false; }, 500);

      if (this.platform.is('cordova') && this.platform.is('ios')) {
        this.checkTrackingPermission();
      }

      console.log('platform ready!');

      this.plt.resize.subscribe(() => {
        this.updateShareAndEarn();
      });


      console.log('device is : ' + this.platform.platforms());

      // alert('platform ready ' + JSON.stringify(this.platform.platforms()));
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      if (this.platform.is('cordova')) {
        if (this.platform.is('android')) {
          this.statusBar.styleLightContent();
          this.statusBar.backgroundColorByHexString('#14294f');
          this.statusBar.overlaysWebView(false);

          // this.statusBar.hide();
        }
        else if (this.platform.is('ios')) {
          this.statusBar.styleLightContent();
          this.statusBar.backgroundColorByHexString('#14294f');
          this.statusBar.overlaysWebView(false);
          //  this.statusBar.hide();
        }

        // this.keyboard.disableScroll(false);
        // this.keyboard.setResizeMode(KeyboardResizeMode.Ionic);

        console.log('trying to hide splash screen');
        setTimeout(() => { self.splashScreen.hide(); }, 1000);
        this.utils.unlockOrientation();
        this.updateOrientation();

        this.screen.onChange().subscribe(() => {
          this.updateOrientation();
        });
      }

      if (!this.auth.getCurrentUser()) {
        console.log('no user');
        this.showCorrectStartPage();
      }

      this.checkUserSession();

      if (!this.platform.is('cordova')) {
        return;
      }

      this.platform.resume.subscribe((e) => {

        console.log('app RESUME event code!!!');

        const now = new Date();

        if (this.appClosedTime && (now.getTime() - this.appClosedTime.getTime() > MAX_APP_TIMEOUT)) {

          setTimeout(() => window.location.reload(true), 150);
          this.splashScreen.show();
          if (this.networkProvider.checkInternet()) {
            this.intercomService.hideLauncher();
          }
          console.log('app timeout TOO LONG, reload.');
        }
        else {
          // setTimeout(() => this.splashScreen.hide(), 500);
          try {
            if (this.platform.is('cordova') && this.networkProvider.checkInternet()) {
              this.networkProvider.goOnline();
            }
            //  if (this.platform.is('ios')) {
            this.initErrorHandler();
            //  }
          }
          catch (err) {
            console.log(err);
          }
        }
      });

      this.platform.pause.subscribe((e) => {
        console.log('app PAUSE event code!!!');
        this.appClosedTime = new Date();
      });
    });

    // this below for develop only
    // this.rootPage = KioskModePage;

    // check is user authenticated and redirect to
    // MyListingPage is the user is authenticated
    // LoginPage if the user is not authenticated

    this.utils.calcVh();
    this.utils.unlockOrientation();

  }

  checkTrackingPermission() {

    try {
      const self = this;
      const win = (res) => {
        console.log('win ' + JSON.stringify(res));

        if (res.trackingPermission === 0) {
          self.askTrackingPermission();
        }
      };
      const fail = (res) => {
        console.log('fail ' + JSON.stringify(res));
      };

      if (this.platform.is('cordova') && this.platform.is('ios')) {

        if (window.cordova) {
          console.log('trying to read permissions ');
          window.cordova.exec(win, fail, 'idfa', 'getInfo', []);
        }
      }
    }
    catch (err) {

    }
  }

  askTrackingPermission() {
    const win = (res) => {
      console.log('win2 ' + JSON.stringify(res));
    };
    const fail = (res) => {
      console.log('fail2 ' + JSON.stringify(res));
    };

    if (this.platform.is('cordova') && this.platform.is('ios')) {

      if (window.cordova) {
        console.log('trying to request permission ');
        window.cordova.exec(win, fail, 'idfa', 'requestPermission', []);
      }
    }
  }

  async checkUserSession() {
    // alert('check user session');

    this.auth.myAuthState().subscribe(user => {

      try {
        if (user) {
          console.log('in checkUserSession myAuthState ' + JSON.stringify(user));
          this.events.publish('login:userLogin', user.uid);
          this.events.publish('listing:menu');
        } else {
          console.log('no user');
          this.nav.navigate(['/login']);
        }
      } catch (err) {
        console.log('decide which page to show');
        this.common.closeLoading();

        this.showCorrectStartPage();
      }
    },
      error => {
        console.log('Error in auth subscription:', error);
        // Handle the error here as needed
        this.common.closeLoading();
        //  this.showCorrectStartPage();
      }
    );
  }

  showCorrectStartPage() {

    if (this.networkProvider.checkInternet()) {
      this.intercomService.showLauncher();
      this.intercomService.registerUnidentifiedUser();
    }


    const url = String(window.location);
    const variant = url.split('?variant=')[1];
    const referralCode = url.split('?connection_invite=')[1];
    const pageurl = url.split('?page=')[1];

    // OVERRIDE FOR DISCLOSURE DEEPLINK
    if (url.indexOf('download-disclosure') > -1) {
      return;
    }

    const route = this.route.snapshot.firstChild;
    let routeValue;
    if (route) {
      routeValue = String(route.url);
    }

    if (pageurl && pageurl.length > 0) {
      if (pageurl === 'login') {
        console.log('navigate to login');
        this.nav.navigate(['/login']);
      }
      else {
        console.log('navigate to signup');
        this.nav.navigate(['/sign-up']);
      }
    } else if (routeValue === 'login') {
      if (referralCode && referralCode.length > 0) {
        this.nav.navigateByUrl('/login?connection_invite=' + referralCode);
      } else {
        console.log('navigate to login 2');
        this.nav.navigate(['/login']);
      }
    } else {
      if (variant && variant.length > 0) {
        this.nav.navigateByUrl('/sign-up?variant=' + variant);
      } else if (referralCode && referralCode.length > 0) {
        this.nav.navigateByUrl('/sign-up?connection_invite=' + referralCode);
      } else {
        console.log('navigate to signup 2');
        this.nav.navigate(['/sign-up']);
      }
    }

    setTimeout(() => {
      this.common.closeLoading();
    }, 1000);
  }

  pushURLhistory(page) {
    setTimeout(() => {
      window.history.pushState(page, 'BlockParty', '/');
    }, 2000);
  }

  logoutUser() {
    this.common.startLoading('Logging out...');
    this.initialized = false; // reset app

    if (this.networkProvider.checkInternet()) {
      this.intercomService.logout();
    }
    // this.nav.setRoot(SignUpPage); // used to be login page
    if (this.lastListingSubs) {
      this.lastListingSubs.unsubscribe();
    }

    if (this.auth.userData) {
      this.auth.userData.unsubscribe();
    }

    this.userDataSub.unsubscribe();

    localStorage.clear();
    window.history.replaceState({}, document.title, '/');

    setTimeout(() => {
      window.location.reload();
    }, 600);
  }

  manualLogout() {
    this.auth.signOutUser().then(() => {
      this.logoutUser();
    });
  }

  openMyProfile(section: string = '') {
    console.log('open my profile');
    console.log('this.userData is ' + JSON.stringify(this.userData));
    const navigationExtras: NavigationExtras = {
      queryParams: { section }
    };

    if (this.userData.role === 'lender') {
      this.nav.navigate(['/my-profile'], navigationExtras);
    }
    else if (this.userData.role === 'agent') {
      this.nav.navigate(['/my-profile'], navigationExtras);
    }
    else if (this.userData.role === 'agentAdmin') {
      //agentAdmin
      this.nav.navigate(['/my-profile'], navigationExtras);
    }
  }

  openPage(page) {
    // Reset the content nav to have just this page
    // we wouldn't want the back button to show in this scenario
    if (page.name === 'OpenHousePage') {
      const listingId = this.listing.listingId;
      const navigationExtras: NavigationExtras = {
        queryParams: { listingId, listing: this.listing.data }
      };
      const navigationExtras2: NavigationExtras = {
        queryParams: { listing: 'new' }
      };
      if (listingId !== '0') {
        this.nav.navigate(['/kiosk-mode'], navigationExtras);
      } else {
        this.nav.navigate(['/listing-detail'], navigationExtras2);
      }
    }
    else if (page === 'MySubscriptionPage') {
      if (this.auth.getUserAuthToken()) {
        this.common.openSubscription(environment.stripeSubscription + this.auth.getUserAuthToken());
      }
    }
    else {
      this.nav.navigate(page.url);
    }
  }

  terms() {
    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }
    this.common.openLink('https://curbhe.ro/terms/');
  }

  privacy() {
    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }
    this.common.openLink('https://curbhe.ro/privacy-policy/');
  }

  /*
    onPageWillLeave(): void {
      console.log('on page leave fired!');
      // alert('on page leave fired!');
      if (this.userDataSub) {
        this.auth.userData.unsubscribe();
        this.userDataSub = undefined;
      }
      if (this.resumeSub) {
        this.resumeSub.unsubscribe();
        this.resumeSub = null;
      }
    }
  */
  checkInviteURL() {
    console.log('checkInviteURL');
    const self = this;
    // check if it's a connection invite
    const url = String(window.location);
    const referralCode = url.split('?connection_invite=')[1];

    if (referralCode && this.userData.role !== 'lender') {

      const isReferralCodeAdmin = referralCode.endsWith('admin');

      window.history.pushState('', 'BlockParty', '/'
        + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
      console.log('referral code is : ' + referralCode);
      self.common.startLoading();

      self.auth.sendConnectionInvite(referralCode, CONNECTION_INVITE).then(res => {
        if (res === 'SUCCESS') {

          if (isReferralCodeAdmin) {
            self.common.toast('Connect with this admin?', 8000);
            console.log('navigate to my profile.');
            self.nav.navigate(['/my-profile']);
          }
          else {
            self.common.toast('Connect with this lender?');
            console.log('navigate to my paired lenders.');
            self.nav.navigate(['/my-paired-lender']);
          }
        }
        else {
          self.common.toast(this.i8nService.messages.invitationLinkUnrecognised);
        }

        setTimeout(() => {
          self.common.closeLoading();
        }, 2000);
      }).catch(err => {
        console.log('error using invite code' + err);
        self.nav.navigate(['my-listings']);
        self.common.closeLoading();
      });
    }
    else if (referralCode && this.userData.role === 'agent') {
      this.rerouteMainPage();
    }
    else if (referralCode && this.userData.role === 'agentAdmin') {
      //agentAdmin
      window.history.pushState('', 'BlockParty', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
      console.log('referral code is : ' + referralCode);
      self.common.startLoading();

      self.auth.sendConnectionInvite(referralCode, CONNECTION_INVITE).then(res => {
        if (res === 'SUCCESS') {
          self.common.toast('Connect with this lender?');
        }
        else {
          self.common.toast(this.i8nService.messages.invitationLinkUnrecognised);
        }

        console.log('navigate to my paired lenders');
        self.nav.navigate(['/my-paired-lender']);

        setTimeout(() => {
          self.common.closeLoading();
        }, 2000);
      }).catch(err => {
        console.log('error using invite code' + err);
        self.nav.navigate(['my-listings']);
        self.common.closeLoading();
      });
    }
    // delete the referal code if lender
    if (referralCode && this.userData.role === 'lender') {
      window.history.pushState('', 'BlockParty', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
    }
    else if (this.userData.role === 'agent') {
      this.checkGuestUrl();

      if (this.platform.is('cordova')) {
        if (this.userData.role === 'agent') {
          this.nav.navigate(['my-listings']);
        }
      }

      //agent
    }
    else if (this.userData.role === 'agentAdmin') {
      //agentAdmin
      window.history.pushState('', 'BlockParty', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
    }
  }

  checkBillingUrl() {
    const self = this;
    // check if it's a connection invite
    const url = String(window.location);
    const billing = url.split('?billing=')[1];

    if (billing) {
      window.history.pushState('', 'BlockParty', '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]);
      self.common.toast('Subscription active. Share your invite link to pair with agents.', 3500);
    }
  }

  checkGuestUrl() {
    if (this.platform.is('cordova')) {
      return;
    }

    console.log('checking url redirect my listings ' + String(window.location));
    const url = String(window.location);

    const guestId = url.split('gst=')[1];
    const navigationExtras: NavigationExtras = {
      queryParams: { gst: guestId }
    };

    if (guestId) {
      console.log('gst found' + guestId);
      this.nav.navigate(['/my-leads'], navigationExtras);
    }
    else {
      this.rerouteMainPage();
    }
  }

  rerouteMainPage() {
    console.log('reroute Main page called');
    const route = this.route.snapshot.firstChild;

    const url = String(window.location);

    const guestId = url.split('gst=')[1];
    const navigationExtras: NavigationExtras = {
      queryParams: { gst: guestId }
    };

    if (route) {
      console.log('route param: ' + route.url);
      const routeValue = String(route.url);

      if (!routeValue || routeValue === 'login' || routeValue === 'sign-up') {
        if (this.userData.role === 'agent') {
          if (guestId) {
            this.nav.navigate(['my-leads'], navigationExtras);
          }
          else {
            this.nav.navigate(['my-listings']);
          }
        }
        else if (this.userData.role === 'lender') {
          //agent
          this.nav.navigate(['my-paired-agents']);
        }
        else if (this.userData.role === 'agentAdmin') {
          //agentAdmin
          this.nav.navigate(['my-paired-agents']);
        }
      }
    }
    else {
      if (this.userData.role === 'agent') {
        if (guestId) {
          this.nav.navigate(['my-leads'], navigationExtras);
        }
        else {
          this.nav.navigate(['my-listings']);
        }
      }
      else if (this.userData.role === 'lender') {
        //agent
        this.nav.navigate(['my-paired-agents']);
      }
      else if (this.userData.role === 'agentAdmin') {
        //agentAdmin
        this.nav.navigate(['my-paired-agents']);
      }
    }
  }

  initErrorHandler = () => {
    console.log('initErrorHandler called.');
    window.onerror = (error: any) => {
      const messagesToLook = ['Indexed Database', 'The database connection is closing',
        'IDBDatabase', 'in-progress transaction', 'INTERNAL ASSERTION FAILED'];

      const errMessage = typeof error === 'string' ? error : error.message;

      // alert('oneerror: ' + errMessage);

      for (const message of messagesToLook) {
        if (errMessage.indexOf(message) >= 0) {
          this.auth.logErrors(`Error Firebase DB throw err: ${JSON.stringify(errMessage)} | ${errorToString(error)}`, true);
          //  this.common.showDBWriteError(true);

          if (message === 'The database connection is closing') {

            this.common.showDBWriteError(true);
            return;
          }
        }
      }

      const chunkErrorMessages = ['ChunkLoadError'];

      for (const message of chunkErrorMessages) {
        if (errMessage.indexOf(message) >= 0) {
          this.auth.logErrors('Error CHUNK LOAD ERROR throw err: ' + JSON.stringify(errMessage), true);
          //   this.common.showDBWriteError(true);
          return;
        }
      }
    };
  };

  async openYourQRCode(event) {
    event.stopPropagation();

    const modal = await this.modalController.create({
      component: QRPopupComponent,
      cssClass: 'dialog-modal',
      componentProps: {
        url: environment.onlylink + this.userData.micrositeUserId,
        common: this.common,
        slug: environment.onlylink + this.userData.micrositeUserId,
        listingQR: null,
        listingQRKiosk: null,
        qrOnlylink: this.userData.qrCodeOnlylink,
        cordova: this.plt.is('cordova'),
        type: 'your-qr'
      }
    });

    await modal.present();
  }


  copyReferralCode() {
    this.common.copyToClipboard(this.referralLink);
  }

  async referralInfoText() {
    const callbackURL = 'https://juuj.me/referral-program';
    const alert = await this.alertCtrl.create({
      header: 'Referral Program Info',
      message: this.i8nService.messages.programReferralInfo,
      buttons: [
        {
          text: 'OK',
          role: 'ok',
          handler: data => {

          }
        },
        {
          text: 'Help Section',
          role: 'help',
          handler: data => {

            if (!this.networkProvider.checkInternet()) {
              this.networkProvider.noNetworkModal();
              return;
            }
            this.common.openLink(callbackURL);
          }
        }
      ]
    });

    await alert.present();
  }

  // async reloadNewAppVer() {
  //   if (this.initialized) {
  //     return;
  //   }

  //   const alert = await this.alertCtrl.create({
  //     header: 'New App Versions',
  //     message: this.i8nService.messages.newAppVersion,
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel',
  //         handler: data => {

  //         }
  //       },
  //       {
  //         text: 'Update',
  //         role: 'update',
  //         handler: data => {

  //           //  localStorage.clear();
  //           window.location.reload();

  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  joinReferralProgram() {
    this.common.openLink('https://curbhe.ro/partner/');
  }

  private handleRouterErrors(event: NavigationError) {
    if (event.error.name === 'ChunkLoadError') {
      this.auth.logErrors('handleRouterErrors: ' + JSON.stringify(event), true);
      //  this.common.showDBWriteError(true);
    }
  }

  private updateShareAndEarn() {
    const height = this.platform.height();
    if (this.showReferralButton && (height < 660)) {
      this.showShare = false;
    } else if (height < 640) {
      this.showShare = false;
    } else {
      this.showShare = true;
    }
  }

  async removeImpersonation() {
    await this.common.withLoading(() => this.auth.removeUserImpersonation(), { loadingText: 'Switching Account Back' })
    window.location.reload();
  }
}

function errorToString(error: any) {
  return JSON.stringify(error, Object.getOwnPropertyNames(error), 2)
}