/* eslint-disable max-len */
/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */

import { Component, Input, OnInit, Output, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { User_global } from '../../app.models';
import { ImagesManagementService } from '../../services/images-management.service';
import { PropertyLayout } from '../../constants';
import { UtilsService } from '../../services/utils.service';
import { Subscription, merge } from 'rxjs';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
export const TEXT_MODE_INTRO = 'intro';
export const TEXT_MODE_OUTRO = 'outro';
import Swiper, { EffectCube, EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';
Swiper.use([EffectCube, EffectFade, Navigation, Pagination]);

@Component({
  selector: 'app-property-layout',
  templateUrl: 'property-layout.component.html',
  styleUrls: ['property-layout.component.scss']
})
export class PropertyLayoutComponent implements OnInit, OnChanges, OnDestroy {

  public static blurredImage = '';
  public static listingImagesArr = [];

  @Input('showOverlay') showOverlay = false;
  @Input('text') textMode = TEXT_MODE_INTRO;
  @Input('mode') mode: number;
  @Input('index') index: number;
  @Input('listing') listing: any;
  @Output('onClick') onClick = new EventEmitter();

  public reference = PropertyLayoutComponent;


  layoutClass: string;
  userData: any;
  textColor: string;
  hasImage: boolean;
  imageData = '';
  leftColor: string;
  rightColor: string;
  leftImage = false;
  rightImage = false;
  textValue: string;
  hasBlur = false;
  layoutWidth: number;
  layoutHeight: number;
  logoFile: string;
  resizeSub: Subscription;

  blurredImage = '';
  listingImagesArr = [];

  loopConfig: SwiperOptions = {
    navigation: false,
    lazy: {
      loadPrevNext: true,
    },
    speed: 600,
    parallax: true,
    autoplay: {
      delay: 8500,
      disableOnInteraction: false
    },
    slidesPerView: 1,
    on: {
      activeIndexChange(swiper) {
        console.log('active slide change ' + swiper);
        const slider = this;
        if (PropertyLayoutComponent.listingImagesArr.length) {
          try {
            PropertyLayoutComponent.blurredImage = PropertyLayoutComponent.listingImagesArr[slider.activeIndex];
          }
          catch (err) {
            console.log(err);
            PropertyLayoutComponent.blurredImage = PropertyLayoutComponent.listingImagesArr[0];
          }
        }
      }
    }
  };

  constructor(
    private images: ImagesManagementService,
    private plt: Platform,
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef,
    private screen: ScreenOrientation
  ) {
    const mappedMode = this.utilsService.mapPropertyLayout(this.mode);
    const propertyLayout = this.utilsService.calcPropertyLayout(mappedMode);
    this.layoutWidth = propertyLayout.width;
    this.layoutHeight = propertyLayout.height;
  }

  ngOnChanges(changes: any) {
    if (this.userData) {
      if (this.textMode === TEXT_MODE_INTRO) {
        this.textValue = this.userData.customWelcome;
      } else {
        this.textValue = this.userData.customThanks;
      }
    }
  }
  async ngOnInit() {
    let mappedMode = this.utilsService.mapPropertyLayout(this.mode);
    this.layoutClass = `type${mappedMode}`;
    this.userData = User_global;
    this.leftColor = this.userData.primaryColor;
    this.rightColor = this.userData.secondaryColor;
    this.textColor = this.userData.primaryColor;

    document.body.style.setProperty('--primary', this.userData.primaryColor);
    document.body.style.setProperty('--secondary', this.userData.secondaryColor);

    if (this.textMode === TEXT_MODE_INTRO) {
      this.textValue = this.userData.customWelcome;
    } else {
      this.textValue = this.userData.customThanks;
    }
    this.createPropertyListingImages(this.listing);

    switch (mappedMode) {
      case PropertyLayout.LANDSCAPE_1: {
        this.hasBlur = this.hasImage;
        this.rightImage = this.hasImage;
        if (this.hasImage) {
          this.textColor = 'white';
        }
        break;
      }
      case PropertyLayout.LANDSCAPE_2: {
        this.hasBlur = this.hasImage;
        this.rightImage = this.hasImage;
        if (this.hasImage) {
          this.textColor = 'white';
        }
        break;
      }
      case PropertyLayout.PORTRAIT_1: {
        this.leftImage = this.hasImage;
        if (this.hasImage) {
          this.rightColor = this.leftColor;
        }
        this.textColor = this.userData.primaryColor;
        break;
      }
      case PropertyLayout.PORTRAIT_2: {
        this.leftImage = this.hasImage;
        if (this.hasImage) {
          this.rightColor = this.leftColor;
        }
        this.textColor = this.userData.primaryColor;
        break;
      }
    }

    console.log('this.hasBlur = this.hasImage ' + this.hasBlur + ' ' + this.hasImage);

    if (this.userData.logoFile) {
      this.images.realImageData({
        imageURL: this.userData.logoFile,
        imagePath: this.utilsService.logoPath(this.userData.logoFile)
      }, true).then((data) => {
        this.logoFile = `url(${data})`;
      });
    }
    this.resizeSub = merge(this.screen.onChange(), this.plt.resize).subscribe(() => {
      mappedMode = this.utilsService.mapPropertyLayout(this.mode);
      const propertyLayout = this.utilsService.calcPropertyLayout(mappedMode);
      if (this.layoutWidth < propertyLayout.width || this.layoutHeight < propertyLayout.height) {
        this.layoutWidth = propertyLayout.width;
        this.layoutHeight = propertyLayout.height;
        this.changeDetector.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    if (this.resizeSub && !this.resizeSub.closed) {
      this.resizeSub.unsubscribe();
    }

    this.listingImagesArr = [];
    this.blurredImage = '';
  }

  handleClick() {
    this.onClick.emit();
  }

  createPropertyListingImages(listing) {
    const imagesList = listing.listingImages;
    if (this.textMode === TEXT_MODE_INTRO) {
      PropertyLayoutComponent.listingImagesArr = [];
      if (imagesList !== undefined) {
        if (imagesList.length === 0) {
          //  this.listingImagesArr.push(imagesList);
        } else {
          this.hasImage = imagesList[0].imageURL || imagesList[0].imagePath;
          for (let imageIndex = 0; imageIndex < imagesList.length; imageIndex++) {
            //   this.hasImage = imagesList[imageIndex].imageURL || imagesList[imageIndex].imagePath;
            this.images.realImageData(imagesList[imageIndex], true).then((data) => {
              this.imageData = `url(${data})`;
              this.listingImagesArr.push(this.imageData);
              PropertyLayoutComponent.listingImagesArr.push(this.imageData);
              if (imageIndex === 0) {
                if (this.listingImagesArr.length > 0) {
                  this.blurredImage = this.listingImagesArr[0];
                  PropertyLayoutComponent.blurredImage = PropertyLayoutComponent.listingImagesArr[0];
                }
              }
            }).catch((error) => {
              this.imageData = '';
            });
          }
        }
      }
    }
    else {
      if (imagesList !== undefined) {
        if (imagesList.length === 0) {
          //  this.listingImagesArr.push(imagesList);
        } else {
          this.hasImage = imagesList[0].imageURL || imagesList[0].imagePath;
          this.images.realImageData(imagesList[0], true).then((data) => {
            this.imageData = `url(${data})`;
            this.listingImagesArr.push(this.imageData);
          }).catch((error) => {
            this.imageData = '';
          });
        }
      }
    }
  }
}
