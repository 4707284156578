import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../environments/environment';
import algoliasearch from 'algoliasearch/lite';

@Component({
  selector: 'algolia-search',
  templateUrl: './algolia-search.html',
  styleUrls: ['algolia-search.scss'],
})
export class AlgoliaSearchComponent implements OnInit {
  algoliaConfig = {};
  algoliaParams = {};

  @Input() searchFor: 'lender' | 'agentAdmin';
  @Input() shouldFocus: boolean = false;
  @Output() itemClicked = new EventEmitter<any>();
  @Output() searchChanged = new EventEmitter<string>();
  searchBoxIsDirty = false;
  displayResults = true;

  searchText = ''

  ngOnInit() {
    this.setupAlgolia(this.searchFor);
    console.log('Initialized algolia to search for', this.searchFor);

    setTimeout(() => {
      console.log('autoFocus3', this.shouldFocus);
      if (this.shouldFocus) {
        //   this.mainInput.setFocus();
      }
    }, 1000);
  }

  setupAlgolia(searchFor: 'lender' | 'agentAdmin') {
    const baseConfig: any = {
      searchClient: algoliasearch(
        'N26A8PC608',
        '068c90fcb4bdad125a840343a2725949'
      ),
      searchFunction: (helper) => {
        // Prevent empty searches, including the one that trigger as soon as the component loads
        if (helper.state.query === '') {
          return;
        }

        helper.search();
      },
    };

    if (searchFor === 'lender') {
      baseConfig.indexName = environment.production
        ? 'public_profiles_prod_lenders'
        : 'public_profiles_dev_lenders';
      baseConfig.initialUiState = {
        [baseConfig.indexName]: {
          refinementList: {
            role: ['lender'],
            enableLenderCommunication: ['true'],
          },
        },
      };
    } else if (searchFor === 'agentAdmin') {
      baseConfig.indexName = environment.production
        ? 'public_profiles_prod'
        : 'public_profiles_dev';
      baseConfig.initialUiState = {
        [baseConfig.indexName]: {
          refinementList: {
            role: ['agentAdmin'],
            enableLenderCommunication: ['true'],
          },
        },
      };
    }

    this.algoliaConfig = baseConfig;
    this.algoliaParams = {};
  }

  algoliaSearchInputChanged(searchText: string) {
    this.searchBoxIsDirty = true;
    this.searchText = searchText;
    this.searchChanged.emit(searchText);

    if (searchText === '') {
      this.displayResults = false;
    } else {
      this.displayResults = true;
    }
  }

  _itemClicked(item) {
    this.itemClicked.emit(item);
  }

  getNoResultsMessage() {
    if (this.searchFor === 'lender') {
      return `Enter a lender's email to invite them`;
    } else if (this.searchFor === 'agentAdmin') {
      return `Enter your admin's email to invite them`;
    }
  }
}
