/* eslint-disable space-before-function-paren */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/prefer-for-of */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */

import { Component, ViewChild, ChangeDetectorRef, OnDestroy, ViewEncapsulation, Renderer2 } from '@angular/core';

import { NavParams, AlertController, Platform, MenuController, IonInput, NavController, PopoverController, ModalController, IonRouterOutlet } from '@ionic/angular';
import { CurbUser, User_global, Listing, SurveyData, MortgageQuestion, QuestionsAnswers, MultipleChoiceKioskOption, DisclosureDoc } from '../../app/app.models';

import { AuthService } from '../services/auth.service';
import { SMSService } from '../services/sms.service';
import { NetworkProvider } from '../services/network';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { CommonProvider } from '../services/common';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { IntercomService } from '../services/intercom.service';
import { ListingDataProviderService } from '../services/listing-data-provider.service';
import { EMAIL_ID_DOMAINS, EMAIL_REGEX, GUEST_NOTE_PLACEHOLDER } from '../constants';
import { UtilsService } from '../services/utils.service';
import { Subscription, merge, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { ConnectionsService } from '../services/connections.service';
import { ImagesManagementService } from '../services/images-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '../services/analytics.service';
import { EditNotesPage } from '../components/edit-notes/edit-notes';
import { GuestService } from '../services/guest.service';
import { SqlService } from '../services/sql.service';
import { SwiperComponent } from 'swiper/angular';
import Swiper, { Autoplay, Pagination, Navigation, SwiperOptions, EffectCreative, Parallax } from 'swiper';
Swiper.use([Autoplay, EffectCreative, Parallax, Pagination, Navigation]);
import { AnimationController } from '@ionic/angular';
import { I8nService } from '../services/i8nService';
import intlTelInput from 'intl-tel-input';
import { AnimationBuilder, AnimationFactory, AnimationPlayer, animate, style } from '@angular/animations';
import { QrCodeService } from '../services/qr-code.service';

import { PdfEditorService } from './../services/pdf.service';
import { DomSanitizer } from '@angular/platform-browser';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { EncryptionService } from '../services/encryption.service';


declare let window;
declare let pdfjsLib: any; // Declare the PDF.js global variable


const DEFAULT_SCREENS = 3;
const DEFAULT_TAP_ANYWHERE_RANGE = 9;
const PARTIAL_ENTRY = 'Partial Entry';

export const TEXT_MODE_INTRO = 'intro';
export const TEXT_MODE_OUTRO = 'outro';


@Component({
  selector: 'app-kiosk-mode',
  templateUrl: 'kiosk-mode.html',
  styleUrls: ['kiosk-mode.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class KioskModePage implements OnDestroy {

  public static isSlideableMenuOpen = false;
  public static MenuEnabled = 'menu-open';

  @ViewChild('nameKiosk') nameKiosk: IonInput;
  @ViewChild('phoneKiosk') phoneKiosk: IonInput;
  @ViewChild('emailKiosk') emailKiosk: IonInput;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('propertylayout', { static: false }) propertylayout?: SwiperComponent;

  inputInterval;
  defaultCountryCode;

  swiper1: Swiper;
  config: SwiperOptions = {
    navigation: false,
    // pagination: { clickable: false, type: 'progressbar' },
    lazy: {
      loadPrevNext: true,
    },
    // effect:'creative',
    // creativeEffect: {
    //   prev: {
    //     shadow: true,
    //     translate: [0, 0, -400]
    //   },
    //   next: {
    //     translate: ['100%', 0, 0]
    //   }
    // },
    speed: 600,
  };
  emailConfig: SwiperOptions = {
    slidesPerView: 4,
    spaceBetween: 0,
    navigation: false
  };

  pdfSrc: any;
  disclosureDoc: DisclosureDoc;
  screensMap = [];

  public loadProgress: number;
  public currentScreen: number;
  public numberOfScreens: number;
  public showPrev: boolean;
  public showNext: boolean;
  public isNextValid = true;
  public isMobilePhone: boolean;
  public endProgress: number;
  public currentListing: Listing;
  public currentListingId: string;
  public listingLoaded = false;
  public guestSurveyData: SurveyData;
  public guest: any;
  public isFirstSlide = 'invisible';
  public selectedCheckbox: any = {};



  highlightedChoice: any; // multichoice highlighted option
  lenderUserLogo: any;
  customMortgageQuestion: any;
  keyboardTimeout: any;
  layoutWidth: number;
  layoutHeight: number;
  kioskFooterMortgage = 'By selecting "Yes", you consent and agree to the following Terms of Use and Privacy Policy. You also agree that loan officer may call and/or text you about your loan inquiry at the number or email address you provided. Message and data rates may apply.';
  guestPrivacyPolicyLink = '';
  loanOfficer: any;
  loanOfficerReviews: any = [];
  mortgageQuestionDetails: MortgageQuestion = {};
  loaded = false;
  loadInterval: any;
  treatNextAsHandleNo = false;
  qrCodeImageData: any;
  qrKioskURL: any;
  qrCodeAgentSignin: any;

  enableInput = true;
  listingImagesArr: any = [];
  currentOfflineGuestId; // guest id for offline save
  isMenuOpen = false;

  public typeOfLayout: number;
  public orientation = 'landscape';
  public SmsDisclaimer = 'Message and data rates may apply';

  public NUMBER_REGEX = `[0-9]*`;
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  userDataSub: any;
  userData: CurbUser = {
    username: '',
    phoneNumber: '',
    primaryColor: '',
    secondaryColor: '',
    logoFile: ''
  };

  isLastSlide = false;
  rawPhone: string;
  tapAnywhereUIRange: number[] = [];
  keyboardSubs: Subscription;
  keyboardVisible = false;
  keyboardHeight = 0;
  backButtonSubs: any;
  tempSMSoptout: boolean;
  hideProgress = false;
  onlineStatus = true;
  env: any = environment;
  currentPopover: any;
  placeholderNote: string;
  prevID: any;
  prevData: SurveyData;
  anyReviewEnabled: any = false;
  layoutId;
  profileImgShow: boolean;
  dataIsLoading = false;
  arrowColorPrimary: string;
  options: any;

  public reference = KioskModePage;
  textMode: string = TEXT_MODE_INTRO;
  textValue: string;
  emailDomains = EMAIL_ID_DOMAINS;

  public enableKioskOptIn = false;
  public checkedOptIn = false;
  public SmsOptInDisclaimer: string;
  public MCQDisclaimer = 'You may receive up to 5 msgs/mo. Txt and data rates may apply. Reply STOP to end or HELP for help.';

  public enableEmailList = 'emailList-invisible';
  public selectedDomain = '';
  public stopTryingToFocus = false;
  public disableButton = false;
  public loadProgressBar = 'donotloadProgressBar';
  private input;
  private iti;
  private lenderOverride = false;

  public initialized = false;

  private kioskLockQuestion1 = {
    isActive: true,
    question: 'Who is your real estate agent?',
    type: 'multipleChoice',
    answers: ['Share agent info', 'I am an agent', 'I\'m not working with an agent'],
    details: [
      { selectionType: 'ChoiceWithDetails', textPrompt: 'Provide Details' },
      { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' },
      { selectionType: 'ChoiceOnly', textPrompt: 'Provide Details' }
    ]
  };


  constructor(
    private listingDataProvider: ListingDataProviderService,
    public navCtrl: Router,
    public nav: NavController,
    private auth: AuthService,
    public plt: Platform,
    public networkProvider: NetworkProvider,
    private alertCtrl: AlertController,
    public navParams: NavParams,
    private sms: SMSService,
    private keyboard: Keyboard,
    public common: CommonProvider,
    private insomnia: Insomnia,
    private screenOrientation: ScreenOrientation,
    private intercom: IntercomService,
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef,
    public menu: MenuController,
    private connectionService: ConnectionsService,
    private imageService: ImagesManagementService,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    private guestService: GuestService,
    private sql: SqlService,
    private ionRouterOutlet: IonRouterOutlet,
    private animationCtrl: AnimationController,
    public i8nService: I8nService,
    private renderer: Renderer2,
    private animationBuilder: AnimationBuilder,
    private qrService: QrCodeService,
    private pdfEditorService: PdfEditorService,
    private sanitizer: DomSanitizer,
    private file: File,
    private encryption: EncryptionService,
  ) {

  }

  swiperDidLoad(swiper: SwiperComponent): void {
    console.log('swiper did load event');
    swiper.swiperRef.update(); // Update the swiper once it has loaded and rendered its slides.
  }

  ngOnDestroy() {
    console.log('destroy kiosk');
  }

  ionViewDidEnter() {

    if (!this.plt.is('cordova')) {
      this.networkProvider.startInternetChecks();
    }

    this.guestSurveyData = this.initialSurveyData();
    this.setMenu();

    sessionStorage.clear();
    this.loanOfficer = {
      calendarLink: '',
      company: '',
      email: '',
      IsActive: false,
      name: '',
      phone: 0,
      states: [],
      website: '',
      zipCodes: [],
      reviews: [],
      showTitle: false,
      showLicense: false,
      title: '',
      license: ''
    };

    /*
    this.backButtonSubs = this.plt.backButton.subscribeWithPriority(10, () => {
      this.close();
    });
    */

    if (this.auth.userInitialized) {
      this.initPage();
    }
    else {
      this.loadInterval = setInterval(() => {
        if (this.auth.userInitialized) {
          clearInterval(this.loadInterval);
          this.initPage();
        }
      }, 3000);
    }
  }

  async initPage() {

    this.auth.getLenderOverride().then(res => {
      this.lenderOverride = res;
    });

    this.initSwiperSliders();
    this.initGeoPhone();

    document.documentElement.style.setProperty('--radio-checked-color', User_global.primaryColor);

    this.env = environment;
    this.loanOfficerReviews = [];

    this.mortgageQuestionDetails.PrivacyPolicy = 'By affirming, you agree that #agent may call and/or message you to help answer your mortgage questions at the phone number or email address you provided. Message and data rates may apply.';
    this.mortgageQuestionDetails.MortgageQuestion = 'Have Mortgage Questions?';
    this.mortgageQuestionDetails.yesAnswer = 'Yes';
    this.mortgageQuestionDetails.noAnswer = 'No';
    this.mortgageQuestionDetails.GuestDisclaimer = 'By proceeding, you consent and agree to the following';

    // write custom question
    if (this.customMortgageQuestion && this.customMortgageQuestion.question) {
      this.mortgageQuestionDetails.MortgageQuestion = this.customMortgageQuestion.question;
      this.mortgageQuestionDetails.yesAnswer = this.customMortgageQuestion.yesAnswer;
      this.mortgageQuestionDetails.noAnswer = this.customMortgageQuestion.noAnswer;
    }

    this.tapAnywhereUIRange = this.common.createLoopRange(DEFAULT_TAP_ANYWHERE_RANGE);
    const id = this.route.snapshot.paramMap.get('id');
    // alert(id);

    this.route.queryParams.subscribe(
      params => {
        console.log('layout ID', params);
        this.layoutId = params.listingLayout;
        console.log(this.layoutId);
      }
    );
    console.log('This is ID', id);
    this.currentListingId = id;
    this.guestSurveyData = this.initialSurveyData();

    this.userData = User_global;
    this.arrowColorPrimary = this.userData.primaryColor;
    document.documentElement.style.setProperty('--arrowColor', this.arrowColorPrimary);
    this.guestPrivacyPolicyLink = User_global.customPrivacyPolicy;

    this.loadProgress = 0;
    this.currentScreen = 0;
    this.endProgress = 0;
    this.hideProgress = true; // hide progress

    this.showNext = false;
    this.showPrev = false;



    this.listingDataProvider.listing(id).then((listing) => {
      // console.log(JSON.stringify(listing));
      this.currentListing = listing;
      if (this.currentListing.listingImages && this.currentListing.listingImages.length > 0) {
        this.createPropertyListingImages(this.currentListing.listingImages);
      }
      this.initializeKiosk();
      this.updateKioskMessage(this.textMode);
      document.body.style.setProperty('--primary', User_global.primaryColor);
      document.body.style.setProperty('--secondary', User_global.secondaryColor);
    });
    // this.fetchSMSOptIn();

    this.keyboardSubs = merge(
      this.keyboard.onKeyboardShow().pipe(
        map((event) => event.keyboardHeight)
      ),
      this.keyboard.onKeyboardHide().pipe(
        map(() => 0)
      )
    ).subscribe((keybVisible) => {
      console.log(`Keyboard visible: ${keybVisible}`);
      this.keyboardVisible = keybVisible !== 0;
      this.keyboardHeight = keybVisible;
      this.changeDetector.detectChanges();
    });

    this.initialized = true;
  }


  async loadDisclosureDoc(): Promise<void> {
    const self = this;

    try {
      const res = await this.auth.getDisclosures();
      if (res && res.active === true) {
        self.disclosureDoc = JSON.parse(JSON.stringify(res));
        console.log('disclosure doc: ' + JSON.stringify(res));
      }
    } catch (err) {
      self.disclosureDoc = undefined;
      console.log('err in fetch doc: ' + err);
    }
  }


  async generateDiscosureSigned() {
    const self = this;

    if (this.disclosureDoc) {

      this.disclosureDoc.fields.forEach(item => {

        if (item.field === 'address') {
          item.value = this.currentListing.address.title;
        }
        else if (item.field === 'fullName') {
          item.value = String(this.guestSurveyData.guest[item.field]).replace(/[^\x00-\x7F]/g, '');
        }
        else if (item.field === 'phoneNumber') {
          item.value = this.common.formatPhone(this.guestSurveyData.guest.phoneNumber, this.guestSurveyData.guest.phoneCountryCode);
        }
        else if (item.field === 'agentName') {
          item.value = User_global.username;
        }
        else if (item.field === 'date') {
          item.value = this.guestSurveyData.createdAt;
        }
        else if (item.field === 'brokerOfRecord') {
          item.value = User_global.brokerOfRecord;
        }
        else if (item.field === 'buyersAgent') {
          const buyersAgentQ =
            this.guestSurveyData.questionsAnswers.find(
              q => q.divId === 'customInput_1'
            );

          let buyersAgentAnswer = '';

          const selectedAnswer = buyersAgentQ.multipleChoiceOptions.find(o => o.isSelected);
          if (selectedAnswer.details && selectedAnswer.details?.inputValue) {
            buyersAgentAnswer = (selectedAnswer) ? selectedAnswer.value + ': ' + selectedAnswer.details?.inputValue : '';
          }
          else {
            buyersAgentAnswer = (selectedAnswer) ? selectedAnswer.value : '';
          }
          item.value = buyersAgentAnswer;
        }
        else {
          const fieldValue = this.guestSurveyData.guest[item.field];
          item.value = fieldValue;
        }
      });

      const modifiedPdfBytes = await this.pdfEditorService.fetchAndModifyPdf(this.disclosureDoc.url, this.disclosureDoc.fields);

      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const urlCreator = window.URL || window.webkitURL;
      const pdfUrl = urlCreator.createObjectURL(blob);
      this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);

      this.loadPdf(pdfUrl);
      // this.setPdfSrc();
      // const base64Data = await this.convertBlobToBase64(blob);
      // const dataUrl = `data:application/pdf;base64,${base64Data}`;

      // // Sanitize the URL
      // this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);

      setTimeout(() => {

        if (document.getElementById('pdfInputForm') !== null) {
          console.log('element ' + 'pdfInputForm' + ' exists');
          const element = document.getElementById('pdfInputForm').querySelector('input');
          element.focus();
        }
      }, 250);


    }
  }

  async loadPdf(pdfData) {
    console.log('loadPDF ' + pdfData);
    // Set the worker source to the locally included PDF.js worker script
    // pdfjsLib.GlobalWorkerOptions.workerSrc = 'assets/pdfjs/pdf.worker.min.js';

    this.clearPdfViewer();

    // Load the PDF document
    const loadingTask = pdfjsLib.getDocument(pdfData);
    loadingTask.promise.then(pdf => {
      const viewer = document.getElementById('pdfViewer');

      // Render the first page of the PDF
      pdf.getPage(1).then(page => {

        let multiplier = 1.8; // Default for phones

        if (window.innerWidth > 768) { // Adjust the breakpoint as needed for desktop
          multiplier = 1; // Smaller multiplier for desktop
        }

        const viewerWidth = viewer.clientWidth; // Get the width of the viewer element
        const originalViewport = page.getViewport({ scale: 1 }); // Get the original viewport

        // Calculate the scale to fit the PDF within the viewer's width
        const scale = (viewerWidth / originalViewport.width) * multiplier;

        const viewport = page.getViewport({ scale });


        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        viewer.appendChild(canvas);

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        page.render(renderContext);
      });
    }).catch(error => {
      console.error('Error loading PDF:', error);
    });
  }

  clearPdfViewer() {
    try {
      const viewer = document.getElementById('pdfViewer');

      if (viewer) {
        // Remove all child elements (canvases) from the viewer
        while (viewer.firstChild) {
          viewer.removeChild(viewer.firstChild);
        }

        viewer.innerHTML = '';
      }
    }
    catch (err) {
      console.log('err clearing pdf ' + JSON.stringify(err));
    }
  }


  // async setPdfSrc() {

  //   const self = this;

  //   const baseDir = this.file.dataDirectory;

  //   // Ensure the directory exists
  //   try {
  //     await this.file.checkDir(this.file.dataDirectory, 'pdfs');
  //   } catch (err) {
  //     // Directory does not exist, create it
  //     await this.file.createDir(this.file.dataDirectory, 'pdfs', false);
  //   }

  //   const fileName = 'pdfs/' + this.disclosureDoc.url.split('/').pop();
  //   const filePath = `${baseDir}${fileName}`;

  //   // Now check if the file exists before using it
  //   try {
  //     console.log('trying to load pdf: ' + filePath);

  //     await self.file.checkFile(baseDir, fileName);
  //     // File exists, safe to use the filePath

  //     self.file.resolveDirectoryUrl(baseDir).then((dir) => {
  //       self.file.getFile(dir, fileName, {}).then((file) => {
  //         console.log('Get file: ' + file);

  //         const url = self.convertFileSrc(file.nativeURL);
  //         console.log('Resolved URL:', url);
  //         self.pdfSrc = self.sanitizer.bypassSecurityTrustResourceUrl(url);

  //         this.loadPdf(self.pdfSrc);
  //       });
  //     });



  //   } catch (err) {
  //     console.error('File not found:', err);
  //     // Handle file not found, maybe trigger the download or show an error message
  //   }
  // }

  convertFileSrc(url) {
    const win: any = window;
    return win.Ionic.WebView.convertFileSrc(url);
  }

  convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        resolve(base64data.split(',')[1]);
      };
      reader.onerror = () => {
        reject(new Error('Failed to convert Blob to Base64'));
      };
    });
  }


  initGeoPhone() {

    const self = this;
    if (this.inputInterval) {
      clearInterval(this.inputInterval);
    }

    this.inputInterval = setInterval(() => {

      this.input = document.querySelector('.internationalPhone');
      if (this.input) {
        clearInterval(this.inputInterval);
        console.log(this.input);
        this.iti = intlTelInput(this.input, {
          preferredCountries: ['us', 'ca', 'au', 'nz', 'gb', 'za'],
          initialCountry: 'auto',
          customPlaceholder: function (selectedCountryPlaceholder, selectedCountryData) {
            const replacedPlaceholder = selectedCountryPlaceholder.replace(/[0-9]/g, '#');
            return replacedPlaceholder;
          },
          geoIpLookup: function (callback) {
            fetch('https://ipapi.co/json')
              .then(function (res) { return res.json(); })
              .then(function (data) {
                if (data) {
                  self.defaultCountryCode = data.country_code;
                  callback(data.country_code);
                }
              })
              .catch(function () { callback('us'); });
          },
          utilsScript: '../../assets/js/utils.js',
        });
        //  this.iti = this.iti;
      }
    }, 1000);
  }

  fetchSMSOptIn() {
    this.listingDataProvider.getKioskOptIn().then((res) => {
      console.log('kiosk-mode-optIn: ' + JSON.stringify(res));
      this.enableKioskOptIn = res.enableKioskOptIn;
      this.checkedOptIn = res.isOptInChecked;
      this.SmsOptInDisclaimer = res.kioskSmsDisclaimerText;
    });
  }

  updateKioskMessage(mode: string) {
    if (this.userData.customWelcome !== undefined && this.userData.customThanks !== undefined) {
      if (mode === TEXT_MODE_INTRO) {
        this.textValue = this.userData.customWelcome;
      } else {
        this.textValue = this.userData.customThanks;
      }
    }
  }

  createPropertyListingImages(listingImage) {
    if (listingImage.length === 0) {
      this.listingImagesArr.push(listingImage);
    } else {
      for (let imageIndex = 0; imageIndex < listingImage.length; imageIndex++) {
        this.listingImagesArr.push(listingImage);
      }
    }
  }

  setDataLoading() {
    this.dataIsLoading = true;

    setTimeout(() => {
      this.dataIsLoading = false;
    }, 1000);
  }

  async displayListing(listing: any) {
    const self = this;

    this.currentListing = listing;

    this.resizeKioskSize();
    this.getLender();
    this.getCustomMortgageQuestions();
    await this.loadDisclosureDoc();
    this.createSurvey();
    const now = new Date();

    const qrImageToLoad = this.currentListing.qrCodeImageKiosk;
    // LOAD QR CODE IMAGE DATA
    if (qrImageToLoad) {
      this.imageService.realImageData({
        imagePath: qrImageToLoad,
        imageURL: qrImageToLoad
      }).then((data) => {
        console.log('get qr code real image data result 1: ' + data);
        this.qrCodeImageData = data + '?rand=' + now.getMilliseconds();
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.qrCodeImageData = null;
      });
    }
    else if (this.currentListing.slug) {
      const listingUpdate = this.currentListing;
      const extension = '';
      listingUpdate.id = this.currentListingId;

      // KIOSK SPECIFIC QR CODE
      if (!listingUpdate.qrCodeImageKiosk) {

        const url1 = this.env.blockparty + listingUpdate.slug + '/?display=touch-free-sign-in' + (extension ? '&' + extension : '');
        const fileName = this.currentListing.id + '/' + listingUpdate.slug + '_kiosk';

        self.qrService.generateQRCode(url1, User_global.primaryColor, User_global.logoFile).then(blob => {

          self.imageService.uploadQRCodeBloblListing(blob, fileName, false).then(res => {
            listingUpdate.qrCodeImageKiosk = String(res);
            self.currentListing.qrCodeImageKiosk = String(res);

            self.imageService.realImageData({
              imagePath: listingUpdate.qrCodeImageKiosk,
              imageURL: listingUpdate.qrCodeImageKiosk
            }).then((data) => {
              console.log('get qr code real image data result 2: ' + data);
              self.qrCodeImageData = data + '?rand=' + now.getMilliseconds();
            }).catch((error) => {
              console.log('error finding qr code file. ' + error);
              self.qrCodeImageData = null;
            });

            self.auth.updateListing(listingUpdate, {
              qrCodeImageKiosk: listingUpdate.qrCodeImageKiosk
            });
          });
        });
      }
    }
    else {
      this.qrCodeImageData = null;
    }

    // this.qrKioskURL = 'https://api.qrserver.com/v1/create-qr-code/'
    //   + '?color=' + User_global.primaryColor.replace('#', '')
    //   + '&data=' + this.env.blockparty + this.currentListing.slug
    //   + '/?display=touch-free-sign-in&amp;size=130x130';

    setTimeout(() => {
      this.loaded = true;
      this.listingLoaded = true;
    }, 600);
    // this.getLoanOfficer();

    if (this.currentListing.qrCodeAgentSignIn) {
      this.imageService.realImageData({
        imagePath: this.currentListing.qrCodeAgentSignIn,
        imageURL: this.currentListing.qrCodeAgentSignIn
      }).then((data) => {
        console.log('get qr code real image data result: ' + data);
        this.qrCodeAgentSignin = data;

        this.loaded = true;
        this.listingLoaded = true;
      }).catch((error) => {
        console.log('error finding qr code file. ' + error);
        this.qrCodeAgentSignin = null;
      });
    }
  }

  setMenu() {

    let options;
    // event.stopPropagation();
    this.prevData = JSON.parse(sessionStorage.getItem('prevSurveyData')) || false;
    this.prevID = sessionStorage.getItem('prevId') || false;
    console.log('Previous Data ' + this.prevData + ' ' + this.prevID + ' ' + this.currentScreen);
    if (this.prevData && this.prevID && this.currentScreen === 0) {
      console.log(this.currentScreen, typeof (this.currentScreen));
      options = {
        close: true,
        restart: false,
        skip: false,
        addNotePrev: true,
      };
    } else if (!this.prevData && !this.prevID && this.currentScreen === 0) {
      console.log(this.currentScreen, typeof (this.currentScreen));
      options = {
        close: true,
        restart: false,
        skip: false,
        addNotePrev: false,
      };
    }
    else if (this.prevData && this.prevID && this.currentScreen === 1) {
      options = {
        close: true,
        restart: true,
        skip: false,
        addNotePrev: true,
      };
    }
    else if (!this.prevData && !this.prevID && this.currentScreen === 1) {
      options = {
        close: true,
        restart: true,
        skip: false,
        addNotePrev: false,
      };
    }
    else if (this.prevData && this.prevID && (this.currentScreen > 1 && this.currentScreen <= 3)) {
      options = {
        close: true,
        restart: true,
        skip: true,
        addNotePrev: true,
      };
    }
    else if (!this.prevData && !this.prevID && (this.currentScreen > 1 && this.currentScreen <= 3)) {
      options = {
        close: true,
        restart: true,
        skip: true,
        addNotePrev: false,
      };
    }
    else if (this.prevData && this.prevID && this.currentScreen > 3) {
      options = {
        close: true,
        restart: true,
        skip: false,
        addNotePrev: true,
      };
    }
    else if (!this.prevData && !this.prevID && this.currentScreen > 3) {
      options = {
        close: true,
        restart: true,
        skip: false,
        addNotePrev: false,
      };
    }
    else {
      console.log(this.prevData, this.prevID, this.currentScreen);
      options = {
        close: true,
        restart: true,
        skip: true,
        addNotePrev: true,
      };
    }

    console.log(JSON.stringify(this.prevData));

    if (this.prevData && this.prevData.guest.partialLead === true) {
      options.addNotePrev = false;
    }

    this.options = options;
  }

  async addNote() {
    console.log('prevData ' + JSON.stringify(this.prevData));
    this.toggleMenu();

    if (this.networkProvider.checkInternet) {
      const oldGuest = await this.guestService.getGuest(this.prevID);

      if (oldGuest) {
        console.log(JSON.stringify(oldGuest.guest.notes));
        this.prevData.guest.notes = oldGuest.guest.notes;
      }
    }

    const profileModal = await this.modalCtrl.create({ component: EditNotesPage, componentProps: { label: 'Notes', data: this.prevData.guest.notes } });
    await profileModal.present();
    const { data } = await profileModal.onWillDismiss();

    if (data === undefined || !data) {
      return;
    }

    if (data === GUEST_NOTE_PLACEHOLDER) {
      this.prevData.guest.notes = '';
    }
    else {
      this.prevData.guest.notes = data || '';
    }

    sessionStorage.setItem('prevSurveyData', JSON.stringify(this.prevData));
    this.updateGuest();
  }

  updateGuest() {
    // this.prevID = sessionStorage.getItem('prevId');
    // this.prevData = JSON.parse(sessionStorage.getItem('prevSurveyData'));
    console.log('Previous Data', this.prevData, this.prevID);
    // console.log('Previous Data',typeof(this.prevData));
    if (this.prevID) {
      this.auth.updateGuestSurvey(this.prevData, this.prevID).then(
        async (success) => {
          console.log('Update listing success : ', success);
          this.common.toast('This note can be viewed in My Leads', 3000, 'top');

        },
        error => console.log(error)
      );
    }


    console.log(this.prevData);
  }

  skipQuestion() {
    if (this.currentScreen === 2) {
      this.toggleMenu();
      console.log(this.guestSurveyData);
      this.guestSurveyData.guest.phoneNumber = '';
      this.rawPhone = '';
      this.isNextValid = true;
      this.next();
    } else if (this.currentScreen === 3 && this.guestSurveyData.guest.phoneNumber !== '') {
      this.toggleMenu();
      this.guestSurveyData.guest.email = '';
      this.isNextValid = true;
      this.next();
    } else {
      this.skipQuestionAlert();
    }
  }

  async skipQuestionAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Unable To Skip Question',
      message: 'Either a phone number or an email must be entered.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          role: 'OK',
          handler: data => { }
        }
      ]
    });
    await alert.present();
  }

  restartKiosk() {
    this.noAuth('restart');
  }

  // gets connected listing lender
  async getLender() {

    if (this.currentListing.loanOfficer === 'updating') {
      return;
    }

    const self = this;
    this.translateOldLenderId();

    await this.connectionService.public_profile(this.currentListing.loanOfficer).then(res => {
      console.log('loan officer res: ' + JSON.stringify(res));
      self.loanOfficer = res;

      self.loanOfficer.name = self.loanOfficer.teamname || self.loanOfficer.username;
      self.loanOfficer.shortname = self.loanOfficer.username.split(' ')[0];

      self.loanOfficerReviews = self.loanOfficer.reviews;

      this.getMortgageQuestion();
      // attach img path to logos
      for (let i = 0; i < self.loanOfficerReviews.length; i++) {
        if (!self.loanOfficerReviews[i].review.reviewLogo.startsWith('assets')) {
          self.loanOfficerReviews[i].review.reviewLogo = environment.lenderReviewImages + self.loanOfficerReviews[i].review.reviewLogo;
        }
        if (self.loanOfficerReviews[i].review.enabled && !this.anyReviewEnabled) {
          this.anyReviewEnabled = self.loanOfficerReviews[i].review.enabled;
        }
        console.log('Review Enabled', this.anyReviewEnabled);
      }

      console.log('user data: ' + JSON.stringify(res));

      if (self.loanOfficer.profilePhoto[0]) {
        self.imageService.realImageData(self.loanOfficer.profilePhoto[0]).then((data) => {
          if (data) {
            self.loanOfficer.image = data;
          }
        });
      }

      if (self.loanOfficer.logoFile) {
        console.log('loading user logo from app component. ' + self.loanOfficer.logoFile);
        this.imageService.realImageData({ imageURL: self.loanOfficer.logoFile, imagePath: self.loanOfficer.id + '_logo' }).then((data) => {
          if (data) {
            self.lenderUserLogo = data + '?=' + Math.random();
          }
        });
      }
    });
    this.checkProfileImageLogo();
  }

  // translate legacy lender ids to new ones
  translateOldLenderId() {
    if (this.currentListing.loanOfficer === environment.lender_julie_old) {
      this.currentListing.loanOfficer = environment.lender_julie_new;
    }
    else if (this.currentListing.loanOfficer === environment.lender_wendy_old) {
      this.currentListing.loanOfficer = environment.lender_wendy_new;
    }
  }

  getMortgageQuestion() {
    const self = this;

    this.listingDataProvider.mortgageQuestionDetails().then((res) => {
      self.mortgageQuestionDetails.MortgageQuestion = res.MortgageQuestion;
      this.mortgageQuestionDetails.PrivacyPolicy = res.PrivacyPolicy;
      this.mortgageQuestionDetails.yesAnswer = 'Yes';
      this.mortgageQuestionDetails.noAnswer = 'No';
      this.mortgageQuestionDetails.GuestDisclaimer = res.GuestDisclaimer;

      // write custom question
      if (self.customMortgageQuestion && self.customMortgageQuestion.question) {
        self.mortgageQuestionDetails.MortgageQuestion = self.customMortgageQuestion.question;
        self.mortgageQuestionDetails.yesAnswer = self.customMortgageQuestion.yesAnswer;
        self.mortgageQuestionDetails.noAnswer = self.customMortgageQuestion.noAnswer;
      }

      console.log('mortgageQuestionDetails: ' + JSON.stringify(this.mortgageQuestionDetails));

      const title = (this.loanOfficer.showTitle) ? ' ( ' + this.loanOfficer.title + ' )' : '';
      const license = (this.loanOfficer.showTitle) ? ' ( ' + this.loanOfficer.license + ' )' : '';
      // ([title value] [license value])
      self.kioskFooterMortgage = self.mortgageQuestionDetails.PrivacyPolicy.replace('#agent', this.loanOfficer.name + title + license);
      self.kioskFooterMortgage = this.kioskFooterMortgage.replace('affirming', 'selecting "' + this.mortgageQuestionDetails.yesAnswer + '"');
    });
  }

  getCustomMortgageQuestions() {

    if (User_global.mortgageQuestionOptimization && User_global.assignedMortgageQuestionId) {
      this.customMortgageQuestion = this.auth.getSpecificMortgageQuestion(User_global.assignedMortgageQuestionId);
    }
    else {
      this.customMortgageQuestion = this.auth.getDefaultMortgageQuestion();
    }

    if (this.customMortgageQuestion && this.customMortgageQuestion.question) {
      console.log('this.customMortgageQuestion : ' + JSON.stringify(this.customMortgageQuestion));
      this.mortgageQuestionDetails.MortgageQuestion = this.customMortgageQuestion.question;
    }
  }

  introImage() {
    if (((this.currentListing.propertyImage || {}).imageURL || '').length > 0) {
      return this.currentListing.propertyImage.imageURL;
    } else {
      return null;
    }
  }

  terms(event) {
    event.stopPropagation();
    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }
    else {
      this.common.openLink('https://curbhe.ro/terms/');
    }

  }

  privacy(event) {
    event.stopPropagation();
    if (!this.networkProvider.checkInternet()) {
      this.networkProvider.noNetworkModal();
      return;
    }
    else {
      if (this.guestPrivacyPolicyLink !== '') {
        this.common.openLink(this.guestPrivacyPolicyLink);
      }
    }
  }

  resizeKioskSize() {
    console.log('resize called');
    this.showNext = true;
    //  this.isNextValid = true;
    this.intercom.hideLauncher();
    let listingPropertyLayout = this.utilsService.mapPropertyLayout(this.currentListing.propertyLayout);
    let propertyLayout = this.utilsService.calcPropertyLayout(listingPropertyLayout);
    this.layoutWidth = propertyLayout.width;
    this.layoutHeight = propertyLayout.height;

    console.log('layoutheight: ' + this.layoutHeight);

    merge(this.plt.resize, this.screenOrientation.onChange()).subscribe(() => {
      setTimeout(() => {
        listingPropertyLayout = this.utilsService.mapPropertyLayout(this.currentListing.propertyLayout);
        propertyLayout = this.utilsService.calcPropertyLayout(listingPropertyLayout);
        if (this.layoutWidth !== propertyLayout.width || this.layoutHeight !== propertyLayout.height) {
          console.log(`layout changes to ${propertyLayout.width}x${propertyLayout.height}`);
          this.layoutWidth = propertyLayout.width;
          this.layoutHeight = propertyLayout.height;
          this.changeDetector.detectChanges();
        }

        // if (this.currentScreen === 2) {
        //   let flagElement = document.getElementsByClassName('iti__flag')[0];
        //   flagElement['style'].backgroundImage = '../imgs/largeFlags.png';
        //   const boxes = document.querySelectorAll('.iti__flag');

        //   boxes.forEach(box => {
        //     (box as HTMLElement).style.backgroundImage = '../imgs/largeFlags.png';
        //   });
        // }
      }, 500);
    });

    if (this.plt.is('cordova')) {
      this.lockOrientationOnLayout(listingPropertyLayout);
      this.insomnia.keepAwake();
    }
    // this.changeDetector.detectChanges();
  }

  lockOrientationOnLayout(layout: number) {
    const orientation = this.utilsService.propertyLayoutOrientation(layout);

    console.log('orientation to lock: ' + orientation);
    this.screenOrientation.lock(orientation);
  }

  ionViewWillEnter() {
    this.menu.enable(false);
    this.intercom.hideLauncher();
    //  this.menu.swipeGesture(false, 'left');
  }

  initializeKiosk() {

    this.networkProvider.checkFirestoreDataConnection();
    this.isMobilePhone = this.utilsService.isMobilePhone();
    this.onlineStatus = this.networkProvider.checkInternet();

    this.intercom.hideLauncher();
    console.log('isMobilePhone ' + this.isMobilePhone);


    console.log(`platform width: ${this.plt.width()} height: ${this.plt.height()}`);

    if (this.currentListing) {
      this.displayListing(this.currentListing);
      this.typeOfLayout = this.currentListing.propertyLayout;
      this.orientation = (this.typeOfLayout === 1 || this.typeOfLayout === 2) ? 'landscape' : 'portrait';

      this.updateKioskTime();
      if (this.isMobilePhone) {
        this.orientation = 'portrait';
      }
    } else {
      this.listingDataProvider.listing(this.currentListingId).then((listing) => {
        this.displayListing(listing);
        this.typeOfLayout = listing.propertyLayout;
        this.updateKioskTime();
        this.orientation = (this.typeOfLayout === 1 || this.typeOfLayout === 2) ? 'landscape' : 'portrait';
        if (this.isMobilePhone) {
          this.orientation = 'portrait';
        }
      }).catch((error) => {
        console.log(error);
      });
    }


    if (this.plt.is('cordova')) {
      this.keyboard.disableScroll(true);
      //    this.keyboard.setResizeMode(KeyboardResizeMode.None);
    }
    // warn user about SMS notifications if they are using enhanced mode and kiosk on mobile phones
    console.log('this.userData.guestSMSoptOut ' + this.userData.guestSMSoptOut);
    if (!this.userData.guestSMSoptOut && this.currentListing && this.currentListing.enhancedMode && this.utilsService.isMobilePhone()) {
      this.askToChangeNotifications();
    }

    /*
    this.plt.resize.subscribe(() => {
      setTimeout(() => { this.resizeKioskSize(); }, 500);
    });
    */

    setTimeout(() => {
      this.loadProgressBar = 'loadProgressBar';
    }, 2000);

    this.sql.InsertKioskLog(this.currentListingId, 'Session Start', this.guestSurveyData);
    this.saveGuestToCache(this.guestSurveyData);
  }

  saveGuestToCache(guestSurveyData) {
    try {
      localStorage.setItem('guest_' + this.currentOfflineGuestId, JSON.stringify(guestSurveyData));
    }
    catch (err) {
      console.log('error saving to local storage ' + err);
    }
  }

  updateKioskTime() {
    console.log('update kiosk time called');
    try {
      this.listingDataProvider.updateKioskTimestamp(this.currentListingId).then(res => {
        if (res) {
          console.log('updated kiosk timestamp');
        }
      }).catch(error => {
        this.auth.logErrors('updateKioskTime throw err 2: ' + error, true);
        this.common.showDBWriteError();
        console.log('submit survey error 1 ' + error);
      });
    }
    catch (err) {
      console.log('error updating kiosk time: ' + err);
      this.common.showDBWriteError();
      this.auth.logErrors('updateKioskTime throw err: ' + err, true);
    }
  }

  async askToChangeNotifications() {
    const self = this;

    const alert = await this.alertCtrl.create({
      header: 'Disable Text Message Prompts For Guest Notes?',
      message: 'Since it looks like you\'re using a mobile phone, we can disable texts to ' + this.userData.phoneNumber + ' while your Open House Sign-in is running. Message and data rates may apply.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Disable Texts',
          role: 'OK',
          handler: data => {
            self.tempSMSoptout = false;
            self.guestSMSnotificationChange(true);
          }
        },
        {
          text: 'Keep Texts Enabled',
          role: 'cancel',
          handler: data => { }
        }
      ]
    });
    await alert.present();
  }

  guestSMSnotificationChange(setting) {
    // we are sending the reverse value because we want default in the DB to be false
    // the actuall db value is gueust opt out, but UI will show guest opt in
    this.auth.updateUserDataProperty({ guestSMSoptOut: setting });
  }

  initialSurveyData(): any {

    this.currentOfflineGuestId = this.auth.getNewGuestId();
    //  this.currentOfflineGuestId = this.sql.generatePushID();

    const obj = {
      listingId: this.currentListingId,
      guest: {
        fullName: '',
        phoneNumber: '',
        phoneCountry: '',
        phoneCountryCode: '',
        email: '',
        notes: PARTIAL_ENTRY,
        verified: '',
        source: 'Open House',
        mortgageAnswer: '',
        assignedMortgageQuestionId: '',
        userId: this.auth.getUID() || '',
        loanOfficer: this.currentListing?.loanOfficer || '',
        archived: false,
        partialLead: true, // start with partial
        offlineSync: false
      },
      questionsAnswers: [],
      createdAt: this.common.formatDate(this.common.getPSTTime()),
      time: this.common.formatAMPM(this.common.getPSTTime()),
      createdDate: this.common.getPSTTime().getTime(),
      archived: false
    };
    const phoneInputElement = (document.getElementById('phoneKiosk') as HTMLInputElement);
    if (phoneInputElement !== null) {
      phoneInputElement.value = '';
    }

    this.selectedCheckbox = {};
    console.log('obj=>', obj);

    return obj;
  }

  updateSurveyTimestamps(obj) {
    obj.createdAt = this.common.formatDate(this.common.getPSTTime());
    obj.time = this.common.formatAMPM(this.common.getPSTTime());
    obj.createdDate = this.common.getPSTTime().getTime();

    return obj;
  }


  createSurvey() {
    const self = this;

    if (self.disclosureDoc && self.disclosureDoc.kioskQuestionLocked1 === true) {
      this.currentListing.kioskQuestions[1] = this.kioskLockQuestion1;
    }

    this.clearPdfViewer();

    // number of screen without the initial one (Tap anywhere to start)
    this.numberOfScreens = DEFAULT_SCREENS;
    this.screensMap = [
      { id: 'welcome', name: 'Welcome' },
      { id: 'name', name: 'Fullname' },
      { id: 'phone', name: 'Phone Number' },
      { id: 'email', name: 'Email' },
    ];

    //   this.currentListing.kioskQuestions.map((question, index) => {
    this.highlightedChoice = {}; // reset highlighted multi choice

    for (let index = 0; index < this.currentListing.kioskQuestions.length; index++) {

      const question = this.currentListing.kioskQuestions[index];

      console.log('create survey question: ' + JSON.stringify(question));
      if (question.isActive
        && question.question !== ''
        && question.question !== null
        && (question.type !== ''
          || question.question === 'mortgage')) {

        if (question.question === 'mortgage' || question.question === 'Are you looking For Info Regarding a Mortgage?') {
          question.question = 'Are you looking For Info Regarding a Mortgage?';
          question.type = 'yesNo';

          if (self.lenderOverride) {
            //    alert('continue!');
            continue;
          }
        }

        // NOTE: START Re-enable this block if you want to remove multiple choice questions that are badly set up.
        // Also, re-enable the if block around 'shouldShowQuestion'.

        // const multipleChoiceOptions: MultipleChoiceKioskOption[] = [];
        // if (question.answers && question.answers.length > 0) {
        //   question.answers.forEach(o => {
        //     if (o && o.trim().length > 0) {
        //       multipleChoiceOptions.push({
        //         isSelected: false,
        //         value: o
        //       });
        //     }
        //   });
        // }

        // const shouldShowQuestion = (question.type !== 'multipleChoice' || (multipleChoiceOptions.length > 0));
        // NOTE: END Re-enable this block if you want to remove multiple choice questions that are badly set up.

        const multipleChoiceOptions: MultipleChoiceKioskOption[] = [];
        if (question.type === 'multipleChoice' && question.answers && question.answers.length > 0) {

          for (let i = 0; i < question.answers.length; i++) {
            multipleChoiceOptions.push({
              isSelected: false,
              value: question.answers[i],
              details: question.details && question.details[i] ? question.details[i] : {}
            });

          }

          this.guestSurveyData.questionsAnswers.push({
            question: question.question,
            type: question.type,
            answer: '',
            divId: 'customInput_' + index,
            multipleChoiceOptions: multipleChoiceOptions
          });
        }
        else {
          this.guestSurveyData.questionsAnswers.push({
            question: question.question,
            type: question.type,
            answer: '',
            divId: 'customInput_' + index
          });

        }

        this.numberOfScreens++;
        this.screensMap.push({ id: question.question, name: question.question });

      }
    }

    // reset chocies
    if (this.guestSurveyData && this.guestSurveyData.questionsAnswers.length) {
      this.guestSurveyData.questionsAnswers.forEach(slide => {
        if (slide && slide.multipleChoiceOptions) {
          // Reset the multipleChoiceOptions array for the current slide
          slide.multipleChoiceOptions.forEach(option => {
            if (option && option.details && option.details.inputValue) {
              option.details.inputValue = '';
            }
            // Reset any other properties related to multiple choice options if needed
          });
        }
      });
    }

    // add disclosure doc
    if (this.disclosureDoc) {
      this.guestSurveyData.disclosureDoc = self.disclosureDoc.id;
      this.guestSurveyData.disclosureSigned = '';
      this.guestSurveyData.disclosureSelection = '';

      this.numberOfScreens++;
      this.screensMap.push({ id: 'disclosure', name: self.disclosureDoc.title });
    }
  }

  close() {
    const self = this;
    self.noAuth();
  }

  async noAuth(options?: any) {

    let textHeader: string; let textMessage: string;
    let incomplete = false;
    if (options === 'restart') {
      console.log('guestDate', this.guestSurveyData, this.guestSurveyData.guest.fullName === '');
      if (this.guestSurveyData.guest.fullName === '') {
        textHeader = 'Restart Confirmation';
        textMessage = 'Are you sure you want to restart your Open House Sign-in?';
      } else {
        textHeader = 'Sign-in Incomplete';
        textMessage = 'Are you sure you want to restart your Open House Sign-in?';
        incomplete = true;
      }
      const alert = await this.alertCtrl.create({
        header: textHeader,
        message: textMessage,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Restart',
            cssClass: 'ion-color-danger',
            handler: data => {

              if (incomplete) {
                this.saveGuestOffline(JSON.parse(JSON.stringify(this.guestSurveyData)));
              }

              if (this.plt.is('cordova')) {
                this.keyboard.disableScroll(false);
                //     this.keyboard.setResizeMode(KeyboardResizeMode.Ionic);
              }

              this.restartSurvey(30);
              console.log('reached here screen', this.currentScreen);
              this.toggleMenu();
            }
          }

        ]
      });
      await alert.present();
    }
    else {
      incomplete = false;
      if (this.guestSurveyData.guest.fullName === '') {
        textHeader = 'Exit Confirmation';
        textMessage = 'Are you sure you want to exit out of Open House Sign-in mode?';
      } else {
        textHeader = 'Sign-in Incomplete';
        textMessage = 'Are you sure you want to exit out of Open House Sign-in mode?';
        incomplete = true;
      }
      console.log('guestDate', this.guestSurveyData);
      const alert = await this.alertCtrl.create({
        header: textHeader,
        message: textMessage,
        backdropDismiss: false,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          },
          {
            text: 'Exit',
            cssClass: 'ion-color-danger',
            handler: data => {
              if (this.plt.is('cordova')) {
                this.keyboard.disableScroll(false);
                //      this.keyboard.setResizeMode(KeyboardResizeMode.Ionic);
              }

              if (incomplete) {
                this.saveGuestOffline(JSON.parse(JSON.stringify(this.guestSurveyData)));
              }

              this.revertSMSoptout();
              this.currentScreen = 1;
              //   this.backButtonSubs();

              if (this.ionRouterOutlet.canGoBack()) {
                this.nav.back();
              }
              else {
                this.nav.navigateBack('/my-listings');
              }
            }
          },

        ]
      });
      await alert.present();
    }

  }


  revertSMSoptout() {
    if (!this.tempSMSoptout) {
      this.guestSMSnotificationChange(false);
    }
  }

  slideTransitionStart(event) {
    this.swiperStopMoving(true);
    this.enableInput = false;
    this.isNextValid = false;
    const element = document.querySelector('.mySwiper');
    if (this.currentScreen === event[0].slides.length - 1) {
      this.textMode = TEXT_MODE_OUTRO;
      this.updateKioskMessage(this.textMode);
      this.enableEmailList = 'emailList-invisible';
      const isVisible = document.querySelector('.visible');
      if (isVisible !== null) {
        element.classList.remove('visible');
      }
      element.classList.add('invisible');
    }
    else if (this.currentScreen === 0) {
      this.textMode = TEXT_MODE_INTRO;
      this.updateKioskMessage(this.textMode);
      const isVisible = document.querySelector('.visible');
      if (isVisible !== null) {
        element.classList.remove('visible');
      }
      element.classList.add('invisible');
    }
    else {
      const isInVisible = document.querySelector('.invisible');
      setTimeout(() => {
        if (isInVisible !== null) {
          element.classList.remove('invisible');
        }
        element.classList.add('visible');
      }, 1000);
    }
  }

  slideTransitionEnd(event) {

    this.enableInput = true;
    this.treatNextAsHandleNo = false;
    this.disableButton = false; // removing disable setting from handleNo/handleYes button.
    const element = document.querySelector('.mySwiper');
    if ((event[0].activeIndex === 0 || event[0].activeIndex === event[0].slides.length - 1)) {
      const isVisible = document.querySelector('.visible');
      if (isVisible !== null) {
        element.classList.remove('visible');
      }
      element.classList.add('invisible');
    }
    else {
      const isInVisible = document.querySelector('.invisible');
      setTimeout(() => {
        if (isInVisible !== null) {
          element.classList.remove('invisible');
        }
        element.classList.add('visible');
      }, 1000);
    }

    //  const progress = document.querySelector('.swiper-pagination-progressbar');

    const progress = document.querySelector('.app-custom-progress');

    if (this.currentScreen === 1) {
      //  this.common.toast(`First some basic info (${this.currentScreen} of ${this.numberOfScreens})`, 3000, 'top', 1500);
      this.pdfSrc = null;
      this.onNameChange(this.guestSurveyData.guest.fullName);

      const secondary = getComputedStyle(document.documentElement).getPropertyValue('--ion-color-secondary');
      if (secondary.includes('#FFFFFF')) {
        if (progress !== null) {
          progress.classList.add('progress_primary');
        }
      }
      else {
        if (progress !== null) {
          progress.classList.add('progress_secondary');
        }
      }
      setTimeout(() => {
        this.callKeyboard('nameKiosk');
        this.nameKiosk.setFocus();
      }, 500);
    }
    if (this.currentScreen === 2) {
      this.plt.ready().then(() => {
        const inputInterval = setInterval(() => {
          const phone = document.getElementById('phoneKiosk');
          if (phone) {
            clearInterval(inputInterval);
            phone.focus();
          }
        }, 200);
      });
      if (this.guestSurveyData.guest.phoneNumber.length === 10) {
        this.isNextValid = true;
      } else {
        this.isNextValid = false;
      }
      const input_div = document.querySelector('.iti');
      if (this.isNextValid) {
        input_div.classList.add('valid-input');
      } else {
        input_div.classList.add('not-valid-input');
      }
      if (this.input) {
        const placeholder = document.getElementById('phoneKiosk').getAttribute('placeholder');
        const replacedPlaceholder = placeholder.replace(/[0-9]/g, '#');
        document.getElementById('phoneKiosk').setAttribute('placeholder', replacedPlaceholder);
      }
    }
    if (this.currentScreen === 3) {
      this.callKeyboard('emailKiosk');
      this.emailKiosk.setFocus();
      this.onEmailChange(this.guestSurveyData.guest.email);
    }

    console.log('current screen : ' + this.currentScreen);
    console.log('this.guestSurveyData.questionsAnswers ' + JSON.stringify(this.guestSurveyData.questionsAnswers));
    if (this.currentScreen > 3) {

      const extraScreen = this.guestSurveyData.questionsAnswers[this.currentScreen - 4];

      // if (extraScreen && extraScreen.type && extraScreen.type === 'yesNo') {
      //   this.common.toast('Almost done', 2000, 'top', 1500);
      // }

      if (this.guestSurveyData.questionsAnswers.length > 0) {
        if (extraScreen &&
          extraScreen.type &&
          extraScreen.type !== 'yesNo' &&
          extraScreen.type !== 'multipleChoice') {
          this.callKeyboard(extraScreen.divId);
        }
        else {
          this.closeKeyboard();
        }


        if (extraScreen &&
          extraScreen.question === 'Are you looking For Info Regarding a Mortgage?' &&
          this.customMortgageQuestion &&
          this.customMortgageQuestion.style === 'new') {
          this.isNextValid = true;
          this.treatNextAsHandleNo = true;


        }
        else if (extraScreen && extraScreen.multipleChoiceOptions &&
          extraScreen.multipleChoiceOptions.length > 0) {
          const selectedOption = extraScreen.multipleChoiceOptions.find(o => o.isSelected);

          this.isNextValid = (selectedOption !== undefined);

          // IF THE OPTION IS EMPTY AND NOTHING IS SELECTED LET THE USER SKIP
          if (!this.isNextValid
            && extraScreen.multipleChoiceOptions.length === 1
            && extraScreen.multipleChoiceOptions[0].value === ''
            && !extraScreen.multipleChoiceOptions[0].isSelected
          ) {
            this.isNextValid = true;
          }
        }
        else if (extraScreen && extraScreen.answer.toString().length > 0) {
          this.isNextValid = true;
        }
        else {
          this.isNextValid = false;
        }
      } else {
        this.isNextValid = false;
        this.closeKeyboard();
      }
    }

    //alert(JSON.stringify(this.screensMap[this.currentScreen].id));

    if (this.screensMap[this.currentScreen] && this.screensMap[this.currentScreen].id === 'disclosure') {
      //  alert('generate!');
      this.generateDiscosureSigned();
      //   this.isNextValid = true;
    }

    this.swiperStopMoving(true);
    this.intercom.hideLauncher();
  }

  overrideNextWithNo() {
    const question = this.guestSurveyData.questionsAnswers[this.currentScreen - 4];
    if (question) {
      question.answer = 'No';
    }
    this.next();
  }

  next(event = null) {

    if (event) {
      event.stopPropagation();
    }

    if (this.isNextValid) {

      if (this.keyboardVisible) {
        this.closeKeyboard();
        this.isNextValid = false;
        setTimeout(() => {
          this.moveNext();
        }, 500);
      } else {
        this.moveNext();
      }
    }

    try {

      this.sql.InsertKioskLog(this.currentListingId, 'Next clicked', this.guestSurveyData);
      this.saveGuestOffline(JSON.parse(JSON.stringify(this.guestSurveyData)));
      this.intercom.hideLauncher();
      this.saveGuestToCache(this.guestSurveyData);
    }
    catch (err) {
      this.auth.logErrors('Error inserting log: ' + err);
    }
  }

  moveNext() {
    this.currentScreen++;
    this.loadProgress += (100 / (this.numberOfScreens));
    this.swiperStopMoving(false);

    this.swiper.swiperRef.slideNext();
    this.swiper.swiperRef.update();

    const totalScreens = this.numberOfScreens + 1;
    console.log('move next: ' + this.currentScreen + ' ' + totalScreens);
    this.isNextValid = false;
    if (this.currentScreen === totalScreens) {
      this.showNext = false;
      this.showPrev = false;
      this.hideProgress = true;
      this.submit();
      this.startEndAnimation();

    } else {
      this.hideProgress = false;
      this.showNext = true;
      if (this.currentScreen > 1) {
        this.showPrev = true;
      } else {
        this.showPrev = false;
      }
    }
  }

  checkProfileImageLogo() {

    if (this.loanOfficer.logoFile === '' && this.loanOfficer.logoImg === undefined && this.loanOfficer.profilePhoto.length === 0) {
      this.profileImgShow = false;
    } else {
      this.profileImgShow = true;
    }
  }

  closeKeyboard() {
    if (this.keyboardTimeout) {
      clearTimeout(this.keyboardTimeout);
      this.keyboardTimeout = null;
    }
    if (this.plt.is('cordova')) {
      this.keyboard.hide();
    }

    const elem = document.activeElement as HTMLElement;
    if (elem) {
      elem.blur();
    }
  }

  checkKey(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.isNextValid) {
      this.next();
    }
  }

  prev() {

    this.setDataLoading();

    this.sql.InsertKioskLog(this.currentListingId, 'Back clicked', this.guestSurveyData);

    this.currentScreen--;
    this.loadProgress -= (100 / (this.numberOfScreens - 1));
    this.swiperStopMoving(false);
    this.swiper.swiperRef.slidePrev();
    if (this.currentScreen === this.numberOfScreens + 1) {
      this.showNext = false;
      this.showPrev = false;
    } else {
      this.showNext = true;
      if (this.currentScreen > 1) {
        this.showPrev = true;
      } else {
        this.showPrev = false;
      }
    }
    this.isNextValid = true;
  }

  onNameChange(name: string) {
    if (name) {
      this.guestSurveyData.guest.fullName = name.trim();

      if (this.guestSurveyData.guest.fullName.length > 1) {
        this.isNextValid = true;
      } else {
        this.isNextValid = false;
      }
    }
  }

  onPhoneChange(phone: string) {
    if (phone) {
      this.rawPhone = phone;
      const output = phone.replace(/\D+/g, '');
      const input_div = document.querySelector('.iti');
      console.log(`phone: ${phone} output: ${output}`);
      if (this.iti.isValidNumber()) {
        const selectedCountry = this.iti.getSelectedCountryData();
        this.guestSurveyData.guest.phoneNumber = output;
        this.guestSurveyData.guest.phoneCountry = selectedCountry.iso2;
        this.guestSurveyData.guest.phoneCountryCode = selectedCountry.dialCode;
        // if (this.checkedOptIn) {
        if (this.guestSurveyData.guest.phoneCountry === 'us') {
          if (output.length >= 10) {
            this.isNextValid = true;
          }
          else {
            this.isNextValid = false;
          }
        }
        else {
          this.isNextValid = true;
        }
        this.isNextValid = true;
        input_div.classList.add('valid-input');
        if (input_div.classList.contains('not-valid-input')) {
          input_div.classList.remove('not-valid-input');
        }
        // }
      }
      else {
        this.isNextValid = false;
        input_div.classList.add('not-valid-input');
        if (input_div.classList.contains('valid-input')) {
          input_div.classList.remove('valid-input');
        }
      }
    }
  }

  onEmailChange(email: string) {
    this.guestSurveyData.guest.email = email;
    if (email.length > 0) {
      if (this.enableEmailList === 'emailList-invisible') {
        this.enableEmailList = 'emailList-visible';
        this.slideEmailIn();
      }
    }
    else {
      this.slideEmailOut();
      setTimeout(() => {
        this.enableEmailList = 'emailList-invisible';
      }, 300);
    }
    if (email) {
      const isValid = EMAIL_REGEX.test(this.guestSurveyData.guest.email);
      if (isValid) {
        this.isNextValid = true;
      } else {
        this.isNextValid = false;
      }
    }
  }

  slideEmailIn() {
    const emailListAnimate = this.animationCtrl.create()
      .addElement(document.querySelector('.emailList'))
      .duration(150)
      .iterations(1)
      .fromTo('transform', 'translate3d(0,5px,0)', 'translate3d(0,0,0)');
    emailListAnimate.play();
  }

  slideEmailOut() {
    // const emailListAnimate = this.animationCtrl.create()
    // .addElement(document.querySelector('.emailList'))
    // .duration(150)
    // .iterations(1)
    // .fromTo('transform', 'translate3d(0,0,0)', 'translate3d(0,15px,0)')
    // emailListAnimate.play();
  }

  restartSurvey(timeOption: number = 3000) {
    const self = this;

    this.createSurvey = this.createSurvey.bind(this);

    this.highlightedChoice = {};

    this.hideProgress = false; // show progress
    //   if (!timeOption) { timeOption = 3000; };
    console.log('time option restart is ' + timeOption);

    setTimeout(() => {
      console.log('restart survey called');
      //  this.currentScreen = 0;
      self.showNext = true;
      self.guestSurveyData = self.initialSurveyData();
      self.rawPhone = '';
      self.createSurvey();
      self.loadProgress = 0;
      self.currentScreen = 0;
      self.endProgress = 0;
      self.showPrev = false;
      self.swiperStopMoving(false);
      self.swiper.swiperRef.slideTo(0, 1000);
      self.swiperStopMoving(true);
      self.isNextValid = true;
      self.selectedDomain = '';

      try {
        if (this.iti && this.defaultCountryCode) {
          this.iti.setCountry(this.defaultCountryCode);
        }
      }
      catch (err) {
        console.log('err in restart ', err);
      }


    }, timeOption);
  }

  // save guest information
  submit() {
    console.log('FROM SUBMIT');

    // this is not a partial save
    if (this.guestSurveyData.guest.notes === PARTIAL_ENTRY) {
      this.guestSurveyData.guest.notes = '';
    }
    this.guestSurveyData.guest.partialLead = false;
    this.guestSurveyData.guest.archived = false;
    this.guestSurveyData.archived = false;
    // update timestamps

    // save download url
    // try {
    //   if (this.guestSurveyData.disclosureSelection === 'confirmButton') {
    //     this.guestSurveyData.disclosureFulfilledDownloadUrl = environment.downloadDisclosure + this.encryption.encryptIds(this.guestSurveyData.guest.userId, this.currentOfflineGuestId);
    //   }
    // }
    // catch (err) {
    //   console.error('error getting download url ', err);
    // }

    this.guestSurveyData = this.updateSurveyTimestamps(this.guestSurveyData);
    try {

      this.guestSurveyData.guest.verified = 'unknown';
      this.guestSurveyData.guest.source = 'Open House';

      const mortgageFollowUpEligibleAnswer =
        this.guestSurveyData.questionsAnswers.find(
          q => q.divId === 'customInput_0'
        );

      if (mortgageFollowUpEligibleAnswer && this.customMortgageQuestion && this.customMortgageQuestion.id) {

        console.log('mortgageFollowUpEligibleAnswer: ' + JSON.stringify(mortgageFollowUpEligibleAnswer));
        this.guestSurveyData.guest.assignedMortgageQuestionId = this.customMortgageQuestion.id;
        this.guestSurveyData.guest.mortgageAnswer = mortgageFollowUpEligibleAnswer.answer;

        this.guestSurveyData.guest.userId = this.auth.getUID();
        this.guestSurveyData.guest.loanOfficer = this.currentListing.loanOfficer;
      }
    }
    catch (err) {
      console.log('err in analytics.');
      this.auth.logClientInfo('Error in analytics: ' + err);
      this.auth.logClientInfo('guest signed in error: ' + JSON.stringify(this.guestSurveyData));
    }


    //this.generateDiscosureSigned();

    // save offline guest
    this.saveGuestOffline(JSON.parse(JSON.stringify(this.guestSurveyData)));
    this.saveGuestToCache(this.guestSurveyData);

    // WEB APP MUST BE ONLINE
    setTimeout(() => {
      if (!this.networkProvider.checkInternet() && !this.plt.is('cordova')) {
        this.submitErrorPrompt(JSON.parse(JSON.stringify(this.guestSurveyData)));
      }
      else {
        this.logGuestToServer(JSON.parse(JSON.stringify(this.guestSurveyData)));
        this.writeGuestDataDB(JSON.parse(JSON.stringify(this.guestSurveyData)));
      }
    }, 250);

    if (this.guestSurveyData.guest.assignedMortgageQuestionId && this.guestSurveyData.guest.mortgageAnswer) {
      this.analyticsService.logKioskEvent({
        assignedMortgageQuestionId: this.guestSurveyData.guest.assignedMortgageQuestionId,
        mortgageAnswer: this.guestSurveyData.guest.mortgageAnswer,
        userId: this.guestSurveyData.guest.userId,
        loanOfficer: this.guestSurveyData.guest.loanOfficer
      });
    }
  }

  logGuestToServer(guestSurveyData) {

    if (this.plt.is('cordova') && this.networkProvider.checkInternet()) {
      this.auth.logClientInfo('guest signed in: ' + JSON.stringify(guestSurveyData));
    }
    // ON WEB APP MAKE SURE THERE IS A CONNECTION
    else if (!this.plt.is('cordova')) {

      if (this.networkProvider.checkInternet()) {
        this.auth.logClientInfo('guest signed in: ' + JSON.stringify(guestSurveyData)).then(res => {
          console.log('client log response: ' + res);
          if (!res) {
            this.common.showOfflineError(true);
          }
        }).catch((error) => {
          console.log('error in submit: ' + error);
          // add server error logs for wifi is on
          this.common.showOfflineError(true);
        });
      }
      else {
        // add server log for error is on
        console.log('no internet in kiosk mode on web');
        this.common.showOfflineError(true);
      }
    }
  }

  writeGuestDataDB(guestSurveyData) {

    try {
      this.endProgress = 33;

      // WARN THE WEB USER THEIR CONNECTION IS NOT WORKING
      if (!this.plt.is('cordova') && this.networkProvider.isKioskOffline()) {
        this.noInternetConnectionWarning();
      }

      this.listingDataProvider.submitGuestSurvey(
        guestSurveyData,
        this.currentOfflineGuestId,
        !this.networkProvider.checkInternet()
      ).then((res) => {
        if (res) {
          console.log('submited survey! ' + res);
          sessionStorage.setItem('prevId', res);
          sessionStorage.setItem('prevSurveyData', JSON.stringify(guestSurveyData));
        }
        else {
          this.auth.logErrors('Error saving guest: ' + JSON.stringify(guestSurveyData), true);
          this.common.showDBWriteError(true);
        }
      }).catch((error) => {
        const formatError = error.message ? error.message + ' ' + JSON.stringify(error.stack) : JSON.stringify(error);
        this.auth.logErrors('Error saving guest1: ' + JSON.stringify(guestSurveyData) + ' err:' + formatError);

        // IF NO INTERNET THROW AN ERROR TO THE USER RIGHT AWAY
        if (this.networkProvider.isKioskOffline() || !this.networkProvider.checkInternet()) {
          this.auth.logErrors('Error saving guest2: ' + JSON.stringify(guestSurveyData) + ' err:' + formatError, true); // try to send log anyway
          this.common.showDBWriteError(true);
        }
        // TRY SERVER SIDE SAVE IF ONLINE
        else {
          console.log('Chance user can save lead serverside');
          this.auth.serverLeadSave(this.currentOfflineGuestId, guestSurveyData)
            .then(response => {
              console.log('Lead saved successfully:', response);

              console.log('submited survey! via server ' + response);
              sessionStorage.setItem('prevId', response?.guestId);
              sessionStorage.setItem('prevSurveyData', JSON.stringify(guestSurveyData));
              // Handle the successful response here
            })
            .catch(error => {
              const formatError = error.message ? error.message + ' ' + JSON.stringify(error.stack) : JSON.stringify(error);
              console.error('Error saving lead:', error);
              this.auth.logErrors('Error saving guest3: ' + JSON.stringify(guestSurveyData) + ' err:' + formatError, true);
              this.common.showDBWriteError(true);
            });
        }

        console.log('submit survey error ' + error);
      });

      this.endProgress = 66;
      this.restartSurvey();
    }
    catch (err) {

      this.auth.logErrors('Error with submitting guest: ' + JSON.stringify(guestSurveyData) + ' err:' + err, true);
      this.common.showDBWriteError(true);

      this.endProgress = 66;
      this.restartSurvey();
    }
  }

  async submitErrorPrompt(guestSurveyData) {

    const alert = await this.alertCtrl.create({
      header: 'Offline Error',
      message: 'This information can\'t be saved because the device is offline. To save this information, make sure you are connected to the internet and then retry this operation. Another option is to use our mobile application which can be used when offline.',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Retry',
          role: 'OK',

          handler: data => {
            this.retrySubmitData(guestSurveyData);
          }
        }
      ]
    });
    await alert.present();
  }

  async noInternetConnectionWarning() {
    const alert = await this.alertCtrl.create({
      header: 'Offline Error',
      message: 'Data can\'t be saved because the device has limited connectivity. Make sure you are connected to the internet and the retry this operation. Another option is to use our mobile application which can be used when offline',
      backdropDismiss: false,
      buttons: [
        {
          text: 'OK',
          role: 'OK',

          handler: data => {

          }
        }
      ]
    });
    await alert.present();
  }

  retrySubmitData(guestSurveyData) {
    if (this.networkProvider.checkInternet()) {
      this.logGuestToServer(guestSurveyData);
      this.writeGuestDataDB(guestSurveyData);
    }
    else {
      this.submitErrorPrompt(guestSurveyData);
    }
  }

  sendGuestSMS(phoneNumber, guestId) {
    if (this.networkProvider.checkInternet()) {
      this.sms.sendSMSInHalfAnHour(this.currentListing.id, phoneNumber, guestId);
    }
  }

  handleYes(i: number) {
    // if (this.guestSurveyData.questionsAnswers[i].conditionalToggle) {
    //   if (this.guestSurveyData.questionsAnswers[i].conditionalTriggerAnswer) {
    //     this.guestSurveyData.questionsAnswers[i].answer = 'Yes';
    //     this.isNextValid = true;
    //     this.next();
    //   }
    //   else {
    //     this.guestSurveyData.questionsAnswers[i].answer = 'Yes';
    //     this.isNextValid = false;
    //     this.submit();
    //     console.log('Shouldnt trigger for invalid no');
    //   }
    // }
    //  else {
    this.guestSurveyData.questionsAnswers[i].answer = 'Yes';
    this.isNextValid = true;
    this.next();
    // }
  }

  handleNo(i: number) {
    if (!this.disableButton) {
      // if (this.guestSurveyData.questionsAnswers[i].conditionalToggle) {
      //   if (!this.guestSurveyData.questionsAnswers[i].conditionalTriggerAnswer) {
      //     this.guestSurveyData.questionsAnswers[i].answer = 'No';
      //     this.isNextValid = true;
      //     this.next();
      //   }
      //   else {
      //     this.guestSurveyData.questionsAnswers[i].answer = 'No';
      //     this.isNextValid = false;
      //     this.submit();
      //     console.log('Shouldnt trigger for invalid yes');
      //   }
      // }
      //else {
      this.guestSurveyData.questionsAnswers[i].answer = 'No';
      this.isNextValid = true;
      this.next();
      // }
    }
    this.disableButton = true; // disabling the handleNo/handleYes button.
    setTimeout(() => {
      this.disableButton = false; // removing disable setting from handleNo/handleYes button.
    }, 5000);
  }

  handleQuestionInputAnswers(value) {
    if (value.length > 0) {
      this.isNextValid = true;
    } else {
      this.isNextValid = false;
    }
  }

  startEndAnimation() {
    this.endProgress = 0;
  }

  callKeyboard(idValue: any) {
    if (this.keyboardTimeout) {
      clearTimeout(this.keyboardTimeout);
      this.keyboardTimeout = null;
    }
    //  this.keyboard.show();
    // we need to delay our call in order to work with ionic ...
    console.log('call keyboard for element: ' + idValue);
    this.keyboardTimeout = setTimeout(() => {

      if (document.getElementById(idValue) !== null) {
        console.log('element ' + idValue + ' exists');
        const element = document.getElementById(idValue).querySelector('input');
        element.focus();
      }
    }, 250);
  }

  resetField(field: string) {
    console.log(`reset ${field}`);
    if (field === 'phoneKiosk') {
      //  this.phone.value = this.rawPhone;
      // this.guestSurveyData.guest.phoneNumber = this.rawPhone;
    }
  }

  ionViewWillLeave() {
    this.networkProvider.stopInternetChecks();

    this.menu.enable(true);
    this.menu.swipeGesture(true);

    clearInterval(this.loadInterval);

    if (this.keyboardSubs && !this.keyboardSubs.closed) {
      this.keyboardSubs.unsubscribe();
    }

    // Android ver doesn't have this
    if (this.userDataSub && !this.userDataSub.closed) {
      this.userDataSub.unsubscribe();
    }


    if (this.plt.is('cordova')) {
      this.insomnia.allowSleepAgain();
      setTimeout(() => {
        this.utilsService.unlockOrientation();
      }, 1000);
      this.intercom.showLauncher();
      this.keyboard.disableScroll(false);
    }
    else {
      this.intercom.showLauncher();
    }
  }

  multiChoiceInputChanged(slide: QuestionsAnswers, event) {

    // alert(JSON.stringify(this.highlightedChoice));
    console.log(event.target.value);
    //   const inputValue = event.target.parentElement.querySelector('.choiceDetailsInput');

    if (event.target.value.trim()) {
      this.isNextValid = true;
      //  alert(inputValue.value);
      //  slide.answer = slide.answer + ' ' + inputValue.value;
    }
    else {
      this.isNextValid = false;
    }
  }

  multipleChoiceOptionChanged(slide: QuestionsAnswers, event) {

    if (!this.enableInput) {
      console.log('slide transitioning, no change to multi choice');
      return false;
    }

    console.log('multipleChoiceOptionChanged');
    const self = this;

    console.log(this.currentScreen.toString());
    console.log(this.selectedCheckbox[this.currentScreen.toString()]);

    if (this.selectedCheckbox[this.currentScreen.toString()] && Object.keys(this.selectedCheckbox[this.currentScreen.toString()]).length > 0) {

      slide.multipleChoiceOptions.forEach(o => {

        if (typeof this.selectedCheckbox[this.currentScreen.toString()] !== 'string') {
          console.log('is object?! ' + JSON.stringify(this.selectedCheckbox[this.currentScreen.toString()]));
          o.isSelected = (o.value === this.selectedCheckbox[this.currentScreen.toString()].value);
        }
        else {
          console.log(this.selectedCheckbox[this.currentScreen.toString()] + ' ----- ' + o.value);
          o.isSelected = (o.value === this.selectedCheckbox[this.currentScreen.toString()]);
          if (o.isSelected) {
            console.log(o.isSelected + ' -> ' + this.selectedCheckbox[this.currentScreen.toString()]);
          }
        }
      });
    }

    if (this.selectedCheckbox[this.currentScreen.toString()]) {

      slide.answer = this.selectedCheckbox[this.currentScreen.toString()];

      const selectedIndex = slide.multipleChoiceOptions.findIndex(o => o.value === this.selectedCheckbox[this.currentScreen.toString()]);
      const selectedChoice = slide.multipleChoiceOptions[selectedIndex];

      if (selectedChoice &&
        selectedChoice.details &&
        selectedChoice.details.selectionType &&
        selectedChoice.details.selectionType === 'ChoiceWithDetails') {

        //  alert(JSON.stringify(selectedChoice.details));
        // this.highlightedChoice = JSON.parse(JSON.stringify(slide.multipleChoiceOptions[selectedIndex]));
        this.highlightedChoice[this.currentScreen.toString()] = slide.multipleChoiceOptions[selectedIndex];

        //  this.triggerAnimation();

        const inputValue = event.target.parentElement.querySelector('.choiceDetailsInput');

        this.keyboardTimeout = setTimeout(() => {

          const idValue = 'multiChoiceWithDetails';
          if (document.getElementById(idValue) !== null) {
            console.log('element ' + idValue + ' exists');
            const element = document.getElementById(idValue).querySelector('input');
            element.focus();

            if (!element.value?.trim()) {
              self.isNextValid = false;
            }
            else {
              self.isNextValid = true;
            }
          }

        }, 200);
      }
      else {
        this.isNextValid = true;
        this.highlightedChoice[this.currentScreen.toString()] = {};
      }
      // if (inputValue) {
      //   console.log(inputValue.value);
      //   slide.answer = slide.answer + ' ' + inputValue.value;
      // }

      // setTimeout(() => { this.renderer.selectRootElement(inputValue).focus(); }, 500);
    }
    else if (this.selectedCheckbox[this.currentScreen.toString()] === '') {

      let emptySelected = false;
      slide.multipleChoiceOptions.forEach(o => {

        if (typeof this.selectedCheckbox[this.currentScreen.toString()] !== 'string') {
          console.log('is object?! ' + JSON.stringify(this.selectedCheckbox[this.currentScreen.toString()]));
          o.isSelected = (o.value === this.selectedCheckbox[this.currentScreen.toString()].value);
        }
        else {
          console.log(this.selectedCheckbox[this.currentScreen.toString()] + ' ----- ' + o.value);
          o.isSelected = (o.value === this.selectedCheckbox[this.currentScreen.toString()]);
          if (o.isSelected) {
            emptySelected = true;
            console.log(o.isSelected + ' -> ' + this.selectedCheckbox[this.currentScreen.toString()]);
          }
        }
      });

      if (emptySelected) {
        this.isNextValid = true;
        this.highlightedChoice[this.currentScreen.toString()] = {};
      }
    }
    else {
      this.highlightedChoice[this.currentScreen.toString()] = {};
      this.isNextValid = false;
    }
  }


  disclosureAccept(slide: QuestionsAnswers, event) {

    this.guestSurveyData.disclosureSigned = this.selectedCheckbox[this.currentScreen.toString()];

    if (this.guestSurveyData.disclosureSigned === this.disclosureDoc.confirmButton) {
      this.guestSurveyData.disclosureSelection = 'confirmButton';
    }
    else {
      this.guestSurveyData.disclosureSelection = 'declineButton';
    }

    this.isNextValid = true;

    this.checkKey(event);
  }

  kioskChanged() {

    if (this.guestSurveyData.guest.phoneNumber.length === 10) {
      this.isNextValid = true;
    } else {
      this.isNextValid = false;
    }
  }

  async saveGuestOffline(guestSurveyData) {
    const self = this;
    if (!this.plt.is('cordova')) {
      return;
    }

    if (!guestSurveyData) {
      console.log('Warning! The Guest information is empty.');
      return;
    }

    if (!guestSurveyData.guest.fullName && !guestSurveyData.guest.email && !guestSurveyData.guest.phoneNumber) {
      console.log('Warning! The Guest information is empty.');
      return;
    }

    try {
      guestSurveyData.guest.userId = this.auth.getUID() || '';
      guestSurveyData.guest.loanOfficer = this.currentListing.loanOfficer || '';
    }
    catch (err) {
      console.log('cannot fill in all the guest data!');
    }

    // const guestId = self.sql.generatePushID();
    const guestId = this.currentOfflineGuestId || self.sql.generatePushID();

    console.log('Saving guest to local db: ' + guestId);
    self.sql.InsertGuestInSqliteOffline(guestId, guestSurveyData, 1, 0).then(
      (res) => {
        console.log('guest saved in offline DB ' + res);
      })
      .catch(error => {
        console.log('error saving offline guest: ' + error);
        this.auth.logErrors('InsertGuestInSqliteOfflineError: ' + JSON.stringify(error) + ' GUEST INFO: ' + JSON.stringify(guestSurveyData), true);
      });
  }

  swiperStopMoving(enable: boolean) {
    this.swiper.swiperRef.allowTouchMove = !enable;
  }

  tap() {
    if (this.swiper.swiperRef.activeIndex === 0 && this.swiper1.activeIndex !== 0) {
      this.next();
    }
  }


  initSwiperSliders() {

    const self = this;

    const element = document.querySelector('.menu-button');
    this.setMenu();
    this.swiper1 = new Swiper('.swiper1', {
      slidesPerView: 'auto',
      initialSlide: 1,
      resistanceRatio: 0,
      slideToClickedSlide: true,
      noSwiping: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: false
      },
      noSwipingClass: 'swiper-slide',
      on: {
        slideChangeTransitionStart() {
          const slider = this;
          if (slider.activeIndex === 0) {
            if (element !== null) {
              element.removeEventListener('click', this.toggleMenu, true);
            }
            element.classList.add('cross');
            KioskModePage.isSlideableMenuOpen = true;
            KioskModePage.MenuEnabled = 'menu-close';
            self.isMenuOpen = true;

          } else {
            if (element !== null) {
              element.classList.remove('cross');
            }
          }
        },
        slideChangeTransitionEnd() {
          const slider = this;
          if (slider.activeIndex === 1) {
            if (element !== null) { element.addEventListener('click', this.toggleMenu, true); }
          }
          if (slider.activeIndex !== 0) {
            KioskModePage.MenuEnabled = 'menu-open';
            KioskModePage.isSlideableMenuOpen = false;
            self.isMenuOpen = false;
          }
        },
      }
    });
  }

  toggleMenu() {
    this.setMenu();
    if (this.swiper1.activeIndex === 0) {
      this.swiper1.slideNext();
    } else {
      this.swiper1.slidePrev();
    }
  };

  addEmailDomain(domain) {
    if (this.guestSurveyData.guest.email.trim().length > 0) {
      if (!this.guestSurveyData.guest.email.includes('@')) {
        this.guestSurveyData.guest.email = this.guestSurveyData.guest.email + domain.title;
      }
      else {
        this.guestSurveyData.guest.email = this.guestSurveyData.guest.email.substr(0, this.guestSurveyData.guest.email.indexOf('@'));
        this.guestSurveyData.guest.email = this.guestSurveyData.guest.email + domain.title;
      }
      this.onEmailChange(this.guestSurveyData.guest.email);
    }
    this.selectedDomain = domain.id;
    this.callKeyboard('emailKiosk');
    this.emailKiosk.setFocus();
  }

  changeDomainCallback(event) {
    console.log('Domain Value : ', this.selectedDomain);
  }

  clickOnMainView() {
    if (this.swiper1.activeIndex === 0) {
      this.toggleMenu();
    }
  }

  async showRealEstateAgentInfo(): Promise<any> {

    return new Promise(async (resolve, reject) => {

      const myAlert = await this.alertCtrl.create({
        header: 'Real Estate Agent Sign-in',
        message: this.i8nService.messages.realestateagentInfo,
        buttons: [
          {
            role: 'ok',
            text: 'OK',
            handler: data => {
              resolve(true);
            }
          },
          {
            role: 'Help',
            text: 'Referral Program',
            handler: data => {
              this.common.openLink('https://juuj.me/agent-sign-in-referral-info');
              resolve(false);
            }
          }
        ]
      });
      await myAlert.present();
    });
  }

  triggerAnimation() {
    const element = document.getElementById('highlightedChoice');

    if (element) {
      const animationFactory: AnimationFactory = this.animationBuilder.build([
        style({ opacity: 0, transform: 'scale(0.7)' }),
        animate('0.5s', style({ opacity: 1, transform: 'scale(1)' }))
      ]);

      const player: AnimationPlayer = animationFactory.create(element);
      player.play();
    }
  }
}
